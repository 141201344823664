import React, { memo, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import FilePDF from '@iconify/icons-material-symbols/picture-as-pdf-rounded';
import FileCSV from '@iconify/icons-material-symbols/file-present-rounded';
import ResetIcon from '@iconify/icons-material-symbols/restart-alt-rounded';
import SortByAlpha from '@iconify/icons-material-symbols/sort-by-alpha';
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';
import { ACTIVE_USER, StartDateForReport, DOWNLOAD_PDF, DOWNLOAD_CSV } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import EMenuPopover, { EMenuIcon, EMenuItem } from '../../BaseComponents/EMenuPopover';
import { EmployeeListData } from '../../action/EmployeeAction';
import { EInputAdornment, ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { EIconButton } from '../../BaseComponents/EButtons';
import { EBox } from '../../BaseComponents/EBox';
import { onKeyDown } from '../../utils/CommonFunctions';
import { ETooltip } from '../../BaseComponents/ETooltip';


function WithoutMemo({
  auth,
  setSearch = () => null,
  resetPage = () => null,
  downloadSearchReport = () => null,
  withStartDate = false,
  filter = null,
  startDate = null,
  minStartDate = null,
  withEndDate = false,
  endDate = null,
  maxEndDate = null,
  withEmployee = false,
  withReset = false,
  withNameSort = false,
}) {

  const dispatch = useDispatch();

  const employee = useSelector((state) => state.EmployeeReducer);
  const company = useSelector((state) => state.CompanyReducer)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Parameters, setParameters] = React.useState(filter);

  const [errorByDate, setErrorByDate] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {   //___For calling Employee List Api For Drop Down___//
    if (auth?.authtoken && withEmployee) {
      dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
    }
  }, []);

  const downloadReport = (value) => {
    downloadSearchReport(value);
    setAnchorEl(null);
  }

  const EmployeeList =
    employee && employee?.employeeData?.rows?.map((obj, i) => ({
      id: obj.id,
      label: `${obj.fullname}`,
      name: obj.fullname,
      code: obj.employee_code
    }));

  useEffect(() => {
    handleSearchFunction()
  }, [Parameters.start_date, Parameters?.employee_id, Parameters.end_date, Parameters.sort, Parameters?.employee_details]);

  const handleSearchFunction = () => {
    let Param = {
      ...Parameters,
      date: withStartDate ? Parameters.start_date : moment().startOf('month').toDate()
    }
    if (withStartDate || withEndDate) {
      if (Param.start_date && !Param.end_date && (withStartDate || withEndDate)) {
        Param = {
          ...Param,
          end_date: maxEndDate || new Date()
        };
        setErrorByDate(false);
      } else if (!Param.start_date && Param.end_date) {
        setErrorByDate(true);
      }

      if (Param.end_date && Param.start_date) {
        setErrorByDate(false);
      } else
        if (!Param.end_date || !Param.start_date) {
          Param = {
            ...Param,
            end_date: Param.end_date ? Param.end_date : null,
            start_date: Param.start_date ? Param.start_date : null
          };
        }
    }

    if (withNameSort) {
      Param = {
        ...Param,
        sort: Param.sort,
      }
    }

    if (withEmployee) {
      if (Parameters?.employee_details) {
        Param = {
          ...Param,
          employee_id: Parameters.employee_details.id,
          employee_details: Parameters.employee_details
        };
      } else {
        Param = {
          ...Param,
          employee_details: null
        };
      }
    }
    setSearch(Param);
  }

  const resetPageSearch = useCallback(() => {
    setParameters({
      start_date: startDate,
      end_date: endDate,
      employee_id: null,
      employee_details: null,
    });
    setErrorByDate(false);
    resetPage()
  }, [])

  return (<>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container alignItems="center" mb={1}>
        <Grid item xs={8} sm={9} md={9} lg={9} xl={10} alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            {/* Search dropdown of name */}
            {
              withStartDate &&
              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                <EDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={Parameters.start_date}
                  minDate={minStartDate || StartDateForReport}
                  maxDate={Parameters.end_date ? new Date(Parameters.end_date) : maxEndDate || new Date()}
                  clearable
                  onChange={(newValue) => {
                    setParameters({
                      ...Parameters,
                      start_date: newValue ? newValue : null
                    });
                  }}
                  renderInput={(params) => (
                    <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                      <ETextFieldSearch
                        size="small" fullWidth
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Start Date'
                        }}
                        onKeyDown={onKeyDown}
                        error={errorByDate || (!Parameters.start_date)}
                        helperText={errorByDate ? 'Please select start date.' : !Parameters.start_date ? 'Please select start date.' : ''}
                      />
                      {Parameters.start_date &&
                        <EInputAdornment
                          position='end'
                          onClick={() => setParameters({
                            ...Parameters,
                            start_date: null
                          })}
                          className='clear-icon'
                        >
                          <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                        </EInputAdornment>
                      }
                    </div>
                  )}
                />
              </Grid>
            }
            {
              withEndDate &&
              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                <EDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={Parameters.end_date}
                  placeholder="End Date"
                  clearable
                  minDate={
                    Parameters.start_date
                      ? new Date(Parameters.start_date)
                      : new Date(company?.companyData?.CompanyActivePlan?.plan_valid_from)
                  }
                  maxDate={maxEndDate || new Date()}
                  onChange={(newValue) => {
                    setParameters({
                      ...Parameters,
                      end_date: newValue ? newValue : null
                    });
                  }}
                  renderInput={(params) => (
                    <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                      <ETextFieldSearch
                        size="small"
                        fullWidth
                        onKeyDown={onKeyDown}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'End Date'
                        }}
                        error={!Parameters.end_date}
                        helperText={!Parameters.end_date ? 'Please select end date.' : ''}
                      />
                      {Parameters.end_date &&
                        <EInputAdornment
                          position='end'
                          onClick={() => setParameters({
                            ...Parameters,
                            end_date: null
                          })}
                          className='clear-icon'
                        >
                          <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                        </EInputAdornment>
                      }
                    </div>
                  )}
                />
              </Grid>
            }
            {
              withEmployee &&
              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                <EAutocomplete
                  name="talent"
                  label="Employee Name"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={EmployeeList && EmployeeList?.length > 0 ? EmployeeList : []}
                  value={Parameters?.employee_details}
                  getOptionLabel={(option) => option.label || ''}
                  onChange={(e, value) => {
                    setParameters({ ...Parameters, employee_id: value?.id || '', employee_details: value });
                  }}
                  renderInput={(params) => (
                    <ETextFieldSearch
                      {...params}
                      name="talent"
                      size="small" 
                      placeholder="Search By Name"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={4} sm={3} md={3} lg={3} xl={2} >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <EBox className="display-flex align-center">
              {
                withReset &&
                <ETooltip title="Reset" arrow>
                  <EIconButton
                    onClick={(e, value) => {
                      resetPageSearch()
                    }}
                  >
                    <EIcon icon={ResetIcon} />
                  </EIconButton>
                </ETooltip>
              }
              <ETooltip title="Download" arrow>
                <EIconButton size={"large"} onClick={handleClick}>
                  <EIcon icon={FileDownloadIcon} />
                </EIconButton>
              </ETooltip>
              {
                withNameSort &&
                <ETooltip title="Sort By Name" arrow>
                  <EIconButton size={"large"} onClick={() => {
                    setParameters({
                      ...Parameters,
                      sort: Parameters.sort ? false : true
                    })
                  }}>
                    <EIcon icon={SortByAlpha} />
                  </EIconButton>
                </ETooltip>
              }
            </EBox>
          </Grid>
          <EMenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            sx={{ width: 180, top: 40 }}
          >
            <EMenuItem
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
              onClick={() => downloadReport(DOWNLOAD_PDF)}
            >
              <EMenuIcon icon={FilePDF} />  PDF
            </EMenuItem>
            <EMenuItem
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
              onClick={() => downloadReport(DOWNLOAD_CSV)}
            >
              <EMenuIcon icon={FileCSV} /> CSV
            </EMenuItem>
          </EMenuPopover>
        </Grid>
      </Grid>
    </LocalizationProvider>
  </>
  )
}

export const PayrollReportSearchBar = memo(WithoutMemo)
