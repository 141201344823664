import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { EBoxPage } from "../../BaseComponents/EBox";
import { EPageWithBreadCrumbs } from '../../BaseComponents'
import SalaryPayrollDetail from "../../PageComponents/Payment/SalaryPayrollDetail";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPayrollNewDetailList } from "../../action/PayrollAction";
import base64 from 'base-64'


const SalaryByMonth = (props) => {

  const { auth } = props;
  const dispatch = useDispatch()
  const { id : _id, month } = useParams()
  const id = base64.decode(_id)
  const [showDetailPage, setShowDetailPage] = useState({ isShow: false, payrollId: id });

  const { payrollNewListDetailData, payrollNewListDetailLoading } = useSelector((state) => state.PayrollReducer)

  useEffect(()=>{
    dispatch(getPayrollNewDetailList({ payroll_id: id, is_by_month: true }))
  }, [])

  return (
    <>

    <EPageWithBreadCrumbs
      title="Salary Payment"
      pageHeading={`Month - ${month}`}
      breadcrumbsTitle="View by Month"
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Payment', href: '' },
        { name: 'Salary Payment', href: '/payment/salary-payment' },
        { name: 'View by Month' }
      ]}
    >

      <EBoxPage>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            py={3}
          >
            <Grid item xs={12} sm={12} md={11}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                  <SalaryPayrollDetail
                    payrollNewListDetailData={payrollNewListDetailData}
                    setShowDetailPage={setShowDetailPage}
                    auth={auth}
                    showDetailPage={showDetailPage}
                    id = {id}
                />
                  </Grid>
                </Grid>

            </Grid>
          </Grid>
      </EBoxPage>
    </EPageWithBreadCrumbs>
  </>
  )
}

export default SalaryByMonth;