import axios from 'axios';
import { Errors, Success, } from '../BaseComponents/EToast';
import {
  SUPER_ADMIN,
  SERVER_URL, EMP_AUTH_SUCCESS, EMP_AUTH_LOADING, EMP_AUTH_FAILURE, EMP_AUTH_LIST_FAILURE, EMP_AUTH_LIST_SUCCESS, 
  EMP_AUTH_LIST_LOADING, ACTIVE_USER, PAGES, DEFAULT_ROWS_PERPAGE
} from '../constants';
import { Logout } from './AuthAction';
import { GetTalentDetail } from './TalentActions';
import { EmployeeListData } from './EmployeeAction';

  /** 
   * [2022-11-21]
   * Author: Aanchal Sahu
   * Description:-new component
 **/
export function NewEmployeeAuthCreate(token, Data,page,  navigate, close, auth = null) {
  const data = new FormData();
  data.append('id', Data.id);
  let role = Data.role.value
  let custom_role = ""
  if (Data.role?.parent_role_name) {
    role = Data.role?.parent_role_name
    custom_role = Data.role?.value
  }
  data.append('role', role);
  data.append('custom_role', custom_role);
  data.append('is_active', Data.is_active);
  /**
   * commenting username because we are no longer allowing user's to add or update the username
   */
  // data.append('username', Data.username);
  data.append('updated_by', Data.updated_by);
  if (Data?.role?.role_name != SUPER_ADMIN) {
    data.append('reporting_manager', Data?.reporting_manager?.id);
  }
  if (Data.is_primary) {
    data.append('is_primary', Data.is_primary);
  }
  data.append('show_as_talent', Data.show_as_talent);
  if (Data.show_as_talent == true) {
    data.append('doj', Data.doj);
    data.append('department_id', Data.department_id);
    data.append('designation_id', Data.designation_id);
    data.append('payroll_basic', Data.payroll_basic);
    data.append('work_mode', Data.work_mode_id);
    if (Data.shift_id!=null) {data.append('shift_id', Data.shift_id);}
  }
  return (dispatch) => {
    dispatch(empAuthLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/employee-create-user`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        // close the modal first
        if (close) { close() }
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          // if is_primary is true before save
          if (Data.is_primary) {
            dispatch(Logout())
          } else {
            dispatch(empAuthSuccess(res.data));
            dispatch(EmployeeAuthListData(token, ACTIVE_USER, page, DEFAULT_ROWS_PERPAGE, Data.search))
          }
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(empAuthFailure(error.response.data));
          if (close) { close() }
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function empAuthLoading() {
  return {
    type: EMP_AUTH_LOADING,
  };
}
export function empAuthSuccess(payload) {
  return {
    type: EMP_AUTH_SUCCESS,
    payload
  };
}

export function empAuthFailure(payload) {
  return {
    type: EMP_AUTH_FAILURE,
    payload
  };
}

export function empAuthListLoading() {
  return {
    type: EMP_AUTH_LIST_LOADING,
  };
}
export function empAuthListSuccess(payload) {
  return {
    type: EMP_AUTH_LIST_SUCCESS,
    payload
  };
}

export function empAuthListFailure(payload) {
  return {
    type: EMP_AUTH_LIST_FAILURE,
    payload
  };
}


export function EmployeeAuthListData(token, status = 10, page = null, rowsPerPage = null, search = null, navigate = null) {

  let params = `auth_list=true&user_status=${status}`;
  if (page !== null) {
    params = params + `&page=${page}`
  }
  if (rowsPerPage) {
    params = params + `&per_page=${rowsPerPage}`
  }
  if (search?.name) {
    params = params + `&fullname=${search?.name}`
  }
  if (search?.designation) {
    params = params + `&designation=${search?.designation}`
  }
  if (search?.department) {
    params = params + `&department=${search?.department}`
  }
  if (search?.sort) {
    params = params + `&sort=${search?.sort}`
  }
  if (search?.role) {
    params = params + `&role=${search?.role}`
  }
  if (search?.form_list !== undefined && search?.form_list !== null) {
    params = params + `&form_list=${search?.form_list}`
  }
  if (search?.is_active) {
    params = params + `&is_active=${search?.is_active}`
  }

  var url = `${SERVER_URL}/employee/employee-list?${params}`

  return (dispatch) => {
    dispatch(empAuthListLoading());
    axios({
      method: 'get',
      url,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(empAuthListSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(empAuthListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}
// export function NewSuperAdminCreate(token, Data, close) {
//   const data = new FormData();
//   data.append('fname', Data.fname);
//   data.append('lname', Data.lname);
//   data.append('mobile', Data.mobile);
//   data.append('email', Data.email);
  
//   if (Data.is_primary == true) {
//     data.append('is_primary', Data.is_primary);
//   }
//   data.append('show_as_talent', Data.show_as_talent);

//   if (Data.show_as_talent == true) {
//     data.append('doj', Data.doj);
//     data.append('department_id', Data.department_id);
//     data.append('designation_id', Data.designation_id);
//     data.append('payroll_basic', Data.payroll_basic);
//     data.append('work_mode', Data.work_mode_id);
//   }
//   return (dispatch) => {
//     dispatch(empAuthLoading());
//     axios({
//       method: 'post',
//       url: `${SERVER_URL}/user/add-super-admin`,
//       headers: {
//         "Authorization": 'Bearer ' + token,
//       },
//       data
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           Success.fire({
//             text: "html",
//             html: `<span style="color:white">${res.data.message}</span>`,
//           })
//           dispatch(empAuthSuccess(res.data));
//           dispatch(EmployeeAuthListData(token, ACTIVE_USER, PAGES, DEFAULT_ROWS_PERPAGE, Data.search))
//           close()
//         }
//       }).catch((error) => {
//         if (error?.response) {
//           Errors.fire({
//             text: "html",
//             html: `<span style="color:white">${error.response.data.message}</span>`,
//           })
//           dispatch(empAuthFailure(error.response.data));
//           if (error?.response?.status == 401) {
//             dispatch(Logout())
//           }
//         }
//       });
//   };
// }

// INFO: This action is used to reset the password in user management
export function NewPasswordForUser(token, Data,page, close) {
  const data = new FormData();
  data.append('employee_id', Data.employee_id);
  // data.append('new_password', Data.new_password);
  // data.append('confirm_password', Data.confirm_password);

  return (dispatch) => {
    dispatch(empAuthLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/reset-employee-password`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(empAuthSuccess(res.data));
          dispatch(EmployeeAuthListData(token, ACTIVE_USER, page, DEFAULT_ROWS_PERPAGE, Data.search))
          close()
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(empAuthFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

// INFO: This action is used to change the primary super admin
export function  ChangePrimarySuperAdminAction(token, Data,page, close) {
  const data = new FormData();
  data.append('employee_id', Data.employee_id);

  return (dispatch) => {
    dispatch(empAuthLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/update-prmiary-superadmin`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(empAuthSuccess(res.data));
          dispatch(EmployeeAuthListData(token, ACTIVE_USER, page, DEFAULT_ROWS_PERPAGE, Data.search))
          close()
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(empAuthFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}