import axios from 'axios';
import moment from 'moment';
import { Success, Errors } from '../BaseComponents/EToast';
import {
  SERVER_URL, EMPLOYEE_PROFILE_LOADING, EMPLOYEE_PROFILE_SUCCESS, EMPLOYEE_PROFILE_FAILURE,
  EMPLOYEE_PAYMENT_FAILURE, EMPLOYEE_PAYMENT_LOADING, EMPLOYEE_PAYMENT_SUCCESS, EMPLOYEE_FORM_LOADING, EMPLOYEE_FORM_FAILURE, EMPLOYEE_FORM_SUCCESS, EMP_LEAVE_LOG_LOADING, EMP_LEAVE_LOG_SUCCESS, EMP_LEAVE_LOG_FAILURE, 
  EMPLOYEE_LOADING,EMPLOYEE_SUCCESS, EMPLOYEE_FAILURE, RELEASED_USER, EMPLOYEE_CARD_LIST, PAGES, ADHAAR_DOC_ID, PAN_DOC_ID, DRIVING_LICENCE_DOC_ID, PASSPORT_DOC_ID, GET_ACCOUNT_SETTING_API, SETTING_ACCOUNT
} from '../constants';
import { Logout } from './AuthAction';
import { AllEmployeeList, EmployeeListData } from './EmployeeAction';
import { CompanyDetail } from './CompanyAction';
import { GetCompanySetting } from './SettingAction';
// import { UserAvatarData } from './UserAvatarAction';

export function CreateTalent(token, Data, navigate,onboarding) {

  const data = new FormData();

  data.append('user_check', Data.user_check);
  data.append('personal_photo_check', Data.personal_photo_check);
   
  data.append('created_by', Data.created_by);
  data.append('lname', Data.lname);
  data.append('fname', Data.fname);
  data.append('email', Data.email);
  data.append('mobile', Data.mobile);
  
  //Official Detail
  data.append('doj', moment(Data.doj).format('YYYY-MM-DD'));
  data.append('designation', Data.designation);
  if (Data.shift_id !== null) {
    data.append('shift_id', Data.shift_id);
  }
  data.append('department', Data.department);
  data.append('company_id', Data.company_id);
  data.append('payroll_basic', Data.payroll_basic);
  data.append('work_mode', Data.work_mode);

  // user login
  if (Data.user_check) {
    let role = Data.role.value
    let custom_role = ""
    if (Data.role?.parent_role_name) {
      role = Data.role?.parent_role_name
      custom_role = Data.role?.value
    }
    data.append('role', role);
    data.append('custom_role', custom_role);
    // data.append('username', Data.username);
    data.append('reporting_manager_id', Data?.reporting_manager?.id);
  }

  if (Data.personal_photo_check &&  Data.personal_photo){ data.append('personal_photo', Data.personal_photo);}

  //Personal Details
  if (Data.personal_detail_check) {
    if(Data.personal_gender &&  Data.personal_gender) { data.append('personal_gender', Data.personal_gender);}
    if(Data.personal_dob){data.append('personal_dob', moment(Data.personal_dob).format('YYYY-MM-DD'));}
    if(Data.personal_marital_status){data.append('personal_marital_status', Data.personal_marital_status);}
    if(Data.personal_blood_group){data.append('personal_blood_group', Data.personal_blood_group);}
    if(Data.personal_address_one){data.append('personal_address_one', Data.personal_address_one);}//address
    if (Data.landmark) { data.append('landmark', Data.landmark);}//landmark 
    if (Data.personal_state_id) { data.append('personal_state_id', Data.personal_state_id);}
    if (Data.personal_district_id) { data.append('personal_district_id', Data.personal_district_id);}
    if (Data.personal_pin_code) { data.append('personal_pin_code', Data.personal_pin_code);}
  }

  if (Data.documents_check) {
    Data.doc_array.forEach((e, i) => {
      const t = `${e.document_id}^${e.document_id_no}`;
      if (e.file) {
        data.append('files', e.file, t);
      }
    });
    }

  // Bank Detail Check
  if (Data.bank_detail_check) {
    if (Data.ifsc_code) {data.append('bank_ifsc_code', Data.ifsc_code);}
    if (Data.bank_name) {data.append('bank_name', Data.bank_name);}
    if (Data.branch_name) {data.append('bank_branch_name', Data.branch_name);}
    if (Data.account_no) {data.append('bank_account_no', Data.account_no);}
    if (Data.bank_account_holder_name) {data.append('bank_account_holder_name', Data.bank_account_holder_name);}
  }

  // Emergency Contact Detail Check
  if (Data.emergency_contact_detail_check) {
    if (Data.emr_contact_mobile) {data.append('emr_contact_mobile', Data.emr_contact_mobile);}
    if (Data.emr_person_name) {data.append('emr_person_name', Data.emr_person_name);}
    if (Data.emr_contact_relation) {data.append('emr_contact_relation', Data.emr_contact_relation);}
  }


  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/add`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(employeeFormSuccess(res.data))
          dispatch(GetCompanySetting(token, GET_ACCOUNT_SETTING_API, SETTING_ACCOUNT))//Calling this API here so that the total allowed count of employee can stay updated
          dispatch(AllEmployeeList(token))//INFO : All Employee list is for the dropdowns, hence calling it to update it with the new employee
          if(onboarding){
            dispatch(CompanyDetail(token)) // INFO: Calling to get chnages during onboarding
          }
         
          navigate('/employee/employee-list', { replace: true });
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function CreateHRProfile(token, Data,navigate) {
  const data = new FormData();
  data.append('personal_photo_check', Data.personal_photo_check);
  data.append('doj', moment(Data.doj).format('YYYY-MM-DD'));
  data.append('designation', Data.designation);
  if (Data.shift_id !== null) {
    data.append('shift_id', Data.shift_id);
  }
  data.append('department', Data.department); 
  data.append('payroll_basic', Data.payroll_basic);
  data.append('work_mode', Data.work_mode);

  if (Data.personal_photo_check &&  Data.personal_photo){ data.append('personal_photo', Data.personal_photo);}
  
  //Personal Details
  if (Data.personal_detail_check) {
    if(Data.personal_gender &&  Data.personal_gender) { data.append('personal_gender', Data.personal_gender);}
    if(Data.personal_dob){data.append('personal_dob', moment(Data.personal_dob).format('YYYY-MM-DD'));}
    if(Data.personal_marital_status){data.append('personal_marital_status', Data.personal_marital_status);}
    if(Data.personal_blood_group){data.append('personal_blood_group', Data.personal_blood_group);}
    if(Data.personal_address_one){data.append('personal_address_one', Data.personal_address_one);}//address
    if (Data.landmark) { data.append('landmark', Data.landmark);}//landmark 
    if (Data.personal_state_id) { data.append('personal_state_id', Data.personal_state_id);}
    if (Data.personal_district_id) { data.append('personal_district_id', Data.personal_district_id);}
    if (Data.personal_pin_code) { data.append('personal_pin_code', Data.personal_pin_code);}
  }

  if (Data.documents_check) {
    Data.doc_array.forEach((e, i) => {
      const t = `${e.document_id}^${e.document_id_no}`;
      if (e.file) {
        data.append('files', e.file, t);
      }
      // console.log(data, 't', t);
    });
    }

  // Bank Detail Check
  if (Data.bank_detail_check) {
    if (Data.ifsc_code) {data.append('bank_ifsc_code', Data.ifsc_code);}
    if (Data.bank_name) {data.append('bank_name', Data.bank_name);}
    if (Data.branch_name) {data.append('bank_branch_name', Data.branch_name);}
    if (Data.account_no) {data.append('bank_account_no', Data.account_no);}
    if (Data.bank_account_holder_name) {data.append('bank_account_holder_name', Data.bank_account_holder_name);}
  }

  // Emergency Contact Detail Check
  if (Data.emergency_contact_detail_check) {
    if (Data.emr_contact_mobile) {data.append('emr_contact_mobile', Data.emr_contact_mobile);}
    if (Data.emr_person_name) {data.append('emr_person_name', Data.emr_person_name);}
    if (Data.emr_contact_relation) {data.append('emr_contact_relation', Data.emr_contact_relation);}
  }


  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/hr-profile-update`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(employeeFormSuccess(res.data))
          dispatch(CompanyDetail(token))
          navigate('/setup')
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          // if (error?.response?.status == 401) {
          //   dispatch(Logout())
          // }
        }
      });
  };
}


export function employeeFormLoading() {
  return {
    type: EMPLOYEE_FORM_LOADING,
  };
}
export function employeeFormSuccess(payload) {
  return {
    type: EMPLOYEE_FORM_SUCCESS,
    payload
  };
}

export function employeeFormFailure(payload) {
  return {
    type: EMPLOYEE_FORM_FAILURE,
    payload
  };
}

export function GetTalentDetail(token, empid, navigate = null, next = () => {}) {
  const data = new FormData();
  data.append('id', empid);

  return (dispatch) => {
    dispatch(employeeDetailLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/get-user-profile`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(employeeDetailSuccess(res.data))
          next(res.data)
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeDetailFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function employeeDetailLoading() {
  return {
    type: EMPLOYEE_PROFILE_LOADING,
  };
}
export function employeeDetailSuccess(payload) {
  return {
    type: EMPLOYEE_PROFILE_SUCCESS,
    payload
  };
}
export function employeeDetailFailure(payload) {
  return {
    type: EMPLOYEE_PROFILE_FAILURE,
    payload
  };
}



export function TalentPaymentList(token, employee_id, date = null, payment_category = null, page_name = null, page = null, pageSize = null, year_month = null, sort=null) {
  let queryString = '';
  queryString += `&page=${page}&per_page=${pageSize}&employee_id=${employee_id}`;

  if (payment_category && date) {
    let dateformat = moment(date).format('yy-MM-DD')
    queryString += `&payment_category=${payment_category}&date=${dateformat}`;
  }
  else if (payment_category && !date) {
    queryString += `&payment_category=${payment_category}`;
  }
  else if (date && !payment_category) {
    let dateformat = moment(date).format('yy-MM-DD')
    queryString += `&date=${dateformat}`;
  }
  if (page_name) {
    queryString += `&page_name=${page_name}`;
  }
  if (sort != null) {
    queryString += `&sort=${sort}`;
  }
  if (year_month) {
    queryString += `&year_month=${moment(year_month).format('YYYYMM')}`;
  }

  return (dispatch) => {

    dispatch(employeePaymentLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payment/payment-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(employeePaymentSuccess(res.data))
        }
      }).catch((error) => {

        if (error?.response) {
          dispatch(employeePaymentFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
          // above code was commented before
        }
      });
  };
}

export function employeePaymentLoading() {
  return {
    type: EMPLOYEE_PAYMENT_LOADING,
  };
}
export function employeePaymentSuccess(payload) {
  return {
    type: EMPLOYEE_PAYMENT_SUCCESS,
    payload
  };
}
export function employeePaymentFailure(payload) {
  return {
    type: EMPLOYEE_PAYMENT_FAILURE,
    payload
  };
}

export function updateBasicDetails(token, Data, navigate) {
  const data = new FormData();
  data.append('id', Data.talent_id);

  if(Data.lname){data.append('lname', Data.lname);}
  data.append('fname', Data.fname);
  data.append('email', Data.email);
  data.append('mobile', Data.mobile);

  if (Data.personal_photo) { data.append('personal_photo', Data.personal_photo); }

  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/update-employee-basic-detail`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(GetTalentDetail(token, res.data.data.id, navigate));
          dispatch(employeeFormSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      })
  }

}
/**
 * [2022-10-19]
 * Changes By:- Gaurav Singh
 * Description:- Commented unwanted data ie, personal_marital_status and applied condition on appending personal_blood_group
 */

export function updateTalentPersonalDetail(token, Data, navigate) {
  const data = new FormData();
  data.append('id', Data.talent_id);

  if(Data.personal_gender){data.append('personal_gender', Data.personal_gender);}
  if(Data.personal_dob){data.append('personal_dob', moment(Data.personal_dob).format('YYYY-MM-DD'));}
  if(Data.personal_marital_status){data.append('personal_marital_status', Data.personal_marital_status);}
  if(Data.personal_blood_group){data.append('personal_blood_group', Data.personal_blood_group);}
  if(Data.personal_address_one){data.append('personal_address_one', Data.personal_address_one);}//address
  if (Data.landmark) { data.append('landmark', Data.landmark);}//landmark 
  if (Data.personal_state_id) { data.append('personal_state_id', Data.personal_state_id);}
  if (Data.personal_district_id) { data.append('personal_district_id', Data.personal_district_id);}
  if (Data.personal_pin_code) { data.append('personal_pin_code', Data.personal_pin_code);}
  
  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/update-employee-personal-detail`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(GetTalentDetail(token, res.data.data.id, navigate));
          dispatch(employeeFormSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}
export function empLeaveLoading() {
  return {
    type: EMP_LEAVE_LOG_LOADING,
  };
}
export function empLeaveSuccess(payload) {
  return {
    type: EMP_LEAVE_LOG_SUCCESS,
    payload
  };
}
export function empLeaveFailure(payload) {
  return {
    type: EMP_LEAVE_LOG_FAILURE,
    payload

  }
}

export function updateTalenEmergency(token, Data, navigate) {
  const data = new FormData();
  // data.append('emr_person_name', Data.emr_person_name);
  // data.append('emr_contact_mobile', Data.emr_contact_mobile);
  // data.append('emr_contact_relation', Data.emr_contact_relation);
  if (Data.emr_contact_mobile) {data.append('emr_contact_mobile', Data.emr_contact_mobile);}
  if (Data.emr_person_name) {data.append('emr_person_name', Data.emr_person_name);}
  if (Data.emr_contact_relation) {data.append('emr_contact_relation', Data.emr_contact_relation);}
  data.append('id', Data.talent_id);

  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/update-employee-emergency-detail`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(GetTalentDetail(token, res.data.data.id, navigate));
          dispatch(employeeFormSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

// INFO: This Api is used for update & delete documents of a employee
export function updateDocument(token, Data, navigate, isDelete=null) {

  const url= !isDelete? `update-employee-document`: `delete-document`;
  const data = new FormData();

  data.append('document_id', Data.document_id);
  data.append('employee_id', Data.talent_id);

  if (!isDelete) { //new updated on - 24-4-23
    data.append('file', Data.file);
    data.append('document_id_no', Data.document_id_no);
  }
 
  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/${url}`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(employeeFormSuccess(res.data))
          dispatch(GetTalentDetail(token,  Data.talent_id, navigate));

        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}



export function updateTalenBankInformation(token, Data, navigate) {
  const data = new FormData();
  // data.append('bank_ifsc_code', Data.bank_ifsc_code);
  // data.append('bank_name', Data.bank_name);
  // data.append('bank_branch_name', Data.bank_branch_name);
  // data.append('bank_account_no', Data.bank_account_no);
  // data.append('bank_account_holder_name', Data.bank_account_holder_name);

  if (Data.bank_ifsc_code) {data.append('bank_ifsc_code', Data.bank_ifsc_code);}
  if (Data.bank_name) {data.append('bank_name', Data.bank_name);}
  if (Data.bank_branch_name) {data.append('bank_branch_name', Data.bank_branch_name);}
  if (Data.bank_account_no) {data.append('bank_account_no', Data.bank_account_no);}
  if (Data.bank_account_holder_name) {data.append('bank_account_holder_name', Data.bank_account_holder_name);}

  data.append('id', Data.talent_id);
  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/update-employee-bank-detail`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(GetTalentDetail(token, res.data.data.id, navigate));
          dispatch(employeeFormSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function DeleteTalentAction(token, TalentId, navigate, status,Data=null) {
  const data = new FormData();
  data.append('uid', TalentId);
  data.append('employee_status', status)
  data.append('trash_remark', Data.trash_remark)
  
  return (dispatch) => {
    dispatch(employeeLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/employee-trash`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(employeeSuccess(res.data))
          if(status == RELEASED_USER){
            navigate('/employee/released-employee-list', { replace: true });
          }else {
            navigate('/employee/employee-list', { replace: true });
          }          
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


//__INFO: This action used to get called from leave Log List by since the design has beel changed, this is not getting called__
// export function LeaveLogList(token, id, page = null, pageSize = null) {
//   let queryString = '';
//   if (id) {
//     queryString += `?employee_id=${id}`;
//   }
//   return (dispatch) => {
//     dispatch(empLeaveLoading());
//     axios({
//       method: 'get',
//       url: `${SERVER_URL}/employee/employee-leave-log${queryString}`,
//       crossDomain: true,
//       headers: {
//         "Authorization": 'Bearer ' + token,
//       },
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           dispatch(empLeaveSuccess(res.data))
//         }
//       }).catch((error) => {

//         if (error?.response) {
//           dispatch(empLeaveFailure(error.response.data));
//           if (error?.response?.status == 401) {
//             dispatch(Logout())
//           }
//           // above code was commented before
//         }
//       });
//   };
// }

export function updateTaletOfficialDetail(token, Data, navigate, close=null) {
  const data = new FormData();
  data.append('doj', moment(Data.doj).format('YYYY-MM-DD'));
  data.append('department', Data.department);
  data.append('designation', Data.designation);
  data.append('id', Data.talent_id);
  data.append('payroll_basic', Data.payroll_basic);
  data.append('work_mode', Data.work_mode);
  if (Data.shift_id !== null) {
    data.append('shift_id', Data.shift_id);
  }
  
  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/update-employee-official-detail`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          if(close){close();}
          dispatch(GetTalentDetail(token, res.data.data.id, navigate));
          dispatch(employeeFormSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          if(close){close();}
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function employeeLoading() {
  return {
    type: EMPLOYEE_LOADING,
  };
}
export function employeeSuccess(payload) {
  return {
    type: EMPLOYEE_SUCCESS,
    payload
  };
}
export function employeeFailure(payload) {
  return {
    type: EMPLOYEE_FAILURE,
    payload
  };
}

// INFO: This API is made to  release employee
export function releaseTalent(token, Data,navigate) {
  const data = new FormData();
  data.append('last_working_date', moment(Data.last_working_date).format('YYYY-MM-DD'));
  data.append('transfer_type', Data.transfer_type);
  data.append('employee_id', Data.employee_id);
  data.append('company_id', Data.company_id);
  data.append('reason', Data.reason);
  if( Data.attachment_file){data.append('attachment_file', Data.attachment_file);}
  data.append('noc_file', Data.noc_file);
 
  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/employee-release`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          navigate('/employee/employee-list', { replace: true });
          dispatch(AllEmployeeList(token)) //INFO: calling all employee list which is used for searching
          dispatch(GetCompanySetting(token, GET_ACCOUNT_SETTING_API, SETTING_ACCOUNT))//Calling this API here so that the total allowed count of employee can stay updated
          // dispatch(employeeFormSuccess(res.data))
          // dispatch(EmployeeListData(token, RELEASED_USER,null, PAGES, EMPLOYEE_CARD_LIST)); //INFO: calling released list
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          // dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      })
  }

}

// INFO: This API is made to restore the released employee
export function restoreReleasedTalent(token, id, navigate) {
  const data = new FormData();
  data.append('employee_id', id);

  return (dispatch) => {
    dispatch(employeeFormLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/employee/restore`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(employeeFormSuccess(res.data))
          dispatch(EmployeeListData(token, RELEASED_USER,null, PAGES, EMPLOYEE_CARD_LIST)); //INFO: calling released list
          dispatch(AllEmployeeList(token)) //INFO: calling all employee list which is used for searching
          dispatch(GetCompanySetting(token, GET_ACCOUNT_SETTING_API, SETTING_ACCOUNT))//Calling this API here so that the total allowed count of employee can stay updated
          navigate('/employee/employee-list')
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(employeeFormFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      })
  }

}