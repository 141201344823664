import axios from 'axios';
import moment from 'moment';
import {
  EMPLOYEE_LOADING, EMPLOYEE_SUCCESS, EMPLOYEE_FAILURE, SERVER_URL, ACTIVE_USER, ALL_EMPLOYEE_LOADING, ALL_EMPLOYEE_SUCCESS, ALL_EMPLOYEE_FAILURE, NULL_STRING
} from '../constants';
import { Logout } from './AuthAction';


// INFO: the NULL_STRING condition is for employee dropdown in manual attendance
export function EmployeeListData(token, status = ACTIVE_USER, search = null, page = null, rowsPerPage = null, navigate =null) {
 
    let params = status!=NULL_STRING?`user_status=${status}`:``;

  if (page !== null) {
    params = params + `&page=${page}`
  }
  if (rowsPerPage) {
    params = params + `&per_page=${rowsPerPage}`
  }
  if (search?.name) {
    params = params + `&fullname=${search?.name}`
  }
  // if (data) {
  //   params = params + `&employee_id=${data}`
  // }
  if (search?.year_month) {  //INFO: This is for the month picker filter in released and archived employee list
    let date=moment(search?.year_month).format('YYYYMM')
    params = params + `&talent_left_yearmonth=${date}`
  }
  if (search?.designation) {
    params = params + `&designation=${search?.designation}`
  }
  if (search?.department) {
    params = params + `&department=${search?.department}`
  }
  if (search?.sort === true || search?.sort === false) {
    params = params + `&sort=${search?.sort}`
  }
  if(search?.is_active){
    params = params + `&auth_list=${search?.is_active}`
  }
 else{
    params = params + `&auth_list=false`
  }
  if (search?.form_list) {
    params = params + `&form_list=${search?.form_list}`
  }
  if (search?.month_year) {
    params = params + `&month_year=${search?.month_year}`
  }
  if (search?.role) {
    params = params + `&role=${search?.role}`
  }

  var url = `${SERVER_URL}/employee/employee-list?${params}`

  return (dispatch) => {
    dispatch(employeeLoading());
    axios({
      method: 'get',
      url,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(employeeSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(employeeFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}



export function employeeLoading() {
  return {
    type: EMPLOYEE_LOADING,
  };
}
export function employeeSuccess(payload) {
  return {
    type: EMPLOYEE_SUCCESS,
    payload
  };
}

export function employeeFailure(payload) {
  return {
    type: EMPLOYEE_FAILURE,
    payload
  };
}


//INFO: This Api collects All Employee List, will be called when login, refresh, or whenever any eploye's profile has been updated 
export function AllEmployeeList(token) {

  var url = `${SERVER_URL}/employee/employee-list`
  
  return (dispatch) => {
    dispatch(allEmployeeLoading());
    axios({
      method: 'get',
      url,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(allEmployeeSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(allEmployeeFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function allEmployeeLoading() {
  return {
    type: ALL_EMPLOYEE_LOADING,
  };
}
export function allEmployeeSuccess(payload) {
  return {
    type: ALL_EMPLOYEE_SUCCESS,
    payload
  };
}
export function allEmployeeFailure(payload) {
  return {
    type: ALL_EMPLOYEE_FAILURE,
    payload
  };
}


