import React, { useState } from 'react';
import { ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import { EBox } from '../../BaseComponents/EBox';
import { EButtonOutlined } from '../../BaseComponents/EButtons';
import SearchIcon from "@iconify/icons-mdi/search";
import RightIcon from '@iconify/icons-material-symbols/chevron-right-rounded';
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";
import { ETypography } from '../../BaseComponents/ETypography';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { GlobalSearchConfig } from './GlobalSearchConfig';


// Main GlobalSearch component
function GlobalSearch(props) {
  const { setOpen, auth } = props;

  // Fetch items based on user's auth
  const { items } = GlobalSearchConfig(auth);

  // State to manage search term
  const [searchValue,setSearchValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')



  // Handle search term change
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    let timeoutId;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setSearchTerm(event.target.value);
    }, 1200);
  }

  // Handle close action
  const handleClose = () => {
    setOpen(false);
    setSearchTerm('');
    setSearchValue('')
  };

  // Filter paths based on search term
  const filteredPaths = items.flatMap((item) =>
    item.paths.filter((path) =>
    path.show &&
   path.title.toLowerCase().includes(searchTerm.toLowerCase()) ||  path.show && path.heading.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Filter default paths
  const defaultPaths = items?.filter((item) => item.default);

  return ( 
    <EBox maxHeight={'560px'} sx={{backgroundColor:'#FBFBFB'}}>
      {/* Search Field */}
      <ETextFieldSearch
        placeholder="I am looking for"
        fullWidth
        autoComplete='off'
        value={searchValue}
        onChange={(e) => handleSearch(e)}
        InputProps={{
          startAdornment: <EIconInputAdornment icon={SearchIcon} />,
          endAdornment: searchTerm && <EIconInputAdornment className='cursor-pointer' onClick={() => {
            setSearchTerm('')
            setSearchValue('')
          }
          } icon={CancelIcon} />

        }}
      />

      {/* Display search results */}
      <EBox className='flex-column' sx={{backgroundColor:'#FBFBFB'}} maxHeight={'100%'} paddingBottom={1}>
        <EBox margin={1}>
          {searchTerm?.length !== 0 ? (
            filteredPaths.length !== 0 ? (
              filteredPaths?.map((item, index) => {
                return (
                  // SEARCHED ITEM

                  item.show && <EBox key={index} >
                    <SearchedItem item={item} handleClose={handleClose} border={index !== filteredPaths.length - 1} />
                  </EBox>


                )
              }
              )
            ) : (

              // NO DATA FOUND
              <EBox overflow={'clip'} height={'400px'} className='flex-column-allCenter' marginY={2}>
                <img height={'95px'} width={'128px'} src="/assets/images/SearchNoData.svg" alt="No result found" />
                <ETypography className='font-size-24px bold-600 grey-color-static mt-16px'>
                  Oops! No result found for {`"${searchTerm}"`}
                </ETypography>
              </EBox>
            )
          ) : (
            // DEFAULT PATHS
            defaultPaths?.map((item, index) => (
              <DynamicColumnLayout key={index} heading={item?.defaultHeading} paths={item?.paths} setOpen={setOpen} />
            ))
          )}
        </EBox>
        {/* Close Button */}
        <EBox marginTop={2} className="flex-row-horizontaRight">
          <EButtonOutlined onClick={handleClose} variant="outlined">Close</EButtonOutlined>
        </EBox>
      </EBox>
    </EBox>
  );
}

export default GlobalSearch;

// Component to render dynamic columns for paths
export const DynamicColumnLayout = ({ heading, paths, setOpen }) => {
  const filteredPaths = paths.filter((item) => item.show)
  const middleIndex = Math.ceil(paths.length / 2);
  const column1 = filteredPaths.slice(0, middleIndex);
  const column2 = filteredPaths.slice(middleIndex);


  return (
    <div>
      <ETypography className='mt-16px' variant="h6">{heading}</ETypography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ul>
            {column1.map((path, index) => (
              <Link key={index} onClick={() => setOpen(false)} to={`${path?.route}`}>
                <li className='ml-24px cursor-pointer font-size-14px bold-400 theme-color-static mb-8px'>
                  {path.title}
                </li>
              </Link>
            ))}
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <ul>
            {column2.map((path, index) => (
              <Link key={index} onClick={() => setOpen(false)} to={`${path?.route}`}>
                <li className='ml-24px cursor-pointer font-size-14px bold-400 theme-color-static mb-8px'>
                  {path.title}
                </li>
              </Link>
            ))}
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};
  
  // Component to render searched items
export const SearchedItem = ({ item, handleClose, border }) => {
  return (
    <Link onClick={() => handleClose()} to={`${item?.route}`}>
      <EBox

        padding={2}
        marginTop={1}
        className={`flex-row-spaceBetween globalSearchCard ${border ? 'globalSearchCardBorder' : ''   }`} // Apply the class for the last item
      >
        <ETypography>
          {item?.heading} |{' '}
          <span className='cursor-pointer font-size-14px bold-400 theme-color-static mb-8px'>
            {item?.title}
          </span>
        </ETypography>
        <EIcon cursor={'pointer'} icon={RightIcon} />
      </EBox>
    </Link>
  )


};