import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import currencyRupeeIcon from '@iconify/icons-material-symbols/currency-rupee'
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import blockIcon from '@iconify/icons-material-symbols/block'
import SimCardIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded'
import AlternateMailIcon from '@iconify/icons-material-symbols/alternate-email'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { numberWithCommas } from '../../utils/formatNumber';
import {
  DEFAULT_ROWS_PERPAGE, ROW_SIZE_PER_PAGE,
  CANCELLED_STATUS,
  PENDING_STATUS,
  PARTIAL_STATUS,
  PAID_STATUS,
  PARTIALLY_PAID_STATUS,
  PAYMENT_TRANSACTION_STATUS_CHIP,
  ROLE_HR,
  ROLE_ADMIN,
  SUPER_ADMIN,
  ACCOUNTANT,
  SALARY_PAYMENT_TRANSACTION_STATUS,
  SALARY_STATUS,
  PAYROLL_DETAIL_DOWNLOAD
} from '../../constants';

import EChip from '../../BaseComponents/EChip';
import ActionMenu from './ActionMenu';
import { EMenuItem } from '../../BaseComponents/EMenuPopover';
import { EIcon } from '../../BaseComponents/EIcon';
import EModal from '../../BaseComponents/EModal';
import PaymentModal from './PaymentModal';
import PaymentCancelModal from './PaymentCancelModal';
import { getPayrollNewDetailList } from '../../action/PayrollAction';
import { EDataGrid } from '../../BaseComponents';
import { PaymentSearchFilter } from './PaymentSearchFilter';
import SalaryStatusDetalViewModal from './SalaryPaidViewModal';
import { ETypography } from '../../BaseComponents/ETypography';
import { EButton } from '../../BaseComponents/EButtons';
import { useNavigate } from 'react-router-dom';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ETable, ETableContainer } from '../../BaseComponents/ETable';
import SearchNotFound from '../SearchNotFound';
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { DownLoadFile } from '../../action/AdminAction';

const initialBulkSelectedValue = {
  ids: [], data: []
}

function SalaryPayrollDetail(props) {

  const { auth, showDetailPage, id } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { payrollNewListDetailData, payrollNewListDetailLoading } = useSelector((state) => state.PayrollReducer)

  const IS_HR = auth?.authData?.role == ROLE_HR
  const IS_SUPERIOR = auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == SUPER_ADMIN || auth?.authData?.role == ACCOUNTANT

  const initialBulkSelectedValue = {
    ids: [], total_amount: 0, payroll_id: showDetailPage?.payrollId, payment_ids: []
  };

  const [selectedData, setSelectedData] = useState(initialBulkSelectedValue)
  const SelectedRowsIds = useRef([])
  const SelectedRowsIdsData = useRef([])

  const [searchParam, setSearchParam] = useState({
    employee_id: null,
    payroll_id: null,
    sort: null,
    status: null
  });

  const [page, setPage] = useState(0);
  const [salaryDueModal, setSalaryDueModal] = useState({ modalData: null, modalCategory: false });
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

  const callApi = (fnParams) => {
    const params = {
      employee_id: fnParams?.employee?.id ? fnParams?.employee?.id : null,
      sort: fnParams?.sort === null ? null : fnParams?.sort ? "ASC" : "DESC",
      transaction_status: fnParams?.status ? fnParams?.status?.map(item => item.id) : null
    }
    dispatch(getPayrollNewDetailList({ ...params, payroll_id: id }))
  }

  useEffect(() => {
    if (auth.authtoken) {
      callApi({ ...searchParam });
    }
  }, [])

  function onSearch(data) {
    setSearchParam(data)
    callApi(data);
  }

  const actionEvent = (params1, params2) => {
    setSalaryDueModal({ modalData: params1, modalCategory: params2 });
  }

  const rows = gridRows(payrollNewListDetailData);
  const columns = gridColumns(actionEvent, IS_SUPERIOR, IS_HR);

  const SetSelectedIds = (ids) => {
    SelectedRowsIds.current[page] = ids
    const reduceData = payrollNewListDetailData?.filter((item) => ids.includes(item.id))

    const arrayMainIds = SelectedRowsIds.current.flat();
    SelectedRowsIdsData.current[page] = reduceData;

    const arrayMainIdsData = SelectedRowsIdsData.current.flat();

    setSelectedData({ data: arrayMainIdsData, ids: arrayMainIds });
  }

  const DownloadReport = (type) => {
    const employee_id = searchParam?.employee?.id ? searchParam?.employee?.id : null;
    const sort = searchParam?.sort === null ? null : searchParam?.sort ? "ASC" : "DESC";
    const transaction_status = searchParam?.status ? searchParam?.status?.map(item => item.id) : [];
    const query_tr_status = transaction_status.map(value => `${'transaction_status'}=${encodeURIComponent(value)}`).join('&');
    let params = {
      download_format: type,
      payroll_id: id,
      ...(employee_id && { employee_id }),
      ...(sort && { sort }),
    };
    let queryString = new URLSearchParams(params).toString();
    if (query_tr_status) {
      queryString = `${queryString}&${query_tr_status}`
    }
    const url = `${PAYROLL_DETAIL_DOWNLOAD}?${queryString}`;
    DownLoadFile(auth.authtoken, url, "payroll-payment-report")
  }

  return (
    <>


      <Grid item sm={12} md={12} my={2}>

        <EBox sx={{ marginTop: '-24px', marginLeft: '-24px' }}>

          <EButton
            onClick={() => navigate('/payment/salary-payment/bulk-salary-payment', { state: selectedData })}
            sx={{ marginBottom: '16px' }}
            disabled={selectedData?.ids?.length === 0}
          >
            Bulk Payment
          </EButton>

          <PaymentSearchFilter
            DownloadReport={DownloadReport}
            initialFilter={searchParam}
            onSearch={onSearch}
            onSort={onSearch}
            showTalents={true}
            status={SALARY_STATUS}
          />
          <div style={{ width: '100%', marginTop: '16px' }}>
            <EBoxPage className='p0' sx={{ padding: 0, margin: 0 }}>
              {
                payrollNewListDetailData?.length == 0
                  ?
                  <SearchNotFound />
                  :
                  <EDataGrid
                    rows={rows}
                    getRowId={(row) => row?.id}
                    columns={columns}
                    checkboxSelection={IS_SUPERIOR ? true : false}
                    isRowSelectable={(params) =>
                      params?.row?.Status == PENDING_STATUS || params?.row?.Status == PARTIALLY_PAID_STATUS || params?.row?.Status == PARTIAL_STATUS
                    }
                    hideDisabledCheckbox={true}
                    loading={payrollNewListDetailLoading}
                    onSelectionModelChange={(ids) => {
                      SetSelectedIds(ids)
                    }}
                    selectionModel={selectedData?.ids}
                    keepNonExistentRowsSelected
                  />
              }
            </EBoxPage>

          </div>
        </EBox>
      </Grid>

      {salaryDueModal && salaryDueModal.modalCategory == PAID_STATUS &&
        <EModal open={(salaryDueModal.modalCategory == PAID_STATUS)}
          headervalue={`Payment - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
        >
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
              <PaymentModal
                paymentData={salaryDueModal.modalData}
                setSalaryDueModal={setSalaryDueModal}
                getPaymentFilterData={() => callApi(searchParam, 0, rowsPerPage)}
                auth={auth}
              />
            </Grid>
          </Grid>
        </EModal >}



      {
        salaryDueModal && salaryDueModal.modalCategory == CANCELLED_STATUS &&
        <EModal open={(salaryDueModal.modalCategory == CANCELLED_STATUS)}
          headervalue={`Cancel Payment - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}

        >
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
              <PaymentCancelModal
                paymentData={salaryDueModal.modalData}
                setSalaryDueModal={setSalaryDueModal}
                getPaymentFilterData={() => callApi(searchParam, 0, rowsPerPage)}
                auth={auth}
              />
            </Grid>
          </Grid>
        </EModal>
      }

      {
        salaryDueModal && salaryDueModal.modalCategory == 'VIEW' &&
        <EModal open={(salaryDueModal.modalCategory == 'VIEW')}
          headervalue={`Salary Details - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
        >
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
              <SalaryStatusDetalViewModal
                paymentData={salaryDueModal.modalData}
                setSalaryDueModal={setSalaryDueModal}
                auth={auth}
              />
            </Grid>
          </Grid>
        </EModal>
      }

    </>
  );
}

const gridRows = (payrollNewListDetailData) => {

  const rows =
    payrollNewListDetailData
      ?
      payrollNewListDetailData?.map((data, index) => {

        const { balance_amount, cancelled_amount, employeeData, childTransaction, id, paid_amount, pay_amount, payrollData, transaction_date, transaction_status, updated_at } = data
        return ({
          ...data,
          SNo: index + 1,
          PayableAmt: pay_amount ? numberWithCommas(pay_amount) : numberWithCommas(0),
          PaidAmt: paid_amount ? numberWithCommas(paid_amount) : numberWithCommas(0),
          BalanceAmt: balance_amount ? numberWithCommas(balance_amount) : numberWithCommas(0),
          CancelledAmt: cancelled_amount ? numberWithCommas(cancelled_amount) : numberWithCommas(0),
          UpdatedOn: moment(updated_at).format('DD/MM/YYYY'),
          Status: transaction_status
        })
      })
      : [];
  return rows;
};

const gridColumns = (clickEventFun, IS_SUPERIOR, IS_HR) => {


  let gridClm = [
    {
      field: 'SNo',
      headerName: 'SNO.',
      minWidth: 50,
      headerClassName: 'super-app-theme--header',
      flex: 2,
      editable: false,
      sortable: false,
      hide: IS_SUPERIOR ? true : false
    },

    {
      field: 'PayableAmt',
      headerName: 'Payable Amt',
      minWidth: 130,
      maxWidth: 130,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 2,
      editable: false,
      sortable: false,
    },
    {
      field: 'PaidAmt',
      headerName: 'Paid Amt.',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      minWidth: 130,
      maxWidth: 130,
      sortable: false,
      editable: false,
    },
    {
      field: 'BalanceAmt',
      headerName: 'Balance Amt.',
      type: 'number',
      minWidth: 130,
      maxWidth: 130,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      editable: false
    },
    {
      field: 'CancelledAmt',
      headerName: 'Cancelled Amt.',
      type: 'number',
      minWidth: 130,
      maxWidth: 130,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      editable: false
    },
    {
      field: 'UpdatedOn',
      headerName: 'Updated On',
      type: 'number',
      minWidth: 110,
      maxWidth: 110,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      editable: false
    },

  ];

  gridClm.splice(1, 0,
    {
      field: 'TalentName',
      headerName: 'Employee Name',
      minWidth: 150,
      headerClassName: 'super-app-theme--header',
      flex: 2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ETooltip arrow title={params?.row?.employeeData?.fullname ? params?.row?.employeeData?.fullname : '-'}>
            <EBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <ETypography variant="subtitle1" noWrap className="cursor-pointer font-size-14px text-capitalize blue-color" >
                {params?.row?.employeeData?.fullname ? params?.row?.employeeData?.fullname : '-'} <br />
              </ETypography>
              <ETypography variant="subtitle1" noWrap className="cursor-pointer font-size-14px text-capitalize blue-color font-weight-400-with-imp" sx={{ marginTop: '-3px' }}>
                ({params?.row?.employeeData?.employee_code ? params?.row?.employeeData?.employee_code : '-'})
              </ETypography>
            </EBox>
          </ETooltip>
        )
      }
    },);

  gridClm.push({
    field: 'Status',
    headerName: 'Status',
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    sortable: false,
    minWidth: 120,
    renderCell: (params, value) => {

      return (
        <Grid className="display-flex align-items-center">
          {params?.row?.Status ? (
            <EChip
              fontSize='12px'
              label={SALARY_PAYMENT_TRANSACTION_STATUS[params?.row?.Status] || ''}
              width="100px"
              className={PAYMENT_TRANSACTION_STATUS_CHIP[params?.row?.Status]}
            />
          ) : (
            '-'
          )}

        </Grid>
      );
    }
  });

  gridClm.push({
    field: 'Action',
    headerName: 'Action',
    headerClassName: 'super-app-theme--header',
    sortable: false,
    minWidth: 80,
    renderCell: (params, value) => {

      return (
        <>
          <EIcon icon={VisibilityIcon} onClick={() => clickEventFun(params?.row, "VIEW")} sx={{ cursor: 'pointer' }} />
          {
            [PARTIAL_STATUS, PENDING_STATUS, PARTIALLY_PAID_STATUS].includes(params?.row?.Status)
            &&
            <>
              {
                IS_SUPERIOR
                &&
                <ActionMenu>
                  {/* {useCheckPermission([PERMISSIONS.SideBarSalaryPayment]) && */}
                  <EMenuItem onClick={() => clickEventFun(params?.row, PAID_STATUS)}>
                    <ListItemIcon>
                      <EIcon icon={currencyRupeeIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Pay Salary" />
                  </EMenuItem>
                  <EMenuItem onClick={() => clickEventFun(params?.row, CANCELLED_STATUS)}>
                    <ListItemIcon>
                      <EIcon icon={blockIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Cancel Salary" />
                  </EMenuItem>
                </ActionMenu>
              }
              {
                // (useCheckPermission([PERMISSIONS.SideBarSalaryPayment]) || useCheckPermission([PERMISSIONS.ViewAndCancelSalary]))
                IS_HR
                &&
                <ActionMenu>
                  <EMenuItem onClick={() => clickEventFun(params?.row, CANCELLED_STATUS)}>
                    <ListItemIcon>
                      <EIcon icon={blockIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Cancel Salary" />
                  </EMenuItem>
                </ActionMenu>
              }


            </>
          }


          {
            (params?.row?.Status == PAID_STATUS)
            &&
            <ActionMenu>

              <EMenuItem onClick={() => null}>
                <ListItemIcon>
                  <EIcon icon={SimCardIcon} />
                </ListItemIcon>
                <ListItemText primary="Salary Slip" />
              </EMenuItem>

              <EMenuItem onClick={() => null}>
                <ListItemIcon>
                  <EIcon icon={AlternateMailIcon} />
                </ListItemIcon>
                <ListItemText primary="Email Salary Slip" />
              </EMenuItem>

            </ActionMenu>
          }

          {
            (params?.row?.Status == CANCELLED_STATUS)
            &&
            <ActionMenu >

              <EMenuItem onClick={() => null} >
                <ListItemIcon>
                  <EIcon icon={AlternateMailIcon} />
                </ListItemIcon>
                <ListItemText primary="Email Details" />
              </EMenuItem>

            </ActionMenu>
          }
        </>

      )
    }
  });

  return gridClm;
};


export default SalaryPayrollDetail;