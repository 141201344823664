import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import search from '@iconify/icons-material-symbols/search'
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable';
import { DEFAULT_ROWS_PERPAGE, ROW_SIZE_PER_PAGE, SUPPORT_STATUS } from '../../constants';
import {  EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EInputAdornment, ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EDivider } from '../../BaseComponents/EDivider';
import { EBoxPage } from '../../BaseComponents/EBox';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import EModal from '../../BaseComponents/EModal';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import { SupportList } from '../../action/SupportAction';
import AddTicket from '../../PageComponents/Support/AddTicket';
import moment from 'moment';
import EChip from '../../BaseComponents/EChip';
import { capitalizeCapitalString } from '../../utils/formatText';
import base64 from 'base-64'
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import AddTicketIcon from '@iconify/icons-material-symbols/confirmation-number-outline';

const TABLE_HEAD = [
    { id: 'Department', label: 'Department', alignRight: false },
    { id: 'TicketNumber', label: 'Ticket Number', alignRight: 'center' },
    { id: 'Subject', label: 'Subject', alignRight: false },
    { id: 'Status', label: 'Status', alignRight: 'center' },
    { id: 'Updated On', label: 'Updated On', alignRight: 'center' },
];

function SupportTickets(props) {
    const { auth } = props;
    const navigate = useNavigate()
    const support = useSelector((state) => state.SupportReducer);

    const dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const [modalState, setModalState] = useState({ modalData: null, modalName: null });
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

    const [SearchParameters, setSearchParameters] = useState({
        sort: true,
        employee_id: null,
        ticket_no: null
    });

    const getFilterData = (searchData) => {
        callApi({ ...searchData, sort: !searchData.sort ? ' id ASC ' : 'id DESC' }, 0, rowsPerPage);
        setPage(0);
        setModalState({ modalData: null, modalName: null });
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...SearchParameters, employee_id: auth.authData.id }, page, rowsPerPage);
            setSearchParameters({ ...SearchParameters, employee_id: auth.authData.id });
        }
    }, [auth?.authtoken])

    const handleChangePage = (event, newPage) => {
        callApi(SearchParameters, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(SearchParameters, 0, rowPerPageCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(SupportList(auth.authtoken, { ...fnParams, sort: !fnParams.sort ? ' id ASC ' : 'id DESC' }, fnPage, fnRowPerPage));
    }

    const setSelectedRow = (row) => {
        navigate(`/support/ticket/${base64.encode(row.id)}`)
    }
 
    return (
        <Page title="Support" >
            <Grid container display="row" justifyContent="space-between" className='align-center'>
                <Grid item >
                    <EHeaderBreadcrumbs
                        heading='Contact Support'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Support', href: '' },
                            {
                                name: 'Contact Support',
                            }
                        ]}
                    /></Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButton variant="contained"
                            onClick={() => setModalState({ modalData: null, modalName: 'CREATE' })}
                        >  Generate Ticket</EButton>
                        <EButtonOutlined className='button-left-margin' variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid container display="row"
                spacing={2}
                className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                <Grid item xs={9} sm={9} md={3} lg={3} xl={2.5}>
                    <ETypographyPageHeading variant='h5'>Contact Support</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item xs={3} sm={3} className='display-flex justify-content-flex-end'>
                    <EButtonIcon  onClick={() => setModalState({ modalData: null, modalName: 'CREATE' })} variant="contained" className='button-left-margin'>
                            <EIcon icon={AddTicketIcon} className='height-width-15px'/>
                        </EButtonIcon>
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                            <EIcon icon={ForwardArrow} className='height-width-15px'/>
                        </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
                <Grid item xs={7} sm={6} md={4} lg={4} xl={2.5}>
                    <ETextFieldSearch
                        fullWidth
                        size='small'
                        placeholder='Search '
                        onBlur={(e) => { getFilterData(e.target.value ? { ...SearchParameters, ticket_no: e.target.value } : SearchParameters) }}
                        InputProps={{
                            startAdornment: <EInputAdornment position="start"><EIconInputAdornment icon={search}></EIconInputAdornment></EInputAdornment>,
                        }}
                    />
                </Grid>
                <EHidden width="smDown">  <Grid xs={12} lg={12} item><EDivider className='' /></Grid></EHidden>
            </Grid>
            <EBoxPage>
                <EScrollbar>
                    <ETableContainer >
                        <ETable>
                            {support?.supportListLoading &&
                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            }
                            {!support?.supportListLoading && support?.supportListData?.rows?.length > 0 && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={support?.supportListData?.rows?.length}
                                    onRequestSort={() => null}
                                />
                                <ETableBody>

                                    {support.supportListData.rows?.map((row, index) => {
                                        const { id, support_category, ticket_code, support_title, support_transaction_status, updated_at } = row;
                                        let status = SUPPORT_STATUS[support_transaction_status];
                                        return (
                                            <ETableRow
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                onClick={() => setSelectedRow(row)}
                                                className='cursor-pointer'
                                            >

                                                <ETableCell align="left" sx={{ textTransform: 'capitalize', width: '5rem' }} >
                                                    {support_category}
                                                </ETableCell>
                                                <ETableCell align="center" >
                                                    {ticket_code}
                                                </ETableCell>
                                                <ETableCell align="left" >
                                                    {support_title}
                                                </ETableCell>
                                                <ETableCell align="center" >
                                                    <EChip label={capitalizeCapitalString(status)} />
                                                </ETableCell>
                                                <ETableCell align="center" >
                                                    {moment(updated_at).format("DD/MM/YYYY hh:mm A")}
                                                </ETableCell>
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            </>}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {!support?.supportListLoading && support?.supportListData?.rows?.length == 0 && (
                    <SearchNotFound />
                )}
            </EBoxPage>
            {!support?.supportListLoading && support?.supportListData?.count > DEFAULT_ROWS_PERPAGE &&
                <ETablePagination
                    rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                    component="div"
                    count={support?.supportListData?.count ? support?.supportListData?.count : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }

            {modalState.modalName &&
                <EModal open={modalState.modalName === 'CREATE'} headervalue={`New Ticket`}>
                    <AddTicket
                        setModalState={setModalState}
                        modalState={modalState}
                        getPaymentFilterData={() => getFilterData(SearchParameters)}
                        auth={auth}
                    />
                </EModal>
            }
        </Page>
    );
}
export default SupportTickets