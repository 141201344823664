import React from 'react'
import { EBox } from '../../BaseComponents/EBox';
import { ETypography } from '../../BaseComponents/ETypography';

function PaymentFailed() {
    return (

        <EBox className='text-align'>
            <img height='150px' width='150px' className="mb-16px" src='/assets/images/warning.svg' />
            <ETypography className="font-size-24px bold-600 text-align mb-16px">
                Payment Failed !!
            </ETypography>

            <ETypography className="font-size-16px bold-400 text-align mb-16px">
                Your payment was unable to process due to some technical glitch.Please try again for the same.


            </ETypography>

            <ETypography>
                If any amount is deducted, it will be reverted back into your account within 7 working days.
            </ETypography>




        </EBox>

    )
}

export default PaymentFailed