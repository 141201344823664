import React from 'react'
import Page from '../../../BaseComponents/EPage'
import { Grid } from '@mui/material'
import { AuthRootStyle } from '../../../BaseComponents/EContainer'
import { AuthNavbar } from '../../../layouts/auth-navbar'
import { EBox } from '../../../BaseComponents/EBox'
import { ECard } from '../../../BaseComponents/ECard'
import { ETypography } from '../../../BaseComponents/ETypography'
import { EIconOnboarding } from '../../../BaseComponents/EIcon';
import SupportAgentIcon from '@iconify/icons-material-symbols/support-agent-rounded'
import PlayCircleIcon from '@iconify/icons-material-symbols/play-circle-outline-rounded'
import ExploreIcon from '@iconify/icons-material-symbols/explore-outline-rounded'
import { EButton } from '../../../BaseComponents/EButtons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { onBoardingStepAction } from '../../../action/OnboardingStepAction'
import { ROLE_HR, SUPER_ADMIN } from '../../../constants'

function OnboardingOptions({auth,fromSupport}) {
    const navigateTo = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const company = useSelector((state) => state.CompanyReducer);
    const obData = company.companyData?.onBoarding;
    const role = auth?.authData?.role

    return (
        <Page title="Choose a Option">
            <AuthRootStyle maxWidth="lg">
                {
                    (fromSupport || location?.state?.fromSetup) ?  null : <AuthNavbar/>
                }

                    
           
                <EBox marginTop={'28px'} maxWidth={'747px'} marginX={'auto'}>
                    <ECard className='py-24px '>
                        {/* Heading */}
                        <ETypography className='color-text-primary mb-24px font-size-24px bold-600 text-align'>
                            Choose how you want to experience Entera
                        </ETypography>

                        {/* OPTIONS CONTAINER*/}
                        <Grid container justifyContent={'center'} spacing={3}>
                            <Grid item md={4} >
                                <ECardOnboarding className='text-align p-16px' sx={{ width: '217px', height: '233px' }} >
                                    <EBox>
                                        <EIconOnboarding icon={SupportAgentIcon} />
                                        <EBox>
                                            <ETypography className='bold-600 font-size-16px mb-8px '>
                                                Personalised Support
                                            </ETypography>

                                            <ETypographyOnboarding >
                                                Our team is available to assist you throughout your onboarding process.
                                            </ETypographyOnboarding>
                                        </EBox>
                                    </EBox>

                                    <EBox>
                                        <EButtonOnboarding onClick={() => navigateTo('/schedule-a-call',{state:{
                                            showNavbar:(fromSupport || location?.state?.fromSetup) ? false : true
                                        }})}>
                                            Schedule a Call
                                        </EButtonOnboarding>
                                    </EBox>


                                </ECardOnboarding>

                            </Grid>

                           {
                            location?.state?.fromSetup && 
                            <Grid item md={4} >
                                <ECardOnboarding className='text-align p-16px' sx={{ height: '233px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <EBox>


                                        <EIconOnboarding icon={PlayCircleIcon} />
                                        <EBox>
                                            <ETypography className='bold-600 font-size-16px mb-8px'>
                                                Demo Videos
                                            </ETypography>

                                            <ETypographyOnboarding>
                                                Entera Payroll provides demo videos to guide you through the setup process.
                                            </ETypographyOnboarding>
                                        </EBox>
                                    </EBox>
                                    <EBox>



                                        <EButtonOnboarding 
                                            onClick={() => navigateTo('/watch-demo',{state:{
                                                showNavbar:(fromSupport || location?.state?.fromSetup) ? false : true
                                            }})} 
                                            className='mt-24px'
                                        >
                                            Watch Videos
                                        </EButtonOnboarding>
                                    </EBox>
                                </ECardOnboarding>

                            </Grid>
                           }                                
                          

                           

                        {
                            (role == SUPER_ADMIN && obData?.explore_welcome_initial_category_superadmin != 'EXPLORE' && !obData?.onboarding_is_completed  )
                            &&
                            <Grid item md={4} >
                            <ECardOnboarding className='text-align p-16px' sx={{ height: '233px' }} >
                                <EBox>
                                    <EIconOnboarding icon={ExploreIcon} />
                                    <EBox>
                                        <ETypography className='bold-600 font-size-16px mb-8px'>
                                            Explore by Self
                                        </ETypography>

                                        <ETypographyOnboarding >
                                            Explore Entera Payroll on your own.

                                        </ETypographyOnboarding>
                                    </EBox>
                                </EBox>
                                <EBox>
                                    <EButtonOnboarding 
                                        className='mt-24px'
                                        onClick={() => dispatch(onBoardingStepAction(auth?.authtoken, 'EXPLORE'))} >
                                        Explore
                                    </EButtonOnboarding>
                                </EBox>



                            </ECardOnboarding>

                            </Grid> 
                        }

                        {
                            (role == ROLE_HR && obData?.explore_welcome_initial_category_hr != 'EXPLORE' && !obData?.onboarding_is_completed )  
                            &&
                            <Grid item md={4} className='ml-24px'>
                            <ECardOnboarding className='text-align p-16px' sx={{ height: '233px' }} >
                                <EBox>
                                    <EIconOnboarding icon={ExploreIcon} />
                                    <EBox>
                                        <ETypography className='bold-600 font-size-16px mb-8px'>
                                            Explore by Self
                                        </ETypography>

                                        <ETypographyOnboarding >
                                            Explore Entera Payroll on your own.

                                        </ETypographyOnboarding>
                                    </EBox>
                                </EBox>
                                <EBox>
                                    <EButtonOnboarding 
                                        className='mt-24px'
                                        onClick={() => dispatch(onBoardingStepAction(auth?.authtoken, 'EXPLORE'))} >
                                        Explore
                                    </EButtonOnboarding>
                                </EBox>



                            </ECardOnboarding>

                            </Grid> 
                        }
                         


                        </Grid>

                    </ECard>


                </EBox>


            </AuthRootStyle>


        </Page>
    )
}

export default OnboardingOptions

const EButtonOnboarding = ({ children,onClick }) => {
    return (
        <EButton sx={{ minWidth: '170px' }} onClick={onClick} >
            {children}
        </EButton>
    )
}

const ETypographyOnboarding = ({ children }) => {
    return (
        <ETypography sx={{ maxWidth: '164px', margin: 'auto', minHeight: '53px' }} className='font-size-12px text-align bold-400 line-height-17px pb-24px'>
            {children}

        </ETypography>
    )
}

const ECardOnboarding = ({ children }) => {
    return (
        <ECard className='text-align p-16px' sx={{ height: '233px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {children}

        </ECard>
    )
}

