import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Grid } from '@mui/material';
import { EHelperText, ETypography } from './ETypography';
import { ERadio, ERadioGroup } from './ERadio';
import { EBox } from './EBox';

/**
 * INFO: This component provides a custom radio button with title and/or subtitle 
 */
export const ECustomizedRadioWithTitle = React.memo((props) => {

  const { label, extraLabel, name, labelClassName = "", value, onChange = () => { }, error = "", options = [], disabled = false, withOutCheckBox = false } = props;

  return (
    <EBox display="flex" justifyContent="space-between" width="100%" >
      <EBox>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <span className={`bold-600 font-size-16px ${labelClassName}`}>{label}</span>
            <br />
          </Grid>
          {
            !!extraLabel
            &&
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <span className='greyColor4-color font-size-16px'>
                {extraLabel}
              </span>
            </Grid>
          }
        </Grid>
      </EBox>
      <EBox className='py0 text-align-right'>
        <FormControl component="fieldset" >
          {
            !withOutCheckBox &&
            <ERadioGroup
              row={true}
              aria-label={label}
              name={name}
              value={value}
              sx={{ flexWrap: 'nowrap ' }}
            >
              {
                options?.map(item => (
                  <FormControlLabel
                    value={item?.value}
                    key={item?.value}
                    sx={{ margin: '0' }}
                    disabled={disabled}
                    control={
                      <ERadio
                        className='py-2px px-6px'
                        onChange={() => onChange(item?.value)}
                      />
                    }
                    label={<ETypography className='font-size-16px' sx={{ whiteSpace: 'nowrap' }} >{item?.label}</ETypography>}
                  />
                ))
              }
            </ERadioGroup>
          }
        </FormControl>
        {
          error && !value ?
            <EHelperText className='color-text-error-red' sx={{ whiteSpace: 'nowrap' }}>{error}</EHelperText>
            :
            ''
        }
      </EBox>
    </EBox>
  );
});

ECustomizedRadioWithTitle.propTypes = {
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  extraLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};
