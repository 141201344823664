import {useEffect} from 'react'
import { Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material"
import Page from "../../BaseComponents/EPage"
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum"
import EHidden from "../../BaseComponents/EHidden"
import { EButton, EButtonOutlined, EButtonOutlinedIcon, EIconButton } from "../../BaseComponents/EButtons"
import { Link, useNavigate } from "react-router-dom"
import { EIcon } from "../../BaseComponents/EIcon"
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography"
import { EDivider } from "../../BaseComponents/EDivider"
import { EBox } from "../../BaseComponents/EBox"
import fullscreenOnIcon from '@iconify/icons-material-symbols/zoom-out-map-rounded';
import fullscreenOffIcon from '@iconify/icons-material-symbols/zoom-in-map-rounded';
import DownloadIcon from '@iconify/icons-material-symbols/download-rounded';
import UploadIcon from '@iconify/icons-material-symbols/upload-rounded';
import ListIcon from '@iconify/icons-material-symbols/list-alt-outline-rounded';
import FileIcon from '@iconify/icons-material-symbols/file-present-outline-rounded';
import WarningIcon from '@iconify/icons-material-symbols/warning-outline-rounded';
import CheckIcon from '@iconify/icons-material-symbols/check-circle-outline-rounded';
import React, { useState } from "react"
import { ECard } from "../../BaseComponents/ECard"
import EScrollbar from "../../BaseComponents/EScrollbar"
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable"
import { useDropzone } from "react-dropzone";
import { usePapaParse } from 'react-papaparse';
import { ETooltip } from "../../BaseComponents/ETooltip"
import { useDispatch, useSelector } from "react-redux"
import DepartmentReducer from "../../reducers/DepartmentReducer"
import { ROLE_OPTIONS, WORK_MODE } from "../../constants"
import EModal from '../../BaseComponents/EModal';
import { BulkTalentUploadAction } from "../../action/BulkImportAction"
import { useCompanySettingProvider } from "../../context/CompanySettingContext"
import moment from "moment"
import { EmployeeListData } from "../../action/EmployeeAction"
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';
import GroupIcon from '@iconify/icons-material-symbols/group-outline';

import { useCallback } from "react"
import { DesignationList } from "../../action/DesignationAction"
import { DepartmentList } from "../../action/DepartmentAction"
import DepartmentForm from '../Masters/DepartmentForm'
import DesignationForm from '../Masters/DesignationForm'






const tableHead = () => {
    let heads = [];
    heads.push(
        { id: "Error", label: "Error", alignRight: "center" },
        { id: "S.No.", label: "S.No.", alignRight: "center" },
        { id: "First Name", label: "First Name", alignRight: "center" },
        { id: "Last Name", label: "Last Name", alignRight: "center" },
        { id: "Email ID", label: "Email ID", alignRight: "center" },
        { id: "Contact Number", label: "Contact Number", alignRight: "center" },
        { id: "DOJ", label: "DOJ", alignRight: "center" },
        { id: "CTC/Month", label: "CTC/Month", alignRight: "center" },
        { id: "Department", label: "Department", alignRight: "center" },
        { id: "Designation", label: "Designation", alignRight: "center" },
        { id: "Work Mode", label: "Work Mode", alignRight: "center" },
        { id: "Role", label: "Role", alignRight: "center" },

    );
    return heads;
};



/**
 * [2023-05-02]
 * Made By: Purva Sharma
 * Description:- Use: Will be used when new company onboards and no employee is added, then user can bulk upload the employee
 *             - Packages used: react-dropzone: https://react-dropzone.js.org/ (Drag and Drop component)
 *                              react-papaparse: to convert csv file to json
 * 
 *                                 
 **/


function BulkTalentUpload() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const setting = useCompanySettingProvider()
    const department = useSelector(state => state.DepartmentReducer);
    const company = useSelector(state => state.CompanyReducer);
    const designation = useSelector(state => state.DesignationReducer);
    const planDetail = useSelector(state => state.CompanyReducer);
    const employeeListData = useSelector(state => state.EmployeeReducer);
    const auth = useSelector((state) => state.authReducer);
    const [fullscreenActive, setFullScreenActive] = useState(false)

    const [openDepartmentModal,setOpenDepartmentModal]=useState(false)
    const [openDesignationModal,setOpenDesignationModal]=useState(false)

    const [alert, setAlert] = useState({ openModal: false, msg: '' })
    const [csvData, setCsvData] = useState({});
    const [CSVError, SetCSVError] = useState([]);
    const { readString } = usePapaParse();
    const [tableRow, setTableRow] = useState([])

    const [myFiles, setMyFiles] = useState([])

// ONDrop: to handle drag and drop file upload
  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
  }, [myFiles])

  const {  getRootProps, getInputProps } = useDropzone({
    onDrop
  });


//   removing the file 
  const removeFile = file => () => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
    SetCSVError([])
    setCsvData([])
    setTableRow([])
  }


    // Regex 
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*)?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
    const dojRegex =    /^(0?[1-9]|[1-2][0-9]|3[01])[/](0?[1-9]|1[0-2])[/]\d{4}$/
    const digit = /^\d+$/;
    const nameRegExp = /^[a-zA-Z][a-zA-Z ]*$/;




    // TO handle Upload
    const handleUpload = () => {
        if (CSVError.length > 0) {
            SetCSVError([])
            setCsvData([])
            setTableRow([])
        }

        getAsText(myFiles)
    }


   
    // Converting the file as text
    const getAsText = (myFiles) => {
        var reader = new FileReader();
        reader.readAsText(myFiles[0]);
        reader.onload = fileReadingFinished; // Reading the file 
        reader.onerror = errorHandler;  // Error if file is unreadable
    }


    const errorHandler = (event) => {
        if (event.target.error.name === "NotReadableError") {
            alert("Cannot read file!");
        }
    }


    const fileReadingFinished = (event) => {
        var csv = event.target.result;
        processData(csv); //Processing the CSV data to check for errors in rows and columns
    }

    // Generating table row
    const genrateTableRow = (data) => {
        let sliceFrom = data[2]?.includes('DD/MM/YYYY') ? 4 : 1
        let dataIndex = data[0]?.includes('First Name', 'Last Name') ? 0 : 1
        // Slicing and mapping the table as per the smaple file
        let row = [];
        data.slice(sliceFrom).map((item) => {
            return row.push(item)
        })
        const result = row.map(item => {
            return data[dataIndex].reduce((obj, key, i) => {
                obj[key] = item[i];
                return obj;
            }, {});
        });
        // validating each row and setting the  Errors on CSVError state
        result?.forEach((row, index) => {
            // validate each row of data
            const errorMessage = validateRow(Object.entries(row));
            if (errorMessage) {
                // if the row fails validation, add an error message to the errors array
                SetCSVError((prev) => [...prev, { row: index, msg: errorMessage.filter((item) => item !== true) }])
            }
        });
        setTableRow(result)
    }

    const processData = (csv) => {
        readString(csv, {
            worker: true,
            skipEmptyLines: true,
            complete: function (results) {
                const data = results.data;
                let slicedData = data[2]?.includes('DD/MM/YYYY') ? data.slice(4) : data.slice(1)
                const errors = [];
                if (myFiles[0].type !== 'text/csv') { // checking for correct extension
                    setAlert({ openModal: true, msg: <ETypography className="font-size-16px bold-400">Invalid File extension.</ETypography> })
                } else if (data[0].length !== 11) { // total number of column [11] as per the csv format
                    setAlert({
                        openModal: true, msg:
                            <ETypography className="font-size-16px bold-400">Invalid File format. </ETypography>
                    })
                } else if (slicedData.length > planDetail?.companyData?.CompanyActivePlan?.allowed_talent) { //Cheking file as per plan
                    setAlert({
                        openModal: true, msg:
                            <ETypography className="font-size-16px bold-400">
                                Your plan validates only <span className="bold-600 theme-color-static">[{planDetail?.companyData?.CompanyActivePlan?.allowed_talent}]</span>  active users. <br /> To add more employee, please upgrade your plan.
                            </ETypography>
                    })
                }
                else {
                    genrateTableRow(data)
                }
                // render the table with the validated data
                setCsvData(() => data)
            },
        });
    }


    // Function to validate row
    function validateRow(row) {
        return row.map((item) => {
            return validateRowFields(item)
        })
    }

    // Function to validate each field
    function validateField(value, regexPattern) {
        if (value[1].trim() === '') {
            return `${value[0]} is Required !`;
        } else if (!regexPattern.test(value[1].trim())) {
            return `${value[0]} is Invalid !`;
        } else {
            return null
        }
    }

    const validateDOJ = (value) => { //  removed the date of joining validation as per disucssion 
        let checkEmpty =  validateField(value,dojRegex)
        if(checkEmpty == null){
            let compareDate = moment(value[1],"DD/MM/YYYY ")
            let startDate = moment(setting?.setting?.accountSettingData?.company_data?.CompanyActivePlan?.plan_valid_from)
            let endDate = moment();
            return !startDate.isSame(compareDate, "day") && !compareDate.isBetween(startDate,endDate)  ? `DOJ does not matches with subscription date`  : null
        }else if(checkEmpty !== ''){
            return checkEmpty
        }else{
            return null
        }
    }
    // Function to validate duplicate values i.e ['contact number', 'email id']
    const validateDuplicate = (value,type)=>{
        const list = employeeListData?.allEmployeeData?.rows
        return list?.findIndex((item)=>item[type] == value[1]) >= 0 || tableRow?.findIndex((item)=>item[type] == value[1]) >= 0
    }

    
    // Calling HandleUpload everytime a new designation/department is added to remove the error msg
    useEffect(()=>{
        if((!department?.departmentListLoading || !designation?.designationListLoading ) && myFiles.length !== 0){
         handleUpload()
        }
    },[department?.departmentListLoading,designation?.designationListLoading])


    // Switch Case to validate each field
    const validateRowFields = (value) => {
        switch (value[0]) {
            case 'S.No.':
                return validateField(value, digit)
            case 'Email ID':
                return  validateField(value,emailRegExp )
            case 'Contact Number':
                return  validateField(value,phoneRegExp )
            case 'Date of Joining':
                return  validateField(value,dojRegex)
            case 'CTC/Month':
                return validateField(value, digit)
            case 'Department':
                return value[1].trim() === '' ? 'Department is Required!' :  !department?.departmentListLoading && !department?.departmentListData?.rows.filter((item) => item.department_name.toLowerCase() == value[1].toLowerCase()).length > 0 ? 'Department does not exist!' : null 
            case 'Designation':
                return value[1].trim() === '' ? 'Designation is Required!' : designation?.designationListData?.rows !== undefined  && !designation?.designationListData?.rows.filter((item) => item.designation_name.toLowerCase() == value[1].toLowerCase()).length > 0 ? 'Designation does not exist!' : null
            case 'Work Mode':
                return value[1].trim() === '' ? 'Work Mode is Required!' : !WORK_MODE?.filter((item) => item.label == value[1]).length > 0 ? 'Work mode does not exist!' : null
            case 'Role':
                return value[1].trim() === '' ? 'Role is Required!' : !ROLE_OPTIONS.filter((item) => item.label == value[1]).length > 0 ? 'Role does not exist!' : null
            case 'First Name':
                return validateField(value, nameRegExp)
            case 'Last Name':
                return validateField(value, nameRegExp)
            default:
                return null
        }
    }




    // Checking if a row has an error and set return the icon and error msg
    const checkRowHasError = (index) => {
        let hasError = false;
        let errorMsg = ''
        CSVError.forEach(item => {
            if (item.row === index && item.msg.length !== 0) {
                hasError = true;
                errorMsg = item.msg.filter((item) => item !== null)
            }

        });
        if (hasError && errorMsg.length !== 0) {
            return (
                <ETooltip className="tooltip-error" title={errorMsg.map((item, index) => { return (<p key={index}>{item}</p>) })} arrow>
                    <EIcon className={'icon-red'} icon={WarningIcon} />
                </ETooltip>
            );
        } else {
            return <EIcon className={'icon-green'} icon={CheckIcon} />;
        }
    }


    //    Function to handle cancel
    const handleCancel = () => {
        setTableRow([])
        setCsvData([])
        SetCSVError([])
        navigate(-1)
    }


    //Function to create the object to send as data to API
    function createEmployeeObj(row) {
        const employee = {
            "emp_fname": row['First Name'],
            "emp_lname": row['Last Name'],
            "emp_email": row['Email ID'],
            "emp_contact": row['Contact Number'],
            "emp_doj": row['Date of Joining'],
            "emp_ctc": row['CTC/Month'],
            "emp_department": row['Department'],
            "emp_designation": row['Designation'],
            "emp_work_mode": row['Work Mode'].toUpperCase(),
            "emp_role": row['Role']
        };
        return employee;
    }

    // Function to handle submit
    const handleSubmit = (rows) => {
        let finalData = []
        rows.map((item) => {
            let newep = createEmployeeObj({ ...item })
            finalData.push(newep)
        })
        if (finalData.length > 0) {
            // calling action
            dispatch(BulkTalentUploadAction(auth.authtoken, finalData, navigate));
        }
    }


    // Toggle fullscreen
    const enterFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().then(() => {
                setFullScreenActive(!fullscreenActive)
            });
        } else {
            document.exitFullscreen().then(() => {
                setFullScreenActive(!fullscreenActive)
            });
        }

    }

 useEffect(() => {
        if (auth?.authtoken) {
            dispatch(DesignationList(auth.authtoken))//INFO: This is called to get all the updated list
            dispatch(DepartmentList(auth.authtoken))//INFO: This is called to get the updated list
        }
    }, [auth.authtoken])

    return (
        <Page title={`Employee Directory`} >

            {/* BREADCRUMBS */}
            <Grid container display="row" justifyContent="space-between" className="align-center">
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading={company?.companyData?.onBoarding?.onboarding_is_completed  ?  `Employee Directory` : `Dashboard`}
                        links={
                                company?.companyData?.onBoarding?.onboarding_is_completed ? 
                             [ { name: 'Dashboard', href: '/' },
                                { name: 'Employee', href: '' },
                                {
                                    name: `Employee Directory`,
                                },
                                {
                                    name: `Bulk Employee Upload `,
                                }] : [
                                    {
                                        name: `Bulk Employee Upload `,
                                    }
                                ]
                           
                        }
                    />
                </Grid>



<EHidden width="mdDown">
                    <Grid item>
                    <EButton
                           onClick={() => setOpenDepartmentModal(!openDepartmentModal)}
                           className='button-left-margin'>  Add Department </EButton> 
                            <EButton
                           
                           onClick={() => setOpenDesignationModal(!openDesignationModal)}
                           className='button-left-margin'>  Add Designation </EButton>                  
                           
                        <EButtonOutlined
                            variant="outlined"
                            onClick={() => navigate(-1)}
                            className='button-left-margin'>  Back </EButtonOutlined>
                            
                            </Grid>
                    
                </EHidden>
                        
            </Grid>


            {/* HEADER  */}
            <Grid
                container
                display="row"
                justifyContent="space-between"
                className='margin-top-20px '
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        Bulk Employee Upload
                    </ETypographyPageHeading>
                </Grid>
                <Grid item>
                    <EHidden width="mdUp">
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                    </EHidden>
                    <Grid item>
                        <EHidden width="mdDown">
                            <IconButton onClick={enterFullscreen}>
                                {!fullscreenActive ? (
                                    <EIcon
                                        icon={fullscreenOnIcon}
                                        className="height-width-22px"
                                    />
                                ) : (
                                    <EIcon
                                        icon={fullscreenOffIcon}
                                        className="height-width-22px"
                                    />
                                )}
                            </IconButton>
                        </EHidden>

                    </Grid>
                </Grid>
            </Grid>
            {/* DIVIDER */}
            <EHidden width="mdDown">
                <EDivider className='mt-8px' />
            </EHidden>


            {/* MAIN */}
            <ECard className="mt-16px">
                <Grid container flexWrap={'wrap-reverse'} spacing={2}>
                    {/* TEXT */}
                    <Grid item sm={12} md={6} lg={7} xl={6} >
                        <EBox>
                            <List>
                                {/* DOWNLOAD CSV */}
                                <ListItem disablePadding>

                                    <ListItemIcon>
                                        <EIcon
                                            icon={DownloadIcon}
                                            className="height-width-22px greyColor4-color"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary=
                                        {<ETypography className="font-size-14px greyColor4-color"  >
                                            <a href='/bulk-employee-upload.csv' download className="link-color bold-600">Download a sample CSV file</a> , to help you upload bulk employee details.
                                        </ETypography>
                                        }
                                    />
                                </ListItem>

                                {/* UPGRADE */}

                                <ListItem disablePadding alignItems="center" >

                                    <ListItemIcon>
                                        <EIcon
                                            icon={ListIcon}
                                            className="height-width-22px greyColor4-color"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                            Read the instructions carefully on the CSV file and fill in the details in required format, to successfully upload the file.
                                        </ETypography>
                                    } />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                            Note that Entera Payroll will restrict the count based on your subscription. You can always upgrade your plan or add more users. 
                                             {/* hiding until the upgrade plan module is completed <a href='https://entera.in/#pricing' target="_blank" rel="no_rel" className="link-color bold-600">Upgrade Plan</a> */}
                                        </ETypography>
                                    } />
                                </ListItem>                                

                                <ListItem disablePadding>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                            Until all the fields are in standard format, the file cannot be submitted.
                                        </ETypography>

                                    } />
                                </ListItem>

                                {/* UPLOAD */}
                                <ListItem disablePadding sx={{ marginTop: '10px' }}>

                                    <ListItemIcon>
                                        <EIcon
                                            icon={UploadIcon}
                                            className="height-width-22px greyColor4-color"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                            On submission, database for employees will be generated.
                                        </ETypography>

                                    } />
                                </ListItem>

                                {/* Add Designation / Add Department */}

                                
                                <ListItem disablePadding alignItems="center" >

                                    <ListItemIcon>
                                        <EIcon
                                            icon={GroupIcon}
                                            className="height-width-22px greyColor4-color"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                            To upload employee in bulk, first add Department and Designation.
                                        </ETypography>
                                    } />
                                   
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                           You can add department and designation in two ways - 
                                        </ETypography>

                                    } />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                            1. From above buttons
                                        </ETypography>

                                    } />
                                   
                                </ListItem>

                                <ListItem disablePadding>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <ETypography className="font-size-14px greyColor4-color">
                                            2. From Master modules
                                        </ETypography>

                                    } />
                                   
                                </ListItem>
                            </List>
                        </EBox>
                    </Grid>


                    {/* DRAG AND DROP COMPONENT */}
                    <Grid item sm={12} md={6} lg={5} xl={6} sx={{position:'relative',width:'100%'}}>
                        <Grid container  {...getRootProps()}
                            alignItems={'center'} justifyContent={'center'} className="border-radius-2px drag-and-drop-responsive-height">
                            <input type="file" accept=".csv" capture="filesystem" {...getInputProps()} />
                            <Grid item  alignItems={'center'} justifyContent={'center'} className="m-auto">
                                <Stack direction='row' spacing={2} justifyContent={'center'}>
                                    <EButton   disabled={myFiles?.length > 0 ? true : false} sx={{marginBottom:'20px'}}> Choose CSV </EButton>
                                    <ETypography>  or drop a file</ETypography>
                                </Stack>
                             </Grid>
                        </Grid>

                            <Grid item  className="drag-and-drop-file ">
                                    <Stack direction='row' marginTop={2} spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
                                        {myFiles?.map((file, index) => {
                                            return (
                                                <EBox sx={{padding:'50 0'}} key={index} className="flex-row-allCenter">
                                                        <EIcon icon={FileIcon} className="pb-0" />
                                                        <ETypography className="font-size-14px " alignItems={'center'} sx={{margin:'0 10px'}}> {file.path.trim('.')} </ETypography>
                                                        {/* <EIcon className="icon-green" icon={CheckIcon} /> */}
                                                        <EIcon onClick={removeFile()} className="theme-color-static" icon={CancelIcon} />
                                                </EBox>
                                            )

                                        })}
                                    </Stack>
                                </Grid>
                        <Grid item xs={12} className="mt-16px mb-16px" align={'right'} >
                            <EButton onClick={() => handleUpload()} disabled={myFiles.length !== 0 ? false : true}>Upload</EButton>
                        </Grid>
                    </Grid>
                </Grid>


                {/* TABLE */}
                {
                    tableRow.length !== 0 &&
                    <EScrollbar className="margin-top-05">
                        <ETableContainer>
                            <ETable>
                                <EListHead
                                    headLabel={tableHead()}
                                />
                                <ETableBody>
                                    {
                                        tableRow?.map((item, index) => {

                                            return (
                                                <ETableRow key={index} className="border-bottom-border8-color">
                                                    {/* Error */}
                                                    <ETableCell key={`error-${index}`} align="center" sx={{ py: 3 }}>
                                                        {checkRowHasError(index)}
                                                    </ETableCell>
                                                    {
                                                        Object.entries(item).map((item, index) =>
                                                            <ETableCell key={`${index}-${item[0]}`} align="center" sx={{ py: 3 }}>
                                                                <ETypography className={validateRowFields(item) ? 'table-error-cell' : ''}>
                                                                    {item[1] !== '' ? item[1] : '\u00A0'}
                                                                </ETypography>
                                                            </ETableCell>
                                                        )}
                                                </ETableRow>

                                            )
                                        })}

                                </ETableBody>

                            </ETable>

                        </ETableContainer>
                    </EScrollbar>

                }




                {/* Action Button */}
                <Grid item xs={12} xl={12} className="mt-20px" >
                    <Stack direction='row' spacing={2} justifyContent={'flex-end'} >
                        <EButtonOutlined onClick={() => handleCancel()} variant='outlined' size='large'> Cancel</EButtonOutlined>
                        <EButton type="submit" onClick={() => handleSubmit(tableRow)} disabled={CSVError.length !== 0 ? CSVError.some((row) => row.msg.filter((item) => item !== null).length > 0) : true} variant='contained' size='large'> Submit</EButton>
                    </Stack>
                </Grid>


                {/* ALERT MODAL */}
                <EModal open={alert.openModal} headervalue="Alert">
                    {alert.msg}
                    <Stack direction='row' spacing={2} justifyContent={'flex-end'} >
                        <EButtonOutlined onClick={() => setAlert({ openModal: !alert.openModal, msg: '' })} variant='outlined' size='large'> Close</EButtonOutlined>
                    </Stack>

                </EModal>


                {/* Department Modal */}
                <EModal open={openDepartmentModal} headervalue="Add Department" >
                    <DepartmentForm
                    auth={auth}
                    dept={department?.departmentListData?.rows}
                    close={()=>setOpenDepartmentModal(false)}
                    />
                </EModal>

                {/* Designation Modal */}
                <EModal open={openDesignationModal} headervalue="Add Designation">
                    <DesignationForm
                    auth={auth}
                    designation={designation?.designationListData?.rows}
                    close={()=>setOpenDesignationModal(false)}
                    />
                </EModal>
            </ECard>
        </Page>
    )
}

export default BulkTalentUpload


