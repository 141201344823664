import axios from "axios";
import { SERVER_URL, REGULARIZATION_REQUEST_LIST_FAILIURE, REGULARIZATION_REQUEST_LIST_SUCCESS, REGULARIZATION_REQUEST_LIST_LOADING } from "../constants";



export function getRegularizationRequestList(token, data, page = null, rowsPerPage = null) {
  let params = ``;
  if (Array.isArray(data.status)) {
    params = data.status.map(value => `status=${value}`).join('&');
  }
  if (page != null && rowsPerPage != null) {
    params += `&page=${page}&per_page=${rowsPerPage}`;
  }
  if (data?.year_month && data?.year_month != null) {
    params += `&year_month=${data?.year_month}`;
  }
  if (data?.employee_id && data?.employee_id != null) {
    params += `&employee_id=${data?.employee_id}`;
  }
  if (data?.sort === true || data?.sort === false) {
    params += `&sort=${data?.sort}`;
  }

  return (dispatch) => {
    dispatch(regularizeListLoading())
    axios({
      method: "get",
      url: `${SERVER_URL}/attendance/employee-attendance-regularize-request-list?${params}`,
      headers: {
        Authorization: "Bearer " + token,
      },

    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(regularizeListSuccess(res))
        }
      })
      .catch((error) => {
        dispatch(regularizeListFailure(error?.response))
      });
  };
}



// Reducers
export function regularizeListLoading() {
  return {
    type: REGULARIZATION_REQUEST_LIST_LOADING,
  };
}
export function regularizeListSuccess(payload) {
  return {
    type: REGULARIZATION_REQUEST_LIST_SUCCESS,
    payload
  };
}
export function regularizeListFailure(payload) {
  return {
    type: REGULARIZATION_REQUEST_LIST_FAILIURE,
    payload
  };
}


export function approveRegularizationRequest(token, Data) {
  const data = new FormData();
  data.append('request_id', Data.request_id)
  data.append("employee_id", Data.employee_id)
  if (Data.approval_remark) { data.append("approval_remark", Data.approval_remark) }
  data.append('status', Data.regularized_request_status)

  return (dispatch) => {
    dispatch(regularizeListLoading())
    axios({
      method: "post",
      url: `${SERVER_URL}/attendance/employee-attendance-regularize-request-action`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getRegularizationRequestList(token))
        }
      })
      .catch((error) => {
        dispatch(getRegularizationRequestList(token))
      });
  };
}