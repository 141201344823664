import React, { useEffect } from 'react';
import { Grid} from '@mui/material';
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { useSelector, useDispatch } from 'react-redux';
import { GetCompanySetting } from '../../action/CompanySettingAction';
import Page from '../../BaseComponents/EPage';
import { ELabel, ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import { ECard } from '../../BaseComponents/ECard';
import { EBoxPage } from '../../BaseComponents/EBox';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import CompanyProfileForm from '../../PageComponents/company/CompanyProfileForm';
import { EButton, EButtonOutlined } from '../../BaseComponents/EButtons';
import { useNavigate } from 'react-router-dom';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';

/** 
 * [2022-10-07] 
 * Changes By :- Gaurav Singh
 * Description :- Changed  'Company Info' to 'Basic Details' and 'Contact Person Details ' to 'Contact Details'  
 **/
function CompanyOnboardingProfile(props) {
    const { auth } = props;
    const { setting } = useCompanySettingProvider();
    const navigate = useNavigate()
    // const theme = useTheme();
    // const dispatch = useDispatch();
   
    
    // const StateListData = useSelector((state) => state.StateReducer.stateListData?.rows);
    // const CityListData = useSelector((state) => state.CityReducer.cityListData?.rows);
    const company = useSelector((state) => state.CompanyReducer?.companyData);
    // const primaryAdmin = setting.accountSettingData?.companySetting?.company_data?.primaryAdmin;
    // const companyData = useSelector((state) => state.CompanyReducer?.companyData);
    // console.log(companyData);
    // const navigate = useNavigate();

    // const [settingData, setSettingData] = useState(company);

    // const company = companyDetail?.companySettingData ? companyDetail?.companySettingData : null;
    // const [imageUrlData, setImageUrlData] = useState(null);

    // useEffect(() => {
    //     if (auth.authData?.id) {
    //         dispatch(GetCompanySetting(auth.authtoken, auth.authData?.company_id));
    //     }
    // }, [auth]);



    return (
        <>
            <Page title="Company Profile" >

                <Grid container display="row" justifyContent="space-between" className='align-center'>
                    <Grid item >
                        <EHeaderBreadcrumbs
                            heading='Company Profile'
                            links={[
                                { name: 'Dashboard', href: '/' },
                                { name: 'Setting', href: '' },
                                {
                                    name: 'Company Profile',
                                }
                            ]}
                        />
                    </Grid>
                </Grid>

                <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                    <Grid item>
                        <ETypographyPageHeading variant='h5'> Company Profile</ETypographyPageHeading>
                    </Grid>
                    <Grid item>
                    <EButtonOutlined className='button-left-margin' variant="outlined" onClick={() => navigate(-1)} color="secondary" > Back </EButtonOutlined>
                    </Grid>

                    <Grid xs={12} lg={12} item><EDivider className='margin-top-05' /></Grid>
                </Grid>

                <EBoxPage>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item xs={12} sm={12} md={12} my={1}>
                            {company && Object.keys(company).length > 0 &&
                                <CompanyProfileForm
                                    settingData={company}
                                    auth={auth}
                                />
                            }

                        </Grid>

                        {/* Business owner
                        <Grid item xs={12} sm={12} md={12} my={1}>
                            <ECard p={20}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                >
                                    <ETypography variant='h5'>
                                        Primary Contact
                                    </ETypography>

                                </Grid>
                                <EDivider className='margin-top-05' />
                                <Grid
                                    container
                                    py={3}
                                >
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ELabelInputMainGrid label={'Name'} isNotForm={true}>
                                            {primaryAdmin?.fullname ? primaryAdmin?.fullname : '-'}
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ELabelInputMainGrid label={'Contact Number'} isNotForm={true}>
                                            <ELabel>{primaryAdmin?.mobile ? primaryAdmin?.mobile : '-'}</ELabel>
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ELabelInputMainGrid label={'Email ID'} isNotForm={true}>
                                            <ELabel>{primaryAdmin?.email ? primaryAdmin?.email : '-'}</ELabel>
                                        </ELabelInputMainGrid>
                                    </Grid>

                                </Grid>
                            </ECard>
                        </Grid> */}
                    </Grid>
                </EBoxPage>
            </Page>
        </>
    );
}
export default CompanyOnboardingProfile;
