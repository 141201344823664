import { Grid, LinearProgress, CircularProgress, useTheme, ListItem, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ETypography } from "../../../BaseComponents/ETypography";
import { Link, useNavigate } from "react-router-dom";
import { EBox } from "../../../BaseComponents/EBox";
import Page from "../../../BaseComponents/EPage";
import { EButton } from "../../../BaseComponents/EButtons";
import { ECard } from "../../../BaseComponents/ECard";
import HrDashboard from "../HrDashboard";
import { useGreetings } from "../../../hooks/useGreetings";

const OnBoardingHRSetup = ({auth,notDisplayGreet}) => {
    const company = useSelector((state) => state.CompanyReducer);
    const {greetMsg} = useGreetings()
    const obData = company.companyData?.onBoarding;

    const navigate = useNavigate()

    const theme = useTheme()

  return (
    <Page title="Dashboard">

    {/* Welcome message */}
    <EBox className="mb-16px">
    <ETypography className="font-size-32px ">
            {greetMsg? greetMsg + ", ":null}
            <span className="bold-700">
              {auth?.authData?.fname ? auth?.authData?.fname + "!" : null}
            </span>
    </ETypography>

    {!obData?.onboarding_is_completed &&   <ETypography className="font-size-16px bold-500 mb-16px">
      Need help? &nbsp;  
      <span style={{color:theme.palette.text.blueColor, cursor:'pointer'}} onClick={()=>navigate('/setup/onboarding-options',{state:{fromSetup:true}})} >
        <u>Click Here</u>
      </span>
    </ETypography>}
    </EBox>
   
    

    {/* Get Started */}

    <ECard sx={{background:theme.palette.background.Champagne,marginX:'32px'}}>
      {/* Get started and invite HR */}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        // alignItems="center"
        sx={{margin:'16px'}}

      >
        <EBox>
          <ETypography sx={{fontSize:'24px', fontWeight:'600'}}>
            Get Started
          </ETypography>

          <ETypography sx={{fontSize:'16px', fontWeight:'400', color:theme.palette.text.greyColor}}>
          Setup your account by completing My Profile details.
          </ETypography>
        </EBox>


      </Grid>

      {/* HR Profile and Invite Employee to Entera Payroll */}
      <Grid container item
       md={12} lg={12} xl={12} xxl={12}           
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        >

          {/* HR Profile and Grid */}
          <Grid item  md={6} lg={6} xl={6} xxl={6} sx={{ paddingRight: '8px'}}>
            <ECard sx={{width:'100%', minHeight:'240px'  }}>

              <EBox className="flex-column" sx={{justifyContent: "space-between", height:'170px'}}>
                <ETypography sx={{fontWeight:'600', fontSize:'18px', marginBottom:'16px'}}>My Profile</ETypography>
                <ETypography sx={{fontWeight:'400', fontSize:'14px', marginBottom:'100px'}}>To ensure a smooth and uninterrupted experience, we kindly ask you to complete My Profile with us using Entera Payroll.</ETypography>
              </EBox>

              <EBox>
                <EBox className="flex-row-horizontaRight" sx={{marginTop:'30px'}}>
                <EButton
                className={
                  obData.hr_profile_completed
                    ? "onboarding-list-btn-verified align-center"
                    : "onboarding-list-btn"
                }
                to={!obData.hr_profile_completed ?'/add-hr-profile' : null }
                    component={Link}   
                >
                     {!obData.hr_profile_completed ? 'Start Now' : 'Completed'}
                    </EButton>
                </EBox>  
              </EBox>              
            </ECard>
          </Grid>

          {/* Invite Employee Grid */}

          <Grid item md={6} lg={6} xl={6} xxl={6} sm={12} sx={{ paddingRight: '8px'}}>
            <ECard sx={{width:'100%', minHeight:'240px'}}>

              <EBox className="flex-column" sx={{justifyContent: "space-between", height:'170px'}}>
                <ETypography sx={{fontWeight:'600', fontSize:'18px', marginBottom:'16px'}}>Invite Employee to Entera Payroll</ETypography>
                <ETypography sx={{fontWeight:'400', fontSize:'14px', marginBottom:'100px'}}>To have your team onboard, invite them now!</ETypography>
              </EBox>

              <EBox>
                <EBox className="flex-row-horizontaRight" sx={{marginTop:'30px'}}>
                    <EButton 
                        sx={{marginRight:'10px'}}
                        onClick={() => navigate('/employee/bulk-employee-upload')} 
                        disabled= {!obData.hr_profile_completed}
                    >
                        Import
                    </EButton>
                    <EButton
                        onClick={() => navigate('/add-employee')}
                        disabled= {!obData.hr_profile_completed}
                    >
                        Add Employee
                    </EButton>
                </EBox>  
              </EBox>              
            </ECard>
          </Grid>

      </Grid>

    </ECard>

    <HrDashboard auth={auth} notDisplayGreet={notDisplayGreet}/>

  </Page>
  )
}

export default OnBoardingHRSetup