import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import { Grid } from "@mui/material";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { ETextField } from "../../BaseComponents/ETextField";
import { Stack } from "@mui/system";
import { EButton, EButtonOutlined } from "../../BaseComponents/EButtons";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { AccountBillingInfoValidation } from "../../utils/validation";
import { CityList } from "../../action/StateCityAction";
import { UpdateBillingInfo } from "../../action/AccountAction";
import { ETypography } from "../../BaseComponents/ETypography";

function BillingInfoForm(props) {
  const dispatch = useDispatch();


  const StateListData = useSelector((state) => state.StateReducer.stateListData?.rows);
  const CityListData = useSelector((state) => state.CityReducer.cityListData?.rows);

  // To close the modal
  const { close, data, auth } = props;

  // saving object return from useFormik as formik
  const formikUpdateInfo = useFormik({

    // IniitalValues for input fields
    initialValues: {
      fname: data?.primaryAdmin?.fullname ? data?.primaryAdmin?.fullname.split(' ')[0] : '',
      lname: data?.primaryAdmin?.fullname ? data?.primaryAdmin?.fullname.split(' ')[1] : '',
      company_name: data?.company_name ? data?.company_name : '',
      gst: data?.company_gst ? data?.company_gst : '',
      email: data?.company_email ? data?.company_email : '',
      phone_no: data?.company_phone ? data?.company_phone : '',
      address: data?.address ? data?.address : '',
      state: data?.permanentState ? data?.permanentState : null,
      city: data?.permanentCity ? data?.permanentCity : null,
      pin_code: data?.pin_code ? data?.pin_code : '',
    },
    // Validation for input fields
    validationSchema: AccountBillingInfoValidation,
    // onSubmit action
    onSubmit: (data) => {
      // calling action
      dispatch(UpdateBillingInfo(auth.authtoken, data, close));
    },
  });

  // Formik Props
  const { getFieldProps, setFieldValue, handleSubmit, touched, errors } =
    formikUpdateInfo;

  return (
    <FormikProvider value={formikUpdateInfo}>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ paddingTop: "10px" }}>

          {/* Company Name */}
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid
              label={"Company Name"}
              isModal={true}
              labelPT={-2}
              isNotForm={true}
              isfullgrid={true}
            >
              <ETypography className="bold-400" >
                {data?.company_name}
              </ETypography>
            </ELabelInputMainGrid>
          </Grid>

          {/* GSTIN */}
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid
              label={"GSTIN"}
              isModal={true}
              isfullgrid={true}
              style={{ border: "solid 2px red" }}
            >
              <ETextField
                name="gst"
                {...getFieldProps("gst")}
                fullWidth
                error={Boolean(touched.gst && errors.gst)}
                helperText={touched.gst && errors.gst}
              />
            </ELabelInputMainGrid>
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid
              label={"Email ID*"}
              isModal={true}
              isfullgrid={true}
              style={{ border: "solid 2px red" }}
            >
              <ETextField
                name="email"
                {...getFieldProps("email")}
                fullWidth
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </ELabelInputMainGrid>
          </Grid>

          {/* Contact Number */}
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid
              label={"Contact Number*"}
              isModal={true}
              isfullgrid={true}
              style={{ border: "solid 2px red" }}
            >
              <ETextField
                name="phone_no"
                {...getFieldProps("phone_no")}
                fullWidth
                type="text"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 }}
                error={Boolean(touched.phone_no && errors.phone_no)}
                helperText={touched.phone_no && errors.phone_no}
              />
            </ELabelInputMainGrid>
          </Grid>

          {/* Address */}
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid
              label={"Address"}
              isModal={true}
              isfullgrid={true}
              style={{ border: "solid 2px red" }}
            >
              <ETextField
                name="address"
                {...getFieldProps("address")}
                fullWidth
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </ELabelInputMainGrid>
          </Grid>



          {/* State */}
          <Grid item xs={12} sm={12} lg={12} xl={12}  >
            <ELabelInputMainGrid label={"State*"} isModal={true}
              isfullgrid={true}  >
              <EAutocomplete
                name="state"
                {...getFieldProps("state")}
                fullWidth
                options={StateListData && StateListData?.length > 0 ? StateListData : []}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                onChange={(e, value) => {

                  if (value) {
                    dispatch(CityList(auth.authtoken, value.id));
                  }
                  setFieldValue("state", value ? value : null);
                }}
                renderInput={(params) => (
                  <ETextField
                    {...params}
                    name="state"
                    placeholder="Search State"
                    fullWidth
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />
            </ELabelInputMainGrid>
          </Grid>

          {/* City */}
          <Grid item xs={12} sm={12} lg={12} xl={12}  >
            <ELabelInputMainGrid label={"City*"} isModal={true}
              isfullgrid={true}  >
              <EAutocomplete
                name="city"
                {...getFieldProps("city")}
                fullWidth
                options={
                  CityListData && CityListData?.length > 0 ? CityListData : []
                }
                isOptionEqualToValue={(option, value) => option.id == value.id}
                onChange={(e, value) => {
                  setFieldValue("city", value ? value : null);
                }}
                renderInput={(params) => (
                  <ETextField
                    {...params}
                    name="city"
                    placeholder="Search City"
                    fullWidth
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                )}
              />
            </ELabelInputMainGrid>
          </Grid>



          {/* PIN Code */}
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid
              label={"PIN Code*"}
              isModal={true}
              isfullgrid={true}

              style={{ border: "solid 2px red" }}
            >
              <ETextField
                name="pin_code"
                {...getFieldProps("pin_code")}
                fullWidth
                error={Boolean(touched.pin_code && errors.pin_code)}
                helperText={touched.pin_code && errors.pin_code}
              />
            </ELabelInputMainGrid>
          </Grid>


          <Grid item>
            <ETypography className="font-size-14px bold-400">
              Note: Above mentioned address will be reflected in  “My Company” page as well.
            </ETypography>

          </Grid>

        </Grid>





        {/*Form Action */}
        <Stack
          direction="row"
          spacing={2}
          paddingTop={2}
          justifyContent="flex-end"
          className="pb-24px"
        >
          <EButtonOutlined size="large" variant="outlined" onClick={close}>
            {" "}
            Cancel{" "}
          </EButtonOutlined>
          <EButton size="large" type="submit" variant="contained">
            Update
          </EButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default BillingInfoForm;
