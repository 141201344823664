import React from 'react'
import { FormControl, FormControlLabel, Grid, Stack } from '@mui/material'
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid'
import { EFormHelperText, ETypography } from '../../BaseComponents/ETypography'
import { ECheckbox } from '../../BaseComponents/ECheckbox'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { ETextField } from '../../BaseComponents/ETextField'
import { EAutocomplete } from '../../BaseComponents/EAutocomplete'
import { ACTIVE_USER, EMAIL, MOBILE_NO, OTHER, SUPER_ADMIN, WORK_MODE } from '../../constants'
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio'
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddNewUserSettingValidation } from '../../utils/validation'
import { NewEmployeeAuthCreate } from '../../action/EmployeeAuthAction'
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { EmployeeListData } from '../../action/EmployeeAction'
import moment from 'moment'
import { EBox } from '../../BaseComponents/EBox'
import { ReturnBoolean } from '../../utils/CommonFunctions'
import { useMemo } from 'react'

function AddNewUserForm(props) {

    // Props
    const { close, isEdit, search, page, empAuth, auth, setting, loadingButton, designation, dept } = props

    // Hooks
    const navigate = useNavigate()
    const dispatch = useDispatch();

    // Reducers
    const employee = useSelector(state => state.EmployeeReducer);

    // Selected Work Mode
    const selectedWorkMode = isEdit && WORK_MODE.find(function (e) {
        return e.id == isEdit?.work_mode;
    });

    //Shift List and Setting
    const shiftList = setting?.attendanceSettingData?.companySetting?.shifts
    // Shift Name change is allowed only if attendance is set to automate. Otherwise, it is not allowed.
    const isShiftAllowed = shiftList?.length >= 1



    //Custom User role
    const { customRoleData = [] } = useSelector((state) => state.CustomUserRole)
    //filtering the super admin role in case of new allocate credential form
    const customRole = !isEdit && customRoleData ? customRoleData?.filter(item => item.value != SUPER_ADMIN) : customRoleData;
    /**
    * AdminList is for selecting user as a Reporting Manager
    */
    const [AdminList, setAdminList] = useState(null);
    let find_default_role = useRef(null);
    let find_default_workmode = useRef(null);
    const [openDateDOJ, setOpenDateDOJ] = useState(false);


    // Last Payroll Date
    const LastPayrollDataToDate = setting?.accountSettingData?.companySetting?.company_data?.lastPayroll?.to_date// fetching payroll data here from settings
    // Info: As per discussion with @Dharam Sir & @sunil Sir on 16th May 23, The only validation in the DOJ will be the last Payroll locked date (to_date+1) else it will be open.
    const minDojValidation = LastPayrollDataToDate ? moment(LastPayrollDataToDate).add(1, 'day').toDate() : null;


    const initialValues = useMemo(() => ({
        isEdit: !!isEdit,
        talent_name: isEdit ? { id: isEdit.id, label: isEdit.fullname, email: isEdit.email, mobile: isEdit.mobile } : null,
        username: isEdit?.username || '',
        username_type: isEdit?.username && (isEdit.username === isEdit.mobile ? { id: MOBILE_NO, label: 'Mobile No.' } : isEdit.username === isEdit.email ? { id: EMAIL, label: 'Email' } : { id: OTHER, label: 'Other' }),
        is_active: isEdit?.is_active || "1",
        role: null,
        reporting_manager: isEdit?.reportingManager && (isEdit.is_primary === 1 ? { label: isEdit.fullname, id: isEdit.id } : { label: isEdit.reportingManager.fullname, id: isEdit.reportingManager.id }),
        is_primary: !!isEdit?.is_primary && isEdit.is_primary === 1,
        show_as_talent: !isEdit || !!isEdit?.show_as_talent,
        doj: isEdit?.doj || null,
        department: isEdit?.departmentInfo && { label: isEdit.departmentInfo.department_name, id: isEdit.departmentInfo.id },
        designation: isEdit?.designationInfo && { label: isEdit.designationInfo.designation_name, id: isEdit.designationInfo.id },
        payroll_basic: Number(isEdit?.employeePayrollInfo?.payroll_basic) || '',
        work_mode: isEdit?.work_mode ? selectedWorkMode : false,
        shift_object: isEdit?.userShiftInfo ? { shift_name: isEdit?.userShiftInfo?.shift_name, id: isEdit?.userShiftInfo?.id } : null,
        isShiftAllowed,
    }), [isEdit, selectedWorkMode, isShiftAllowed]);


    // useFormik
    const formik = useFormik({
        validateOnChange: true,
        initialValues: initialValues,
        validationSchema: AddNewUserSettingValidation(auth.authtoken, isEdit?.id ? isEdit?.id : ''),
        onSubmit: (data) => {
            data = {
                ...data,
                id: data?.talent_name?.id,
                shift_id: data?.shift_object ? data?.shift_object?.id : null,
                updated_by: auth?.authData?.id,
                search: { ...search },
                department_id: data?.department ? data?.department?.id : null,
                designation_id: data?.designation ? data?.designation?.id : null,
                work_mode_id: data?.work_mode ? data?.work_mode?.id : null,
                show_as_talent: formik?.values?.role?.value == SUPER_ADMIN ? data?.show_as_talent : true
            }


            dispatch(NewEmployeeAuthCreate(auth.authtoken, data, page, navigate, close, auth))

        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, } = formik;


    // useEffect
    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER, { is_active: true, form_list: true }))
        }
    }, [empAuth?.empAuthSuccess])


    /**
     * Updates the AdminList with filtered employee rows based on certain conditions.
     * @param {Object} employee - The employee data object.
     * @param {Object} isEdit - The edited employee object.
     * @param {Function} setAdminList - Function to update the AdminList.
     */
    const updateAdminList = (employee, isEdit, setAdminList) => {
        let adminList = [];

        if (employee && employee?.employeeData && employee?.employeeData?.rows) {
            // Filter employee rows by excluding the edited employee and including active ones.
            adminList = employee?.employeeData?.rows.filter(
                item => item?.id !== isEdit?.id && item?.is_active == 1
            );
        }

        // Update the AdminList using the filtered rows.
        setAdminList(adminList);
    };


    /**
    * Sets the default role and updates the form field value based on the employee's custom role or role.
    * @param {Object} isEdit - The edited employee object.
    * @param {Object} find_default_role - Reference for the default role object.
    * @param {Array} customRoleData - Array of custom role data.
    * @param {Function} setFieldValue - Function to set form field value.
    */
    const setDefaultRole = (isEdit, find_default_role, customRoleData, setFieldValue) => {
        // Determine the preferred role, using custom_role if available, otherwise use role.
        const preferredRole = isEdit?.custom_role || isEdit?.role;

        // Find and set the default role object from customRoleData.
        find_default_role.current = preferredRole && customRoleData?.find(element => element?.value == preferredRole);

        // Update the form field value for role.
        setFieldValue('role', find_default_role?.current);
    };


    /**
    * Sets the default work mode and updates the form field value based on the employee's work mode.
    * @param {Object} isEdit - The edited employee object.
    * @param {Object} find_default_workmode - Reference for the default work mode object.
    * @param {Array} WORK_MODE - Array of work mode options.
    * @param {Function} setFieldValue - Function to set form field value.
    */
    const setDefaultWorkMode = (isEdit, find_default_workmode, WORK_MODE, setFieldValue) => {
        // Find the matching work mode object in WORK_MODE array, based on the employee's work_mode.
        const defaultWorkModeObject = isEdit?.work_mode ? WORK_MODE?.find(element => element?.id == isEdit?.work_mode) : null;

        // Set the default work mode and update the form field value.
        find_default_workmode.current = defaultWorkModeObject;
        setFieldValue('work_mode', find_default_workmode?.current);
    };


     /**
     * getEmployeeList
     * Retrieves a list of employees based on the context.
     * @returns {Array} An array containing either edited employee details,
     *                  filtered inactive employee rows, or an empty array.
     */
        const getEmployeeList = () => {
            if (isEdit) {
                // If editing, create an array with edited employee details
                const editedEmployee = [{
                    id: isEdit?.id,
                    label: isEdit?.fullname,
                    email: isEdit?.email,
                    mobile: isEdit?.mobile
                }];
                return editedEmployee;
            } else if (employee && employee?.employeeData && employee?.employeeData.rows) {
                // If not editing, filter and return inactive employee rows
                const inactiveRows = employee?.employeeData?.rows.filter(item => !item.is_active);
                return inactiveRows;
            } else {
                // If no data available, return an empty array
                return [];
            }
        }
    
        //State
    const [EmployeeList,setEmployeeList] = useState([])
    
    //__INFO : __ this useeffect is for setting initial values in Edit User Credentials Modal
    useEffect(() => {
         /**
         * EmployeeList is for selecting employee when Allocate Credentials   
         */
        setEmployeeList(getEmployeeList())
        // Update AdminList based on the editing mode
        updateAdminList(employee, isEdit, setAdminList);
        if (isEdit) {
            // Set the default role if not already set
            setDefaultRole(isEdit, find_default_role, customRoleData, setFieldValue);

            // Set the default work mode if not already set and valid work mode value exists
            setDefaultWorkMode(isEdit, find_default_workmode, WORK_MODE, setFieldValue);
        }
    }, [isEdit, employee])

    // Handle Close
    const HandleClose = () => {
        close()
    }


    //This method is called when the talend is selected
    const selectUser = (user) => {
        setFieldValue('talent_name', user ? user : null)
        //setting all the fields to null if employee is changed
        setFieldValue('username', '')
        setFieldValue('username_type', null)
        setFieldValue('role', null)
        setFieldValue('reporting_manager', null)

        // to by pass all the validations for following fields
        setFieldValue('doj', user?.doj)
        setFieldValue('designation', user?.designationInfo)
        setFieldValue('department', user?.departmentInfo)
        setFieldValue('payroll_basic', Number(user?.employeePayrollInfo?.payroll_basic))
        setFieldValue('shift_object', { shift_name: user?.userShiftInfo?.shift_name, id: user?.userShiftInfo?.id })
        setFieldValue('work_mode', user?.employeePayrollInfo?.work_mode)
        const userWorkMode = WORK_MODE.find(function (e) {
            return e.id == user?.work_mode;
        });
        setFieldValue('work_mode', userWorkMode ? userWorkMode : null);
    }




    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2} >
                    <>
                        {
                            !!isEdit
                                ?
                                <>
                                    {/* EMPLOYEE NAME */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true} isNotForm={true}>
                                            <ETypography>{formik.values?.talent_name?.label}</ETypography>
                                        </ELabelInputMainGrid>
                                    </Grid>

                                    {/* USERNAME */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ELabelInputMainGrid label={'Username'} isfullgrid={true} isModal={true} isNotForm={true}>
                                            <ETypography>{formik.values?.username}</ETypography>
                                        </ELabelInputMainGrid>
                                    </Grid>
                                </>
                                :
                                <>
                                    {/* FORM */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true}>
                                            <EAutocomplete
                                                name="Employee Name"
                                                fullWidth
                                                forcePopupIcon={formik?.values?.talent_name ? false : true}
                                                disabled={isEdit ? true : false}
                                                {...getFieldProps('talent_name')}
                                                getOptionLabel={(option) => option.label || ''}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                options={EmployeeList && EmployeeList?.length > 0 ? EmployeeList : []}
                                                renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                                                    <EBox component="li" {...props} key={option.id}>
                                                        {option.label}
                                                    </EBox>
                                                )}
                                                onChange={(e, value) => {
                                                    selectUser(value);
                                                    setFieldValue('talent_name', value ? value : null);
                                                }}
                                                renderInput={params => (
                                                    <ETextField
                                                        {...params}
                                                        name='talent_name'
                                                        placeholder="Select employee name"
                                                        fullWidth
                                                        error={Boolean(touched.talent_name && errors.talent_name)}
                                                        helperText={touched.talent_name && errors.talent_name}
                                                    />
                                                )}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                </>
                        }

                        {formik?.values?.talent_name &&
                            <>
                                {/* ROLE */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <ELabelInputMainGrid label={'Role'} isfullgrid={true} isModal={true}>
                                        <EAutocomplete
                                            name="Role"
                                            fullWidth
                                            forcePopupIcon={formik?.values?.role ? false : true}
                                            disabled={isEdit?.role == SUPER_ADMIN && auth?.authData?.role === SUPER_ADMIN && isEdit?.is_primary == '1' ? true : false}
                                            {...getFieldProps('role')}
                                            getOptionLabel={(option) => option.label || ''}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            options={customRole}
                                            onChange={(e, value) => {
                                                setFieldValue('role', value);
                                                if (value == SUPER_ADMIN && !isEdit?.role) { setFieldValue('reporting_manager', formik.values.talent_name); }
                                                else if (value != SUPER_ADMIN && isEdit?.role == SUPER_ADMIN) { setFieldValue('reporting_manager', null); }
                                            }}
                                            renderInput={params => (
                                                <ETextField
                                                    {...params}
                                                    name='role'
                                                    placeholder="Select role"
                                                    fullWidth
                                                    error={Boolean(touched.role && errors.role)}
                                                    helperText={touched.role && errors.role}
                                                />
                                            )}
                                        />
                                    </ELabelInputMainGrid>
                                </Grid>

                                {formik?.values?.role?.value != SUPER_ADMIN &&
                                    <>
                                        {/* Reporting Manager */}
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <ELabelInputMainGrid label={'Reporting Manager'} isfullgrid={true} isModal={true}>
                                                <EAutocomplete
                                                    name="Reporting Manager"
                                                    fullWidth
                                                    {...getFieldProps('reporting_manager')}
                                                    forcePopupIcon={formik?.values?.reporting_manager ? false : true}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.id}>
                                                            {option.label}
                                                        </li>
                                                    )}
                                                    options={AdminList && AdminList?.length > 0 ? AdminList : []}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    onChange={(e, value) => {
                                                        setFieldValue('reporting_manager', value ? value : null)
                                                    }}
                                                    renderInput={params => (
                                                        <ETextField
                                                            {...params}
                                                            name='reporting_manager'
                                                            placeholder="Select reporting manager"
                                                            fullWidth
                                                            error={Boolean(touched.reporting_manager && errors.reporting_manager)}
                                                            helperText={touched.reporting_manager && errors.reporting_manager}
                                                        />
                                                    )}
                                                />
                                            </ELabelInputMainGrid>
                                        </Grid>
                                    </>
                                }


                                {/*  Make user primary  */}
                                {/* INFO: If the logged in user is primary SUPER ADMIN then when the user which we are updating is SUPER ADMIN then show below option */}
                                {formik?.values?.role?.value == SUPER_ADMIN && auth?.authData?.is_primary == true &&
                                    isEdit?.is_primary != 1 &&
                                    <Grid xs={12} sm={12} md={12} lg={12} item >
                                        <ELabelInputMainGrid label={''} isfullgrid={true} disableColon={true} isModal={true} >
                                            <ECheckbox name="is_primary" className='pl0'
                                                {...getFieldProps('is_primary')}
                                                onChange={(e, value) => {
                                                    setFieldValue('is_primary', value);
                                                    if (value === true) {
                                                        setFieldValue('is_active', 1);
                                                    }
                                                }
                                                } />
                                            Make user primary
                                        </ELabelInputMainGrid>
                                    </Grid>
                                }

                                {/*  Show in payroll */}
                                {/* INFO: IF the logged in user is SUPER ADMIN then when the user which we are updating is SUPER ADMIN or we change the the updating user role to SUPER ADMIN then show below option */}
                                {(isEdit?.role === SUPER_ADMIN || formik?.values?.role?.value == SUPER_ADMIN) && isEdit && auth?.authData?.role === SUPER_ADMIN && formik?.values?.role?.value == SUPER_ADMIN &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='pt-0'>
                                        <ELabelInputMainGrid label={''} disableColon={true} isfullgrid={true} isModal={true}>
                                            <ECheckbox
                                                name="show_as_talent"
                                                checked={isEdit === null || !isEdit ? true : ReturnBoolean(formik?.values?.show_as_talent)}
                                                {...getFieldProps('show_as_talent')}
                                                onChange={(e, value) => { setFieldValue('show_as_talent', value); }}
                                                className='pl0'
                                            />
                                            Show in payroll
                                        </ELabelInputMainGrid>
                                    </Grid>
                                }


                            </>
                        }
                    </>

                    {
                        formik?.values?.show_as_talent == true && isEdit && formik?.values?.role?.role_name == SUPER_ADMIN && isEdit?.doj == null &&
                        (
                            <>
                                {/* Date of Joining */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>

                                    <ELabelInputMainGrid label={'Date of Joining'} isfullgrid={true} isModal={true}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <EDatePicker
                                                disabled={isEdit?.doj != null ? true : false}
                                                name="doj"
                                                {...getFieldProps('doj')}
                                                minDate={minDojValidation}
                                                maxDate={new Date()}
                                                open={openDateDOJ}
                                                onOpen={() => setOpenDateDOJ(true)}
                                                onClose={() => setOpenDateDOJ(false)}
                                                inputFormat="dd/MM/yyyy"
                                                onChange={(selectedDate) => {
                                                    setFieldValue('doj', selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null);
                                                }}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <Field
                                                        component={ETextField}
                                                        {...params}
                                                        onClick={(e) => { if (isEdit?.doj == null) { setOpenDateDOJ(true) } }}
                                                        fullWidth
                                                        name="doj"
                                                        error={Boolean(touched.doj && errors.doj)}
                                                        helperText={touched.doj && errors.doj}
                                                    />
                                                )}

                                            />
                                        </LocalizationProvider>
                                    </ELabelInputMainGrid>
                                </Grid>

                                {/* Department List */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <ELabelInputMainGrid label={'Department'} isfullgrid={true} isModal={true}>
                                        <EAutocomplete
                                            name="department"
                                            forcePopupIcon={formik?.values?.department ? false : true}
                                            fullWidth
                                            options={
                                                dept?.departmentListData?.rows && dept?.departmentListData?.rows?.length > 0 ? dept?.departmentListData?.rows : []
                                            }
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...getFieldProps('department')}
                                            onChange={(e, value) => {
                                                setFieldValue('department', value ? value : null);
                                            }}
                                            renderInput={(params) => (
                                                <ETextField
                                                    {...params}
                                                    name="department"
                                                    fullWidth
                                                    placeholder="Select Department"
                                                    error={Boolean(touched.department && errors.department)}
                                                    helperText={touched.department && errors.department}
                                                />
                                            )}
                                        />
                                    </ELabelInputMainGrid>
                                </Grid>

                                {/* Designation List */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <ELabelInputMainGrid label={'Designation'} isfullgrid={true} isModal={true}>
                                        <EAutocomplete
                                            forcePopupIcon={formik?.values?.designation ? false : true}
                                            name="designation"
                                            fullWidth
                                            options={
                                                designation && designation?.designationListData?.rows?.length > 0
                                                    ? designation?.designationListData?.rows
                                                    : []
                                            }
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...getFieldProps('designation')}
                                            onChange={(e, value) => {
                                                setFieldValue('designation', value ? value : null);
                                            }}
                                            renderInput={(params) => (
                                                <ETextField
                                                    {...params}
                                                    name="designation"
                                                    placeholder="Select Designation"
                                                    fullWidth
                                                    error={Boolean(touched.designation && errors.designation)}
                                                    helperText={touched.designation && errors.designation}
                                                />
                                            )}
                                        />
                                    </ELabelInputMainGrid>
                                </Grid>

                                {/* Salary */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <ELabelInputMainGrid label={'Salary'} small_text='(CTC/Month)' isfullgrid={true} isModal={true} >
                                        <ETextField
                                            fullWidth
                                            placeholder='Enter Salary'
                                            {...getFieldProps('payroll_basic')}
                                            error={Boolean(touched.payroll_basic && errors.payroll_basic)}
                                            helperText={touched.payroll_basic && errors.payroll_basic}
                                        />
                                    </ELabelInputMainGrid>
                                </Grid>

                                {/* Work Mode */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <ELabelInputMainGrid label={'Work Mode'} isfullgrid={true} isModal={true}>
                                        <EAutocomplete
                                            name="work_mode"
                                            // disabled={isEdit?.work_mode != null && isEdit?.work_mode != 0 ? true : false}
                                            // readOnly={isEdit?.work_mode != null && isEdit?.work_mode != 0 ? true : false}
                                            fullWidth
                                            options={WORK_MODE}
                                            forcePopupIcon={formik?.values?.work_mode ? false : true}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={option => option.label || ""}
                                            {...getFieldProps('work_mode')}
                                            onChange={(e, value) => {
                                                setFieldValue("work_mode", value)
                                            }}
                                            renderInput={params => (
                                                <ETextField
                                                    {...params}
                                                    name='work_mode'
                                                    placeholder='Select Work Mode'
                                                    fullWidth
                                                    error={Boolean(touched.work_mode && errors.work_mode)}
                                                    helperText={touched.work_mode && errors.work_mode}
                                                />
                                            )}
                                        />
                                    </ELabelInputMainGrid>
                                </Grid>

                                {/* Shift Name */}
                                {
                                    isShiftAllowed &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ELabelInputMainGrid label={'Shift Name'} isfullgrid={true} isModal={true} >
                                            <EAutocomplete
                                                name="shift_object"
                                                fullWidth
                                                options={shiftList}
                                                forcePopupIcon={formik?.values?.shift_object ? false : true}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={option => option.shift_name || ""}
                                                {...getFieldProps('shift_object')}
                                                onChange={(e, value) => {
                                                    setFieldValue("shift_object", value)
                                                }}
                                                renderInput={params => (
                                                    <ETextField
                                                        {...params}
                                                        name='shift_object'
                                                        placeholder=' Select Shift'
                                                        fullWidth
                                                        error={Boolean(touched.shift_object && errors.shift_object)}
                                                        helperText={touched.shift_object && errors.shift_object}
                                                    />
                                                )}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                }
                            </>

                        )
                    }

                    {/* Status */}
                    {formik.values.is_primary == false && isEdit &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ELabelInputMainGrid label={'Status'} isfullgrid={true} labelPT='0.5rem' isModal={true}>
                                    <FormControl component="fieldset" error={Boolean(touched.is_active && errors.is_active)}>
                                        <ERadioGroup row aria-label="is_active" name="is_active" {...getFieldProps('is_active')} >
                                            <FormControlLabel value="1" control={
                                                <ERadio />
                                            } label='Enable' />
                                            <FormControlLabel value="2" control={
                                                <ERadio />
                                            } label="Disable" />
                                        </ERadioGroup>
                                        {touched.is_active && <EFormHelperText> {errors.is_active}</EFormHelperText>}
                                    </FormControl>
                                </ELabelInputMainGrid>
                            </Grid>
                        </>
                    }

                    {/*  Action Button  */}
                    <Grid item xs={12} xl={12} className='modal1-buttons-stick-bottom' >
                        <Stack direction="row" spacing={2} justifyContent='flex-end'>
                            {

                                <EButtonOutlined
                                    onClick={() => { HandleClose() }}
                                    variant="outlined" size="large"> Cancel </EButtonOutlined> //Cancel button
                            }

                            <ELoadingButton type="submit" variant="contained" size="large" // Update & create button
                                loading={loadingButton}> {isEdit ? 'Update' : 'Create'}
                            </ELoadingButton>
                        </Stack>
                    </Grid>

                </Grid>
            </Form>
        </FormikProvider>

    )
}

export default AddNewUserForm

