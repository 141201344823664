import React from 'react';
import { Grid, styled } from '@mui/material';
import arrowDown from "@iconify/icons-material-symbols/keyboard-arrow-down-rounded";

import { EIconAccordion } from '../../../BaseComponents/EIcon';
import { ETypography } from '../../../BaseComponents/ETypography';
import { EAccordionDetails, EAccordionSummary } from '../../../BaseComponents/EAccordion';
import { EAccordion as Accordion } from '../../../BaseComponents/EAccordion';
import { PermissionCheckbox } from './PermissionCheckbox';

const EAccordion = styled(Accordion)(({ theme }) => ({
  border: 'none',
  borderRadius: '8px',
  boxShadow: '0 0 0 0px !important',
  '&:before': {
    display: 'none',
  },
  '&.MuiPaper-root': {
    padding: '0 24px',
    backgroundColor: theme.palette.background.card4,
  },
  '& .MuiAccordionSummary-root': {
    padding: '0 !important',
  },
  '& .MuiAccordionSummary-content': {
    margin: '16px 0 !important',
    '&.Mui-expanded': {
      marginBottom: '4px !important',
      transition: 'margin 250ms',
      transitionDelay: '50ms',
    }
  },
  '& .MuiAccordionDetails-root': {
    padding: '0 !important',
    paddingBottom: '16px !important',
  },
}))


export const AddOnPermission = ({ formik, data }) => {

  const { values, setFieldValue } = formik;
  const { permissionObject = {} } = values;

  const expended = Object.hasOwnProperty.call(permissionObject, data?.permission) ? !!permissionObject[data?.permission] : false

  const handleChange = (e, value) => {
    const oldPermissionObject = { ...permissionObject }
    oldPermissionObject[data?.permission] = value
    setFieldValue('permissionObject', oldPermissionObject)
  }

  return (
    <EAccordion
      disableGutters
      square
      sx={{
        border: 'none',
        mt: 2
      }}
      expanded={expended}
      onChange={handleChange}
    >
      <EAccordionSummary className="py0" expandIcon={<EIconAccordion icon={arrowDown} />}>
        <Grid container justifyContent={"space-between"} className="align-center">
          <Grid item xs={12}>
            <ETypography sx={{ fontWeight: `600 !important`, fontSize: { lg: 16, md: 14 } }}>
              {data?.label}
            </ETypography>
          </Grid>
        </Grid>
      </EAccordionSummary>
      <EAccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container>
          {
            data?.child?.map((child, i) => {
              if (child?.child?.length > 0) {
                const _child = child?.child
                return (
                  <Grid item md={6} xs={12} key={`${child?.permission}_${i}`} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ETypography
                      variant="h6"
                      sx={{
                        my: 1,
                        fontWeight: `600 !important`,
                        fontSize: {
                          lg: 16,
                          md: 14
                        }
                      }}
                    >
                      {child?.label}
                    </ETypography>
                    {
                      _child?.map((subChild, j) => (
                        <PermissionCheckbox key={`${subChild?.permission}_${i}_${j}`} data={subChild} formik={formik} />
                      ))
                    }
                  </Grid>
                )
              }
              return (
                <Grid item lg={12} xs={12} key={`${child?.permission}_${i}`}>
                  <PermissionCheckbox data={child} formik={formik} />
                </Grid>
              )
            })
          }
        </Grid>
      </EAccordionDetails>
    </EAccordion>
  )
}
