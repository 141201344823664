import React from 'react'
import { EBox } from '../../BaseComponents/EBox';
import { EButton } from '../../BaseComponents/EButtons';
import { ETypography } from '../../BaseComponents/ETypography';



function PaymentSuccess() {
    return (
        <EBox className='text-align'>
            <img height='150px' width='150px' className="mb-16px" src='/assets/images/hollowSuccess.svg' />
            <ETypography className="font-size-24px bold-600 text-align mb-16px">
                Payment Successful !
            </ETypography>

            <ETypography className="font-size-16px bold-400 text-align mb-16px">
                Your Payment was successfully processed.
            </ETypography>

            <EBox className='p-16px'>
                {/* Payment Type   */}
                <EBox className='display-flex justify-content-space-between ' marginY={2}>
                    <ETypography className='bold-600 font-size-16px'>
                        Payment Type
                    </ETypography>

                    <ETypography style={{ maxWidth: '100%', width: '55%', textAlign: 'left' }} className='bold-400'>
                        <span className='mr-8px'> :  </span> UPI
                    </ETypography>
                </EBox>

                {/*   Bank   */}
                <EBox className='display-flex justify-content-space-between ' marginY={2}>
                    <ETypography className='bold-600 font-size-16px'>
                        Bank
                    </ETypography>

                    <ETypography style={{ width: '55%', textAlign: 'left' }} className='bold-400'>
                        <span className='mr-8px'> :  </span>   SBI
                    </ETypography>
                </EBox>

                {/*   Mobile Number */}
                <EBox className='display-flex justify-content-space-between ' marginY={2}>
                    <ETypography className='bold-600 font-size-16px'>
                        Mobile Number
                    </ETypography>

                    <ETypography style={{ width: '55%', textAlign: 'left' }} className='bold-400'>
                        <span className='mr-8px'> :  </span>   9998887777
                    </ETypography>
                </EBox>


                {/*  Email ID    */}
                <EBox className='display-flex justify-content-space-between ' marginY={2}>
                    <ETypography className='bold-600 font-size-16px'>
                        Email ID
                    </ETypography>

                    <ETypography style={{ width: '55%', textAlign: 'left' }} className='bold-400'>
                        <span className='mr-8px'> :  </span>   biz@xyz.com
                    </ETypography>
                </EBox>

                {/*  Transaction ID */}
                <EBox className='display-flex justify-content-space-between ' marginY={2}>
                    <ETypography className='bold-600 font-size-16px'>
                        Transaction ID
                    </ETypography>

                    <ETypography style={{ width: '55%', textAlign: 'left' }} className='bold-400'>
                        <span className='mr-8px'> :  </span> AKS784AA89787687
                    </ETypography>
                </EBox>

                {/*   Amount Paid */}
                <EBox className='display-flex justify-content-space-between ' marginY={2}>
                    <ETypography className='bold-600 font-size-16px'>
                        Amount Paid
                    </ETypography>

                    <ETypography style={{ width: '55%', textAlign: 'left' }} className='bold-400'>
                        <span className='mr-8px'> :  </span>    ₹ 2500
                    </ETypography>
                </EBox>
            </EBox>

            {/* Download Invoice */}
            <EButton sx={{ width: '100%' }}>
                Download Invoice
            </EButton>
        </EBox>





    )
}

export default PaymentSuccess