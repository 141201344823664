import React from 'react';

import { TALENT_LIST_DOWNLOAD_URL, ACTIVE_USER } from '../../constants';

import { DownLoadFile } from '../../action/AdminAction';
import { useSelector } from 'react-redux';
import { PaymentSearchFilter } from '../Payment/PaymentSearchFilter';
/**
 * [2022-22-11]
 * Author: Aanchal Sahu
 **/
function UserSettingSearchForm(props) {
    const { search, setSearch, auth } = props
    const { customRoleData = [] } = useSelector((state) => state.CustomUserRole)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const onSearchHandle = (filter) => {
        setSearch(filter)
    }

    const ReportDownload = (type) => {
        const fullname = search.name ? search.name : null;
        const role = search.role ? search.role : null;
        const sort = search.sort;

        let params = {
            download_format: type,
            auth_list: true,
            user_status: ACTIVE_USER,
            form_list: false,
            ...((sort === true || sort === false) && { sort }),
            ...(fullname && { fullname }),
            ...(role && { role }),
        };
        let queryString = new URLSearchParams(params).toString();

        const url = `${TALENT_LIST_DOWNLOAD_URL}?${queryString}`;
        DownLoadFile(auth.authtoken, url, "User-list");
        setAnchorEl(null)
    }

    return (
        <>
            <PaymentSearchFilter
                showTalents={true}
                initialFilter={search}
                onSearch={onSearchHandle}
                particulars={customRoleData}
                particularPlaceholder='All Roles'
                onSort={onSearchHandle}
                DownloadReport={ReportDownload}
            />
        </>
    );
}

export default UserSettingSearchForm