
import {
    COST_TO_COMPANY_LIST_FAILURE, COST_TO_COMPANYT_LIST_SUCCESS, COST_TO_COMPANY_LIST_LOADING
} from '../constants';


const INITIAL_STATE = {
    costToCompanyListData: {},
    costToCompanyListSuccess: false,
    costToCompanyListLoading: false,
    costToCompanyListMessage: null,
};

const CostToCompanyListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case COST_TO_COMPANY_LIST_LOADING:
            return {
                ...state,
                costToCompanyListLoading: true,
                costToCompanyListData: {}
            };

        case COST_TO_COMPANYT_LIST_SUCCESS:
            return {
                ...state,
                costToCompanyListLoading: false,
                costToCompanyListSuccess: true,
                costToCompanyListData: action.payload.data,
                costToCompanyListMessage: action.payload.message,
            };

        case COST_TO_COMPANY_LIST_FAILURE:
            return {
                ...state,
                costToCompanyListLoading: false,
                costToCompanyListSuccess: false,
                costToCompanyListMessage: action.payload.message,
            };
            
        default:
            return state;
    }
};

export default CostToCompanyListReducer;
