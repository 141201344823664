
import { InputAdornment, styled, TextField } from "@mui/material";

export const ETextField = styled(TextField)((({ theme,multiline }) => ({
 
    borderRadius: '4px !important',
    borderColor:theme.palette.input.primary,
    '& .Mui-error , .MuiFormHelperText-contained': {
        marginLeft: '0px',
    },
    // for auto selected inputs
    "& input:-internal-autofill-selected": {
        'WebkitTextFillColor': theme.palette.text.primary,
        'WebkitBoxShadow': `0 0 0px 1000px ${theme.palette.background.textFieldBg2} inset`,
        border: '0.5px solid', 
        borderColor: theme.palette.input.primary,
        borderRadius: '4px !important',
    },
    '.Mui-disabled':{
            '& fieldset': {
                backgroundColor: theme.palette.background.textfieldDisabled+'!important',
            }
        },
    // (Note: space or no space after `&` matters. See SASS "parent selector".)
    '& .MuiOutlinedInput-root': {
        minHeight:'54px !important',    
        '& input,svg,textarea,div,span': {
            zIndex: 1
        },
        '& input[type="file"] ':{
            height:'40px !important',
            padding:'7px 11px',
            margin:'0 !important'
        },
        // - The Input-root, inside the TextField-root
        '& fieldset': {
            backgroundColor: theme.palette.background.textFieldBg2,
            border: '0.5px solid ',
            borderColor: theme.palette.input.primary,
            borderRadius: '4px !important',
        },
        '&:hover fieldset': {
            border: '0.5px solid',
            borderColor: theme.palette.input.primary,

        },
        '&:active svg': {
            'svg': {
                color: theme.palette.input.inputIcon,
                zIndex: 1
            },
        },
        '&.Mui-focused fieldset ': { // - Set the Input border when parent is focused 
            border: '0.5px solid rgba(217, 121, 4, 0.2)',
        },
        '&.Mui-focused svg ': {
            color: theme.palette.primary.main,
        },
    },
    // INFO: Below is the code, resizing the text field in small screens
   [theme.breakpoints.down("lg")]: {
        '& .MuiOutlinedInput-root': {
            height:multiline ? '100% !important' : '40px !important',// INFO: for height of text field
            '& input[type="file"] ':{
                '&::file-selector-button': {
                   marginTop:'7px',
                    padding: '7px 11px !important', 
                }
        }
        },

        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0px',
            position:'absolute',
            width:'100% !important',
        }
    },
})));

export const EInputAdornment = styled(InputAdornment)((({ theme }) => ({
    color: theme.palette.input.inputIcon,
    zIndex:'1'
})));
  /**
 * [2022-01-10]
 * changes by: Purva Sharma
 * Description:- AutoComplete dropdown styles
 **/
export const ETextFieldSearch = styled(TextField)((({ theme }) => ({
    borderRadius: '4px !important',
    borderColor: theme.palette.input.primary,
    paddingRight: '0px!important',
    '& .MuiOutlinedInput-root': {
        paddingRight: '0.6rem',//previously it was 4px
    // paddingLeft:'6px !important',
    height:'40px', //INFO: height of textfield
    minWidth:'8rem', //INFO: 200px, default min width of search textfield ; changes on 23-3-23 from 12.5rem
    marginRight:'0.625rem', //INFO: 10px, default right margin of search textfield
    [theme.breakpoints.down('md')]: {
        minWidth:'8rem',
    },

   
        "& input:-internal-autofill-selected": {
            'WebkitTextFillColor': theme.palette.text.subtitle2,
            'WebkitBoxShadow': `0 0 0px 1000px ${theme.palette.background.textFieldBg2} inset`,
            'WebkitBorderRadius': '0',
            padding: '8px 14px',
            // borderLeft: `1px solid ${theme.palette.primary.main}`
            // -webkit-border-left-colors
        },
        '& :-webkit-autofill': {
            'WebkitTextFillColor': theme.palette.text.subtitle2,
            'WebkitBoxShadow': `0 0 0px 1000px ${theme.palette.background.textFieldBg2} inset`,
            'WebkitBorderRadius': '0',
            padding: '8px 14px',
            borderLeft: `1px solid ${theme.palette.input.primary}`
        },

        '& input': {
            fontSize:'0.875rem'// '14px'
        },
        '& input,svg,textarea': {
            zIndex: 1,
        },
        '& svg': { //INFO: this css is for svg in this textfield
            height:'24px',
            width:'24px',
            color: theme.palette.input.inputIcon,
            marginRight: '4px!important',
        },
        '& fieldset': {
            backgroundColor: theme.palette.background.textFieldBg2,
            border: '0.5px solid ',
            borderColor: theme.palette.input.primary,
            borderRadius: '4px !important',
        },
        '&:hover fieldset': {
            border: '0.5px solid',
            borderColor: theme.palette.input.primary,
            'svg': {
                color: theme.palette.input.inputIcon,
                zIndex: 1
            },
        },
        '&.Mui-focused fieldset': {
            border: '0.5px solid rgba(217, 121, 4, 0.2)',
        },
        '&.Mui-focused svg ': {
            color: theme.palette.primary.main,
        },

    },
   

})));


//TextField for login auth forms
export const ETextFieldLogin = styled(TextField)((({ theme }) => ({
    borderRadius: '4px !important',
    borderColor: theme.palette.input.primary,
    '& .Mui-error': {
        marginLeft: '0px'
    },
    '& .MuiOutlinedInput-root': {
        '& input,svg,textarea': {
            zIndex: 1
        },
        // - The Input-root, inside the TextField-root
        '& fieldset': {
            backgroundColor: theme.palette.background.textFieldBg2,
            border: '0.5px solid ',
            borderColor: theme.palette.input.primary,
            borderRadius: '4px !important',
        },
        '&:hover fieldset': {
            border: '0.5px solid',
            borderColor: theme.palette.input.primary,

        },
        '&:active svg': {
            'svg': {
                color: theme.palette.input.inputIcon,
                zIndex: 1
            },
        },
        '&.Mui-focused fieldset ': { // - Set the Input border when parent is focused 
            border: '0.5px solid rgba(217, 121, 4, 0.2)',
        },
        '&.Mui-focused svg ': {
            color: theme.palette.primary.main,
        },
    },
})));


export const ETextFieldSmall = styled(ETextField)((({ theme }) => ({ //this is ETextField just small sized
    minHeight: '40px',
    '& .MuiOutlinedInput-root': {
        minHeight: '40px',// INFO: for height of text field
        'input': {
            padding: '8px 10px'
        }
    },
})));