
import { Grid, Stack, useTheme } from "@mui/material";
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from "react-redux";
import { NewPasswordForUser } from "../../action/EmployeeAuthAction";
import { ETextField } from "../../BaseComponents/ETextField";
import { NewPassSchema } from "../../utils/validation";
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { EFieldLabel, ETypographyPageHeading, EViewFieldLabel } from "../../BaseComponents/ETypography";
import Typography from '@mui/material/Typography';
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";


/**
 * [2023-04-05]
 * Admin: Aanchal Sahu
 * Description: This Form is used in User Management section. The concept is that it will be used by admin to change the user's password without knowing the old one.
 **/
function UserPasswordChangeForm(props) {
  const dispatch = useDispatch();
  const { search, loading, auth, data,page, close} = props;
  const theme = useTheme();  


  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      employee_id: data?.id? data?.id: null,
    },
    onSubmit: (data) => {
      data = {
        ...data,
        search: { ...search },
        updated_by: auth?.authData?.id,
      };
      dispatch(NewPasswordForUser(auth.authtoken, data,page, close));
    }
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 0, sm: 1, md: 2 }} mt={1}>

                <Grid mt={1} px={2}>
                  <EFieldLabel sx={{fontSize:'1rem'}}>
                  Do you wish to reset the password for <span className="theme-color-static">{data.fullname}</span>?<br/>
                      Please note that an email with updated credentials will be sent to the registered email address of the employee.
                  </EFieldLabel>
                </Grid>
                
                {/* Employee Name */}
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true} isNotForm={true}>
                    {data?.fullname? data?.fullname:''}{data?.employee_code? '('+data?.employee_code+')':''}
                  </ELabelInputMainGrid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'New Password'} isfullgrid={true} isModal={true}>
                    <ETextField
                      fullWidth
                      placeholder="New Password"
                      {...getFieldProps('new_password')}
                      error={Boolean(touched.new_password && errors.new_password)}
                      helperText={touched.new_password && errors.new_password}
                    />
                  </ELabelInputMainGrid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ELabelInputMainGrid label={'Confirm Password'} isfullgrid={true} isModal={true}>
                    <ETextField
                      fullWidth
                      placeholder="Confirm Password"
                      {...getFieldProps('confirm_password')}
                      error={Boolean(touched.confirm_password && errors.confirm_password)}
                      helperText={touched.confirm_password && errors.confirm_password}
                    />
                  </ELabelInputMainGrid>
                </Grid> */}

                <Grid item xs={12} xl={12} className="modal1-buttons-stick-bottom">
                  <Stack direction="row" spacing={2} justifyContent='flex-end'>
                    <EButtonOutlined color="secondary" onClick={() => close()} variant="outlined" size="large"> Cancel </EButtonOutlined>{/* back button */}
                    <ELoadingButton type="submit" variant="contained" size="large" loading={loading}> Send </ELoadingButton>
                  </Stack>
                </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}

export default UserPasswordChangeForm;
