import React, { useEffect, useState } from "react";
import {
  Grid, Stack, styled, IconButton,
  InputAdornment
} from "@mui/material";
import { EFieldLabelSemiBold, ETypography } from "../../../BaseComponents/ETypography";
import { ETextFieldLogin } from "../../../BaseComponents/ETextField";
import { EButton } from "../../../BaseComponents/EButtons";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { Logout, UpdateCredentialsAction, logoutReset } from "../../../action/AuthAction";
import { UpdateCredentialsSchema } from "../../../utils/validation";
import { AuthLockIconSolid } from "../../../layouts/icons/auth-lock-icon-solid";
import { THEMES, USER_LOGIN_STATUS } from "../../../constants";
import useSettings from "../../../hooks/useSettings";
import { ETooltip } from "../../../BaseComponents/ETooltip";

const AuthGrid = styled(Grid)(({ theme }) => (
  {
    textAlign: 'center',
  }));


export const AuthUpdateCredentialsForm = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);

    // Handling tooltip on password field to show information
    const [isPwdClicked, setIsPwdClicked] = useState(false);
    const handleTooltipClose = () => {
      setIsPwdClicked(false);
    };
  
    const handleTooltipOpen = () => {
      setIsPwdClicked(true);
    };


  const { authData, authtoken } = useSelector((state) => state.authReducer);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      confirm_password: '',
      secretToken: authtoken,
      login_status: authData?.login_status,
    },
    validationSchema: UpdateCredentialsSchema(authtoken, authData?.id),
    onSubmit: (data) => {
      dispatch(UpdateCredentialsAction(data, navigate));
    }
  });

  const { errors, touched, handleSubmit, getFieldProps, values } = formik;


  // Setting the theme to light 
   const {  saveSettings } = useSettings();

    useEffect(()=>{
        saveSettings({
            theme:THEMES.LIGHT
        })
    },[])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <AuthGrid>
            <AuthLockIconSolid />
          </AuthGrid>

          <AuthGrid >
            <ETypography variant="h5" mb={0.5} mt={3} className="font-size-20px">
              Change Password
            </ETypography>
            <ETypography mb={1}>
              Your password must have at least 8 characters and should include letters and numbers only.
            </ETypography>
          </AuthGrid>

          {
            values.login_status === USER_LOGIN_STATUS.FIRST_LOGIN
            &&
            <div className=" text-align-webkit-left" >
              <EFieldLabelSemiBold className="line-height-140perc">Custom Username</EFieldLabelSemiBold>
              <ETextFieldLogin
                fullWidth
                autoComplete="off"
                placeholder="Enter your custom username"
                type={'text'}

                {...getFieldProps('username')}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
            </div>
          }

          <div 
            onMouseOver={() => setIsPwdClicked(true)}
            onMouseLeave={() => setIsPwdClicked(false)}
          className=" text-align-webkit-left" >
            <EFieldLabelSemiBold className="line-height-140perc">New Password</EFieldLabelSemiBold>
            <ETooltip
              onClose={() => handleTooltipClose}
              open={isPwdClicked}
              arrow placement="right-start" signup="true" title={
                <ul className="p-16px">
                  <ETypography className="font-size-16px bold-500">
                    Password must:
                  </ETypography>
                  <p className="pl-16px signup-info-list">
                    <li>Be a minimum of 8 characters</li>
                    <li>Include at least one lowercase letter (a-z)</li>
                    <li>Include at least one uppercase letter (A-Z)</li>
                    <li>Include at least one number (0-9)</li>
                    <li>Include at least one symbol (!@#$%^&*_=+-)</li>
                  </p>
                </ul>
              }>
              <ETextFieldLogin
                fullWidth
                autoComplete="off"
                placeholder="Enter your new password"
                type={showPassword ? 'text' : 'password'}
                onClick={handleTooltipOpen}  

                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}

                      </IconButton>
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </ETooltip>
          </div>

          <div className=" text-align-webkit-left " >
            <EFieldLabelSemiBold className="line-height-140perc ">Confirm Password</EFieldLabelSemiBold>
            <ETextFieldLogin
              fullWidth
              placeholder="Confirm your new password"
              autoComplete="false"
              type={showConPassword ? 'text' : 'password'}
              {...getFieldProps('confirm_password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConPassword((show) => !show)} edge="end">
                      {showConPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              InputLabelProps={{ shrink: true }}
              error={Boolean(touched.confirm_password && errors.confirm_password)}
              helperText={touched.confirm_password && errors.confirm_password}
            />
          </div>

          <EButton
            fullWidth
            size="large"
            type="submit"
            className="borderRadius-4px font-size-16px "
            pt={2}
          >
            Update Password
          </EButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

