import axios from "axios";
import { GET_CALENDAR_DASHBOARD_FAILURE, GET_CALENDAR_DASHBOARD_LOADING, GET_CALENDAR_DASHBOARD_SUCCESS, SERVER_URL } from "../constants";
import { Logout } from "./AuthAction";

export function getCalendarDashboard(token, date, setLoading = () => null) {

    return (dispatch) => {
        setLoading(true)
        dispatch(getCalendarDashboardLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/dashboard/calender-data?date=${date}`,
            // crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getCalendarDashboardSuccess(res?.data?.data))
                }

            }).catch((error) => {
                if (error?.response) {
                    dispatch(getCalendarDashboardFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                    // Above if block was commented
                }
            }).finally(() => setLoading(false))
    };
}

export function getCalendarDashboardLoading() {
    return {
        type: GET_CALENDAR_DASHBOARD_LOADING,
    };
}
export function getCalendarDashboardSuccess(payload) {
    return {
        type: GET_CALENDAR_DASHBOARD_SUCCESS,
        payload
    };
}

export function getCalendarDashboardFailure(payload) {
    return {
        type: GET_CALENDAR_DASHBOARD_FAILURE,
        payload
    };
}