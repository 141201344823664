import React from 'react';
import { EButton } from '../../BaseComponents/EButtons';
import { EBox } from '../../BaseComponents/EBox';
import { ETypography } from '../../BaseComponents/ETypography';
import { GST, PLAN_TIME_FREQUENCY_TYPE } from '../../constants';
import { numberWithCommas } from '../../utils/formatNumber';
import { getBillTenure } from '../../utils/CommonFunctions';
import { ECardBorderLess } from '../../BaseComponents/ECard';

export const PaymentSummary = ({ data, skipButton, isAdditonal = false, title = null }) => {

  return (

    <ECardBorderLess className='p-34px'>
      <ETypography className='font-size-20px theme-color-static bold-400' mb={1}>
        Payment Summary
      </ETypography>

      <EBox className='p0'>
        {/*  Plan */}
        <EBox display="flex" alignItems="center">
          <ETypography className='font-size-16px bold-600'>{isAdditonal ? 'Additional License ' : 'Standard Plan '}</ETypography>
          <ETypography className='font-size-14px'>{` (₹ ${Number(data?.plan_data?.price || 0)} per employee/month)`}</ETypography>
        </EBox>

        {/*  Bill Tenure */}
        <FlexBox
          label={'Bill Tenure'}
          title={title ? title : getBillTenure(data?.plan_data?.plan_time_frequency_type, data?.plan_data?.plan_valid_from, data?.plan_data?.plan_valid_to)}
          subtitle={data?.plan_data?.plan_time_frequency_type == PLAN_TIME_FREQUENCY_TYPE.YEAR ? '(Billed Annually)' : '(Billed Monthly)'} />

        {/* No.of Talent */}
        <FlexBox
          label={'No.of Employee'}
          title={data?.noOfTalent} />

        <EBox borderTop={1} borderColor={'#D0D0D0'} py={2} className='display-flex justify-content-space-between align-center'>
          <ETypography className='bold-600 font-size-16px'>
            Sub Total
          </ETypography>

          <ETypography className='bold-600 text-align-right font-size-16px'>
            {numberWithCommas(data?.plan_cost || data?.planCost || 0)}
          </ETypography>

        </EBox>
        {/* GST Detail */}
        {
          (data.igst || data.cgst) ?
            <EBox mt={-2}>
              {data.igst
                ?
                <FlexBox
                  label={`IGST (${GST}%)`}
                  title={`${numberWithCommas(data.igst)}`} />
                :
                <>
                  <FlexBox
                    label={`CGST (${GST / 2}%)`}
                    title={`${numberWithCommas(data.cgst)}`} />
                  <FlexBox
                    label={`SGST (${GST / 2}%)`}
                    title={`${numberWithCommas(data.sgst)}`} />
                </>}
            </EBox>
            :
            null
        }
      </EBox>

      {/*   Grand Total */}
      <EBox borderTop={1} borderBottom={1} borderColor={'#D97904'} py={2} className='display-flex justify-content-space-between align-center' mb={2}>
        <ETypography className='bold-600 font-size-20px'>
          Grand Total
        </ETypography>

        <ETypography className='bold-600 text-align-right font-size-24px theme-color-static'>
          {numberWithCommas(data?.grand_total || data?.total || 0)}
        </ETypography>
      </EBox>

      <EButton type='submit' fullWidth>
        Pay Now
      </EButton>
      {skipButton}
    </ECardBorderLess>

  )
}

const FlexBox = ({ label, title, subtitle }) => {
  return (
    <EBox className='display-flex justify-content-space-between' marginY={1}>
      <ETypography className='bold-400 font-size-14px color-black'>
        {label}
      </ETypography>
      <ETypography className='bold-400 font-size-14px text-align-right color-black'>
        {title}
        <br />
        {
          subtitle &&
          <span className='bold-400 font-size-12px greyColor5-color'> {subtitle}</span>
        }
      </ETypography>
    </EBox>
  )
}
