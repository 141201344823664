import { GET_CALENDAR_DASHBOARD_FAILURE, GET_CALENDAR_DASHBOARD_LOADING, GET_CALENDAR_DASHBOARD_SUCCESS } from '../constants';

const INITIAL_STATE = {
    calendarDashboardData: [],
    calendarDashboardSuccess: false,
    calendarDashboardLoading: false,
    calendarDashboardMessage: null,
};

const CalendarDashboardReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_CALENDAR_DASHBOARD_LOADING:
            return {
                ...state,
                calendarDashboardLoading: true,
                calendarDashboardData: []
            };

        case GET_CALENDAR_DASHBOARD_SUCCESS:
            return {
                ...state,
                calendarDashboardLoading: false,
                calendarDashboardSuccess: true,
                calendarDashboardData: action.payload,
                calendarDashboardMessage: action.payload.message,
            };

        case GET_CALENDAR_DASHBOARD_FAILURE:
            return {
                ...state,
                calendarDashboardLoading: false,
                calendarDashboardSuccess: false,
                calendarDashboardMessage: action.payload.message,
            };

        default:
            return state;
    }
};

export default CalendarDashboardReducer;