import React, { useState } from 'react'
import { EBoxHRDash } from '../../BaseComponents/EBox'
import { ETypography } from '../../BaseComponents/ETypography'
import { EAutocomplete } from '../../BaseComponents/EAutocomplete'
import { ETextField } from '../../BaseComponents/ETextField'
import { Line } from 'react-chartjs-2';
import { EBox } from '../../BaseComponents/EBox';
import { EChipHrStats } from '../../BaseComponents/EChip';
import { Box, useTheme } from '@mui/material';
// import StatisticsChart from './StatisticsChart'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  //   Filler //used to fill bottom
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  //   Filler
);

const AttendanceStats = ({ attendanceStatsArray }) => {

  const theme = useTheme();


  // Initializing the state variables for selectedShift, labels, and backgroundColors
  const [selectedShift, setSelectedShift] = useState(
    attendanceStatsArray?.length > 0 ? attendanceStatsArray[0] : {}
  );

  // Finding the shiftData that matches the selectedShift
  const shiftData = attendanceStatsArray?.find(
    (item) => item?.shift_id === selectedShift?.shift_id
  );

  // const formattedLabels = shiftData?.shift_labels?.map(dateStr => {
  //   const date = moment(dateStr);
  //   return date.format("DD MMM");
  // });


  // const data = {
  //   labels: formattedLabels || [],
  //   datasets: [
  //     {
  //       label: '',
  //       data: shiftData?.checkin_is_desktop || [],
  //       backgroundColor: '#A6775B', //Point background color
  //       borderColor: '#D9B88F', //Line background color

  //       // tension:0.4, //for smoothness of the line in graph
  //       // fill:true //to fill the bottom of the line
  //       // pointStyle:'rect', //Rectangle instead of circle in graph
  //     },
  //     {
  //       label: '',
  //       data: shiftData?.checkin_is_mobile || [],
  //       backgroundColor: '#2C4A73', //Point background color
  //       borderColor: '#049DBF', //Line background color
  //     }
  //   ]
  // }

  // const options = {

  //   responsive: true,
  //   height: 150,

  //   plugins: {
  //     legend: {
  //       display: false,
  //       position: 'bottom',
  //       align: 'start',
  //       reverse: false, //put True if you want to reverse the legend order
  //       labels: {
  //         boxWidth: 90, // The width of the colored boxes in the legend
  //         boxHeight: 30, //Height of the colored boxes in the legend
  //         fontSize: 12, // The font size of the legend labels
  //         fontStyle: "normal", // The font style of the legend labels (normal, italic, oblique)
  //         fontColor: "#666", // The font color of the legend labels
  //         // fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif", // The font family of the legend labels
  //         padding: 10, // The padding between the legend items and the legend container
  //         usePointStyle: false, // Whether to use a circle or square for the colored boxes in the legend
  //       },
  //     },

  //     title: {
  //       display: false,
  //       text: 'HR dashboard Statistics Chart',
  //     },
  //   },
  // }

  const isDataAvailable = (parseInt(shiftData?.checkin_is_desktop) + parseInt(shiftData?.checkin_is_mobile)) > 0;

  return (
    <>
      <EBoxHRDash className="font-size-20px bold-400">
        <ETypography className="font-size-20px bold-600">Attendance Statistics</ETypography>

        {/* If the attendanceStatsArray contains more than one chartData object, display an Autocomplete component that allows users to select a specific shift*/}
        {
          attendanceStatsArray && attendanceStatsArray.length > 1 &&
          <EAutocomplete
            clearIcon={false}
            options={attendanceStatsArray}
            isOptionEqualToValue={(option, value) => option.shift_id === value.shift_id}
            getOptionLabel={(option) => option.shift_name || ''}
            renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
              <Box component="li" {...props} key={option.shift_id}>
                {option.shift_name}
              </Box>
            )}
            value={selectedShift}
            onChange={(event, value) => setSelectedShift(value)}
            sx={{ width: '250px' }}
            renderInput={(params) => <ETextField {...params} placeholder='Shift' />}
          />
        }

      </EBoxHRDash>

      {
        isDataAvailable ? (
          <EBoxStatsDiv>

            <EBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: '0px',
                width: '187px',
                height: '67px',
                borderRadius: '2px',
              }}>
              <ETypography sx={{ color: '#808080', fontSize: '26px', lineHeight: '22.4px', marginBottom: '10px' }}>{shiftData?.shift_name || ""} </ETypography>
              <ETypography sx={{ color: '#808080', fontSize: '18px' }}>Average Check in Time</ETypography>
              <ETypography sx={{ fontSize: '40px', fontWeight: '600' }}>{shiftData?.check_in_average_time || "00 : 00"}</ETypography>
            </EBox>

            <EBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: '0px',
                borderRadius: '2px',
              }}>

              <ETypography sx={{ color: '#808080', fontSize: '26px', lineHeight: '22.4px' }}>Today</ETypography>

              <EChipHrStats
                label={
                  <>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: '24px',
                        lineHeight: '120%',
                        marginRight: '4px',
                      }}
                    >
                      {shiftData?.checkin_is_desktop || 0}
                    </span>
                    <span style={{ fontWeight: '400', fontSize: '16px' }}>Desktop Checkin</span>
                  </>
                }
                sx={{
                  backgroundColor: theme.palette.background.darkOrangeBrown,
                  color: 'white',
                  textAlign: 'left',
                  minWidth: '170px',
                  display: 'flex',
                  margin: '15px 0 !important',
                  justifyContent: 'flex-start',
                  padding: '20px 0 !important'
                }}
              />

              <EChipHrStats
                label={
                  <>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: '24px',
                        lineHeight: '120%',
                        marginRight: '4px',
                      }}
                    >
                      {shiftData?.checkin_is_mobile || 0}
                    </span>
                    <span style={{ fontWeight: '400', fontSize: '16px' }}>Mobile Checkin</span>
                  </>
                }
                sx={{
                  backgroundColor: theme.palette.background.midnightBlue,
                  color: 'white',
                  textAlign: 'left',
                  minWidth: '170px',
                  display: 'flex',
                  margin: '15px 0 !important',
                  justifyContent: 'flex-start',
                  padding: '20px 0 !important'
                }}
              />
            </EBox>

          </EBoxStatsDiv>
        ) : (
          
          
          <EBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 0px' }}>
            <img className="no-data-image" src="/assets/images/hrDashboardNodata/attendance statistc.svg" alt="No data" style={{ width: '70%', marginBottom: '4px' }} />
            <ETypography sx={{ fontSize: '16px', fontWeight: '500' }}>No Data Found</ETypography>
          </EBox>
        )
      }



      {/* Commenting graph as per discussion with Dharam sir, Sunil sir and Abhishek */}
      {/* {showImage == true ? (
        <>
          <EBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img className="no-data-image" src="/assets/images/hrDashboardNodata/attendance statistc.svg" alt="No data" style={{ width: '70%', marginBottom: '4px' }} />
            <ETypography sx={{ fontSize: '16px', fontWeight: '500' }}>No Data Found</ETypography>
          </EBox>
        </>
      ) : (
        <Line data={data} options={options} />
      )} */}
    </>
  )
}

export default AttendanceStats

const EBoxStatsDiv = (props) => {
  const { children } = props
  return (
    <EBox
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '8px 0px',
        gap: '10px',
        height: '70%',
      }}
    >
      {children}
    </EBox>
  )
}