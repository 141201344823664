import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import Page from '../../BaseComponents/EPage';
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import Attendance from '../../PageComponents/Attendance/Attendance';
import EHidden from '../../BaseComponents/EHidden';
import AttendanceLog from './AttendanceLog';
import { EBoxPage } from '../../BaseComponents/EBox';
import { ETab, ETabContext, ETabList, ETabPanel } from '../../BaseComponents/ETabs';
import { RegularizationRequest } from './Regularization_request';
import { ACCOUNTANT, MY_ATTENDANCE_TAB, REGUALRIZATION_REQUEST_TAB, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, SUPER_ADMIN, TALENT_ATTENDANCE_TAB } from '../../constants';
import { useLocation } from 'react-router-dom';




export default function Attendance_attendanceLogs() {

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const stateParam = searchParams.get('tab');
    const initialTabValue = stateParam === REGUALRIZATION_REQUEST_TAB ? REGUALRIZATION_REQUEST_TAB : MY_ATTENDANCE_TAB; 
    const [tabValue, setTabValue] = useState(initialTabValue);
    const auth = useSelector(state => state.authReducer);

    const attendanceSetting = useSelector(state => state.SettingReducer)

    const IS_WEB_CHECK_ENABLED = attendanceSetting?.attendanceSettingData?.companySetting?.enable_web

    useEffect(() => {
        if (location.state) {
          setTabValue(REGUALRIZATION_REQUEST_TAB);
        }
      }, [location.state]);
    

    //INFO:  handles tab change
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);

    };

    return (
        <Page title="Attendance Log">
            <Grid container display="row" justifyContent="space-between" >
                {
                    auth?.authData?.role !==  ROLE_EMPLOYEE ? 
                    <EHeaderBreadcrumbs
                    heading='Attendance Log'
                    links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Attendance', href: '/attendance/attendance-log'},
                        {
                            name: 'Attendance Log',
                            href: '/'
                        }
                    ]}
                />:
                <EHeaderBreadcrumbs
                    heading='Attendance Log'
                    links={[
                        { name: 'Dashboard', href: '/' },
                        {
                            name: 'Attendance Log',
                            href: '/'
                        }
                    ]}
                />
                }
                <EHidden width="mdDown">
                    <Grid item>
                        <Attendance status="attendance" IS_WEB_CHECK_ENABLED={IS_WEB_CHECK_ENABLED}/>{/* for CHECKIN, CHECKOUT & BACK BUTTONS */}
                    </Grid>
                </EHidden>

            </Grid>

            <Grid container display="row" justifyContent="space-between" className=' margin-top-20px'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>Attendance Log</ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        <Attendance status="attendance" IS_WEB_CHECK_ENABLED={IS_WEB_CHECK_ENABLED}/>{/* for CHECKIN, CHECKOUT & BACK BUTTONS */}
                    </Grid>
                </EHidden>

                <EHidden width="mdDown">
                    <Grid lg={12} xs={12} item><EDivider className='margin-top-05' /></Grid>
                </EHidden>


            </Grid>

            <EBoxPage>
                {
                    (auth?.authData?.role && [ROLE_EMPLOYEE, ACCOUNTANT].includes(auth?.authData?.role))
                    ?
                    <AttendanceLog tab={tabValue} />
                    :
                <ETabContext value={tabValue} sx={{ paddingTop: '0px' }}>
                    <ETabList
                        sx={{
                            minHeight: ((auth?.authData?.role && [ROLE_EMPLOYEE, ACCOUNTANT].includes(auth?.authData?.role))) && '0'  // Hiding the tab for employee/accountant roles 
                        }}
                        onChange={handleTabChange} aria-label="lab API tabs example" allowScrollButtonsMobile variant="scrollable"
                        alignitems='left!important'>
                        <ETab label="My Attendance" value={MY_ATTENDANCE_TAB}
                            sx={{ display: ((auth?.authData?.role && [ROLE_EMPLOYEE, ACCOUNTANT].includes(auth?.authData?.role))) ? 'none' : 'inline-flex' }}
                        />
                        <ETab label="Employee’s Attendance" value={TALENT_ATTENDANCE_TAB}
                            sx={{ display: ((auth?.authData?.role && [ROLE_ADMIN, ROLE_HR, SUPER_ADMIN].includes(auth?.authData?.role))) ? 'inline-flex' : 'none' }}
                        />
                        <ETab
                            sx={{ display: ((auth?.authData?.role && [ROLE_ADMIN, ROLE_HR, SUPER_ADMIN].includes(auth?.authData?.role))) ? 'flex' : 'none' }}
                            label="Regularization Request" value={REGUALRIZATION_REQUEST_TAB} />

                    </ETabList>


                    {/* MY ATTENDANCE TAB */}
                    <ETabPanel value={MY_ATTENDANCE_TAB} className=' pl-0px'>
                        <AttendanceLog tab={tabValue} />
                    </ETabPanel>

                    {/* EMPLOYEE'S ATTENDANCE TAB */}
                    <ETabPanel value={TALENT_ATTENDANCE_TAB} className=' pl-0px'>
                        <AttendanceLog tab={tabValue} />
                    </ETabPanel>

                    {/* Regularization Request TAB */}
                    <ETabPanel value={REGUALRIZATION_REQUEST_TAB} className=' pl-0px'>
                        <RegularizationRequest />
                    </ETabPanel>

                </ETabContext>
                }

            </EBoxPage>




        </Page>

    )
}