import { Box, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ItemBox } from "../../BaseComponents/EBox";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import { PAYMENT_CATEGORY_ALL, SUPER_ADMIN, ROLE_ADMIN, ROLE_HR, COMPANY_PAYMENT_LOG_DOWNLOAD_URL, DOWNLOAD_CSV, DOWNLOAD_PDF} from "../../constants";
import { ETextFieldSearch } from "../../BaseComponents/ETextField";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { EIcon } from "../../BaseComponents/EIcon";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { DownLoadFile } from "../../action/AdminAction";
import moment from "moment";
import EMenuPopover, { EMenuItem } from "../../BaseComponents/EMenuPopover";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { getPaymentCategory } from "../../utils/getPaymentCategory";
import { useDispatch, useSelector } from "react-redux";
import { AllFreelancerList } from "../../action/FreelancerAction";


const PaymentLogSearchBar = (props) => {

    const { searchParam, setSearchParam, setting,getFilterData, employeeList, companyData, auth } = props;
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // INFO: Since freelancerList is just list of freelancers, we have imported it in here; lessening the dependency with another components
    const freelancerList = useSelector((state) => state.FreelancerReducer);

    // INFO: Commented PAYMENT_CATEGORY_NEW since it was not used anywhere
    //  const PAYMENT_CATEGORY_NEW = setting?.paymentSettingData?.enable_reimbursement ? PAYMENT_CATEGORY_ALL : PAYMENT_CATEGORY_ALL.filter((item)=>item.id !== PAYMENT_CATEGORY_REIMBURSEMENT )

    const setSearch = (key, value) => {
        const sParams = { ...searchParam, [key]: value };
        setSearchParam(sParams);
        getFilterData(sParams)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //INFO: Calling this All Freelancer list here as it was increasing the number of props and dependency as well 
    useEffect(() => {
        if (auth.authtoken) {
            dispatch(AllFreelancerList(auth?.authtoken));//INFO: calling this API as a replacement of FreelancerListData
        }
    }, [auth?.authtoken])

    const DownloadReport = (value) => {

        let url = '';
        let file_name = 'Payment_log';
        url += COMPANY_PAYMENT_LOG_DOWNLOAD_URL + '?page_name=talent_log&';

        if (value != null) { url += `download_format=${value}&`; }
        if (searchParam?.employee_id?.id != null) { url += `user_id=${searchParam?.employee_id?.id}`; }
        if (searchParam?.particular?.id != null) { url += `transaction_category=${searchParam?.particular?.id}`; }
        if (searchParam?.start_date != null) { url += `start_date=${moment(searchParam?.start_date).format('YYYY-MM-DD')}`; }

        DownLoadFile(auth.authtoken, url, file_name);

        setAnchorEl(null);
        handleClose();
    };

    return (
        <>
            <Grid
                container
                className="mb-16px"
            >
                <Grid className="pl-0" item sm={8} xs={12} md={8} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <ItemBox  className="ml-0px mr-16px">
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <EDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={searchParam.start_date ? new Date(searchParam.start_date) : null}
                                    minDate={new Date(companyData?.companyData?.CompanyActivePlan?.plan_valid_from)}
                                    maxDate={searchParam.end_date ? new Date(searchParam.end_date) : new Date()}
                                    clearable
                                    onChange={(newValue) => setSearch('start_date', newValue ? newValue : null)}
                                    renderInput={({ inputProps, ...params }) => <ETextFieldSearch
                                        size='small'
                                        sx={{ width: 200 }}
                                        {...params}
                                        inputProps={{
                                            ...inputProps,
                                            placeholder: "Start Date",
                                        }}
                                    />}
                                />
                            </LocalizationProvider>
                        </ItemBox>

                        <ItemBox  className="ml-0px mr-16px ">
                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                <EDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={searchParam.end_date ? new Date(searchParam.end_date) : null}
                                    minDate={
                                        searchParam.start_date
                                            ? new Date(searchParam.start_date)
                                            : new Date(companyData?.companyData?.CompanyActivePlan?.plan_valid_from)
                                    }
                                    maxDate={new Date()}
                                    clearable
                                    onChange={(newValue) => setSearch('end_date', newValue ? newValue : null)}
                                    renderInput={({ inputProps, ...params }) => <ETextFieldSearch
                                        size='small'
                                        sx={{ width: 200 }}
                                        {...params}
                                        inputProps={{
                                            ...inputProps,
                                            placeholder: "End Date",
                                        }}
                                    />}
                                />
                            </LocalizationProvider>
                        </ItemBox>

                        {employeeList && <>

                            {(auth?.authData?.role == ROLE_HR ||
                                auth?.authData?.role == ROLE_ADMIN ||
                                auth?.authData?.role == SUPER_ADMIN) && <ItemBox  className="ml-0px mr-16px ">
                                    <EAutocomplete
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        options={employeeList && employeeList?.employeeData?.count > 0 ? employeeList?.employeeData?.rows : []}
                                        value={searchParam?.employee_id}
                                        getOptionLabel={(option) => option.label || ''}
                                        onChange={(e, value) => {
                                            setSearch('employee_id', value ? value : null);
                                        }}
                                        renderInput={(params) => (
                                            <ETextFieldSearch
                                                {...params}
                                                name="talent"
                                                size="small"
                                                sx={{ minWidth: 200 }}
                                                placeholder="Select Employee"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </ItemBox>}

                            <ItemBox  className="ml-0px mr-16px ">
                                <EAutocomplete
                                    name="sort"
                                    // options={PAYMENT_CATEGORY_NEW}
                                    options={ 
                                setting?.paymentSettingData?.companySetting?.enable_reimbursement && setting?.paymentSettingData?.companySetting?.enable_advance_salary
                                ?
                               getPaymentCategory('ENABLE_BOTH',PAYMENT_CATEGORY_ALL):
                                setting?.paymentSettingData?.companySetting?.enable_reimbursement ?
                               getPaymentCategory('ENABLE_REIMBURSEMENT',PAYMENT_CATEGORY_ALL)
                          :   setting?.paymentSettingData?.companySetting?.enable_advance_salary ?
                               getPaymentCategory('ENABLE_ADVANCE_SALARY',PAYMENT_CATEGORY_ALL) :  getPaymentCategory('DISABLE_BOTH',PAYMENT_CATEGORY_ALL)
                          
                            
                            
                            }
                                    value={searchParam?.particular}
                                    onChange={(e, value) => {
                                        setSearch('particular', value ? value : null);
                                    }}
                                    renderInput={(params) => (
                                        <ETextFieldSearch
                                            {...params}
                                            name="sort"
                                            sx={{ minWidth: 200 }}
                                            placeholder="Select Particular"
                                            size="small"
                                        />
                                    )}
                                />
                            </ItemBox>
                        </>
                        }


                        {!employeeList && <>
                            {(auth?.authData?.role == ROLE_HR ||
                                auth?.authData?.role == ROLE_ADMIN ||
                                auth?.authData?.role == SUPER_ADMIN) && 
                                    <ItemBox>
                                    <EAutocomplete
                                        name="freelancer_id"
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        options={freelancerList && freelancerList?.allFreelancerData?.count > 0 ? freelancerList?.allFreelancerData?.rows : []}
                                        // INFO: changed below code to above. since we are using allFreelancerData to store whole list of freelancers
                                        // options={freelancerList && freelancerList?.freelanceListData?.count > 0 ? freelancerList?.freelanceListData?.rows : []} 
                                        value={searchParam?.freelancer_id}
                                        getOptionLabel={(option) => option.label || ''}
                                        onChange={(e, value) => {
                                            setSearch('freelancer_id', value ? value : null);
                                        }}
                                        renderInput={(params) => (
                                            <ETextFieldSearch
                                                {...params}
                                                name="freelancer_id"
                                                size="small"
                                                sx={{ minWidth: 200 }}
                                                placeholder="Select Freelancer"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </ItemBox>}
                        </>
                        }

                    </Grid>
                </Grid>
                <Grid item sm={4} xs={12} md={4}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                    <ETooltip title={'Download'} arrow>
                        <IconButton onClick={handleClick}>
                            <EIcon icon={FileDownloadIcon} />
                        </IconButton>
                    </ETooltip>
                        <EMenuPopover
                            open={open}
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            sx={{ width: 180, top: 40 }}
                        >
                            <EMenuItem
                                sx={{ typography: 'body2', py: 1, px: 2.5 }}
                                onClick={() => DownloadReport(DOWNLOAD_PDF)}
                            >
                                <Box
                                    sx={{ mr: 2, width: 24, height: 24 }}>
                                    <PictureAsPdfIcon />
                                </Box>
                                PDF
                            </EMenuItem>
                            <EMenuItem
                                sx={{ typography: 'body2', py: 1, px: 2.5 }}
                                onClick={() => DownloadReport(DOWNLOAD_CSV)}
                            >
                                <Box
                                    sx={{ mr: 2, width: 24, height: 24 }} >
                                    <InsertDriveFileIcon />
                                </Box>
                                CSV
                            </EMenuItem>
                        </EMenuPopover>

                        <ETooltip title={'Sort By Name'} arrow>
                        <IconButton
                        // onClick={() => setSearch('sort', !searchParam.sort)}
                        >
                            <SortByAlphaIcon />
                        </IconButton>
                        </ETooltip>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
};

export default PaymentLogSearchBar;