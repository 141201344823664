import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import BillingInfoForm from './BillingInfoForm';
import EModal from '../../BaseComponents/EModal';
import { EBox } from '../../BaseComponents/EBox';
import { ETypography } from '../../BaseComponents/ETypography';
import { EIcon } from '../../BaseComponents/EIcon';
import editFill from "@iconify/icons-material-symbols/edit-outline-rounded";
import { StateList } from '../../action/StateCityAction';

function BillingInfo() {
    const dispatch = useDispatch();
    // Modal state for billing information form
    const [formOpen, setFormOpen] = useState(false)
    
    const companyDetail = useSelector((state) => state.GlobalSettingReducer);
    const auth = useSelector((state) => state.authReducer);
    const company = companyDetail?.globalSettingData?.companySetting?.company_data

    useEffect(()=>{
        dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
    },[])
    return (
        <>
            <EBox mt={2}>
                <EBox className='flex-row-spaceBetween'>
                    <ETypography className='font-size-20px theme-color-static bold-400 '>
                        Address
                    </ETypography>

                    <EIcon className='cursor-pointer' onClick={() => setFormOpen(!formOpen)} icon={editFill} width={23} height={23} />
                </EBox>

                <ETypography mt={1.5} className='font-size-14px bold-700'>
                  {company?.company_name ?  company?.company_name : '-'}
                </ETypography>

                <ETypography  className='font-size-14px'>
                    {company?.address}
                    {company?.permanentCity?.label ? ', ' + company?.permanentCity?.label : ''}
                    {company?.permanentState?.label ? ', ' + company?.permanentState?.label : ''}
                    {company?.pin_code ? ', ' + company?.pin_code : ''}
                </ETypography>
                <ETypography className='font-size-14px'>
                    Contact Number: {company?.company_phone ? '+91-' + company?.company_phone : '-'}
                </ETypography>

                <ETypography className='font-size-14px'>
                    Email ID: {company?.company_email ? company?.company_email : '-'}
                </ETypography>

                <ETypography className='font-size-14px'>
                    {company?.company_gst ? 'GSTIN: ' + company?.company_gst : ''}
                </ETypography>
            </EBox>

            {/* Billing INformation Form */}
            {
                formOpen && (
                    <EModal open={formOpen} close={() => setFormOpen(false)} headervalue="Update Address" >
                        <BillingInfoForm auth={auth} data={company} close={() => setFormOpen(false)} />
                    </EModal>
                )
            }
        </>



    )
}

export default BillingInfo