import {
    SUBSCRIPTION_PLAN_FAILURE, SUBSCRIPTION_PLAN_LOADING, SUBSCRIPTION_PLAN_SUCCESS
} from '../constants';


const INITIAL_STATE = {
   subscriptionPlanData: [],
   subscriptionPlanSuccess: false,
   subscriptionPlanLoading: false,
   subscriptionPlanMessage: null,

};

const SubscriptionPlanReducer = (plan = INITIAL_STATE, action) => {
    switch (action.type) {    

        case SUBSCRIPTION_PLAN_LOADING:
            return {
               subscriptionPlanLoading: true,
                ...plan
            };

        case SUBSCRIPTION_PLAN_SUCCESS:
            return {
               subscriptionPlanLoading: false,
               subscriptionPlanSuccess: true,
               subscriptionPlanData: action.payload.data,
               subscriptionPlanMessage: action.payload.message,
            };

        case SUBSCRIPTION_PLAN_FAILURE:
            return {
               subscriptionPlanLoading: false,
               subscriptionPlanSuccess: false,
               subscriptionPlanMessage: action.payload.message,
               subscriptionPlanData: []
            };

        default:
            return plan;
    }
};

export default SubscriptionPlanReducer;
