import React from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, Grid } from "@mui/material";
import { checkOutDpr } from "../../utils/validation";
import { CHARACTER_LIMIT, WORK_MODE_HOME, WORK_MODE_HYBRID, WORK_MODE_OFFICE } from "../../constants";
import moment from "moment";
import PropTypes from "prop-types";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { ETextField } from "../../BaseComponents/ETextField";
import { useDispatch } from "react-redux";
import { CheckinCheckoutAttendance } from "../../action/AttendanceAction";
import useIp from "../../utils/useIp";
import { EFieldLabel, EWordCount } from "../../BaseComponents/ETypography";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import { browserName, isMobile } from 'react-device-detect';

//auth is mandatory in this component
CheckOut.propTypes = {
  auth: PropTypes.object.isRequired,
  sx: PropTypes.object,
  attendanceSettings: PropTypes.object.isRequired
};


const CheckOutBox = (props) => {
  const { auth, close, ipAddress } = props;
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      remark: "",
      updated_by: auth?.authData?.id,
      employee_id: auth?.authData?.id,
      attendance_date: moment().format("YYYY-MM-DD"),
      ip_address: ipAddress ? ipAddress : null,
      is_mobile: isMobile,
      browser_name: browserName
    },
    validationSchema: checkOutDpr,
    onSubmit: (data) => {
      dispatch(CheckinCheckoutAttendance(auth.authtoken, data));
      close();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    resetForm,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container className="align-center">
          <Grid item sx={{ display: { xs: 'none', sm: 'none', md: 'grid' } }} md={4} lg={4} xl={4}>
            <img height={'100%'} width={'100%'} src='/assets/images/writing-animation.gif' alt="No Page Found" /> {/* style={{borderRadius:'44px',marginTop:'50px'}} */}
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            What did I do today ?
            <ETextField
              fullWidth
              multiline
              id="outlined-name"
              name="remark"
              rows={3}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
              onChange={(e, value) => {
                setFieldValue("remark", e?.target?.value);
              }}
              error={Boolean(touched.remark && errors.remark)}
              helperText={touched.remark && errors.remark}
            />
            <EWordCount>{`${formik?.values?.remark.length}/${CHARACTER_LIMIT}`}</EWordCount>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            className="modal1-buttons-stick-bottom"
          >
            <Stack
              direction="row"
              spacing={2}
              className="modal1-buttons-stick-bottom"
            >
              <EButtonOutlined
                size="large"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  close();
                  resetForm();
                }}
              >
                {" "}
                {"Cancel"}
              </EButtonOutlined>
              <ELoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Confirm
              </ELoadingButton>
            </Stack>
          </Grid>

        </Grid>
      </Form>
    </FormikProvider>
  )
}


const IpErrorBox = (props) => {
  const { checkIp, close } = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={12} lg={12} xl={12} className="mb-16px">
        <EFieldLabel>
          {checkIp ?
            <>Please Check-in using the company’s network system</> :
            <>The system is currently unable to retrieve the IP address to which you are connected.
              Please try again later or contact your HR department for assistance.</>}
        </EFieldLabel>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          className="modal1-buttons-stick-bottom"
        >
          <Stack direction={{ xs: "column", sm: "row" }}>
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => {
                close();
              }}
            >
              {" "}
              Close{" "}
            </EButtonOutlined>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function CheckOut(props) {
  const { auth, close, attendanceSettings } = props;
  // To check if company ip and user ip are same
  const { checkIp, ipLoader } = useIp(attendanceSettings.allow_ip_check ? attendanceSettings.ip_address : null);

  /**
   * if company is not enabled the ip setting
   * Then every employee with any mode [Hybrid, Office, Remote] of the company can checkIN
   */

  if (!attendanceSettings.allow_ip_check ||
    auth.authData?.work_mode === parseInt(WORK_MODE_HOME)) {
    return <>
      {!ipLoader ? <>
        <CheckOutBox auth={auth} ipAddress={checkIp} close={close} />
      </> : <LoadingGrid />}
    </>
  }


  /**
   * if company is enabled the ip setting then first we need to fetch the ip address of router 
   * For fetchig ip address we are usig thred party API which is mentioned in useIp Hook    * 
   */

  if (attendanceSettings.allow_ip_check &&
    auth.authData?.work_mode !== parseInt(WORK_MODE_HOME)) {
    return (
      <>
        {!ipLoader ? <>
          {(checkIp && (checkIp == attendanceSettings.ip_address ||
            parseInt(WORK_MODE_HYBRID) == auth.authData?.work_mode)) && <>
              <CheckOutBox auth={auth} ipAddress={checkIp} close={close} />
            </>}

          {(checkIp && checkIp != attendanceSettings.ip_address
            && parseInt(WORK_MODE_OFFICE) == auth.authData?.work_mode) && <>
              <IpErrorBox close={close} checkIp={checkIp} />
            </>}

          {(!checkIp) && <>
            <IpErrorBox close={close} checkIp={checkIp} />
          </>}
        </> : <LoadingGrid />}
      </>
    )
  }
}
