import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import Page from "../../BaseComponents/EPage";
import editOutlineRounded from "@iconify/icons-material-symbols/edit-outline-rounded";
import search from "@iconify/icons-material-symbols/search";
import {
  EListHead,
  ETable,
  ETableBody,
  ETableCell,
  ETableContainer,
  ETableRow,
} from "../../BaseComponents/ETable";
import { DEFAULT_ROWS_PERPAGE, PERMISSIONS, ROW_SIZE_PER_PAGE } from "../../constants";
import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";
import {
  EButton,
  EButtonIcon,
  EButtonOutlined,
  EButtonOutlinedIcon,
  ELoadingButton,
} from "../../BaseComponents/EButtons";
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import {
  EInputAdornment,
  ETextFieldSearch,
} from "../../BaseComponents/ETextField";
import { EDivider } from "../../BaseComponents/EDivider";
import { EBoxPage } from "../../BaseComponents/EBox";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import EModal from "../../BaseComponents/EModal";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { DeleteDesignation, DesignationList } from "../../action/DesignationAction";
import DesignationForm from "../../PageComponents/Masters/DesignationForm";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";
import DeleteIcon from "@iconify/icons-material-symbols/delete-outline-rounded";
import AddDocumentIcon from "@iconify/icons-material-symbols/post-add-rounded";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";
import EHidden from "../../BaseComponents/EHidden";
import EChip from "../../BaseComponents/EChip";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { useCheckPermission } from "../../hooks";


const getTableHead = (allowAction) => {
    const arrayHead = [
      { id: "Designation", label: "Designation ", alignRight: false },
      { id: "Status", label: "Status", alignRight: "center" },
    ]
    if (allowAction) {
        arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' });
    }
    return arrayHead
}


function Designation() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authReducer);
  const Theme = useSelector((state) => state.ThemeReducer);
  const designation = useSelector((state) => state.DesignationReducer);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isEdit, SetIsEdit] = useState({ open: false, data: null });
  const [isDelete, setIsDelete] = useState({ open: false, data: null });
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
  const [SearchParameters, setSearchParameters] = useState({
    designation: "",
  });

  const allowAddEditDeleteMaster = useCheckPermission([PERMISSIONS.ViewAddEditDeleteMaster])

  const TABLE_HEAD = useMemo(() => getTableHead(allowAddEditDeleteMaster), [])

  useEffect(() => {
    if (auth.authtoken && !isEdit.open) {
      callApi(SearchParameters, page, rowsPerPage);
    }
  }, [auth?.authtoken, isEdit.open]);

  const handleChangePage = (event,newPage) => {
    callApi(SearchParameters, newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
    setRowsPerPage(rowPerPageCount);
    callApi(SearchParameters, 0, rowPerPageCount);
    setPage(0);
  };

  const callApi = (fnParams, fnPage, fnRowPerPage) => {
    dispatch(DesignationList(auth.authtoken, fnParams, fnPage, fnRowPerPage));
  };

  const deleteDesignation = (data,close) =>{
    dispatch(DeleteDesignation(auth.authtoken, data.id,close))
  }

  const onSearch = (value) => {
    const searchParm = { designation: value };
    callApi(searchParm, 0, rowsPerPage);
    setSearchParameters(searchParm);
    setPage(0);
  };

 
  return (
    <Page title="Designation">
      <Grid
        container
        display="row"
        justifyContent="space-between "
        className="align-center"
      >
        <Grid item>
          <EHeaderBreadcrumbs
            heading="Designation Master"
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Master", href: "" },
              {
                name: "Designation Master",
              },
            ]}
          />
        </Grid>
        {/* INFO: This complonent will be hidden wgen screen size goes below md */}
        <EHidden width="mdDown">
          <Grid item>
            <Grid item>
              {
                allowAddEditDeleteMaster &&
                <EButton
                  variant="contained"
                  onClick={() => SetIsEdit({ ...isEdit, open: true, data: null })}
                >
                  {" "}
                  Add Designation{" "}
                </EButton>
              }
              <EButtonOutlined
                className="button-left-margin"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {" "}
                Back{" "}
              </EButtonOutlined>
            </Grid>
          </Grid>
        </EHidden>
      </Grid>

      <Grid
        container
        display="row"
        className="pb-2rem margin-top-20px justify-content-space-between align-flex-end"
      >
        <Grid item>
          <ETypographyPageHeading variant="h5">
            Designation
          </ETypographyPageHeading>
        </Grid>
        <EHidden width="mdUp">
          <Grid item>
            {
              allowAddEditDeleteMaster &&
              <EButtonIcon
                onClick={() => SetIsEdit({ ...isEdit, open: true, data: null })}
                component={Link}
                variant="contained"
              >
                <EIcon icon={AddDocumentIcon} className='height-width-15px'/>
              </EButtonIcon>
            }
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              {" "}
              <EIcon icon={ForwardArrow} className='height-width-15px'/>{" "}
            </EButtonOutlinedIcon>
          </Grid>
        </EHidden>
        <EHidden width="mdDown">
          <Grid item>
            <ETextFieldSearch
              color="primary"
              size="small"
              placeholder="Search"
              value={SearchParameters?.designation}
              onChange={(e) => {
                onSearch(e.target.value ? e.target.value : "");
              }}
              InputProps={{
                startAdornment: (
                  <ETooltip arrow title="Search">
<EIconInputAdornment icon={search}></EIconInputAdornment>
                  </ETooltip>
                  
                ),
                endAdornment: (
                  <EInputAdornment
                    position="end"
                    sx={{ margin: "0px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      onSearch("");
                    }}
                  >
                    <ETooltip arrow title="Cancel">
                    <EIcon icon={CancelIcon} />
                    </ETooltip>
                   
                  </EInputAdornment>
                ),
              }}
              sx={{
                width: {
                  xs: "150px",
                  sm: "254px",
                  md: "354px",
                  lg: "354px",
                  xl: "354px",
                },
              }}
            />
          </Grid>
        </EHidden>

        <Grid lg={12} xs={12} item className="pt-8px">
          <EHidden width="mdDown">
          <EDivider />
          </EHidden>
        </Grid>
      </Grid>

      <EBoxPage>
        <EScrollbar>
          <ETableContainer>
            <ETable>
              <EListHead
                headLabel={TABLE_HEAD}
                rowCount={designation?.designationListData?.rows?.length}
                onRequestSort={() => null}
                authvar={auth}
                Theme={Theme}
              />
              {designation?.designationListLoading && (
                <ETableBody>
                  <ETableRow>
                    <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                      <ECircularProgress color="primary" />
                    </ETableCell>
                  </ETableRow>
                </ETableBody>
              )}
              {!designation?.designationListLoading &&
                designation?.designationListData?.rows?.length > 0 && (
                  <ETableBody>
                    {designation.designationListData.rows?.map((row, index) => {
                      const { designation_name, status } = row;
                      return (
                        <ETableRow hover key={index + designation_name}>
                          {/* Designation */}
                          <ETableCell
                            align="left"
                            sx={{
                              textTransform: "capitalize",
                              minWidth: "160px",
                            }}
                          >
                            {designation_name}
                          </ETableCell>

                          {/* Status */}
                          <ETableCell
                            align="center"
                            sx={{
                              textTransform: "capitalize",
                              width: "100px",
                            }}
                          >
                            {status === 1 ? (
                              <EChip label="Enable" />
                            ) : (
                              <EChip label="Disable" />
                            )}
                          </ETableCell>

                          {/* Action */}
                          {
                            allowAddEditDeleteMaster &&
                            <ETableCell align="center" style={{ width: "100px" }}>
                                <EIcon
                                  className="cursor-pointer"
                                  onClick={() =>
                                    SetIsEdit({
                                      ...isEdit,
                                      open: true,
                                      data: row,
                                    })
                                  }
                                  icon={editOutlineRounded}
                                />
                                {/*  showing delete only if status === 1 i.e enabled */}
                                {status === 1 ? (
                                  <EIcon
                                    className="cursor-pointer icon-red ml-12px"
                                    onClick={() =>
                                      setIsDelete({
                                        ...isDelete,
                                        open: true,
                                        data: row,
                                      })
                                    }
                                    icon={DeleteIcon}
                                  />
                                ) : (
                                  ""
                                )}
                            
                            </ETableCell>
                          }
                        </ETableRow>
                      );
                    })}
                  </ETableBody>
                )}
            </ETable>
          </ETableContainer>
        </EScrollbar>
        {!designation?.designationListLoading &&
          designation?.designationListData?.rows?.length == 0 && (
            <SearchNotFound />
          )}

{!designation?.designationListLoading &&
        designation?.designationListData?.count > DEFAULT_ROWS_PERPAGE && (
          <ETablePagination
            rowsPerPageOptions={ROW_SIZE_PER_PAGE}
            component="div"
            count={
              designation?.designationListData?.count
                ? designation?.designationListData?.count
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </EBoxPage>
     


        {/* Edit modal */}
      {isEdit.open && (
        <EModal
          open={isEdit.open}
          close={() => SetIsEdit({ ...isEdit, open: false })}
          headervalue={isEdit.data ? "Update Designation" : "Add Designation"}
        >
          <DesignationForm
            close={() => SetIsEdit({ ...isEdit, open: false })}
            rowData={isEdit?.data}
            designation={designation?.designationListData?.rows}
            auth={auth}
          />
        </EModal>
      )}

      {/* delete modal */}

      {isDelete.open && (
        <EModal
          open={isDelete.open}
          close={() => {
            setIsDelete({ ...isDelete, open: false });
          }}
          headervalue={
            !isDelete.data?.designationInuse 
              ? `Delete - ${isDelete.data.designation_name}`
              : "Alert"
          }
         >
          {!isDelete.data?.designationInuse ? (<>
            <ETypography>
              Are you sure you want to delete{" "}
              <span className="theme-main-text-color-bold">
                {isDelete.data.designation_name} Designation?
              </span>{" "}
            </ETypography>

            <Stack
            direction="row"
            spacing={2}
            paddingTop={2}
            className="modal1-buttons-stick-bottom"
          >
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => {
                setIsDelete({ ...isDelete, open: false });
              }}
            >
              No
            </EButtonOutlined>
            <ELoadingButton
              loading={designation?.departmentLoading}
              
              size="large"
              variant="outlined"
             onClick={()=>deleteDesignation(isDelete.data,setIsDelete({ ...isDelete, open: false }))}
            >
              Yes
            </ELoadingButton>
          </Stack>
          
          </>
          
          ) : (
            <>
             <ETypography>
              <span className="theme-main-text-color-bold">
              {isDelete.data.designation_name} designation
              </span> cannot be deleted at the moment. It
              seems that the users are active in this designation. Please assign
              them to a different designation before deleting <span className="theme-main-text-color-bold">
              {isDelete.data.designation_name}
                </span> .
            </ETypography>
            
            
            
            <Stack
            direction="row"
            spacing={2}
            paddingTop={2}
            className="modal1-buttons-stick-bottom"
          >
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => {
                setIsDelete({ ...isDelete, open: false });
              }}
            >
             Close
            </EButtonOutlined>
           
          </Stack>
            </>
           

          )}

          
        </EModal>
      )}
    </Page>
  );
}
export default Designation;
