import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from "react-redux";
import { MY_ATTENDANCE_TAB, TALENT_ATTENDANCE_TAB } from '../../constants'
import { ATTENDANCE_LOG_DOWNLOAD_URL } from '../../constants'
import { DownLoadFile } from '../../action/AdminAction';
import { PaymentSearchFilter } from '../Payment/PaymentSearchFilter';
import { EBox } from '../../BaseComponents/EBox';


function AttendanceSearchfields(props) {
  const { tab, setAttendanceSearch, AttendanceSearch } = props;
  
  const [yearmonthValue, setyearmonthValue] = useState(moment().format('YYYY-MM-DD'));
  const auth = useSelector(state => state.authReducer);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [dateError, setDateError] = useState({
    startDateError: null,
  })

  const [searchParam, setSearchParam] = useState({
    start: moment(),
  });


  const DownloadReport = (type) => {
    const year_month = AttendanceSearch.yearMonth ? AttendanceSearch.yearMonth : null;
    const employee_id = AttendanceSearch.employee_id ? AttendanceSearch.employee_id : null
    const sort = AttendanceSearch.sortBy;


    let self = null;
    if(MY_ATTENDANCE_TAB == tab){
      self = true
    }
    if(TALENT_ATTENDANCE_TAB == tab){
      self = false
    }
   
    let params = {
      download_format: type,
      self: self,
      ...((sort === true || sort === false) && { sort }),
      ...(year_month && { year_month }),
      ...(employee_id && { employee_id }),
    };

    let queryString = new URLSearchParams(params).toString();
 
    const url = `${ATTENDANCE_LOG_DOWNLOAD_URL}?${queryString}`;
    DownLoadFile(auth.authtoken, url, "Attendance-log-report")
  }

  function onSearch(data) {

    let params = {
      start: data?.start ? data?.start : null,
      year_month: data?.start ? moment(data?.start).format("YYYYMM") : null,
      sort: data?.sort,
      employee_id: data?.employee?.id ? data?.employee?.id : null
    }
    if(MY_ATTENDANCE_TAB == tab){
      params = {
        ...params,
        employee_id: AttendanceSearch.employee_id
      }
    }

    setDateError(prevState => ({
      ...prevState,
      startDateError: data?.start ? null : 'Please select a month.'
    }));

    setAttendanceSearch({
      ...AttendanceSearch,
      yearMonth: params?.year_month,
      sortBy: params?.sort,
      employee_id: params?.employee_id ? params?.employee_id : null
    })

  }

  return (
    <>
      <PaymentSearchFilter
        initialFilter={searchParam}
        onSearch={onSearch}
        onSort={onSearch}
        DownloadReport={DownloadReport}
        showTalents={tab == TALENT_ATTENDANCE_TAB}
        talentOrder={6}
        TalentPlaceholder="Select Employee"
        start={{
          placeholder: 'All Month',
          views: ['month', 'year'],
          inputFormat: "MMM yyyy",
          error: Boolean(dateError?.startDateError),
          helperText: dateError?.startDateError ? dateError?.startDateError : null,
        }}
      />
      <EBox sx={{ height: '16px' }} />

    </>
  );
}

export default AttendanceSearchfields;