import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import moment from 'moment';
import { DeviceWiseAttenanceObject, SUPER_ADMIN } from '../../constants'
import { CheckinCheckoutAttendance, GetTodayAttendance } from '../../action/AttendanceAction';
import { EIcon } from '../../BaseComponents/EIcon';
import EModal from '../../BaseComponents/EModal';
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ETypography } from '../../BaseComponents/ETypography';
import CheckIn from './CheckIn';
import ApplyLeaveForm from './ApplyLeaveForm';
import CheckOut from './CheckOut';
import CheckIcon from '@iconify/icons-material-symbols/check'
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import ApplyLeaveIcon from '@iconify/icons-material-symbols/post-add-rounded';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { CancelModal } from '../HrDashboard/ModalList';
import { EBox } from '../../BaseComponents/EBox';
import useCheckInOutMsgIcon from '../../hooks/useCheckInOutMsgIcon';
import { browserName, isMobile } from 'react-device-detect';

function Attendance(props) {
    const navigate = useNavigate()
    const { status, LeaveSearch, leaveTabReset = null, IS_WEB_CHECK_ENABLED } = props
    const dispatch = useDispatch()
    const { setting } = useCompanySettingProvider()
    const additionalSettings = setting?.additionSettingData?.companySetting ?? {};
    const attendanceSettings = setting?.attendanceSettingData.companySetting ?? {};
    const auth = useSelector(state => state.authReducer);
    const attendanceDay = useSelector(state => state.AttendanceDayEmployeeReducer);
    const leaveComponent = useSelector(state => state.LeaveComponentReducer);


    const [ApplyLeave, setApplyLeave] = useState(false);
    const [WorkDoneModal, setWorkDoneModal] = useState(false);
    const [checkIn, SetcheckIn] = useState(false);

    const checkInDevice = attendanceDay?.attendanceData?.check_in_device || '';
    const checkOutDevice = attendanceDay?.attendanceData?.check_out_device || '';
    const { checkInStatus, checkOutStatus } = useCheckInOutMsgIcon(checkInDevice, checkOutDevice);

    // HandleCheckoutModal: handles checkout modal as per settings 
    const handleCheckoutModal = () => {
        if (additionalSettings?.dprSetting?.dpr_submission_required === false) {
            setWorkDoneModal(true)
        } else {
            dispatch(CheckinCheckoutAttendance(auth.authtoken, {
                remark: "",
                updated_by: auth?.authData?.id,
                employee_id: auth?.authData?.id,
                attendance_date: moment().format("YYYY-MM-DD"),
                is_mobile: isMobile,
                browser_name: browserName
            }))
        }
    }

    const settingsContext = useCompanySettingProvider(); //INFO: Collecting data from Company Setting Context 
    const leaveSettings = settingsContext?.setting?.leaveSetting?.companySetting; //INFO: Collecting leave setting data from settingsContext

    const [noticePeriodLeave, setNoticePeriodLeave] = useState(false)

    const timeMessageCheckIn = (attendanceData) => {
        const office_open_time = attendanceData?.office_open_time;
        const checkintime = attendanceData?.check_in_time;
        if (office_open_time && checkintime) {

            // Parse the time strings into Moment.js objects
            const checkinMoment = moment(checkintime, "YYYY-MM-DD HH:mm:ss");
            const officeOpenMoment = moment(office_open_time, "HH:mm:ss");

            // Add the date from checkintime to office_open_time
            const officeOpenWithDate = officeOpenMoment.clone();
            officeOpenWithDate.year(checkinMoment.year());
            officeOpenWithDate.month(checkinMoment.month());
            officeOpenWithDate.date(checkinMoment.date());

            // Compare the times without seconds
            if (checkinMoment.isSame(officeOpenWithDate, 'minute')) {
                return "Check in";
            } else if (checkinMoment.isAfter(officeOpenWithDate, 'minute')) {
                return "Late Check in";
            } else {
                return "Early Check in";
            }
        }
        return '-'
    }


    const timeMessageCheckOut = (attendanceData) => {

        const checkouttime = attendanceData?.check_out_time;
        const office_close_time = attendanceData?.office_close_time;

        if (checkouttime && office_close_time) {
            // Parse the time strings into Moment.js objects
            const checkoutMoment = moment(checkouttime, "YYYY-MM-DD HH:mm:ss");
            const officeCloseMoment = moment(office_close_time, "HH:mm:ss");

            // Add the date from checkouttime to office_close_time
            const officeCloseWithDate = officeCloseMoment.clone();
            officeCloseWithDate.year(checkoutMoment.year());
            officeCloseWithDate.month(checkoutMoment.month());
            officeCloseWithDate.date(checkoutMoment.date());

            // Compare the times without seconds
            if (checkoutMoment.isSame(officeCloseWithDate)) {
                return "Check out"
            } else if (checkoutMoment.isAfter(officeCloseWithDate)) {
                return "Late Check out"
            } else {
                return "Early Check out"
            }
        }
        return "-";
    }

    return (<>
        <Grid item>
            {
                ApplyLeave
                &&
                <EModal open={ApplyLeave} close={() => setApplyLeave(false)} headervalue="Apply Leave">
                    <ApplyLeaveForm
                        LeaveSearch={LeaveSearch}
                        open={ApplyLeave}
                        close={() => {
                            setApplyLeave(false);
                            if (leaveTabReset) {
                                leaveTabReset();
                            }
                        }}
                        fromPage="Leave" auth={auth} />
                </EModal>
            }

            {
                noticePeriodLeave &&
                <EModal open={noticePeriodLeave} headervalue={'Unable to process your request!'} footeractions={<CancelModal open={noticePeriodLeave} setOpen={setNoticePeriodLeave} />}>
                    <EBox>
                        <ETypography sx={{ fontSize: '16px', marginBottom: '5px' }}>Since you're serving your <b>notice period</b>, you're not eligible to take any leave.</ETypography>
                        <ETypography sx={{ fontSize: '16px' }}>If you have any questions, Please Contact your <b>HR Department</b>.</ETypography>
                    </EBox>
                </EModal>
            }
            {
                WorkDoneModal
                &&
                <EModal open={WorkDoneModal} close={() => setWorkDoneModal(false)} headervalue={"Check Out - " + moment().format('DD/MM/yyyy, hh:mm A')}>
                    <CheckOut
                        close={() => setWorkDoneModal(false)}
                        auth={auth}
                        attendanceSettings={attendanceSettings}
                    />
                </EModal>
            }

            {
                checkIn
                &&
                <EModal open={checkIn} close={() => SetcheckIn(false)} headervalue={"CheckOut - " + moment().format('DD/MM/yyyy, hh:mm A')}>
                    <CheckIn
                        close={() => SetcheckIn(false)}
                        auth={auth}
                        attendanceSettings={attendanceSettings}
                    />
                </EModal>
            }

            <Grid container spacing={1} >
                {
                    status == 'attendance'
                        &&
                        auth?.authData?.show_as_talent == true
                        ?
                        <Grid item className='pl0'>
                            <EButton
                                variant="contained"
                                onClick={() => SetcheckIn(true)}
                                disabled={
                                    attendanceSettings?.allow_attendance_automate != true
                                    ||
                                    attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length > 0
                                    ||
                                    !IS_WEB_CHECK_ENABLED
                                }
                            > Check In</EButton>
                        </Grid>
                        :
                        <></>
                }

                {
                    status == 'attendance'
                        && auth?.authData?.show_as_talent == true
                        ?
                        <Grid item className='pl0'>
                            <EButton variant="contained" className='button-left-margin'
                                disabled={
                                    !attendanceSettings?.allow_attendance_automate ||
                                    attendanceDay?.attendanceData?.check_out_time !== null ||
                                    !IS_WEB_CHECK_ENABLED
                                }
                                onClick={() => handleCheckoutModal()} > Check Out </EButton>
                        </Grid>
                        :
                        <></>
                }

                {
                    status == 'leave'
                        &&
                        (auth?.authData?.role !== SUPER_ADMIN || (auth?.authData?.role === SUPER_ADMIN && auth?.authData?.show_as_talent == true))
                        ?
                        <Grid item>
                            <EHidden width="mdDown">
                                <ETooltip title={' Apply Leave'} arrow>
                                    <EButton
                                        variant="contained"
                                        onClick={() => auth?.authData?.noticePeriod?.last_working_date && !leaveSettings?.enable_paid_leave_notice_period ? setNoticePeriodLeave(true) : setApplyLeave(true)}
                                    >
                                        Apply Leave
                                    </EButton>
                                </ETooltip>
                            </EHidden>
                            <EHidden width="mdUp">
                                <EButtonIcon
                                    variant="contained"
                                    onClick={() => auth?.authData?.noticePeriod?.last_working_date && !leaveSettings?.enable_paid_leave_notice_period ? setNoticePeriodLeave(true) : setApplyLeave(true)}
                                >
                                    <EIcon icon={ApplyLeaveIcon} className='height-width-15px' />
                                </EButtonIcon>

                            </EHidden>
                        </Grid>
                        :
                        <></>
                }

                {status != 'dashboard' &&
                    <Grid item className='pl0'>
                        <EHidden width="mdDown">
                            <EButtonOutlined className='button-left-margin' variant="outlined" onClick={() => navigate(-1)} color="secondary" > Back </EButtonOutlined>
                        </EHidden>
                        <EHidden width="mdUp">
                            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate('/')} className='button-left-margin'>
                                <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                        </EHidden>
                    </Grid>}


            </Grid>
        </Grid>
        {/* ______________________for dashboard __________________ */} {/*--auth?.authData?.show_as_talent login should be replaced in the next version-- */}
        {
            status == 'dashboard'
            &&
            <>
                <Grid container spacing={1} className='justify-content-space-between '>

                    {/* CHECK IN BUTTON */}
                    <Grid item xs={6} sm={4} md={5} lg={5} xl={5} className=' justify-content-space-between '>
                        <EButton variant="contained" className='mr10px px0 checkin-out-button-dashboard' onClick={() => SetcheckIn(true)}
                            disabled={
                                attendanceSettings?.allow_attendance_automate != true
                                ||
                                attendanceDay?.attendanceData && Object.keys(attendanceDay?.attendanceData).length > 0
                                ||
                                !IS_WEB_CHECK_ENABLED
                            }
                            fullWidth
                        >
                            Check In
                        </EButton>
                    </Grid>


                    {/* CHECKIN STATUS */}
                    <Grid item xs={6} sm={8} md={7} lg={7} xl={7} className='display-flex justify-content-space-between align-center' >
                        {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_in_time ?
                            <>
                                {
                                    attendanceDay?.attendanceData?.check_in_time
                                    &&
                                    <ETooltip title={checkInStatus ? DeviceWiseAttenanceObject[checkInDevice]?.CheckInMessage : ''} arrow>
                                        <EIcon icon={checkInStatus} className='responsive-display' />
                                    </ETooltip>
                                }

                                <ETypography sx={{ ETypography: { sm: 'body1', xs: 'body2', md: 'body2', lg: 'h6' }, textAlign: 'left', minWidth: '90px' }} className='mr10px bold-font' >
                                    {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_in_time ?
                                        <span>
                                            <span className='bold-600'> {moment(attendanceDay?.attendanceData?.check_in_time).format('hh:mm A ')}
                                            </span>
                                            <span className='small-text-label font-weight-400'>{timeMessageCheckIn(attendanceDay?.attendanceData)}</span>
                                        </span>
                                        : ''}
                                </ETypography>
                                <EIcon icon={CheckIcon} className='check-css responsive-display' />
                            </>
                            :
                            <>
                                <EBox sx={{ visibility: 'hidden' }}>
                                    <EIcon icon={CheckIcon} className='check-css responsive-display' />
                                </EBox>
                                <ETypography className='responsive-p-before' sx={{ textAlign: 'left', minWidth: '90px' }}>
                                    <span className='bold-600 disabledBottonText'> Pending</span>
                                    <span className='small-text-label disabledBottonText'>Check in time</span>
                                </ETypography>
                                <EIcon sx={{ visibility: 'hidden' }} icon={CheckIcon} className='check-css responsive-display ' />
                            </>
                        }
                    </Grid>


                    {/* CHECK OUT BUTTON */}
                    <Grid item xs={6} sm={4} md={5} lg={5} xl={5} className=' justify-content-space-between'>
                        <EButton variant="contained" className='mr10px px0 checkin-out-button-dashboard'
                            disabled={
                                !attendanceSettings?.allow_attendance_automate ||
                                attendanceDay?.attendanceData?.check_out_time !== null ||
                                !IS_WEB_CHECK_ENABLED
                            }
                            onClick={() => handleCheckoutModal()} fullWidth> Check Out </EButton>

                    </Grid>

                    {/* CHECK OUT STATUS */}
                    <Grid item xs={6} sm={8} md={7} lg={7} xl={7} className='display-flex justify-content-space-between align-center' >
                        {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_out_time ?
                            <>
                                {
                                    attendanceDay?.attendanceData?.check_out_time
                                    &&
                                    <ETooltip title={checkOutStatus ? DeviceWiseAttenanceObject[checkOutDevice]?.CheckOutMessage : ''} arrow>
                                        <EIcon icon={checkOutStatus} className='responsive-display' />
                                    </ETooltip>
                                }

                                <ETypography sx={{ ETypography: { sm: 'body1', xs: 'body2', md: 'body2', lg: 'h6' }, textAlign: 'left', minWidth: '90px' }} className='mr10px bold-font'>
                                    {attendanceDay?.attendanceData && attendanceDay?.attendanceData?.check_out_time ?
                                        <span>
                                            <span className='bold-600'> {moment(attendanceDay?.attendanceData?.check_out_time).format('hh:mm A')}
                                            </span>
                                            <span className='small-text-label font-weight-400'>{timeMessageCheckOut(attendanceDay?.attendanceData)}</span>
                                        </span>
                                        :
                                        ''}
                                </ETypography>

                                <EIcon icon={CheckIcon} className='check-css responsive-display ' />
                            </>
                            :
                            <>
                                <EBox sx={{ visibility: 'hidden', width: 'auto' }}>
                                    <EIcon icon={CheckIcon} className='check-css responsive-display ' />
                                </EBox>
                                <ETypography className='responsive-p-before' sx={{ textAlign: 'left', minWidth: '90px' }}>
                                    <span className='bold-600 disabledBottonText'> Pending</span>
                                    <span className='small-text-label disabledBottonText'>Check out time</span>
                                </ETypography>
                                <EIcon sx={{ visibility: 'hidden' }} icon={CheckIcon} className='check-css responsive-display ' />
                            </>
                        }
                    </Grid>
                </Grid>
            </>
        }
    </>
    );
}

export default React.memo(Attendance)




