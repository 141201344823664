import React, { useState } from 'react'
import { Grid, IconButton, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import TickIcon from '@iconify/icons-material-symbols/check-circle-rounded';
import EditIcon from '@iconify/icons-material-symbols/edit-outline-rounded';
import RightArrowIcon from '@iconify/icons-material-symbols/chevron-right-rounded'
import EModal from '../../../BaseComponents/EModal';
import { EIcon } from '../../../BaseComponents/EIcon';
import SearchNotFound from '../../../PageComponents/SearchNotFound';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { EBox, EBoxPage } from '../../../BaseComponents/EBox';
import { ETypography } from '../../../BaseComponents/ETypography';
import { numberWithCommas } from '../../../utils/formatNumber';
import { EDataGrid, EPageWithBreadCrumbs } from '../../../BaseComponents';
import { SelectableCard } from '../../../PageComponents/Payment/SelectableCard';
import PaymentBulkModal from '../../../PageComponents/Payment/PaymentBulkModal';
import { Form, FormikProvider, useFormik } from 'formik';
import { useMemo } from 'react';
import { ETextFieldSearch } from '../../../BaseComponents/ETextField';
import { SalaryBulkPaymentValidation } from '../../../utils/validation';
import { Errors } from '../../../BaseComponents/EToast';
import moment from 'moment';
import { useBlocker } from '../../../hooks';
import { useEffect } from 'react';
import { EButtonOutlined, ELoadingButton } from '../../../BaseComponents/EButtons';


const PAGE_TITLE = "Bulk Payment"

export const BulkSalaryPayment = () => {

  const theme = useTheme()
  const navigate = useNavigate();
  const route = useLocation()

  const data = route.state || []

  const [modalState, setModalState] = useState({ modalData: null, modalName: null });
  const [selectedData, setSelectedData] = useState(data?.data)

  const [showModal, setShowModal, continueNavigate] = useBlocker(true)

  const auth = useSelector((state) => state.authReducer)

  const payment = useSelector((state) => state.PaymentReducer);

  const totalAmount = useMemo(() => (
    selectedData?.reduce((pre, cur) => {
      return pre += Number(cur?.balance_amount)
    }, 0)
  ), [selectedData])

  const formik = useFormik({
    initialValues: {
      totalBalance: 0,
      totalAmount,
      list: selectedData?.map(item => ({
        ...item,
        updatedPayAmount: item?.balance_amount,
        editMode: false,
      })),
    },
    enableReinitialize: true,
    validationSchema: SalaryBulkPaymentValidation,
    onSubmit: () => null
  })
  const { values, setFieldValue, getFieldProps, touched, errors, validateField } = formik

  const columns = [
    {
      field: 'sno',
      headerName: 'SNo.',
      flex: 1,
      width: 50,
      minWidth: 50,
      maxWidth: 80,
      sortable: false,
      editable: false,
      renderCell: (params) => (params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id) || 0) + 1,
    },
    {
      field: 'TalentName',
      headerName: 'Employee Name',
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      flex: 2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <EBox sx={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            <ETypography
              variant="subtitle1" noWrap className="cursor-pointer  font-size-14px text-capitalize blue-color"
              sx={{display:'block'}}
            >
              {params?.row?.employeeData?.fullname ? params?.row?.employeeData?.fullname : '-'}

            </ETypography>
            <ETypography
              variant="subtitle1" noWrap className="cursor-pointer font-size-14px text-capitalize blue-color font-weight-400-with-imp"
              sx={{marginTop:'-3px'}}
            >
              ({params?.row?.employeeData?.employee_code ? params?.row?.employeeData?.employee_code : '-'})
            </ETypography>
          </EBox>
        )
      }
    },
    {
      field: 'PayAmount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Payable Amount',
      type: 'number',
      minWidth: 170,
      maxWidth: 170,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {params?.row?.pay_amount ? numberWithCommas(params?.row?.pay_amount) : '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'PaidAmount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Paid Amount',
      type: 'number',
      minWidth: 170,
      maxWidth: 170,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {params?.row?.paid_amount ? numberWithCommas(params?.row?.paid_amount) : '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'BalanceAmount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Balance Amount',
      type: 'number',
      minWidth: 170,
      maxWidth: 170,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {params?.row?.balance_amount ? numberWithCommas((Number(params?.row?.balance_amount) - Number(params?.row?.updatedPayAmount || 0))) : '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'PayingAmount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Paying Amount',
      type: 'number',
      minWidth: 230,
      maxWidth: 230,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        const index = params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id)
        return (
          <>
            {
              params?.row?.editMode
                ?
                <>
                  <ETextFieldSearch
                    sx={{ '& .MuiOutlinedInput-root': { height: '35px' } }}
                    size='small'
                    autoComplete="off"
                    name={`list[${index}].updatedPayAmount`}
                    {...getFieldProps(`list[${index}].updatedPayAmount`)}
                    error={Boolean(touched.list?.[index]?.updatedPayAmount && errors.list?.[index]?.updatedPayAmount)}
                  />
                  <IconButton onClick={() => {
                    if (!Boolean(errors.list?.[index]?.updatedPayAmount)) {
                      setFieldValue(`list[${index}.editMode]`, false)
                      const { totalBalance, totalAmount } = values?.list?.reduce((pre, cur) => {
                        return { totalBalance: pre.totalBalance += (Number(cur?.balance_amount) - Number(cur?.updatedPayAmount)), totalAmount: pre.totalAmount += Number(cur?.updatedPayAmount) }
                      }, { totalBalance: 0, totalAmount: 0 })
                      setFieldValue(`totalBalance`, totalBalance)
                      setFieldValue(`totalAmount`, totalAmount)
                    }
                    else {
                      Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${touched.list?.[index]?.updatedPayAmount && errors.list?.[index]?.updatedPayAmount}</span>`,
                      });
                    }
                  }}>
                    <EIcon icon={TickIcon} color={theme.palette.primary.main} />
                  </IconButton>
                </>
                :
                <>
                  <ETypography className='font-size-14px'>
                    {params?.row?.updatedPayAmount ? numberWithCommas(params?.row?.updatedPayAmount) : '-'}
                  </ETypography>

                  <IconButton onClick={() => setFieldValue(`list[${index}.editMode]`, true)}>
                    <EIcon icon={EditIcon} />
                  </IconButton>
                </>
            }
          </>
        );
      }
    },
  ]

  useEffect(() => {
    if (!selectedData) {
      navigate('/payment/salary-payment')
    }
  }, [selectedData])


  return (
    <>
      <EPageWithBreadCrumbs
        title={PAGE_TITLE}
        pageHeading={PAGE_TITLE}
        breadcrumbsTitle={PAGE_TITLE}
        breadcrumbsLinks={[
          { name: 'Dashboard', href: '/' },
          { name: 'Payment', href: '/payment/salary-payment' },
          { name: 'Salary Payment', href: '/payment/salary-payment' },
          { name: PAGE_TITLE },
        ]}
        loading={false}
      >
        <EBoxPage>
          <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                rowGap={2}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                      <SelectableCard
                        title='Month'
                        subTitle={moment(data?.data?.[0]?.payrollData?.month_date).format("MMM YYYY")}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SelectableCard
                        title='No. of Employees'
                        subTitle={data?.data?.length || 0}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SelectableCard
                        title='Balance'
                        subTitle={numberWithCommas(values?.totalBalance)}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <SelectableCard
                        title='Total Amount'
                        subTitle={numberWithCommas(values?.totalAmount)}
                        selected={false}
                        cardSx={{
                          backgroundColor: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                        }}
                        titleSx={{ color: '#fff' }}
                        subTitleSx={{ color: '#fff' }}
                        right={<EIcon icon={RightArrowIcon} sx={{ height: 36, width: 36, color: '#fff' }} />}
                        onClick={(Object.keys(errors).length != 0 || values?.list?.filter(item => item.editMode)?.length > 0) ? () => null : () => setModalState({ modalData: { ...values, payment_ids: data?.ids, total_amount: values?.totalAmount }, modalCategory: 'BULK_PAYMENT' })}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <EBoxPage className='p0'>
                    {
                      !payment?.PaySalaryListData?.payrollListLoading && payment?.PaySalaryListData?.rows?.length == 0
                        ?
                        <SearchNotFound />
                        :
                        <EDataGrid
                          columns={columns}
                          rows={values?.list || []}
                          loading={payment?.PaySalaryListLoading}
                        />
                    }
                  </EBoxPage>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </EBoxPage>
      </EPageWithBreadCrumbs>

      {
        modalState.modalCategory && modalState.modalCategory === 'BULK_PAYMENT' &&
        <EModal
          open={modalState.modalCategory === 'BULK_PAYMENT'}
          headervalue={'Bulk Payment'}
        >
          <PaymentBulkModal
            paymentData={modalState.modalData}
            setModalState={setModalState}
            isSalary={true}
            getPaymentFilterData={() => navigate('/payment/salary-payment')}
            auth={auth}
          />
        </EModal>
      }

      <EModal
        open={showModal}
        headervalue={"Confirmation"}
        parentClassname='delete-confirmation-modal'
      >
        <Grid container rowSpacing={2}>
          <Grid item>
            <ETypography className="font-size-18px ">
              Any unsaved changes will be lost. Would you like to continue?
            </ETypography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
            <EButtonOutlined size="large" variant="outlined" onClick={() => setShowModal(false)}> No</EButtonOutlined>
            <ELoadingButton size="large" type="button" variant="contained" onClick={continueNavigate} className='button-left-margin'> Yes</ELoadingButton>
          </Grid>
        </Grid>
      </EModal>
    </>
  )
}
