import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid, FormControl, FormControlLabel, FormHelperText, Stack, FormGroup } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LeaveFormAttendanceValidation } from '../../utils/validation';
import { CHARACTER_LIMIT, TALENT_STAGE, maxDateWithoutTenure } from '../../constants';
import moment from 'moment';
//base components
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { EWordCount } from '../../BaseComponents/ETypography';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { ECheckbox } from '../../BaseComponents/ECheckbox';
import { ETextField } from '../../BaseComponents/ETextField';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import { EmployeeApplyLeave, EmployeeLeaveBalance } from '../../action/EmployeeLeaveAction';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { LeaveMasterList } from '../../action/LeaveAction';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { EConnector, EStep, EStepIcon, EStepLabel, EStepper } from '../../BaseComponents/EStepper';
import { EBox } from '../../BaseComponents/EBox';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { EIconSmall } from '../../BaseComponents/EIcon';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { ReturnBoolean2, formatDatePeriod, getDayCount, onKeyDown } from '../../utils/CommonFunctions';
import { getSteps } from '../../pages/Attendance/LeaveViewForm';

/**
 * [2022-11-26]
 * Created By: Aanchal Sahu
 * Description:- newly designed this component
 **/

export default function ApplyLeaveForm(props) {
    const dispatch = useDispatch();
    const leave = useSelector(state => state.LeaveEmployeeReducer);
    const attendanceDay = useSelector(state => state.AttendanceDayEmployeeReducer); //Fetching today's check-in time from this reducer

    //INFO: Fetching leave settings
    const settingsContext = useCompanySettingProvider(); //INFO: Collecting data from Company Setting Context 
    const leaveSettings = settingsContext?.setting?.leaveSetting?.companySetting; //INFO: Collecting leave setting data from settingsContext
    const formLoading = leave?.empLeaveLoading;
    let leaveDetail = leave?.empLeaveBalanceData; //INFO: tackles the loading time for the form

    const { auth, close, fromPage, LeaveSearch, isEditData } = props
    const leaveReasons = useSelector((state) => state.LeaveMasterReducer);

    // Calculating duration and remainder leave
    let monthduration = "";
    let reminderLeave = Number(leaveDetail?.total_allowed_leaves) - Number(leaveDetail?.leave_taken);

    const formik = useFormik({
        initialValues: {
            leave_is_multiple: isEditData ? isEditData?.leave_is_multiple : true,
            from_date: isEditData ? isEditData?.from_date : null,
            from_date_is_half: isEditData ? isEditData?.from_date_is_half : false,
            to_date: isEditData ? isEditData?.to_date : null,
            to_date_is_half: isEditData ? isEditData?.from_date_is_half : false,
            isReasonRequired: leaveSettings?.enable_leave_application_reason ? leaveSettings?.enable_leave_application_reason : false,
            reason: isEditData ? isEditData?.reason : '',
            reasonObject: null, //for storing reason dropdown value
            created_by: auth?.authData?.id,
            employee_id: auth?.authData?.id,
            fromPage: fromPage ? fromPage : null,
            isTodayCheckIn: attendanceDay?.attendanceData?.check_in_time ? true : false, //INFO: This is to validate today's leave application
            leave_head_id: isEditData ? isEditData?.id : null,
            leave_is_paid: isEditData ? isEditData?.leave_is_paid : reminderLeave > 0,
            leave_is_paid_object: isEditData ? isEditData?.leave_is_paid ? 'Paid Leave' : 'Unpaid Leave' : reminderLeave > 0 ? 'Paid Leave' : 'Unpaid Leave'
        },
        validationSchema: LeaveFormAttendanceValidation,
        onSubmit: (data) => {
            data = { ...data, search: LeaveSearch }
            dispatch(EmployeeApplyLeave(auth?.authtoken, data, close))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, validateField, setTouched } = formik;
    const { leave_is_multiple, from_date, reason, to_date, to_date_is_half, from_date_is_half, reasonObject, leave_is_paid_object } = formik?.values

    const Leave_options = ['Paid Leave', 'Unpaid Leave']


    //this useEffect calls the view Leave count api
    useEffect(() => {
        if (auth?.authtoken) {
            // dispatch(EmployeeLeaveBalance(auth?.authtoken, auth?.authData?.id));
            dispatch(LeaveMasterList(auth.authtoken));
        }
    }, [auth?.authtoken])

    // INFO: To set the Reason object to the dropdown
    useEffect(() => {
        if (leaveReasons?.leaveMasterListLoading == false //checking reason reducer for loading state
            && leaveReasons?.leaveMasterListData?.rows //checking reason reducer for values
            && Object.keys(leaveReasons?.leaveMasterListData?.rows)?.length > 0 //checking reason reducer for values
            && reasonObject == null //means if the reason dropdown has not been initialized
            && isEditData //means if this form is used for editing
        ) {
            var Reason = leaveReasons?.leaveMasterListData?.rows ? leaveReasons?.leaveMasterListData?.rows?.filter(object => {
                return object.label == isEditData?.reason;
            }) : null;
            //If the reason entered was not found as label in reason reducer, the setting other in the dropdown.
            if (Reason == null || (Reason && Reason?.length == 0)) {
                Reason = leaveReasons?.leaveMasterListData?.rows ? leaveReasons?.leaveMasterListData?.rows?.filter(object => {
                    return object.is_other == true;
                }) : null;
            }
            setFieldValue('reasonObject', Reason && Reason[0] ? Reason[0] : null)
        }
    }, [leaveReasons?.leaveMasterListLoading])

    // INFO: steps value for view leave action progress.
    const steps = useMemo(() => getSteps(), []);

    // Half day UI Logic
    const fromDate = moment(from_date);
    const toDate = moment(to_date);

    let adjustedTotalDays = toDate.diff(fromDate, 'days') + 1;

    if (from_date_is_half) {
        adjustedTotalDays -= 0.5;
    }

    if (to_date_is_half) {
        adjustedTotalDays -= 0.5;
    }

    const HOW_MANY_DAYS_SELECTED = `${fromDate.format('DD/MM/YYYY')} to ${toDate.format('DD/MM/YYYY')}, ${adjustedTotalDays} days selected`;


    const OnIsLeaveMultipleChange = (value) => {
        // Initializing the dates to null
        setFieldValue('from_date', null)
        setFieldValue('to_date', null)
        // To remove unused validation msgs
        setTouched({
            from_date: false,
            to_date: false,
        })
        setFieldValue('leave_is_multiple', value)
    }

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2}>

                    {/* INFO: The following  stepper is for view leave; 
                    The following will be statically in the pending stage since this form will be accessible in the pending stage. */}
                    {isEditData ?
                        <Grid item xs={12} xl={12} className='p0'>
                            <EBox className='mt-24px mb-16px height-50px position-relative overflow-hidden'>
                                <EStepper activeStep={TALENT_STAGE} connector={<EConnector />} alternativeLabel >
                                    {steps && steps.map((row) => (
                                        <EStep key={row?.id} >
                                            <EStepLabel
                                                StepIconComponent={EStepIcon}>
                                                <span className=' display-flex justify-content-center align-center'>
                                                    {row?.label}
                                                    {row?.reason ?
                                                        <ETooltip arrow title={row?.reason}>
                                                            <EIconSmall icon={InfoIcon} className='height-width-18px color-text-primary' />
                                                        </ETooltip>
                                                        : ''
                                                    }
                                                </span>
                                            </EStepLabel>
                                        </EStep>
                                    ))}
                                </EStepper>
                            </EBox>
                        </Grid> : ''}

                    <Grid item xs={12} xl={12}>
                        <EBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <FormControl component="fieldset" error={Boolean(touched.leave_is_multiple && errors.leave_is_multiple)} className='mb-minus-10px mt-minus-6px'>
                                <ERadioGroup row={true} aria-label="leave_is_multiple" name="leave_is_multiple" {...getFieldProps('leave_is_multiple')} >
                                    <FormControlLabel value={false} control={
                                        <ERadio
                                            onChange={() => { OnIsLeaveMultipleChange(false) }}
                                        />
                                    } label='Single Day' />
                                    <FormControlLabel value={true} control={
                                        <ERadio
                                            onChange={() => { OnIsLeaveMultipleChange(true) }}
                                        />
                                    } label="Multiple Days" />
                                </ERadioGroup>
                                {touched.leave_is_multiple && <FormHelperText> {errors.leave_is_multiple}</FormHelperText>}
                            </FormControl>
                            {
                                leaveSettings?.enable_paid_unpaid_option
                                &&
                                <EAutocomplete
                                    sx={{ width: '200px' }}

                                    name="Leave Type"
                                    {...getFieldProps('leave_is_paid_object')}
                                    options={Leave_options}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionDisabled={(option) => option === 'Paid Leave' && reminderLeave <= 0}
                                    onChange={(e, value) => {
                                        setFieldValue('leave_is_paid_object', value ? value : null)
                                    }}
                                    onSelect={() => validateField('leave_is_paid_object')}
                                    onBlur={formik.handleBlur}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='leave_is_paid_object'
                                            placeholder='Leave Type'
                                            error={Boolean(touched.leave_is_paid_object && errors.leave_is_paid_object)}
                                            helperText={touched.leave_is_paid_object && errors.leave_is_paid_object}
                                        />
                                    )}
                                />
                            }
                        </EBox>
                    </Grid>

                    {leave_is_multiple == true || leave_is_multiple == 'true' ?
                        <>
                            <Grid item xs={12} xl={12}>
                                <ELabelInputMainGrid label={'From Date'} isfullgrid={true} isModal={true}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                        <EDatePicker
                                            name="from_date"
                                            minDate={new Date()}
                                            maxDate={leaveSettings?.leave_to_month ? new Date(leaveSettings?.leave_to_month) :
                                                maxDateWithoutTenure}
                                            inputFormat="dd/MM/yyyy"
                                            {...getFieldProps('from_date')}
                                            onChange={(newValue) => {
                                                setFieldValue('from_date', newValue ? moment(newValue).format('YYYY-MM-DD') : '')
                                            }}
                                            renderInput={(params) => <ETextField
                                                {...params}
                                                fullWidth
                                                name="from_date"
                                                onKeyDown={onKeyDown}
                                                error={Boolean(touched.from_date && errors.from_date)}
                                                helperText={touched.from_date && errors.from_date} />}
                                        />
                                    </LocalizationProvider>
                                </ELabelInputMainGrid>
                            </Grid>

                            {/* Multiple Leaves- Half Day on Start Date */}
                            {/* {leaveSettings?.enable_half_day_leave_request && // INFO: leave settings condition; commenting as per @dharam Sir*/}
                            <Grid item xs={12} xl={12} className='pt-0'>
                                <ELabelInputMainGrid label={''} disableColon={true} isfullgrid={true} isModal={true}>
                                    <FormGroup className='mb-minus-10px mt-minus-6px'>
                                        {
                                            leaveSettings?.enable_half_day_leave_request &&
                                            <FormControlLabel control={
                                                <ECheckbox size="small"
                                                    checked={ReturnBoolean2(from_date_is_half)}
                                                    onChange={(e, value) => {
                                                        setFieldValue("from_date_is_half", value);
                                                    }} />
                                            }
                                                label="Half day on start date" />
                                        }
                                    </FormGroup>
                                </ELabelInputMainGrid>
                            </Grid>
                            {/* } */}

                            {/* To Date */}
                            <Grid item xs={12} xl={12}>
                                <ELabelInputMainGrid label={'To Date'} isfullgrid={true} isModal={true}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                        <EDatePicker
                                            minDate={from_date ? moment(from_date).add(1, 'day') : new Date()}
                                            maxDate={leaveSettings?.leave_to_month ? new Date(leaveSettings?.leave_to_month) :
                                                maxDateWithoutTenure}
                                            defaultCalendarMonth={from_date ? new Date(from_date) : new Date()} //To open the calender in (current-18) year
                                            name="to_date"
                                            inputFormat="dd/MM/yyyy"
                                            {...getFieldProps('to_date')}
                                            onChange={(newValue) => {
                                                setFieldValue('to_date', newValue ? moment(newValue).format('YYYY-MM-DD') : '')
                                            }}
                                            renderInput={(params) => <ETextField
                                                {...params}
                                                fullWidth
                                                placeholder="dd/mm/yyy"
                                                name="to_date"
                                                onKeyDown={onKeyDown}
                                                error={Boolean(touched.to_date && errors.to_date)}
                                                helperText={touched.to_date && errors.to_date} />}
                                        />
                                    </LocalizationProvider>
                                </ELabelInputMainGrid>
                            </Grid>

                            {/* Multiple Leaves- Half Day on End Date */}
                            {/* {leaveSettings?.enable_half_day_leave_request && // INFO: leave settings condition; commenting as per @dharam Sir */}
                            <Grid item xs={12} xl={12} className='pt-0'>
                                <ELabelInputMainGrid label={''} disableColon={true} isfullgrid={true} isModal={true}>
                                    {
                                        leaveSettings?.enable_half_day_leave_request
                                        &&
                                        <FormGroup className='mb-minus-10px mt-minus-6px' >
                                            <FormControlLabel control={
                                                <ECheckbox
                                                    checked={ReturnBoolean2(to_date_is_half)}
                                                    size="small" onChange={(e, value) => {
                                                        setFieldValue("to_date_is_half", value);
                                                    }} />
                                            }
                                                label="Half day on end date" />
                                        </FormGroup>
                                    }

                                    {/* Selected Day Count */}
                                    {(leave_is_multiple == true || leave_is_multiple == 'true') && from_date && to_date ?
                                        <span className='font-size-12px greyColor4-color' style={{ marginTop: '120px' }}>
                                            {HOW_MANY_DAYS_SELECTED}
                                        </span>
                                        : ''
                                    }
                                </ELabelInputMainGrid>
                            </Grid>
                            {/* } */}
                        </>
                        :
                        <Grid item xs={12} xl={12}>
                            {/* Single Day Leave- Date */}
                            <ELabelInputMainGrid label={'Date'} isfullgrid={true} isModal={true}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                    <EDatePicker
                                        name="from_date"
                                        minDate={new Date()}
                                        inputFormat="dd/MM/yyyy"
                                        maxDate={leaveSettings?.leave_to_month ? new Date(leaveSettings?.leave_to_month) : maxDateWithoutTenure}
                                        {...getFieldProps('from_date')}
                                        onChange={(newValue) => {
                                            setFieldValue('from_date', newValue ? moment(newValue).format('YYYY-MM-DD') : '')
                                            setFieldValue('to_date', newValue ? moment(newValue).format('YYYY-MM-DD') : '')
                                        }}
                                        renderInput={(params) => <ETextField
                                            {...params}
                                            fullWidth
                                            name="from_date"
                                            placeholder="dd/mm/yyy"
                                            onKeyDown={onKeyDown}
                                            error={Boolean(touched.from_date && errors.from_date)}
                                            helperText={touched.from_date && errors.from_date} />}
                                    />
                                </LocalizationProvider>

                                {/* Single Day Leaves- Half Day on Date; Half Day option will be according to leave settings (enable_half_day_leave_request)*/}
                                {/* {leaveSettings?.enable_half_day_leave_request && // INFO: leave settings condition; commenting as per @dharam Sir*/}
                                <FormControl component="fieldset" error={Boolean(touched.from_date_is_half && errors.from_date_is_half)} className='mb-minus-10px mt-minus-6px'>
                                    <ERadioGroup row aria-label="from_date_is_half" name="from_date_is_half" {...getFieldProps('from_date_is_half')} >
                                        <FormControlLabel value={false} control={
                                            <ERadio size="small"
                                                onChange={(e, value) => {
                                                    setFieldValue('from_date_is_half', false)
                                                }}
                                            />
                                        } label='Full Day' />

                                        {leaveSettings?.enable_half_day_leave_request
                                            && <FormControlLabel value={true} control={
                                                <ERadio size="small"
                                                    onChange={(e, value) => {
                                                        setFieldValue('from_date_is_half', true)
                                                    }}
                                                />
                                            } label="Half Day" />}


                                    </ERadioGroup>
                                    {touched.isMultiple && <FormHelperText> {errors.isMultiple}</FormHelperText>}
                                </FormControl>
                                {/* } */}
                                {/* Selected Day Count Information*/}
                                {(leave_is_multiple == false || leave_is_multiple == 'false') && from_date && to_date ?
                                    <span className='font-size-12px greyColor4-color'>
                                        <br />
                                        {moment(from_date).format('DD/MM/YYYY') + ', 1 day selected'}
                                    </span>
                                    : ''}
                            </ELabelInputMainGrid>
                        </Grid>
                    }
                    {/* Available Leave */}
                    {
                        leave_is_paid_object
                        &&

                        <Grid item xs={12} xl={12}>
                            <ELabelInputMainGrid
                                label={'Available Leave'}
                                isfullgrid={true}
                                isModal={true}
                                isNotForm={true} >
                                {
                                    leave_is_paid_object == 'Paid Leave'
                                        ?
                                        leaveDetail && Object.keys(leaveDetail).length > 0
                                            ?
                                            <>
                                                {isNaN(reminderLeave) || reminderLeave < 0 ? 0 : reminderLeave}{' '}
                                                Paid Leave{reminderLeave > 1 ? 's' : ''} {' '}
                                                {monthduration ? monthduration : ""}
                                            </>
                                            :
                                            <span className='greyColor4-color'>  Loading... </span>//This loading is when the leaveDetail is in the loading state
                                        :
                                        'Unpaid Leave Selected'
                                }
                            </ELabelInputMainGrid>
                        </Grid>
                    }

                    {/* Reason Dropdown */}
                    <Grid item xs={12} xl={12}>
                        <ELabelInputMainGrid label={'Reason' + (leaveSettings?.enable_leave_application_reason ? '*' : '')} // INFO: leave settings condition
                            isfullgrid={true} isModal={true}>
                            <EAutocomplete
                                name="Leave Type"
                                fullWidth
                                {...getFieldProps('reasonObject')}
                                options={!leaveReasons?.leaveMasterListLoading && leaveReasons?.leaveMasterListData?.rows?.length > 0 ?
                                    [...leaveReasons?.leaveMasterListData?.rows] : []}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(e, value) => {
                                    setFieldValue('reasonObject', value ? value : null)
                                    setFieldValue('reason', value && value?.leave_reason && !value?.is_other ? value?.leave_reason : '')
                                }}
                                onSelect={() => validateField('reasonObject')}//to validate this field on select
                                onBlur={formik.handleBlur}
                                renderInput={params => (
                                    <ETextField
                                        {...params}
                                        name='reasonObject'
                                        placeholder="Select Reason"
                                        fullWidth
                                        error={Boolean(touched.reasonObject && errors.reasonObject)}
                                        helperText={touched.reasonObject && errors.reasonObject}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    {/* Reason - Other Textfield */}
                    {formik?.values?.reasonObject?.is_other && formik?.values?.reasonObject?.is_other ?
                        <Grid item xs={12} xl={12}>
                            <ELabelInputMainGrid label={''} disableColon={true} isfullgrid={true} isModal={true}>
                                <ETextField
                                    name='reason'
                                    {...getFieldProps('reason')}
                                    inputProps={{
                                        maxLength: CHARACTER_LIMIT
                                    }}
                                    rows={3}
                                    multiline
                                    placeholder="Give a brief"
                                    fullWidth
                                    error={Boolean(touched.reason && errors.reason)}
                                    helperText={touched.reason && errors.reason}
                                />
                                <EWordCount>{`${reason.length}/${CHARACTER_LIMIT}`}</EWordCount>
                            </ELabelInputMainGrid>
                        </Grid> : ''}

                    <Grid item xs={12} xl={12} className='modal1-buttons-stick-bottom' >
                        <Stack direction='row' spacing={2} className='modal1-buttons-stick-bottom'>
                            <EButtonOutlined disabled={formLoading} onClick={() => close()} variant='outlined' size='large'> Cancel</EButtonOutlined>
                            <ELoadingButton loading={formLoading} type="submit" variant='contained' size='large'> {isEditData ? 'Update' : 'Apply'}</ELoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
