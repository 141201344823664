
import React from "react";
import { Grid, Stack } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { ETypography } from "../../BaseComponents/ETypography";
import { EDivider } from "../../BaseComponents/EDivider";
import { IconButton } from '@mui/material';
import { EIcon } from "../../BaseComponents/EIcon";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import { ETextField } from "../../BaseComponents/ETextField";
import { Form, FormikProvider, useFormik } from "formik";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { CityList } from "../../action/StateCityAction";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { UpdateCompanyAdress } from "../../action/CompanyAction";
import { CompanyAdressValidation } from "../../utils/validation";

export default function AddressDetails({ auth, company, allowEdit = false, open, setOpen }) {

  const StateListData = useSelector((state) => state.StateReducer.stateListData?.rows);
  const CityListData = useSelector((state) => state.CityReducer.cityListData?.rows);
  const dispatch = useDispatch();



  const formik = useFormik({
    initialValues: {
      address:company?.address,
      state: company?.permanentState ? company?.permanentState : null,
      city: company?.permanentCity ? company?.permanentCity : null,
      pin_code:company?.pin_code

    },
     validationSchema: CompanyAdressValidation,
    onSubmit: (data) => {
    
      data = { ...data, updated_by: auth?.authData?.id, id: company.id };

      // CONDITION  IS FOR ONBOARDING REDIRECTION TO DASHBOARD
     
      dispatch(UpdateCompanyAdress(auth.authtoken, data ));
    }
  });


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue ,setValues,  values } = formik;
 
  return (
    
    <FormikProvider value={formik}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
    <ECard  className="mb-16px" p={20}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ETypography className="font-size-20px bold-600"> {open.isAddressDetails ?" Update Address Details" :"Address Details"} </ETypography>
        {allowEdit &&  !open.isAddressDetails &&
          <IconButton onClick={() =>{
          setValues({
            address:company?.address,
            state: company?.permanentState ? company?.permanentState : null,
            city: company?.permanentCity ? company?.permanentCity : null,
            pin_code:company?.pin_code
          })

          
          setOpen({ ...open,  isCompanyInfo: false, isAddressDetails: !open.isAddressDetails })}}>
            <EIcon icon={EditIcon} className='p0' />
          </IconButton>
        }

      </Grid>

      <EDivider />
      {!open.isAddressDetails ?
        <Grid container direction="row" spacing={1} pt={1}>

          <Grid  item md={12} sm={12} xs={12}>
           
            <ELabelInputMainGrid 
            childrenClassname={'word-wrap'}
             label={'Address'}  isNotForm isfullgrid>
              {company?.address ? company?.address : '-'}
            </ELabelInputMainGrid>
             

          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ELabelInputMainGrid label={'State'} isNotForm>
              {company?.permanentState?.label ? company?.permanentState?.label : ''}
            </ELabelInputMainGrid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ELabelInputMainGrid label={'City'} isNotForm>
              {company?.permanentCity?.label ? company?.permanentCity?.label : ''}
            </ELabelInputMainGrid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ELabelInputMainGrid label={'PIN Code'} isNotForm>
              {company?.pin_code ? ' ' + company?.pin_code : ''}
            </ELabelInputMainGrid>
          </Grid>
        </Grid>
        :
        <Grid container direction="row" spacing={1} pt={1}> 
      <Grid item md={12} sm={12} xs={12}>
        <ELabelInputMainGrid label={'Address'} isfullgrid={true} alignlabel='true'>
          <ETextField
            fullWidth
            multiline // Enable multi-line textarea
            rows={2} // Set the number of lines to four
            type="address"
            autoComplete="address"
            placeholder="Address"
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
            inputProps={{
              maxLength: 250, // Set the maximum character limit to 250
            }}
          />
        </ELabelInputMainGrid>
      </Grid>
    
          <Grid item md={6} sm={6} xs={12}>
            <ELabelInputMainGrid label={'State*'}>
              <EAutocomplete
                name="state"
                value={values.state}
                fullWidth
                options={
                  StateListData && StateListData?.length > 0 ? StateListData : []
                }
                forcePopupIcon={!formik?.values?.state ? true : false}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.label || ''}
                {...getFieldProps('state')}
                onChange={(e, value) => {
                  if (value) {
                    dispatch(CityList(auth.authtoken, value.id));
                  }
                  setFieldValue('state', value ? value : null);
                  setFieldValue('city', null);
                  if (formik.values.cr_address_same == true) {
                    setFieldValue('cr_state', value ? value : null);
                    setFieldValue('cr_city', null);
                  }
                }}
                renderInput={(params) => (
                  <ETextField
                    {...params}
                    name="state"
                    placeholder="Select State"
                    fullWidth
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />
            </ELabelInputMainGrid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ELabelInputMainGrid label={'City*'}>
              <EAutocomplete
                name="city"
                fullWidth
                options={
                  CityListData && CityListData?.length > 0 ? CityListData : []
                }
                forcePopupIcon={!formik?.values?.city ? true : false}
                {...getFieldProps('city')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.label || ''}
                onChange={(e, value) => {
                  setFieldValue('city', value ? value : null);
                  if (formik.values.cr_address_same == true) {
                    setFieldValue('cr_city', value ? value : null);
                  }
                }}
                renderInput={(params) => (
                  <ETextField
                    {...params}
                    name="city"
                    placeholder="Select City"
                    fullWidth
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                )}
              />
            </ELabelInputMainGrid>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <ELabelInputMainGrid label={'Pincode*'}>
              <ETextField
                autoComplete="pin_code"
                name="pin_code"
                placeholder="Pincode"
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                onKeyUp={(e, value) => {
                  setFieldValue('pin_code', e.target.value ? e.target.value : '');
                  if (formik.values.cr_address_same === true) {
                    setFieldValue(
                      'cr_pin_code',
                      e.target.value ? e.target.value : ''
                    );
                  }
                }}
                onKeyDown={(e, value) => {
                  setFieldValue('pin_code', e.target.value ? e.target.value : '');
                  if (formik.values.cr_address_same === true) {
                    setFieldValue(
                      'cr_pin_code',
                      e.target.value ? e.target.value : ''
                    );
                  }
                }}
                {...getFieldProps('pin_code')}
                error={Boolean(touched.pin_code && errors.pin_code)}
                helperText={touched.pin_code && errors.pin_code}
              />
            </ELabelInputMainGrid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="baseline"
              spacing={2}
              marginTop={2}
            >

              <EButtonOutlined size="large" variant="outlined" className='mr-16px' onClick={() => setOpen({ ...open, isAddressDetails: false })}> Cancel </EButtonOutlined>
              <ELoadingButton loading={false} size="large" type="submit" className="ml-0px" >Update</ELoadingButton>

            </Stack>
          </Grid>

        </Grid>
      }
    </ECard>
    </Form>
    </FormikProvider>
  )
}
