import axios from 'axios';
import Cookies from 'universal-cookie';
import { Success, Errors } from '../BaseComponents/EToast';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_LOADING,
  // LOGIN_FIRST,
  LOGIN_LINK_SUCCESS, LOGIN_LINK_FAILURE, LOGIN_LINK_LOADING, LOGIN_LINK_CLEAR,
  LOGIN_RESET_SUCCESS, LOGIN_RESET_TOKEN, LOGIN_RESET_FAILURE, LOGIN_RESET_LOADING,
  LOGIN_RESET_REDIRECT,
  SERVER_URL,
  LOGOUT,
  RESET,
  SUPER_ADMIN,
  USER_ROLE_FETCH_TYPE,
  USER_LOGIN_STATUS
} from '../constants';

import base64 from 'base-64'
import { CompanyDetail } from './CompanyAction';
// import { DepartmentList } from './DepartmentAction';
// import { DesignationList } from './DesignationAction';
// import { StateList } from './StateCityAction';
import { AllEmployeeList } from './EmployeeAction';
// import { GetCompanySetting } from './CompanySettingAction';
// import { AllFreelancerList } from './FreelancerAction';
// import { closeSocket } from '../utils/socket';
import { NotificationList } from './NotificationAction';
// import { GlobalUserSettingsAction } from './UserSettingAction';
import { getCustomUserRoleData, getPermissionList } from './CustomUserRole';
import { GetTodayAttendance } from './AttendanceAction';
// import { ThemeSwitcher } from './ThemeAction';
// import { closeSocket } from '../utils/socket';
// import { NotificationCountView } from './NotificationNewAction';


// import socket from '../utils/socket';

const cookies = new Cookies();

//For Cookies expiration
let cookieDateExpire = new Date();
cookieDateExpire.setHours(23, 59, 59, 999);

export function LoginAction(Data, navigate) {
  const data = new FormData();
  data.append('username', `${Data.username}`);
  data.append('password', `${Data.password}`);
  return async (dispatch) => {
    dispatch(loginLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/login`,
      data,
    })
      .then((res) => {

        if (res.status === 200
          && res.data.data.user?.login_at) {

          cookies.set('role', res.data.data.user?.role);
          cookies.set('secretToken', res.data.secretToken, { path: '/', expires: cookieDateExpire });
          cookies.remove("people");
          
          if (Data.remember) {
            // 14 day expire set cookie 
            let todayT = new Date();
            let expireT = new Date();
            expireT.setTime(todayT.getTime() + 3600000 * 24 * 14);
            const cookieData = {
              username: base64.encode(Data.username),
              password: base64.encode(Data.password),
            }
            cookies.set('people', cookieData, { path: '/', expires: expireT });
          }

          dispatch(loginSuccess(res.data))
          /**
           * Only call other APIs if User's login_status if not PLAN_PENDING(3) i.e. User's company have a valid active plan
           */
          if (res.data.data?.user?.login_status === USER_LOGIN_STATUS.PLAN_PENDING) {
            return 
          }
          dispatch(CompanyDetail(res.data.secretToken, res.data.data.user.company_id));
          dispatch(NotificationList(res.data.secretToken));//INFO: calling notification for count when employee logs in
          // dispatch(GlobalUserSettingsAction(res.data.secretToken))
          // dispatch(GetCompanySetting(res.data.secretToken));
          dispatch(AllEmployeeList(res.data.secretToken));//INFO: calling all Employee List when employee logs in
          dispatch(GetTodayAttendance(res.data.secretToken));
          
          dispatch(getPermissionList()) // INFO: calling this API to store the all the static permissions
          
          /**
           * INFO: If the logged in user is SUPER ADMIN then fetch all Role otherwise exclude SUPER ADMIN Role
           */
          const params = {
              type: res.data.data?.user?.role == SUPER_ADMIN ? USER_ROLE_FETCH_TYPE.ALL : USER_ROLE_FETCH_TYPE.WITHOUT_SUPER_ADMIN
          }
          /**
           * INFO: To fetch User Roles
           */
          dispatch(getCustomUserRoleData({ params }))
          
          //INFO: Commenting following APIs due to excess load of API calling after login; To solve the issue we will be calling these APIs in the root page of any page/form.
          // dispatch(StateList(res.data.secretToken));
          // dispatch(DesignationList(res.data.secretToken));
          // dispatch(DepartmentList(res.data.secretToken));
          // dispatch(AllFreelancerList(res.data.secretToken));//INFO: calling all Freelancer List when employee logs in

          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
        } else {
          dispatch(loginResetRedirect({ userId: res.data.data?.user?.id, firstLogin: res.data.firstLogin, secretToken: res.data.secretToken }));
        }
      }).catch((error) => {

        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(loginFailure(error.response.data));
        }
      });
  };
}

export function ForgetPassAction(Data, navigate) {
  const data = new FormData();
  data.append('username', Data.username)  

  return (dispatch) => {
    dispatch(linkLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/forgot-password`,
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(linkSuccess(Data.email));
        }
      }).catch((error) => {

        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(linkFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout());
          }
        }
      });
  };
}

export function linkClear() {
  return {
    type: LOGIN_LINK_CLEAR
  };
}
export function linkLoading(payload = null) {
  return {
    type: LOGIN_LINK_LOADING,
    payload
  };
}


export function linkSuccess(payload) {
  return {
    type: LOGIN_LINK_SUCCESS,
    payload
  };
}

export function linkFailure(payload) {
  return {
    type: LOGIN_LINK_FAILURE,
    payload
  };
}


export function ResetPassAction(Data, navigate) {

  const data = new FormData();
  data.append('password', `${Data.password}`);
  data.append('confirm_password', `${Data.confirm_password}`);
  data.append('token', `${Data.secretToken}`)

  return (dispatch) => {
    dispatch(resetLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/reset-password`,
      data,
    })
      .then((res) => {

        dispatch(resetSuccess(res.data.data.passwordUpdateSuccess));

      }).catch((error) => {
        if (error?.response) {
          dispatch(resetFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout());
          }
          navigate('/login', { replace: true });
        }
      });
  };
}
export function UpdateCredentialsAction(Data, navigate) {

  const data = new FormData();
  if (Data.username) {
    data.append('username', `${Data.username}`);
  }
  data.append('password', `${Data.password}`);
  data.append('confirm_password', `${Data.confirm_password}`);
  data.append('token', `${Data.secretToken}`)

  return (dispatch) => {
    dispatch(resetLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/update-credentials`,
      data,
    })
      .then((res) => {
        /**
         * After success again verify the token to redirect to dashboard
         */
        if (res.status === 200) {
          dispatch(verifyToken(Data.secretToken))
        }

      }).catch((error) => {
        if (error?.response) {
          dispatch(resetFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout());
          }
          navigate('/login', { replace: true });
        }
      });
  };
}

export function VerifyResetPasswordToken(token, navigate) {

  const data = new FormData();
  data.append('token', token);

  return (dispatch) => {
    dispatch(resetLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/verify-reset-password-token`,
      crossDomain: true,
      data
    })
      .then((res) => {
        if (res.status === 200 && res.data.data.tokenIsVerified) {
          dispatch(resetTokenStatus(res.data.data.tokenIsVerified));
        } else {
          dispatch(Logout())
          navigate("/login")
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(resetFailure(error.response.data));
        }
        dispatch(Logout())
        navigate("/login")
      });
  };
}

/**
 * [2023-04-28]
 * Changes By:- Homendra Patil
 * Description:- next Callback function is for loading handling
 */
export function verifyToken(token, next = () => null) {
  // export function LoginVerification(token) {
  const data = new FormData();
  data.append('token', token);
  return (dispatch) => {
    dispatch(loginLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/user/check-token`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          // (res.data.data);

          cookies.set('role', res.data.data.user?.role);
          cookies.set('secretToken', res.data.secretToken, { path: '/', expires: cookieDateExpire });
          /**
           * Only call other APIs if User's login_status if not PLAN_PENDING(3) i.e. User's company have a valid active plan
           */
          if (res.data.data?.user?.login_status === USER_LOGIN_STATUS.PLAN_PENDING) {
            return dispatch(loginSuccess(res.data))
          }
          dispatch(CompanyDetail(res.data.secretToken, res.data.data.user.company_id));
          /**
           * loginSuccess() is dispatched after CompanyDetail() so App will stay in loading state
           */
          dispatch(loginSuccess(res.data))
          // dispatch(GetCompanySetting(res.data.secretToken));
          dispatch(NotificationList(token));
          // dispatch(GlobalUserSettingsAction(token))
          dispatch(AllEmployeeList(res.data.secretToken));//INFO: calling all Employee List when employee logs in
          dispatch(getPermissionList()) // INFO: calling this API to store the all the static permissions
          dispatch(GetTodayAttendance(res.data.secretToken));
          /**
           * INFO: If the logged in user is SUPER ADMIN then fetch all Role otherwise exclude SUPER ADMIN Role
           */
          const params = {
            type: res.data.data?.user?.role == SUPER_ADMIN ? USER_ROLE_FETCH_TYPE.ALL : USER_ROLE_FETCH_TYPE.WITHOUT_SUPER_ADMIN
          }
          /**
           * INFO: To fetch User Roles
           */
          dispatch(getCustomUserRoleData({ params }))
          // dispatch(LocationList(res.data.secretToken, res.data.data.user.company_id));      // socket.emit('join_room', res.data.data.user);
          // dispatch(NotificationCountView());

          // INFO: Commenting following APIs due to excess load of API calling after login; To solve the issue we will be calling these APIs in the root page of any page/form.
          // dispatch(StateList(res.data.secretToken));
          // dispatch(DesignationList(res.data.secretToken));
          // dispatch(DepartmentList(res.data.secretToken));
          // dispatch(AllFreelancerList(res.data.secretToken));//INFO: calling all Freelancer List when employee logs in

        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(loginFailure(error.response.data));
        }
        dispatch(Logout())
      })
      .finally(next)
  };
}

// export function userProfileData(token, navigate) {

//   const data = new FormData();
//   data.append('token', token);
//   return (dispatch) => {
//     dispatch(loginLoading());
//     axios({
//       method: 'post',
//       url: `${SERVER_URL}/user/check-token`,
//       crossDomain: true,
//       headers: {
//         "Authorization": 'Bearer ' + token,
//       },
//       data
//     })
//       .then((res) => {
        // if (res.status === 200) {
          // (res.data.data);
          // dispatch(loginSuccess(res.data))
        // }
//       }).catch((error) => {
//         if (error?.response) {
//           dispatch(loginFailure(error.response.data));
//           if (error?.response?.status == 401) {
//             dispatch(Logout())
//           }
//         }
//       });
//   };
// }




export function Logout(token = null) {

  const data = new FormData();
  data.append('token', token);

  return async (dispatch) => {
    dispatch(loginLoading());
    if (!token) {
      // for unauthorized access
      // const token = await cookies.get('secretToken'); //INFO(14-3-2023)=> commenting because it was not getting used
      cookies.remove('secretToken', { path: '/' })
      dispatch(logoutSuccess());
      dispatch(logoutReset());
      // if (token) {
      //   closeSocket(token);
      // }
    } else {
      // for proper logout button press
      axios({
        method: 'post',
        url: `${SERVER_URL}/user/logout`,
        crossDomain: true,
        data
      })
        .then((res) => {
          if (res.status === 200) {
            cookies.remove('secretToken', { path: '/' })
            dispatch(logoutSuccess());
            dispatch(logoutReset());
            // if (token) {
            //   closeSocket(token);
            // }
          }
        }).catch((error) => {
          if (error?.response) {
            dispatch(loginFailure(error.response.data));
            if (error?.response?.status == 401) {
              dispatch(Logout());
              dispatch(logoutReset());
            }
          }
        });
    }
  };
}


export function logoutReset() {
  return {
    type: RESET,
  };
}

/// Reset password ================================
export function resetLoading() {
  return {
    type: LOGIN_RESET_LOADING,
  };
}

export function resetTokenStatus(payload) {
  return {
    type: LOGIN_RESET_TOKEN,
    payload
  };
}

export function resetSuccess(payload) {
  return {
    type: LOGIN_RESET_SUCCESS,
    payload
  };
}

export function resetFailure(payload) {
  return {
    type: LOGIN_RESET_FAILURE,
    payload
  };
}


/// Logout  ================================
export function logoutSuccess() {
  return {
    type: LOGOUT,
  };
}


/// Login  ================================

export function loginLoading() {
  return {
    type: LOGIN_LOADING,
  };
}
export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload
  };
}

export function loginFailure(payload) {
  return {
    type: LOGIN_FAILURE,
    payload
  };
}

export function loginResetRedirect(payload) {
  return {
    type: LOGIN_RESET_REDIRECT,
    payload
  };
}




