import moment from "moment";
import monitor from '@iconify/icons-material-symbols/desktop-windows-outline-rounded'
import mobile from '@iconify/icons-material-symbols/phone-iphone-outline'
import fingerprint from '@iconify/icons-material-symbols/fingerprint-outline'

export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const StartDateForReport = moment('2022-09-13').toDate();

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};
export const REMARK_LIMIT = 250;
export const MOBILE_NUMBER_LIMIT = 10;
export const PINCODE_LIMIT = 6;


export const SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
export const SOCKET_API_URL = process.env.REACT_APP_SOCKET_ENDPOINT;
export const IMAGE_URL = process.env.REACT_APP_ASSETS_ENDPOINT;
export const GET_USER_IP_LINK = process.env.REACT_APP_GET_IP_LINK;

export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY

export const DEVICE = {
  NODEVICE : "0",
  DESKTOP : "1", 
  MOBILE: "2",
  BIOMETRIC : "3"
}

export const DeviceWiseAttenanceObject = {
  [DEVICE.NODEVICE]: {
    icon: monitor,
    CheckInMessage: "Checked in from Desktop",
    CheckOutMessage: "Checked out from Desktop",
  },
  [DEVICE.DESKTOP]: {
    icon: monitor,
    CheckInMessage: "Checked in from Desktop",
    CheckOutMessage: "Checked out from Desktop",
  },
  [DEVICE.MOBILE]: {
    icon: mobile,
    CheckInMessage: "Checked in from Mobile",
    CheckOutMessage: "Checked out from Mobile",
  },
  [DEVICE.BIOMETRIC]: {
    icon: fingerprint,
    CheckInMessage: "Checked in from Biometric",
    CheckOutMessage: "Checked out from Biometric",
  },
};


export const PRIVACY_POLICY_LINK = 'https://policies.google.com/privacy'
export const TERMS_OF_SERVICE_LINK = 'https://policies.google.com/terms'
export const DASHBOARD = 'Dashboard'
export const PAGES = 0;
export const DEFAULT_ROWS_PERPAGE_4 = 4;
export const DEFAULT_ROWS_PERPAGE_5 = 5;
export const DEFAULT_ROWS_PERPAGE_40 = 40;
export const DEFAULT_ROWS_PERPAGE_20 = 20;
export const DEFAULT_ROWS_PERPAGE = 10;
export const ROW_SIZE_PER_PAGE = [10, 20, 50];
export const REPORT_BY_DATE = 1;
export const REPORT_BY_TALENT = 2;
export const REPORT_BY_TIME = 3;
export const ROW_SIZE_PER_PAGE_40 = [40, 50, 60, 100];
export const ACTIVE_USER = 1;
export const ACTIVE_DESIGNATION = 1;
export const ACTIVE_DEPARTMENT = 1;
export const RELEASED_USER = 2;
export const INACTIVE_USER = 3;
export const USER_WITH_NO_LOGIN = 0;
export const CHARACTER_LIMIT = 250;
export const DRAWER_WIDTH = 265;
export const MINUTES_5 = 5 // 5 minutes checking used in dashboard
export const EMPLOYEE_CARD_LIST = 12;
export const EMPLOYEE_GRID_LIST = 10;
export const XL = 'xl';
export const ADD = 'ADD';
export const UPDATE = 'UPDATE';
export const ROLE_ADMIN = "ADMIN";
export const ROLE_HR = "HR";
export const ROLE_EMPLOYEE = "EMPLOYEE";
export const SUPER_ADMIN = "SUPERADMIN";
export const ACCOUNTANT = "ACCOUNTANT";
export const TALENT = "TALENT";
export const TALENT_SM = "talent";
export const FREELANCER = "FREELANCER";
export const EDIT = 'EDIT';
export const DELETE = 'DELETE';
export const NIL = '-';
export const NULL_STRING = 'null';
export const MANUAL_ATTENDANCE = 1;
export const AUTOMATED_ATTENDANCE = 2;
export const ON = true;
export const OFF = false;
export const SUPPORT_STATUS = { 1: 'OPEN', 2: 'RESPONSE', 3: 'IN PROGRESS', 4: 'CLOSED' };
export const PL = 'PL';
export const ABBREVIATION = [{ id: 'A', label: 'A - Absent' }, { id: 'P', label: 'P - Present' }, { id: 'H', label: 'H - Holiday' }, { id: PL, label: 'PL - Paid Leave' }, { id: 'HD', label: 'HD - Half Day Leave' }];
export const SUPPORT_PRIORITY = [{ id: "High", label: 'High' }, { id: 'Medium', label: 'Medium' }, { id: 'Low', label: 'Low' }];
export const ROLE_OPTIONS = [{ id: ROLE_EMPLOYEE, label: 'Employee' }, { id: ACCOUNTANT, label: 'Accountant' }, { id: ROLE_HR, label: 'HR' }, { id: ROLE_ADMIN, label: 'Admin' }, { id: SUPER_ADMIN, label: 'Super Admin' }];
export const ROLE_OPTIONS_WITHOUT_SUPERADMIN = [{ id: ROLE_EMPLOYEE, label: 'Employee' }, { id: ACCOUNTANT, label: 'Accountant' }, { id: ROLE_HR, label: 'HR' }, { id: ROLE_ADMIN, label: 'Admin' }];
export const PAYROLL_COMPLETED = 'COMPLETED';
export const PAYROLL_PENDING = 'PENDING';
export const PAYROLL_VERIFIED = 'VERIFIED';
export const PAYMENT_CATEGORY_SALARY = 1;
export const PAYMENT_CATEGORY_ADVANCE = 2;
export const PAYMENT_CATEGORY_REIMBURSEMENT = 3;
export const PAYMENT_CATEGORY_OTHER = 4;
export const PAYMENT_CATEGORY = [
  { id: PAYMENT_CATEGORY_ADVANCE, label: 'Advance' },
  { id: PAYMENT_CATEGORY_REIMBURSEMENT, label: 'Reimbursement' },
  { id: PAYMENT_CATEGORY_OTHER, label: 'Other' }
];
export const TIME_FRAME_OPTIONS = [
  { id: 24, label: '24 hrs' },
  { id: 48, label: '48 hrs' },
];
export const TNC_DELETE_WORD_LIMIT = 20

export const ADHAAR_DOC_ID = 1
export const PAN_DOC_ID = 2
export const DRIVING_LICENCE_DOC_ID = 3
export const PASSPORT_DOC_ID = 4

// PERMISSIONS
export const SideBarTalents = 'SideBarTalents'

export const PAYMENT_CATEGORY_ALL = [
  { id: PAYMENT_CATEGORY_ADVANCE, label: 'Advance' },
  { id: PAYMENT_CATEGORY_REIMBURSEMENT, label: 'Reimbursement' },
  { id: PAYMENT_CATEGORY_OTHER, label: 'Other' },
  { id: PAYMENT_CATEGORY_SALARY, label: 'Salary' }
];
export const   APPRAISAL_TYPE_SALARY = 'Salary'
export const   APPRAISAL_TYPE_DESIGNATION = 'Designation'  
export const   APPRAISAL_TYPE_BOTH = 'Both'

export const APPRAISAL_FOR = [
  { id:'SALARY', label: APPRAISAL_TYPE_SALARY },
  { id: 'DESIGNATION', label: APPRAISAL_TYPE_DESIGNATION },
  { id: 'BOTH', label:  APPRAISAL_TYPE_BOTH }
];

export const RELEASE_ID = 1;
export const RESIGN_ID = 2;

export const PAYMENT_MODE = [
  { label: 'Cash', id: 'CASH' },
  { label: 'Cheque', id: 'CHEQUE' },
  { label: 'NEFT', id: 'NEFT' },
  { label: 'RTGS', id: 'RTGS' },
  { label: 'E-Wallet', id: 'E-Wallet' },
  { label: 'UPI', id: 'UPI' }
];

export const FREELANCER_PERSONAL_DETAILS_UPDATE = '/update-freelancer-detail';
export const FREELANCER_BANK_DETAILS_UPDATE = '/update-freelancer-bank'

export const BULK = 'BULK';
export const PARTIAL_STATUS = 'PARTIAL';
export const PARTIALLY_PAID_STATUS = 'PARTIALLY PAID';
export const HOLD_STATUS = 'HOLD';
export const PENDING_STATUS = 'PENDING';
export const APPROVED_STATUS = 'APPROVED';
export const CANCELLED_STATUS = 'CANCELLED';
export const COMPLETE_STATUS = 'COMPLETE';
export const COMPLETED_STATUS = 'COMPLETED';
export const TERMINATED_STATUS = 'TERMINATED';
export const WORKING_STATUS = 'WORKING';
export const LOCKED_STATUS = 'LOCKED';



export const PAYMENT_TRANSACTION_PAYMENT_STATUS_COLOR = {
  PENDING: '',
  APPROVED: '',
  CANCELLED: '',
  COMPLETE: 'color-successText',
}

export const PAYMENT_TRANSACTION_STATUS_OPTIONS = [
  {
    id: 'PENDING',
    label: 'Pending',
  },
  
  {
    id: 'APPROVED',
    label: 'Approved',
  },
  
  {
    id: 'CANCELLED',
    label: 'Rejected',
  },
  
  {
    id: 'COMPLETE',
    label: 'Paid',
  },
]

export const REGULARIZATION_REQUEST_STATUS_OPTIONS = [
  {
    id: 1,
    label: 'Pending',
  },
  
  {
    id: 2,
    label: 'Approved',
  },
  
  {
    id: 3,
    label: 'Rejected',
  },
]

export const PAYMENT_TRANSACTION_PAYMENT_MEDIUM = {
  CASH: 'Cash',
  CHEQUE: 'Cheque',
  NEFT: 'NEFT',
  RTGS: 'RTGS',
  'E-Wallet': 'E-Wallet',
  UPI: 'UPI',
}

export const PAYMENT_TRANSACTION_CATEGORY = {
  [PAYMENT_CATEGORY_ADVANCE]: 'Advance',
  [PAYMENT_CATEGORY_REIMBURSEMENT]: 'Reimbursement',
  [PAYMENT_CATEGORY_OTHER]: 'Other',
}

//DEPARTMENT Reducers
export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING';
export const DEPARTMENT_FAILURE = 'DEPARTMENT_FAILURE';
export const DEPARTMENT_SUCCESS = 'DEPARTMENT_SUCCESS';

export const DEPARTMENT_LIST_LOADING = 'DEPARTMENT_LIST_LOADING';
export const DEPARTMENT_LIST_FAILURE = 'DEPARTMENT_LIST_FAILURE';
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS';

// AUTHENTICATION
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_RESET_REDIRECT = 'LOGIN_RESET_REDIRECT';

// AUTHENTICATION RESET PASSWORD
export const LOGIN_RESET_SUCCESS = 'LOGIN_RESET_SUCCESS';
export const LOGIN_RESET_TOKEN = 'LOGIN_RESET_TOKEN';
export const LOGIN_RESET_FAILURE = 'LOGIN_RESET_FAILURE';
export const LOGIN_RESET_LOADING = 'LOGIN_RESET_LOADING';

// AUTHENTICATION LINK SEND
export const LOGIN_LINK_SUCCESS = 'LOGIN_LINK_SUCCESS';
export const LOGIN_LINK_FAILURE = 'LOGIN_LINK_FAILURE';
export const LOGIN_LINK_LOADING = 'LOGIN_LINK_LOADING';
export const LOGIN_LINK_CLEAR = 'LOGIN_LINK_CLEAR';

// export const LOGIN_FIRST = 'LOGIN_FIRST';
export const LOGOUT = 'LOGOUT';
export const RESET = 'RESET';

//COMPANY
export const COMPANY_LOADING = 'COMPANY_LOADING';
export const COMPANY_FAILURE = 'COMPANY_FAILURE';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';

//DESIGNATION Reducers
export const DESIGNATION_LOADING = 'DESIGNATION_LOADING';
export const DESIGNATION_FAILURE = 'DESIGNATION_FAILURE';
export const DESIGNATION_SUCCESS = 'DESIGNATION_SUCCESS';

export const DESIGNATION_LIST_LOADING = 'DESIGNATION_LIST_LOADING';
export const DESIGNATION_LIST_FAILURE = 'DESIGNATION_LIST_FAILURE';
export const DESIGNATION_LIST_SUCCESS = 'DESIGNATION_LIST_SUCCESS';

export const PAYSLIP_IMAGE_DETAILS = [{ id: '1', src: '/assets/images/fss1.svg' }, { id: '2', src: '/assets/images/fss2.svg' }, { id: '3', src: '/assets/images/fss3.svg' }]
export const PAID_STATUS = 'PAID';
export const UNPAID_STATUS = 'UNPAID';
export const LEAVE_DROPDOWN_OPTIONS = [{ label: 'Paid leave', id: PAID_STATUS }, { label: 'Unpaid Leave', id: UNPAID_STATUS }];
export const UNPAID_LEAVE_DROPDOWN_OPTIONS = [{ label: 'Unpaid Leave', id: UNPAID_STATUS }];
export const CalendarYear = 2
export const FinancialYear = 1
export const Financial_start_month = '04'
export const currentYear = moment().format('YYYY')
export const TenureOptions = [
  { id: FinancialYear, label: 'Financial Year ' }, //removed (April to March) as per new design
  { id: CalendarYear, label: 'Calendar Year ' }, // removed (Jan to Dec) as per new design
  { id: '3', label: '1 Month' },
  { id: '4', label: '2 Month' },
  { id: '5', label: '3 Month' },
  { id: '6', label: '4 Month' },
  { id: '7', label: '5 Month' },
  { id: '8', label: '6 Month' },
  { id: '9', label: '7 Month' },
  { id: '10', label: '8 Month' },
  { id: '11', label: '9 Month' },
  { id: '12', label: '10 Month' },
  { id: '13', label: '11 Month' },
];
export const MALE = 'MALE';
export const FEMALE = 'FEMALE';
export const OTHER = 'OTHER';
export const GenderOptions = [
  { 'id': MALE, 'label': 'Male' },
  { 'id': FEMALE, 'label': 'Female' },
  { 'id': OTHER, 'label': 'Other' },
];

export const EMAIL = 'EMAIL';
export const MOBILE_NO = 'MOBILE_NO';
export const USERNAME_INITIAL_OPTIONS = [{ id: EMAIL, label: 'Email' }, { id: MOBILE_NO, label: 'Mobile No.' }, { id: OTHER, label: 'Other' }]

export const TABLE_HEAD_WEEK = [
  { id: 'Day', label: 'Day', alignRight: false },
  { id: 'Week One', label: 'Week One', alignRight: 'center' },
  { id: 'Week Two', label: 'Week Two', alignRight: 'center' },
  { id: 'Week Three', label: 'Week Three', alignRight: 'center' },
  { id: 'Week Four', label: 'Week Four', alignRight: 'center' },
  { id: 'Week Five', label: 'Week Five', alignRight: 'center' }
];

export const BloodGroupOptions = [
  { id: 'A+', label: 'A+' },
  { id: 'B+', label: 'B+' },
  { id: 'O+', label: 'O+' },
  { id: 'AB+', label: 'AB+' },
  { id: 'A-', label: 'A-' },
  { id: 'O-', label: 'O-' },
  { id: 'B-', label: 'B-' },
  { id: 'AB-', label: 'AB-' }
];

export const MaritalStatusOptions = [
  { id: 'MARRIED', label: 'Married' },
  { id: 'SINGLE', label: 'Single' },
];

export const GST_SLAB_OPTIONS = [
  { id: 0, label: "No GST" },
  { id: 5, label: "5%" },
  { id: 12, label: "12%" },
  { id: 18, label: "18%" },
  { id: 28, label: "28%" }
]
export const GST_TYPE_OPTIONS = [
  { id: false, label: "SGST, CGST" },
  { id: true, label: "IGST" },
]
// EMP_AUTH usersettings
export const EMP_AUTH_FAILURE = 'EMP_AUTH_FAILURE';
export const EMP_AUTH_SUCCESS = 'EMP_AUTH_SUCCESS';
export const EMP_AUTH_LOADING = 'EMP_AUTH_LOADING';

export const EMP_AUTH_LIST_FAILURE = 'EMP_AUTH_LIST_FAILURE';
export const EMP_AUTH_LIST_SUCCESS = 'EMP_AUTH_LIST_SUCCESS';
export const EMP_AUTH_LIST_LOADING = 'EMP_AUTH_LIST_LOADING';


// Holiday Setting
export const HOLIDAY_FAILURE = 'HOLIDAY_FAILURE';
export const HOLIDAY_LOADING = 'HOLIDAY_LOADING';
export const HOLIDAY_SUCCESS = 'HOLIDAY_SUCCESS';
export const HOLIDAY_LIST_FAILURE = 'HOLIDAY_LIST_FAILURE';
export const HOLIDAY_LIST_SUCCESS = 'HOLIDAY_LIST_SUCCESS';
export const HOLIDAY_LIST_LOADING = 'HOLIDAY_LIST_LOADING';


export const DOWNLOAD_PDF = "PDF";
export const DOWNLOAD_CSV = "CSV";
export const ATTENDANCE_AND_PAYROLL_DOWNLOAD_URL = "payroll/get-attendance-download";
export const REPORT_CHECK_IN_OUT_DOWNLOAD_URL = "report/attendance-log-report/by-check-in-out-download";
export const ATTENDANCE_LOG_DOWNLOAD_URL = "attendance/employee-attendance-download";
export const ATTENDANCE_LOG_REPORT_DOWNLOAD_URL = "report/user-attendance-log-report-download";
export const ATTENDANCE_REGULARIZATION_REQUEST_DOWNLOAD_URL = "attendance/employee-attendance-regularize-request-list";
export const LEAVE_LOG_DOWNLOAD_URL = "leave/employee-leave-list-download";
export const HOLIDAY_LIST_DOWNLOAD_URL = "holiday/holiday-list-download";
export const TALENT_LIST_DOWNLOAD_URL = "employee/employee-list-download";
export const CONTRACT_LIST_DOWNLOAD_URL = "contract/contract-list-download";
export const FREELANCER_LIST_DOWNLOAD_URL = "freelancer/freelancer-list-download";

export const PAYMENT_LIST_DOWNLOAD_URL = "payment/payment-list-download";
export const TALENT_PAYMENT_LOG_DOWNLOAD_URL = "payment/payment-list-download";
export const COST_TO_COMPANY_DOWNLOAD = "report/cost-to-company-report-download";
export const COST_TO_COMPANY_BYMONTH_DOWNLOAD = "report/cost-to-company-report-bymonth-download";
export const APPRAISAL_LIST_DOWNLOAD_URL = "report/appraisal-report-download";


export const COMPANY_PAYMENT_LOG_DOWNLOAD_URL = "payment/company-payment-log-download";
export const SALARY_SLIP_DOWNLOAD_URL = "payment/get-salary-slip";
export const PAYROLL_REPORT_DOWNLOAD_URL = "report/payroll-download";
export const NOC_DOCUMENT_DOWNLOAD = "employee/get-noc-document";
export const PAYROLL_LIST_DOWNLOAD = "salary/payroll-list-download";
export const PAYROLL_DETAIL_DOWNLOAD = "salary/payroll-detail-list-download";
export const TALENT_SALARY_LIST_DOWNLOAD = "salary/talent-salary-list-download";


//Employee List
export const EMPLOYEE_SUCCESS = 'EMPLOYEE_SUCCESS';
export const EMPLOYEE_FAILURE = 'EMPLOYEE_FAILURE';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';

//Employee List where we can find all Employees without any filter
export const ALL_EMPLOYEE_SUCCESS = 'ALL_EMPLOYEE_SUCCESS';
export const ALL_EMPLOYEE_FAILURE = 'ALL_EMPLOYEE_FAILURE';
export const ALL_EMPLOYEE_LOADING = 'ALL_EMPLOYEE_LOADING';

//Employee Profile
export const EMPLOYEE_PROFILE_LOADING = 'EMPLOYEE_PROFILE_LOADING';
export const EMPLOYEE_PROFILE_SUCCESS = 'EMPLOYEE_PROFILE_SUCCESS';
export const EMPLOYEE_PROFILE_FAILURE = 'EMPLOYEE_PROFILE_FAILURE';

//company Setting reducer
export const COMPANY_SETTING_LOADING = 'COMPANY_SETTING_LOADING';
export const COMPANY_SETTING_FAILURE = 'COMPANY_SETTING_FAILURE';
export const COMPANY_SETTING_SUCCESS = 'COMPANY_SETTING_SUCCESS';

//Global company Setting reducer
export const GLOBAL_SETTING_LOADING = 'GLOBAL_SETTING_LOADING';
export const GLOBAL_SETTING_FAILURE = 'GLOBAL_SETTING_FAILURE';
export const GLOBAL_SETTING_SUCCESS = 'GLOBAL_SETTING_SUCCESS';

export const COMPANY_REGULARIZATION_API = 'company-regularization-setting';
export const COMPANY_WORKING_TIME_API = 'company-attendance-setting';
export const COMPANY_LEAVE_CYCLE_API = 'company-leavecycle-setting';
export const COMPANY_WEEKOFFS_API = 'company-workingday-setting';
export const SALARY_SLIP_API = 'salary-slip-setting';

// SETTINGS APIs
export const GET_LEAVE_SETTING_API = 'get-leave-setting';
export const SET_LEAVE_SETTING_API = 'set-leave-setting';
export const GET_ACCOUNT_SETTING_API = 'get-account-setting';
export const GET_ADDITIONAL_SETTING_API = 'get-additional-setting';
export const SET_ADDITIONAL_SETTING_API = 'set-additional-setting';
export const GET_PAYROLL_SETTING_API = 'get-payroll-setting';
export const SET_PAYROLL_SETTING_API = 'set-payroll-setting';
export const GET_PAYMENT_SETTING_API = 'get-payment-setting';
export const SET_PAYMENT_SETTING_API = 'set-payment-setting';
export const GET_ATTENDANCE_SETTING_API = 'get-attendance-setting';
export const SET_ATTENDANCE_SETTING_API = 'set-attendance-setting';
export const GET_NOTIFICATION_SETTING_API = 'get-notification-setting';
export const SET_NOTIFICATION_SETTING_API = 'set-notification-setting';
export const GET_TALENT_SETTING_API = 'get-talent-setting';
export const SET_TALENT_SETTING_API = 'set-talent-setting';


export const SETTING_FAILURE = 'SETTING_FAILURE';
export const SETTING_LOADING_START = 'SETTING_LOADING_START';
export const SETTING_LOADING_STOP = 'SETTING_LOADING_STOP';
export const SETTING_ADDITIONAL = 'SETTING_ADDITIONAL';
export const SETTING_ATTENDANCE = 'SETTING_ATTENDANCE';
export const SETTING_ACCOUNT = 'SETTING_ACCOUNT';
export const SETTING_LEAVE = 'SETTING_LEAVE';
export const SETTING_TALENT = 'SETTING_TALENT';
export const SETTING_PAYMENT = 'SETTING_PAYMENT';
export const SETTING_PAYROLL = 'SETTING_PAYROLL';

//Leave Log & Attendance Log
export const ATTENDANCE_LOG_COMPONENT = '1';//used attendance page
export const MY_LEAVE_TAB = 'SELF';
export const LEAVE_HISTORY_TAB = 'HISTORY';
export const LEAVE_REQUEST_TAB = 'REQUEST';

export const MY_ATTENDANCE_TAB = '1';
export const TALENT_ATTENDANCE_TAB = '2';
export const REGUALRIZATION_REQUEST_TAB = '3';

export const maxDateWithoutTenure = moment().add(6, 'months'); //This date is the max date till a talent can apply for leave(6 months )
export const APPROVE_STATUS = "APPROVE";
export const REJECT_STATUS = "REJECT";
export const REJECTED_STATUS = "REJECTED";
export const HRPENDING = "HRPENDING";
export const LEAVE_STATUS_NON_PENDING_OPTIONS = [
  { id: APPROVED_STATUS, label: 'Approved' },
  { id: REJECTED_STATUS, label: 'Rejected' },
];
export const LEAVE_STATUS_PENDING_OPTIONS = [
  { id: PENDING_STATUS, label: 'Pending' },
  { id: HRPENDING, label: 'HR Pending' }
];
export const TALENT_STAGE = 1; // Status of leave progress bar
export const RM_STAGE = 2; // Status of leave progress bar
export const HR_STAGE = 3; // Status of leave progress bar

// Notification API 
export const GET_NOTIFICATION_LIST_API = 'notification/notification-list';

//Contract APIs
export const CONTRACT_PAYMENT_DETAILS_UPDATE_API = '/update-contract-payment-details';
export const CONTRACT_TNC_UPDATE_API = '/update-contract-tnc';
export const CONTRACT_TERMINATED_BY_COMPANY = '2';
export const CONTRACT_TERMINATED_BY_FREELANCER = '1';

//Freelancers APIs
export const FREELANCER_DOC_DELETE_API = '/delete-document';

//url for contract termination
export const CONTRACT_TERMINATION_URL = "contract/terminate-contract";
export const BULK_PAYMENT_URL = "bulk-final-payment-action";
export const BULK_SALARY_PAYMENT_URL = "salary/bulk-salary-payment";

//STATE Reducers
export const STATE_LIST_LOADING = 'STATE_LIST_LOADING';
export const STATE_LIST_FAILURE = 'STATE_LIST_FAILURE';
export const STATE_LIST_SUCCESS = 'STATE_LIST_SUCCESS';

//CITY Reducers
export const CITY_LIST_LOADING = 'CITY_LIST_LOADING';
export const CITY_LIST_FAILURE = 'CITY_LIST_FAILURE';
export const CITY_LIST_SUCCESS = 'CITY_LIST_SUCCESS';

//Employee Working Mode

export const WORK_MODE_OFFICE = '1';
export const WORK_MODE_HOME = '2';
export const WORK_MODE_HYBRID = '3';
export const WORK_MODE_OFFICE_LABEL = 'Office';
export const WORK_MODE_HOME_LABEL = 'Remote';
export const WORK_MODE_HYBRID_LABEL = 'Hybrid';
export const WORK_MODE = [
  { id: WORK_MODE_HYBRID, label: WORK_MODE_HYBRID_LABEL },
  { id: WORK_MODE_HOME, label: WORK_MODE_HOME_LABEL },
  { id: WORK_MODE_OFFICE, label: WORK_MODE_OFFICE_LABEL },
]
//Talent Salary Appraisal
export const SALARY_APPRAISAL_LOADING = 'SALARY_APPRAISAL_LOADING';
export const SALARY_APPRAISAL_LIST_SUCCESS = 'SALARY_APPRAISAL_LIST_SUCCESS';
export const SALARY_APPRAISAL_FAILURE = 'SALARY_APPRAISAL_FAILURE';
export const SALARY_APPRAISAL_SAVE_SUCCESS = 'SALARY_APPRAISAL_SAVE_SUCCESS'


// EMPLOYEE PAYMENT
export const EMPLOYEE_PAYMENT_FAILURE = 'EMPLOYEE_PAYMENT_FAILURE';
export const EMPLOYEE_PAYMENT_SUCCESS = 'EMPLOYEE_PAYMENT_SUCCESS';
export const EMPLOYEE_PAYMENT_LOADING = 'EMPLOYEE_PAYMENT_LOADING';
export const EMPLOYEE_PAYMENT_FORM_FAILURE = 'EMPLOYEE_PAYMENT_FORM_FAILURE';
export const EMPLOYEE_PAYMENT_FORM_SUCCESS = 'EMPLOYEE_PAYMENT_FORM_SUCCESS';
export const EMPLOYEE_PAYMENT_FORM_LOADING = 'EMPLOYEE_PAYMENT_FORM_LOADING';

export const PAYMENT_TRANSACTION_APPROVAL_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  CANCELLED: 'Rejected',
  COMPLETE: 'Approved',
  PAID: 'Paid',
  PARTIAL: 'Partially Paid',
}

export const SALARY_STATUS = [
  {
    id: 'PENDING',
    label: 'Pending',
  },
  {
    id: 'PARTIAL',
    label: 'Partially Paid',
  },
  {
    id: 'PAID',
    label: 'Paid',
  },
  {
    id: 'CANCELLED',
    label: 'Cancelled',
  },
]

export const SALARY_PAYMENT_TRANSACTION_APPROVAL_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  CANCELLED: 'Cancelled',
  COMPLETE: 'Approved',
  PAID: 'Paid',
  PARTIAL: 'Partially Paid',
}

export const SALARY_PAYMENT_MEDIUM = {
  "CASH": 'Cash',
  "CHEQUE": 'Cheque',
  "NEFT": 'NEFT',
  "RTGS": 'RTGS',
  "E-WALLET": 'E-WALLET',
  "E-Wallet": 'E-Wallet',
  "UPI": 'UPI',
}

export const PAYMENT_TRANSACTION_APPROVAL_STATUS_COLOR = {
  PENDING: '',
  APPROVED: 'color-infoText',
  CANCELLED: 'color-errorText',
  COMPLETE: 'color-infoText',
  PAID: 'color-successText',
  PARTIAL: 'color-partialText',
}

export const PAYMENT_TRANSACTION_PAYMENT_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Pending',
  CANCELLED: 'Rejected',
  COMPLETE: 'Paid',
  PAID: 'Paid',
  PARTIAL: 'Partially Paid',
}



// ATTENDANCE

export const ATTENDANCE_LIST_FAILURE = 'ATTENDANCE_LIST_FAILURE';
export const ATTENDANCE_LIST_SUCCESS = 'ATTENDANCE_LIST_SUCCESS';
export const ATTENDANCE_LIST_LOADING = 'ATTENDANCE_LIST_LOADING';

export const ATTENDANCE_FAILURE = 'ATTENDANCE_FAILURE';
export const ATTENDANCE_SUCCESS = 'ATTENDANCE_SUCCESS';
export const ATTENDANCE_LOADING = 'ATTENDANCE_LOADING';

export const EMP_ATTENDANCE_FAILURE = 'EMP_ATTENDANCE_FAILURE';
export const EMP_ATTENDANCE_SUCCESS = 'EMP_ATTENDANCE_SUCCESS';
export const EMP_ATTENDANCE_LOADING = 'EMP_ATTENDANCE_LOADING';
export const EMP_ATTENDANCE_EMPTY = 'EMP_ATTENDANCE_EMPTY';


export const REGULARIZATION_REQUEST_LIST_FAILIURE = 'REGULARIZATION_REQUEST_LIST_FAILIURE';
export const REGULARIZATION_REQUEST_LIST_SUCCESS = 'REGULARIZATION_REQUEST_LIST_SUCCESS';
export const REGULARIZATION_REQUEST_LIST_LOADING = 'REGULARIZATION_REQUEST_LIST_LOADING'

export const APPRAISAL_REPORT_LIST_FAILURE = 'APPRAISAL_REPORT_LIST_FAILURE';
export const APPRAISAL_REPORT_LIST_SUCCESS = 'APPRAISAL_REPORT_LIST_SUCCESS';
export const APPRAISAL_REPORT_LIST_LOADING = 'APPRAISAL_REPORT_LIST_LOADING';


export const COST_TO_COMPANY_LIST_FAILURE = 'COST_TO_COMPANY_LIST_FAILURE';
export const COST_TO_COMPANYT_LIST_SUCCESS = 'COST_TO_COMPANY_LIST_SUCCESS';
export const COST_TO_COMPANY_LIST_LOADING = 'COST_TO_COMPANY_LIST_LOADING';

export const COST_TO_COMPANY_DETAIL_FAILURE = 'COST_TO_COMPANY_DETAIL_FAILURE';
export const COST_TO_COMPANY_DETAIL_SUCCESS = 'COST_TO_COMPANY_DETAIL_SUCCESS';
export const COST_TO_COMPANY_DETAIL_LOADING = 'COST_TO_COMPANY_DETAIL_LOADING';

export const REPORT_BY_CHECK_IN_OUT_DETAIL_FAILURE = 'REPORT_BY_CHECK_IN_OUT_DETAIL_FAILURE';
export const REPORT_BY_CHECK_IN_OUT_DETAIL_SUCCESS = 'REPORT_BY_CHECK_IN_OUT_DETAIL_SUCCESS';
export const REPORT_BY_CHECK_IN_OUT_DETAIL_LOADING = 'REPORT_BY_CHECK_IN_OUT_DETAIL_LOADING';


// Employee CheckinCheckout data
export const TODAY_GRAPH_FAILURE = 'TODAY_GRAPH_FAILURE';
export const TODAY_GRAPH_SUCCESS = 'TODAY_GRAPH_SUCCESS';
export const TODAY_GRAPH_LOADING = 'TODAY_GRAPH_LOADING';


// Today Attendance
export const TODAY_ATTENDANCE_SUCCESS = 'TODAY_ATTENDANCE_SUCCESS';
export const TODAY_ATTENDANCE_FAILURE = 'TODAY_ATTENDANCE_FAILURE';
export const TODAY_ATTENDANCE_LOADING = 'TODAY_ATTENDANCE_LOADING';

//Leave Reducers
export const LEAVE_LOADING = 'LEAVE_LOADING';
export const LEAVE_FAILURE = 'LEAVE_FAILURE';
export const LEAVE_SUCCESS = 'LEAVE_SUCCESS';

export const LEAVE_LIST_LOADING = 'LEAVE_LIST_LOADING';
export const LEAVE_LIST_FAILURE = 'LEAVE_LIST_FAILURE';
export const LEAVE_LIST_SUCCESS = 'LEAVE_LIST_SUCCESS';

//Add Employee
export const EMPLOYEE_FORM_SUCCESS = 'EMPLOYEE_FORM_SUCCESS';
export const EMPLOYEE_FORM_FAILURE = 'EMPLOYEE_FORM_FAILURE';
export const EMPLOYEE_FORM_LOADING = 'EMPLOYEE_FORM_LOADING';

//Employee Leave
export const EMPLOYEE_LEAVE_SUCCESS = 'EMPLOYEE_LEAVE_SUCCESS';
export const EMPLOYEE_LEAVE_FAILURE = 'EMPLOYEE_LEAVE_FAILURE';
export const EMPLOYEE_LEAVE_LOADING = 'EMPLOYEE_LEAVE_LOADING';


export const EMPLOYEE_LEAVE_BALANCE_SUCCESS = 'EMPLOYEE_LEAVE_BALANCE_SUCCESS';
export const EMPLOYEE_LEAVE_BALANCE_FAILURE = 'EMPLOYEE_LEAVE_BALANCE_FAILURE';
export const EMPLOYEE_LEAVE_BALANCE_LOADING = 'EMPLOYEE_LEAVE_BALANCE_LOADING';

export const EMPLOYEE_LEAVE_LIST_FAILURE = 'EMPLOYEE_LEAVE_LIST_FAILURE';
export const EMPLOYEE_LEAVE_LIST_SUCCESS = 'EMPLOYEE_LEAVE_LIST_SUCCESS';
export const EMPLOYEE_LEAVE_LIST_LOADING = 'EMPLOYEE_LEAVE_LIST_LOADING';

export const EMPLOYEE_LEAVE_VIEW_LOADING = 'EMPLOYEE_LEAVE_VIEW_LOADING';
export const EMPLOYEE_LEAVE_VIEW_SUCCESS = 'EMPLOYEE_LEAVE_VIEW_SUCCESS';
export const EMPLOYEE_LEAVE_VIEW_FAILURE = 'EMPLOYEE_LEAVE_VIEW_FAILURE';

// Employee Leave Logs
export const EMP_LEAVE_LOG_FAILURE = 'EMP_LEAVE_LOG_FAILURE';
export const EMP_LEAVE_LOG_LOADING = 'EMP_LEAVE_LOG_LOADING';
export const EMP_LEAVE_LOG_SUCCESS = 'EMP_LEAVE_LOG_SUCCESS';

// INFO: below constants were of no use hence commented them
// export const ABBREVIATION_LIST_FAILURE = 'ABBREVIATION_LIST_FAILURE';
// export const ABBREVIATION_LIST_SUCCESS = 'ABBREVIATION_LIST_SUCCESS';
// export const ABBREVIATION_LIST_LOADING = 'ABBREVIATION_LIST_LOADING';

export const PAYROLL_FAILURE = 'PAYROLL_FAILURE';
export const PAYROLL_LOADING = 'PAYROLL_LOADING';
export const PAYROLL_SUCCESS = 'PAYROLL_SUCCESS';

export const PAYROLL_LIST_FAILURE = 'PAYROLL_LIST_FAILURE';
export const PAYROLL_LIST_SUCCESS = 'PAYROLL_LIST_SUCCESS';
export const PAYROLL_LIST_LOADING = 'PAYROLL_LIST_LOADING_NEW';

export const PAYROLL_NEW_LIST_FAILURE = 'PAYROLL_NEW_LIST_FAILURE';
export const PAYROLL_NEW_LIST_SUCCESS = 'PAYROLL_NEW_LIST_SUCCESS';
export const PAYROLL_NEW_LIST_LOADING = 'PAYROLL_NEW_LIST_LOADING';

export const PAYROLL_NEW_LIST_BY_TALENT_FAILURE = 'PAYROLL_NEW_LIST_BY_TALENT_FAILURE';
export const PAYROLL_NEW_LIST_BY_TALENT_SUCCESS = 'PAYROLL_NEW_LIST_BY_TALENT_SUCCESS';
export const PAYROLL_NEW_LIST_BY_TALENT_LOADING = 'PAYROLL_NEW_LIST_BY_TALENT_LOADING';

export const PAYROLL_NEW_LIST_DETAIL_FAILURE = 'PAYROLL_NEW_LIST_DETAIL_FAILURE';
export const PAYROLL_NEW_LIST_DETAIL_SUCCESS = 'PAYROLL_NEW_LIST_DETAIL_SUCCESS';
export const PAYROLL_NEW_LIST_DETAIL_LOADING = 'PAYROLL_NEW_LIST_DETAIL_LOADING';

export const PAYROLL_DETAIL_FAILURE = 'PAYROLL_DETAIL_FAILURE';
export const PAYROLL_DETAIL_SUCCESS = 'PAYROLL_DETAIL_SUCCESS';
export const PAYROLL_DETAIL_LOADING = 'PAYROLL_DETAIL_LOADING';


//EMPLOYEE PAY SALARY
export const PAY_SALARY_LOADING = 'PAY_SALARY_LOADING';
export const PAY_SALARY_SUCCESS = 'PAY_SALARY_SUCCESS';
export const PAY_SALARY_FAILURE = 'PAY_SALARY_FAILURE';

export const PAY_SALARY_LIST_LOADING = 'PAY_SALARY_LIST_LOADING';
export const PAY_SALARY_LIST_SUCCESS = 'PAY_SALARY_LIST_SUCCESS';
export const PAY_SALARY_LIST_FAILURE = 'PAY_SALARY_LIST_FAILURE';

export const PAY_MODAL_LIST_LOADING = 'PAY_MODAL_LIST_LOADING';
export const PAY_MODAL_LIST_SUCCESS = 'PAY_MODAL_LIST_SUCCESS';
export const PAY_MODAL_LIST_FAILURE = 'PAY_MODAL_LIST_FAILURE';

//EMPLOYEE PAY SALARY SLIP
export const EMPLOYEE_SALARY_SLIP_FAILURE = 'EMPLOYEE_SALARY_SLIP_FAILURE';
export const EMPLOYEE_SALARY_SLIP_SUCCESS = 'EMPLOYEE_SALARY_SLIP_SUCCESS';
export const EMPLOYEE_SALARY_SLIP_LOADING = 'EMPLOYEE_SALARY_SLIP_LOADING';

export const EMPLOYEE_PREV_ADVANCE_LOADING = 'EMPLOYEE_PREV_ADVANCE_LOADING';
export const EMPLOYEE_PREV_ADVANCE_SUCCESS = 'EMPLOYEE_PREV_ADVANCE_SUCCESS';
export const EMPLOYEE_PREV_ADVANCE_FAILURE = 'EMPLOYEE_PREV_ADVANCE_FAILURE';


//Contract 
export const CONTRACT_LIST_LOADING = 'CONTRACT_LIST_LOADING';
export const CONTRACT_LIST_SUCCESS = 'CONTRACT_LIST_SUCCESS';
export const CONTRACT_LIST_FAILURE = 'CONTRACT_LIST_FAILURE';

export const CONTRACT_LOADING = 'CONTRACT_LOADING';
export const CONTRACT_SUCCESS = 'CONTRACT_SUCCESS';
export const CONTRACT_FAILURE = 'CONTRACT_FAILURE';

//Freelancer 
export const FREELANCER_LIST_LOADING = 'FREELANCER_LIST_LOADING';
export const FREELANCER_LIST_SUCCESS = 'FREELANCER_LIST_SUCCESS';
export const FREELANCER_LIST_FAILURE = 'FREELANCER_LIST_FAILURE';

export const FREELANCER_LOADING = 'FREELANCER_LOADING';
export const FREELANCER_SUCCESS = 'FREELANCER_SUCCESS';
export const FREELANCER_FAILURE = 'FREELANCER_FAILURE';

//Employee List where we can find all Employees without any filter
export const ALL_FREELANCER_SUCCESS = 'ALL_FREELANCER_SUCCESS';
export const ALL_FREELANCER_FAILURE = 'ALL_FREELANCER_FAILURE';
export const ALL_FREELANCER_LOADING = 'ALL_FREELANCER_LOADING';

//Terms and conditions
export const TAC_LOADING = 'TAC_LOADING';
export const TAC_SUCCESS = 'TAC_SUCCESS';
export const TAC_FAILURE = 'TAC_FAILURE';

export const TAC_LIST_LOADING = 'TAC_LIST_LOADING';
export const TAC_LIST_SUCCESS = 'TAC_LIST_SUCCESS';
export const TAC_LIST_FAILURE = 'TAC_LIST_FAILURE';


//Support Module
export const SUPPORT_LIST_LOADING = 'SUPPORT_LIST_LOADING';
export const SUPPORT_LIST_SUCCESS = 'SUPPORT_LIST_SUCCESS';
export const SUPPORT_LIST_FAILURE = 'SUPPORT_LIST_FAILURE';

export const SUPPORT_LOADING = 'SUPPORT_LOADING';
export const SUPPORT_SUCCESS = 'SUPPORT_SUCCESS';
export const SUPPORT_FAILURE = 'SUPPORT_FAILURE';


// update profile
export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ADD_LOADING = 'UPDATE_PROFILE_ADD_LOADING';
export const UPDATE_PROFILE_ADD_SUCCESS = 'UPDATE_PROFILE_ADD_SUCCESS';
export const UPDATE_PROFILE_ADD_FAILURE = 'UPDATE_PROFILE_ADD_FAILURE';

export const ON_BOARD_FAILURE = 'ON_BOARD_FAILURE';
export const ON_BOARD_SUCCESS = 'ON_BOARD_SUCCESS';
export const ON_BOARD_LOADING = 'ON_BOARD_LOADING';

export const SUBSCRIPTION_PLAN_FAILURE = 'SUBSCRIPTION_PLAN_FAILURE';
export const SUBSCRIPTION_PLAN_SUCCESS = 'SUBSCRIPTION_PLAN_SUCCESS';
export const SUBSCRIPTION_PLAN_LOADING = 'SUBSCRIPTION_PLAN_LOADING';

// SCHEDULE_CALL_SLOT : time slots in schedule a call form while onboarding
export const SCHEDULE_CALL_SLOT_LOADING = 'SCHEDULE_CALL_SLOT_LOADING';
export const SCHEDULE_CALL_SLOT_SUCCESS = 'SCHEDULE_CALL_SLOT_SUCCESS';
export const SCHEDULE_CALL_SLOT_FAILURE = 'SCHEDULE_CALL_SLOT_FAILURE';

export const PLAN_FAILURE = 'PLAN_FAILURE';
export const PLAN_SUCCESS = 'PLAN_SUCCESS';
export const PLAN_LOADING = 'PLAN_LOADING';



//Leave Master Reducers
export const LEAVE_MASTER_LOADING = 'LEAVE_MASTER_LOADING';
export const LEAVE_MASTER_FAILURE = 'LEAVE_MASTER_FAILURE';
export const LEAVE_MASTER_SUCCESS = 'LEAVE_MASTER_SUCCESS';

export const LEAVE_MASTER_LIST_LOADING = 'LEAVE_MASTER_LIST_LOADING';
export const LEAVE_MASTER_LIST_FAILURE = 'LEAVE_MASTER_LIST_FAILURE';
export const LEAVE_MASTER_LIST_SUCCESS = 'LEAVE_MASTER_LIST_SUCCESS';


// notification list
export const NOTIFICATION_LIST_LOADING = 'NOTIFICATION_LIST_LOADING';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_FAILURE = 'NOTIFICATION_LIST_FAILURE';

// Calendar Dashboard
export const GET_CALENDAR_DASHBOARD_FAILURE = 'GET_CALENDAR_DASHBOARD_FAILURE';
export const GET_CALENDAR_DASHBOARD_SUCCESS = 'GET_CALENDAR_DASHBOARD_SUCCESS';
export const GET_CALENDAR_DASHBOARD_LOADING = 'GET_CALENDAR_DASHBOARD_LOADING';

// notification category list
export const NOTIFICATION_CATEGORY_LIST_LOADING = 'NOTIFICATION_CATEGORY_LIST_LOADING';
export const NOTIFICATION_CATEGORY_LIST_SUCCESS = 'NOTIFICATION_CATEGORY_LIST_SUCCESS';
export const NOTIFICATION_CATEGORY_LIST_FAILURE = 'NOTIFICATION_CATEGORY_LIST_FAILURE';

//TO HOLD COUNT OF USER SETTINGS
export const GLOBAL_USER_SETTING_LOADING = 'GLOBAL_USER_SETTING_LOADING';
export const GLOBAL_USER_SETTING_SUCCESS = 'GLOBAL_USER_SETTING_SUCCESS';
export const GLOBAL_USER_SETTING_FAILURE = 'GLOBAL_USER_SETTING_FAILURE';

export const USER_SETTING_LOADING = 'USER_SETTING_LOADING';
export const USER_SETTING_SUCCESS = 'USER_SETTING_SUCCESS';
export const USER_SETTING_FAILURE = 'USER_SETTING_FAILURE';

export const GET_BIRTHDAY_ANNIVERSARY_FAILURE = 'GET_BIRTHDAY_ANNIVERSARY_FAILURE';
export const GET_BIRTHDAY_ANNIVERSARY_SUCCESS = 'GET_BIRTHDAY_ANNIVERSARY_SUCCESS';
export const GET_BIRTHDAY_ANNIVERSARY_LOADING = 'GET_BIRTHDAY_ANNIVERSARY_LOADING';

//COMPANY TYPE
export const COMPANY_TYPE_ID = {
  PRIVATE_LIMITED: 1,
  PUBLIC_LIMITED: 2,
  NGO: 3,
  SOLE_PROPRIETORSHIP: 4,
  PARTNERSHIP: 5,
}

export const GST = 18
export const COMPANY_STATE_ID = 6
export const COMPANY_TYPE = {
  [COMPANY_TYPE_ID.PRIVATE_LIMITED]: 'Private Limited',
  [COMPANY_TYPE_ID.PUBLIC_LIMITED]: 'Public Limited',
  [COMPANY_TYPE_ID.NGO]: 'NGO',
  [COMPANY_TYPE_ID.SOLE_PROPRIETORSHIP]: 'Sole Proprietorship',
  [COMPANY_TYPE_ID.PARTNERSHIP]: 'Partnership',
}

//FOR PAYROLL REPORT
export const PAYROLL_REPORT_LIST_LOADING = 'PAYROLL_REPORT_LIST_LOADING'
export const PAYROLL_REPORT_LIST_SUCCESS = 'PAYROLL_REPORT_LIST_SUCCESS'
export const PAYROLL_REPORT_LIST_FAILURE = 'PAYROLL_REPORT_LIST_FAILURE'

// FOR CUSTOM USER ROLE
export const CUSTOM_USER_ROLE_LIST_LOADING = 'CUSTOM_USER_ROLE_LIST_LOADING'
export const CUSTOM_USER_ROLE_LIST_SUCCESS = 'CUSTOM_USER_ROLE_LIST_SUCCESS'
export const CUSTOM_USER_ROLE_LIST_FAILURE = 'CUSTOM_USER_ROLE_LIST_FAILURE'

export const PERMISSION_LIST_LOADING = 'PERMISSION_LIST_LOADING'
export const PERMISSION_LIST_SUCCESS = 'PERMISSION_LIST_SUCCESS'
export const PERMISSION_LIST_FAILURE = 'PERMISSION_LIST_FAILURE'

export const CUSTOM_USER_ROLE_DATA_LOADING = 'CUSTOM_USER_ROLE_DATA_LOADING'
export const CUSTOM_USER_ROLE_DATA_SUCCESS = 'CUSTOM_USER_ROLE_DATA_SUCCESS'
export const CUSTOM_USER_ROLE_DATA_FAILURE = 'CUSTOM_USER_ROLE_DATA_FAILURE'

export const CUSTOM_USER_ROLE_LOADING = 'CUSTOM_USER_ROLE_LOADING'
export const CUSTOM_USER_ROLE_SUCCESS = 'CUSTOM_USER_ROLE_SUCCESS'
export const CUSTOM_USER_ROLE_FAILURE = 'CUSTOM_USER_ROLE_FAILURE'

export const SAVE_CUSTOM_USER_ROLE_LOADING = 'SAVE_CUSTOM_USER_ROLE_LOADING'
export const SAVE_CUSTOM_USER_ROLE_SUCCESS = 'SAVE_CUSTOM_USER_ROLE_SUCCESS'
export const SAVE_CUSTOM_USER_ROLE_FAILURE = 'SAVE_CUSTOM_USER_ROLE_FAILURE'


export const GET_DUE_PAYMENT_FAILURE = 'GET_DUE_PAYMENT_FAILURE';
export const GET_DUE_PAYMENT_SUCCESS = 'GET_DUE_PAYMENT_SUCCESS';
export const GET_DUE_PAYMENT_LOADING = 'GET_DUE_PAYMENT_LOADING';

export const GET_INVOICE_HISTORY_FAILURE = 'GET_INVOICE_HISTORY_FAILURE';
export const GET_INVOICE_HISTORY_SUCCESS = 'GET_INVOICE_HISTORY_SUCCESS';
export const GET_INVOICE_HISTORY_LOADING = 'GET_INVOICE_HISTORY_LOADING';

export const USER_LOGIN_STATUS = {
  NORMAL_LOGIN: 0,
  FIRST_LOGIN: 1,
  PASSWORD_RESET: 2,
  PLAN_PENDING: 3,
}

export const PLAN_TIME_FREQUENCY_TYPE = {
  MONTH: "MONTH",
  YEAR: "YEAR",
}

export const PLAN_BILLING_CYCLE_TYPE = {
  MONTH: "Bill Monthly",
  YEAR: "Bill Annually",
}

export const REGULARIZATION_REQUEST = {
  REQUEST_PENDING: 1,
  REQUEST_APPROVED: 2,
  REQUEST_REJECT: 3,
}

export const OFFICE_HOURS_TYPE = {
  HOURLY: 0,
  TIME_BOUND: 1,
}

export const OFFICE_HOURS_TYPE_LABEL = {
  [OFFICE_HOURS_TYPE.HOURLY]: "Hourly",
  [OFFICE_HOURS_TYPE.TIME_BOUND]: "Time-bound",
}

export const PAYMENT_TRANSACTION_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  CANCELLED: 'Rejected',
  COMPLETE: 'Paid',
  PAID: 'Paid',
  PARTIAL: 'Partially Paid',
  "PARTIALLY PAID": 'Partially Paid',
}

export const SALARY_PAYMENT_TRANSACTION_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  CANCELLED: 'Cancelled',
  COMPLETE: 'Paid',
  PAID: 'Paid',
  PARTIAL: 'Partially Paid',
  "PARTIALLY PAID": 'Partially Paid',
}

export const PAYMENT_TRANSACTION_STATUS_CHIP = {
  PENDING: 'active-grey-chip',
  APPROVED: 'active-blue-chip',
  CANCELLED: 'active-red-chip',
  COMPLETE: 'active-green-chip',
}


export const OFFICE_HOURS_TYPE_OPTIONS = [
  {
    label: "Hourly",
    value: OFFICE_HOURS_TYPE.HOURLY,
  },
  {
    label: "Time-bound",
    value: OFFICE_HOURS_TYPE.TIME_BOUND,
  },
]

export const WEEK_OPTIONS = [
  {
    label: "Week 1",
    value: 1,
  },
  {
    label: "Week 2",
    value: 2,
  },
  {
    label: "Week 3",
    value: 3,
  },
  {
    label: "Week 4",
    value: 4,
  },
  {
    label: "Week 5",
    value: 5,
  },
]

export const DAY_OPTIONS = [
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
  {
    label: 'Sunday',
    value: 'Sunday',
  },
]

export const USER_ROLE_FETCH_TYPE = {
  ALL: 0, // ALL THE ROLE
  ONLY_BASE: 1, // ONLY BASE ROLE (INCLUDING SUPERADMIN ROLE)
  ONLY_CUSTOM: 2, // ONLY CUSTOM ROLE WITHOUT BASE ROLE
  WITHOUT_SUPER_ADMIN: 3, // ALL ROLE (INCLUDING BASE AND CUSTOM ROLE) WITHOUT SUPERADMIN ROLE
  BASE_WITHOUT_SUPER_ADMIN: 4, // BASE ROLE WITHOUT SUPERADMIN ROLE
}

export const UNKNOWN_ERROR = 'Unknown error occurred! Please contact to server admin'

export const PERMISSIONS = {
  SideBarTalents: "SideBarTalents",
  SidebarFreelancerContract: "SidebarFreelancerContract",
  SideBarAttendanceList: "SideBarAttendanceList",
  SideBarPayroll: "SideBarPayroll",
  SideBarPayment: "SideBarPayment",
  SideBarReport: "SideBarReport",
  SideBarMaster: "SideBarMaster",
  SideBarUserSetting: "SideBarUserSetting",
  TopBarSetting: "TopBarSetting",
  TopBarProfileSetting: "TopBarProfileSetting",
  TopBarHolidaySetting: "TopBarHolidaySetting",
  OnlyViewTalentDirectory: "OnlyViewTalentDirectory",
  ViewAddTalent: "ViewAddTalent",
  ViewEditTalent: "ViewEditTalent",
  ViewReleaseTalent: "ViewReleaseTalent",
  // OnlyViewFreelancerList: "OnlyViewFreelancerList",
  // ViewAddFreelancer: "ViewAddFreelancer",
  // ViewEditFreelancer: "ViewEditFreelancer",
  // OnlyViewContractList: "OnlyViewContractList",
  // ViewAddEditDeleteContract: "ViewAddEditDeleteContract",
  // ViewTerminateContract: "ViewTerminateContract",
  // ViewAddEditDeleteTnC: "ViewAddEditDeleteTnC",
  VerifyRegularization: "VerifyRegularization",
  VerifyLeave: "VerifyLeave",
  OnlyViewAttendanceList: "OnlyViewAttendanceList",
  ViewEditVerifyAttendanceList: "ViewEditVerifyAttendanceList",
  ViewVerifyAttendanceList: "ViewVerifyAttendanceList",
  OnlyViewPayroll: "OnlyViewPayroll",
  ViewEditFinalizePayroll: "ViewEditFinalizePayroll",
  ViewFinalizePayroll: "ViewFinalizePayroll",
  SideBarSalaryPayment: "SideBarSalaryPayment",
  DownloadPaySlip: "DownloadPaySlip",
  VerifyPayment: "VerifyPayment",
  SideBarTalentPayment: "SideBarTalentPayment",
  // SideBarFreelancerPayment: "SideBarFreelancerPayment",
  SideBarPaymentLog: "SideBarPaymentLog",
  SideBarPerformancePayment: "SideBarPerformancePayment",
  ViewReport: "ViewReport",
  OnlyViewMaster: "OnlyViewMaster",
  ViewAddEditDeleteMaster: "ViewAddEditDeleteMaster",
  OnlyViewUserManagement: "OnlyViewUserManagement",
  ViewAddEditCredentials: "ViewAddEditCredentials",
  ChangeEmployeePassword: "ChangeEmployeePassword",
  SideBarAccountSetting: "SideBarAccountSetting",
  OnlyViewAccountSettings: "OnlyViewAccountSettings",
  ViewEditAccountSettings: "ViewEditAccountSettings",
  SideBarTalentDataSetting: "SideBarTalentDataSetting",
  OnlyViewTalentDataSettings: "OnlyViewTalentDataSettings",
  ViewEditTalentDataSettings: "ViewEditTalentDataSettings",
  SideBarAttendanceSetting: "SideBarAttendanceSetting",
  OnlyViewAttendanceSettings: "OnlyViewAttendanceSettings",
  ViewEditAttendanceSettings: "ViewEditAttendanceSettings",
  SideBarLeaveSetting: "SideBarLeaveSetting",
  OnlyViewLeaveSettings: "OnlyViewLeaveSettings",
  ViewEditLeaveSettings: "ViewEditLeaveSettings",
  SideBarPayrollSetting: "SideBarPayrollSetting",
  OnlyViewPayrollSettings: "OnlyViewPayrollSettings",
  ViewEditPayrollSettings: "ViewEditPayrollSettings",
  SideBarPaymentSetting: "SideBarPaymentSetting",
  OnlyViewPaymentSettings: "OnlyViewPaymentSettings",
  ViewEditPaymentSettings: "ViewEditPaymentSettings",
  SideBarPFSetting: "SideBarPFSetting",
  OnlyViewPFSettings: "OnlyViewPFSettings",
  ViewEditPFSettings: "ViewEditPFSettings",
  SideBarTDSSetting: "SideBarTDSSetting",
  OnlyViewTDSSettings: "OnlyViewTDSSettings",
  ViewEditTDSSettings: "ViewEditTDSSettings",
  // SideBarFreelancerContractSetting: "SideBarFreelancerContractSetting",
  // OnlyViewFreelancerSettings: "OnlyViewFreelancerSettings",
  // ViewEditFreelancerSettings: "ViewEditFreelancerSettings",
  SideBarAdditionalSetting: "SideBarAdditionalSetting",
  OnlyViewAdditionalSettings: "OnlyViewAdditionalSettings",
  ViewEditAdditionalSettings: "ViewEditAdditionalSettings",
  OnlyViewCompanyProfile: "OnlyViewCompanyProfile",
  ViewEditCompanyProfile: "ViewEditCompanyProfile",
  OnlyViewHoliday: "OnlyViewHoliday",
  ViewEditHoliday: "ViewEditHoliday",
  ViewAndCancelSalary: "ViewAndCancelSalary",
  ViewSalary: "ViewSalary",
}
