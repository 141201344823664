import React from 'react'
import { EBox } from '../../BaseComponents/EBox'
import { ETypography } from '../../BaseComponents/ETypography'
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable'
import EChip from '../../BaseComponents/EChip'
import { Grid, useTheme } from '@mui/material'
import { ELinkHRDAshMUI } from '../../BaseComponents/ELink'
import { useNavigate } from 'react-router-dom'
import { numberWithCommas } from '../../utils/formatNumber'

const PaymentREquestHR = ({PaymentRequestCardArray = []}) => {

    const navigate = useNavigate();

    let TABLE_HEAD = [
        { id: 'talentName', label: 'Employee Name', alignRight: false, },
        { id: 'amount', label: 'Amount', alignRight: false, },
        { id: 'status', label: 'Status', alignRight: 'center', },
    ]
    const theme = useTheme()
  return (
    <>
        <EBox sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:'5px'}}>
            <ETypography className='font-size-20px bold-600'>New Payment Request </ETypography>
            <ETypographyHRpayment classname='color-text-box-main'>{PaymentRequestCardArray?.count || 0}</ETypographyHRpayment>
        </EBox>

        {PaymentRequestCardArray?.rows?.length > 0 
            ?
                <>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <ETableContainer sx={{marginTop:'15px', height:'100%'}}>
                        <ETable>
                            <EListHead 
                                headLabel={TABLE_HEAD}
                                rowCount={2}
                                onRequestSort={()=>null}
                            />

                            <ETableBody>
                                {
                                    PaymentRequestCardArray?.rows?.map((item, index)=>{
                                        return(
                                            <ETableRow key={index}>                                  
                                            <>
                                                <ETableCell align="left">
                                                    <EBox >
                                                        <ETypography sx={{fontSize:'14px', fontWeight:'600'}}>{item?.employeeData?.fullname}</ETypography>
                                                        <ETypography sx={{fontSize:'12px', fontWeight:'400'}}>{item?.employeeData?.employee_code}</ETypography>
                                                    </EBox>
                                                </ETableCell>
                                                <ETableCell align="left">
                                                    <EBox >
                                                        <ETypography sx={{fontSize:'14px', fontWeight:'400'}}>{numberWithCommas(item?.pay_amount)}</ETypography>
                                                        <ETypography sx={{fontSize:'12px', fontWeight:'400', color:theme.palette.text.greyColor5}}>{item?.transaction_category}</ETypography>
                                                    </EBox>
                                                </ETableCell>
                                                <ETableCell align="center">
                                                    <EChip 
                                                        width='5.375'
                                                        label={item?.transaction_status}
                                                    />
                                                </ETableCell>
                                            </>

                                    </ETableRow>
                                        )
                                    })
                                }

                            </ETableBody>
                        </ETable>
                    </ETableContainer>
                </Grid>

                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" className="pt12px">
                    <Grid item>
                        <ELinkHRDAshMUI
                            //  disabled={empleave?.empLeaveListData?.rows?.length > 0 ? false : true}
                            onClick={() => navigate('/payment/payment-request')}
                            // onClick={() => navigate('/attendance/leave-log', { state: { name: `${base64.encode(auth?.authData?.fullname)}` } })}
                            className={'font-size-14px bold-600 cursor-pointer'}>
                            Show All
                        </ELinkHRDAshMUI>
                    </Grid>
                </Grid>
                </> 
            :
            <>
                <EBox sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                    <img src="/assets/images/hrDashboardNodata/payments.svg" style={{display:'flex', alignItems:'center', justifyContent:'center'}}/>
                    <ETypography sx={{fontSize:'16px', fontWeight:'500'}}> No Data Found</ETypography>
                </EBox>
            </>}

    </>
    
  )
}

export default PaymentREquestHR

const ETypographyHRpayment = (props) =>{
    const {children} = props
    return( 
        <ETypography sx={{fontWeight:'600', padding:'2px 5px', background:'#D97904', color:'#F5F5F5', borderRadius: "4px", minWidth:'25px', display:'flex', alignItems:'center', justifyContent:'center'}}>
            {children}
        </ETypography>
    )
}