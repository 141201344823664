import {
  PAYROLL_LIST_FAILURE,
  PAYROLL_LIST_SUCCESS,
  PAYROLL_LIST_LOADING,
  PAYROLL_DETAIL_FAILURE,
  PAYROLL_DETAIL_SUCCESS,
  PAYROLL_DETAIL_LOADING,
  PAYROLL_NEW_LIST_LOADING,
  PAYROLL_NEW_LIST_SUCCESS,
  PAYROLL_NEW_LIST_FAILURE,
  PAYROLL_NEW_LIST_DETAIL_FAILURE,
  PAYROLL_NEW_LIST_DETAIL_SUCCESS,
  PAYROLL_NEW_LIST_DETAIL_LOADING,
  PAYROLL_NEW_LIST_BY_TALENT_LOADING,
  PAYROLL_NEW_LIST_BY_TALENT_SUCCESS,
  PAYROLL_NEW_LIST_BY_TALENT_FAILURE
} from '../constants';


const INITIAL_STATE = {
  payrollListData: {},
  payrollListSuccess: false,
  payrollListLoading: false,
  payrollListMessage: null,

  payrollNewListData: {},
  payrollNewListSuccess: false,
  payrollNewListLoading: false,
  payrollNewListMessage: null,

  payrollNewListByTalentData: {},
  payrollNewListByTalentSuccess: false,
  payrollNewListByTalentLoading: false,
  payrollNewListByTalentMessage: null,

  payrollNewDetailListData: {},
  payrollNewDetailListSuccess: false,
  payrollNewDetailListLoading: false,
  payrollNewDetailListMessage: null,

  payrollDetailData: {},
  payrollDetailSuccess: false,
  payrollDetailLoading: false,
  payrollDetailMessage: null,

};

const PayrollReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case PAYROLL_LIST_LOADING:
      return {
        ...state,
        payrollListLoading: true,
        payrollListData: {}
      };

    case PAYROLL_LIST_SUCCESS:
      return {
        ...state,
        payrollListLoading: false,
        payrollListSuccess: true,
        payrollListData: action.payload.data,
        payrollListMessage: action.payload.message,
      };

    case PAYROLL_LIST_FAILURE:
      return {
        ...state,
        payrollListLoading: false,
        payrollListSuccess: false,
        payrollListMessage: action.payload.message,
        payrollListData: {}
      };

    case PAYROLL_DETAIL_LOADING:
      return {
        ...state,
        payrollDetailLoading: true,
        payrollDetailData: {}
      };



// ============================================================================================


    case PAYROLL_NEW_LIST_LOADING:
      return {
        ...state,
        payrollNewListLoading: true,
        payrollNewListData: {}
      };

    case PAYROLL_NEW_LIST_SUCCESS:
      return {
        ...state,
        payrollNewListLoading: false,
        payrollNewListSuccess: true,
        payrollNewListData: action.payload.data,
        payrollNewListMessage: action.payload.message,
      };

    case PAYROLL_NEW_LIST_FAILURE:
      return {
        ...state,
        payrollNewListLoading: false,
        payrollNewListSuccess: false,
        payrollNewListMessage: action.payload.message,
        payrollNewListData: {}
      };

// ============================================================================================


    case PAYROLL_NEW_LIST_BY_TALENT_LOADING:
      return {
        ...state,
        payrollNewListByTalentLoading: true,
        payrollNewListByTalentData: {}
      };

    case PAYROLL_NEW_LIST_BY_TALENT_SUCCESS:
      return {
        ...state,
        payrollNewListByTalentLoading: false,
        payrollNewListByTalentSuccess: true,
        payrollNewListByTalentData: action.payload.data,
        payrollNewListByTalentMessage: action.payload.message,
      };

    case PAYROLL_NEW_LIST_BY_TALENT_FAILURE:
      return {
        ...state,
        payrollNewListByTalentLoading: false,
        payrollNewListByTalentSuccess: false,
        payrollNewListByTalentMessage: action.payload.message,
        payrollNewListByTalentData: {}
      };

// ============================================================================================


    case PAYROLL_NEW_LIST_DETAIL_LOADING:
      return {
        ...state,
        payrollNewListDetailLoading: true,
        payrollNewListDetailData: []
      };

    case PAYROLL_NEW_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        payrollNewListDetailLoading: false,
        payrollNewListDetailSuccess: true,
        payrollNewListDetailData: action.payload.data,
        payrollNewListDetailMessage: action.payload.message,
      };

    case PAYROLL_NEW_LIST_DETAIL_FAILURE:
      return {
        ...state,
        payrollNewListDetailLoading: false,
        payrollNewListDetailSuccess: false,
        payrollNewListDetailMessage: action.payload.message,
        payrollNewListDetailData: []
      };



// ============================================================================================


    case PAYROLL_DETAIL_LOADING:
      return {
        ...state,
        payrollDetailLoading: true,
        payrollDetailData: {}
      };

    case PAYROLL_DETAIL_SUCCESS:
      return {
        ...state,
        payrollDetailLoading: false,
        payrollDetailSuccess: true,
        payrollDetailData: action.payload.data,
        payrollDetailMessage: action.payload.message,
      };

    case PAYROLL_DETAIL_FAILURE:
      return {
        ...state,
        payrollDetailLoading: false,
        payrollDetailSuccess: false,
        payrollDetailMessage: action.payload.message,
        payrollDetailData: {}
      };
    default:
      return state;
  }
};

export default PayrollReducer;
