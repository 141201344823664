import { Box, Grid, styled, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DEFAULT_ROWS_PERPAGE_4, PAGES, } from "../../constants";
import { AttendanceLeaveList } from "../../action/EmployeeLeaveAction";
import { ETab, ETabContext, ETabList, ETabListHR, ETabPanel, ETabVertical, ETabVerticalHR, ETabVerticalHRLeave } from "../../BaseComponents/ETabs";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow, ETableRowSmall } from "../../BaseComponents/ETable";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EBox, EBoxHRDash } from "../../BaseComponents/EBox";
import DeleteIcon from '@iconify/icons-material-symbols/person-add-outline'
import { EIconButton } from "../../BaseComponents/EButtons";
import { EIcon, EIconHRLeave } from "../../BaseComponents/EIcon";
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { EAvatarHrDashboardLeaveIconParent } from "../../BaseComponents/EAvatar";
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonAdd from '@iconify/icons-material-symbols/person-add-outline'
import Block from '@iconify/icons-material-symbols/block'
import CheckCircle from '@iconify/icons-material-symbols/check-circle-outline'
// import  from '@iconify/icons-material-symbols/bloc'
import { ETypography } from "../../BaseComponents/ETypography";
import { ThemeContext } from "@emotion/react";
import { ETooltip } from "../../BaseComponents/ETooltip";
import EChip from "../../BaseComponents/EChip";
import { ELinkHRDAshMUI } from "../../BaseComponents/ELink";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function LeaveRequestHR({LeaveRequestCardArray}) {

    const theme = useTheme()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [tabvalue, setTabValue] = useState('1');


    let TABLE_HEAD = [
        { id: 'talentName', label: 'Employee Name', alignRight: false, },
        { id: 'leavePeriod', label: 'Leave Period', alignRight: false, },
        { id: 'status', label: 'Status', alignRight: 'center', },
    ]


    return ( 
        <>

            <EBox sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:'5px'}}>
                <ETypography className='font-size-20px bold-600'>New Leave Request</ETypography>
                <ETypographyHRLeave classname='color-text-box-main'>{LeaveRequestCardArray?.count || 0}</ETypographyHRLeave>
            </EBox>

            {LeaveRequestCardArray?.rows?.length
            ?
                <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <ETableContainer sx={{marginTop:'15px'}}>
                                <ETable>
                                    <EListHead 
                                        headLabel={TABLE_HEAD}
                                        rowCount={2}
                                        onRequestSort={()=>null}
                                    />

                                    <ETableBody>
                                        {
                                            LeaveRequestCardArray?.rows?.map((item, index)=>{
                                                const to = moment(item?.to_date).format('YYYY-MM-DD')
                                                const from = moment(item?.from_date).format('YYYY-MM-DD')
                                                const diff = item?.to_date && item?.from_date ? ' ' + (Math.floor((moment(to)).diff((moment(from)), 'days', true)) + 1) + ' day (s)' : ''
                                                const EChipLabel = item?.leave_status   
                                                return(
                                                    <ETableRow key={index}>                                  
                                                    <>
                                                        <ETableCell align="left">
                                                            <EBox >
                                                                <ETypography sx={{fontSize:'14px', fontWeight:'600'}}>{item?.employee?.fullname}</ETypography>
                                                                <ETypography sx={{fontSize:'12px', fontWeight:'400'}}>{item?.employee?.employee_code}</ETypography>
                                                            </EBox>
                                                        </ETableCell>
                                                        <ETableCell align="left">
                                                            <EBox >
                                                                <ETypography sx={{fontSize:'12px', fontWeight:'400'}}>{moment(item?.from_date).format('DD-MM-YYYY')} to {moment(item?.to_date).format('DD-MM-YYYY')}</ETypography>
                                                                <ETypography sx={{fontSize:'12px', fontWeight:'400', color:theme.palette.text.greyColor5}}>{item?.to_date ? diff : ""}</ETypography>
                                                            </EBox>
                                                        </ETableCell>
                                                        <ETableCell align="center">
                                                            <EChip width='5.375'
                                                                label={item?.leave_status}
                                                            />
                                                        </ETableCell>
                                                    </>

                                                    </ETableRow>
                                                )
                                            })
                                        }

                                    </ETableBody>
                                </ETable>
                            </ETableContainer>

                    </Grid>

                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" className="pt12px">
                        <Grid item>
                            <ELinkHRDAshMUI
                                //  disabled={empleave?.empLeaveListData?.rows?.length > 0 ? false : true}
                                // onClick={() => navigate('/attendance/leave-log')}
                                onClick={() => navigate('/attendance/leave-log',{state:{fromDashboard:true}})}
                                // onClick={() => navigate('/attendance/leave-log', { state: { name: `${base64.encode(auth?.authData?.fullname)}` } })}
                                className={'font-size-14px bold-600 cursor-pointer'}>
                                Show All
                            </ELinkHRDAshMUI>
                        </Grid>
                    </Grid>
                </>
            :
                <>
                <EBox sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', height:'100% '}}>
                    <img src="/assets/images/hrDashboardNodata/leaves.svg" style={{display:'flex', alignItems:'center', justifyContent:'center'}}/>
                    <ETypography sx={{fontSize:'16px', fontWeight:'500', marginLeft:'10px'}}> No Data Found</ETypography>
                </EBox>
                </>
            }

                    {/* left side tabs */}
                    {/* commenting because of new design */}
                    {/* <Grid item sm={4} xs={4} md={4} lg={4} xl={4} xxl={3}>
                        
                        <ETabListHR onChange={handleChange} 
                            allowScrollButtonsMobile 
                            variant="scrollable" 
                            orientation="vertical"
                            alignitems='left !important' sx={{ border:'0'}}
                            TabIndicatorProps={{ style: { display: 'none' } }}
                        >

                            <ETabVerticalHRLeave
                                label={
                                    <>
                                        <EBox sx={{fontWeight:'600', display:'flex', alignItems:'center'}}>
                                            <EAvatarHrDashboardLeaveIconParent sx={{backgroundColor:'#F1FACF'}}>
                                                <EIconHRLeave icon={PersonAdd} sx={{color: '#403F3F'}} />
                                            </EAvatarHrDashboardLeaveIconParent>
                                            <span>Payment Details</span>
                                        </EBox>

                                        <ETypographyHRLeave>25</ETypographyHRLeave>
                                    </>
                                    } 
                                value="1" 
                            /> 

                            <ETabVerticalHRLeave
                                label={
                                    <>
                                        <EBox sx={{fontWeight:'600', display:'flex', alignItems:'center'}}>
                                            <EAvatarHrDashboardLeaveIconParent sx={{backgroundColor:'#EFD0D2'}}>
                                                <EIconHRLeave icon={Block} sx={{color: '#403F3F'}} />
                                            </EAvatarHrDashboardLeaveIconParent>
                                            <span>Payment Details</span>
                                        </EBox>

                                        <ETypographyHRLeave>25</ETypographyHRLeave>
                                    </>
                                    } 

                                value="2" 
                            /> 

                            <ETabVerticalHRLeave
                                label={
                                <>
                                    <EBox sx={{fontWeight:'600', display:'flex', alignItems:'center'}}>
                                        <EAvatarHrDashboardLeaveIconParent sx={{backgroundColor:'#DFEDD1'}}>
                                            <EIconHRLeave icon={CheckCircle} sx={{color: '#403F3F'}} />
                                        </EAvatarHrDashboardLeaveIconParent>
                                        <span>Payment Details</span>
                                    </EBox>
                                    <ETypographyHRLeave>25</ETypographyHRLeave>
                                </>
                                } 

                                value="3" 
                            /> 

                        </ETabListHR>

                    </Grid> */}

        </>
    );
}

export default React.memo(LeaveRequestHR)



const ETypographyHRLeave = (props) =>{
    const {children} = props
    return( 
        <ETypography sx={{fontWeight:'600', padding:'2px 5px', background:'#D97904', color:'#F5F5F5', borderRadius: "4px", display:'flex', alignItems:'center', justifyContent:'center', minWidth:'25px' }}>
            {children}
        </ETypography>
    )
}



