import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { LEAVE_FAILURE, LEAVE_LOADING, LEAVE_SUCCESS, LEAVE_LIST_FAILURE, LEAVE_LIST_SUCCESS, LEAVE_LIST_LOADING, SERVER_URL, LEAVE_MASTER_LOADING, LEAVE_MASTER_SUCCESS, LEAVE_MASTER_FAILURE, LEAVE_MASTER_LIST_LOADING, LEAVE_MASTER_LIST_SUCCESS, LEAVE_MASTER_LIST_FAILURE, ADD, UPDATE, PAGES, DEFAULT_ROWS_PERPAGE, DELETE } from '../constants';
import { Logout } from './AuthAction';

export function leaveLoading() {
    return {
        type: LEAVE_LOADING,
    };
}
export function leaveSuccess(payload) {
    return {
        type: LEAVE_SUCCESS,
        payload
    };
}

export function leaveFailure(payload) {
    return {
        type: LEAVE_FAILURE,
        payload
    };
}



export function leaveListLoading() {
    return {
        type: LEAVE_LIST_LOADING,
    };
}

export function leaveListSuccess(payload) {
    return {
        type: LEAVE_LIST_SUCCESS,
        payload
    };
}

export function leaveListFailure(payload) {
    return {
        type: LEAVE_LIST_FAILURE,
        payload
    };
}


//____________________INFO: Leave Master APIs below_______________________

//INFO: This API is for add, update and delete of leave master__
export function CreateUpdateDelLeaveMaster(token, Data, Action, close = null) {

    const url = Action == ADD ? `create-leave-reason` : Action == DELETE ? `delete-leave-reason` : `update-leave-reason`;
    const data = new FormData();
    if (Action == UPDATE) { //This condition is id the Action is  UPDATE
        data.append('id', Data.id)
        data.append('updated_by', Data.updated_by);
        data.append('leave_reason', Data.leave_reason);
        data.append('status', Data.status);
    } else if(Action == ADD) {//This condition is id the Action is ADD
        data.append('created_by', Data.created_by);
        data.append('leave_reason', Data.leave_reason);
        data.append('status', Data.status);
    } else {
        data.append('id', Data.id) //This condition is id the Action==DELETE
    }

    return (dispatch) => {
        dispatch(leaveMasterLoading());
        axios({
            method: 'post',
            url: `${SERVER_URL}/leave/${url}`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
            data: Data
        })
            .then((res) => {
                if (res.status === 200) {
                    Success.fire({
                        text: "html",
                        html: `<span style="color:white">${res.data.message}</span>`,
                    })
                    dispatch(leaveMasterSuccess(res.data))
                    dispatch(LeaveMasterList(token, PAGES, DEFAULT_ROWS_PERPAGE))//Calling list api
                    if (close) { close() }
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(leaveMasterFailure(error.response.data));
                    dispatch(LeaveMasterList(token, PAGES, DEFAULT_ROWS_PERPAGE))//Calling list api
                    if (close) { close() }
                    if (error?.response?.status == 401) {
                        // dispatch(Logout())
                    }
                }
            });
    };
}

//INFO: This API is for list of leave master__
export function LeaveMasterList(token, page = null, pageSize = null) {

    let queryString = "";

    if (page !== null && pageSize !== null) {
        queryString += `&page=${page}&per_page=${pageSize}`;
    }

    return (dispatch) => {
        dispatch(leaveMasterListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/leave/list-leave-reasons?${queryString}`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(leaveMasterListSuccess(res.data))
                }
            }).catch((error) => {

                if (error?.response) {
                    dispatch(leaveMasterListFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        // dispatch(Logout())
                    }
                }
            });
    };
}

export function leaveMasterListLoading() {
    return {
        type: LEAVE_MASTER_LIST_LOADING,
    };
}
export function leaveMasterListSuccess(payload) {
    return {
        type: LEAVE_MASTER_LIST_SUCCESS,
        payload
    };
}

export function leaveMasterListFailure(payload) {
    return {
        type: LEAVE_MASTER_LIST_FAILURE,
        payload
    };
}

export function leaveMasterLoading() {
    return {
        type: LEAVE_MASTER_LOADING,
    };
}
export function leaveMasterSuccess(payload) {
    return {
        type: LEAVE_MASTER_SUCCESS,
        payload
    };
}

export function leaveMasterFailure(payload) {
    return {
        type: LEAVE_MASTER_FAILURE,
        payload
    };
}

