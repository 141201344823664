import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { difference, debounce } from 'lodash'
import { Grid } from '@mui/material';
import { Stack } from '@mui/system';
import base64 from 'base-64'

import { Form, FormikProvider, useFormik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { USER_ROLE_FETCH_TYPE } from '../../../constants';
import { ELoadingButton } from '../../../BaseComponents/EButtons';
import { ETypography } from '../../../BaseComponents/ETypography';
import { EBoxPage } from '../../../BaseComponents/EBox';
import { EPageWithBreadCrumbs } from '../../../BaseComponents';
import { ECardBasic } from '../../../BaseComponents/ECard';
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid';
import { ETextFieldSmall } from '../../../BaseComponents/ETextField';
import { EAutocomplete } from '../../../BaseComponents/EAutocomplete';
import { getCustomUserRole, getCustomUserRoleData, saveCustomUserRole } from '../../../action/CustomUserRole';
import { AddOnPermission } from '../../../PageComponents/Settings/CustomUserRole';
import { customUserRoleValidation } from '../../../utils/validation';


const TITLE = 'Custom User Role'
const breadcrumbsLinks = [
  { name: 'Dashboard', href: '/' },
  { name: 'Company Settings', href: '' },
  { name: TITLE },
]

export const AddCustomUserRole = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { id = null } = params

  const { customRoleData = [], customRoleDataLoading, customRoleDataSuccess, permissionList = [], customRole, customRoleLoading, customRoleSuccess } = useSelector((state) => state.CustomUserRole)

  const [loading, setLoading] = useState(false)


  const initialPermissionObject = useMemo(() => {
    const _permissionObject = {}
    permissionList?.forEach(permission => {
      _permissionObject[permission?.permission] = false
    })
    return _permissionObject
  }, [])

  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    existing_role: null,
    permissions: [],
    basePermissions: [],
    permissionObject: initialPermissionObject,
  })

  const initialData = async () => {
    const params = {
      type: USER_ROLE_FETCH_TYPE.BASE_WITHOUT_SUPER_ADMIN
    }
    dispatch(getCustomUserRoleData({ params }))
    if (id) {
      setLoading(true)
      const params = {
        id: base64.decode(id)
      }
      dispatch(getCustomUserRole({ params }, () => setLoading(false)))
    }
  }

  useEffect(() => {
    initialData()
  }, [params])

  useEffect(() => {
    if (!customRoleDataLoading && customRoleDataSuccess) {
      if (id) {
        if (!customRoleLoading && customRoleSuccess) {
          const oldData = customRole[0]
          const existing_role = customRoleData?.find(role => role?.role_name === oldData?.parent_role_name);
          const basePermissions = existing_role?.permissions || [];
          const permissions = [...oldData?.permissions, ...basePermissions];
          const permissionObject = getPermissionObject(permissions)
          setInitialValues({
            id: oldData?.id,
            name: oldData?.role_display_name,
            existing_role,
            basePermissions,
            permissions,
            permissionObject,
          })
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
  }, [customRoleLoading, customRoleSuccess, customRoleDataLoading, customRoleDataSuccess])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: customUserRoleValidation,
    onSubmit: (data) => {
      const addOnPermissions = difference(permissions, basePermissions)
      const payload = {
        id: data?.id,
        name: data?.name,
        parent_role_name: existing_role?.role_name,
        addOnPermissions,
      }
      dispatch(saveCustomUserRole({ data: payload }, (res) => {
        if (res.status === 200) {
          navigate('/setting/custom-user-role')
        }
      }))
    }
  })

  const { values, errors, setFieldValue, handleSubmit, getFieldProps, touched } = formik
  const { basePermissions, existing_role, permissions, permissionObject } = values

  /**
   * debounce is used here to prevent unnecessary API calls while validating custom user role name.
   * debounce delays the execution of formik.validateForm function for 500ms after changing any input field, before validating the form.
   */
  const debouncedValidate = useMemo(() => debounce(formik.validateForm, 500), [formik.validateForm])

  /**
   * Call debouncedValidate whenever values change
   */
  useEffect(() => {
    debouncedValidate(formik.values);
  }, [values])


  const handleExistingRoleChange = (e, value) => {
    setFieldValue('existing_role', value);
    const newBasePermissions = value?.permissions
    setFieldValue('basePermissions', newBasePermissions)
    setFieldValue('permissions', newBasePermissions)
    const _permissionObject = getPermissionObject(newBasePermissions)
    setFieldValue('permissionObject', _permissionObject)
  }

  /**
   * This function is to get permissionObject that is used to expend the accordion when existing role is changed
   * @param {Array} permissions array of permission of selected existing role
   */
  const getPermissionObject = (permissions) => {
    const _permissionObject = {}
    permissionList?.forEach(permission => {
      const childPermissions = permission.child || [];
      const commonValues = childPermissions.filter(permission => {
        const _childPermissions = permission.child || [];
        const _commonValues = _childPermissions.filter(permission => {
          return permissions.includes(permission.permission)
        });
        return permissions.includes(permission.permission) || _commonValues.length > 0
      });
      _permissionObject[permission?.permission] = commonValues.length > 0;
    })
    return _permissionObject
  }

  return (
    <EPageWithBreadCrumbs
      title={TITLE}
      pageHeading={TITLE}
      breadcrumbsTitle={TITLE}
      breadcrumbsLinks={breadcrumbsLinks}
      hideDivider={true}
      loading={loading}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <EBoxPage sx={{ padding: '16px 0 !important' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ECardBasic className='mb-0px border-05px-border6 '>
                    <Grid container columnSpacing={2}>
                      <Grid item lg={12} xs={12} className="align-center">
                        <Grid item xl={4} lg={5} md={5} xs={6} className="align-center">
                          <ELabelInputMainGrid
                            label={"Name"}
                            alignlabel={'align-center'}
                            labelPT={'0px'}
                          >
                            <ETextFieldSmall
                              fullWidth
                              size='small'
                              autoComplete="off"
                              placeholder="Custom Role Name"
                              {...getFieldProps("name")}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name} />
                          </ELabelInputMainGrid>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} xs={12} mt={2} className="align-center">
                        <Grid item xl={4} lg={5} md={5} xs={6} className="align-center">
                          <ELabelInputMainGrid
                            label={"Existing Role"}
                            alignlabel={'align-center'}
                            labelPT={'0px'}
                          >
                            <EAutocomplete
                              name="Role"
                              fullWidth
                              forcePopupIcon={existing_role ? false : true}
                              {...getFieldProps('existing_role')}
                              getOptionLabel={(option) => option.label || ''}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              options={customRoleData}
                              onChange={handleExistingRoleChange}
                              renderInput={params => (
                                <ETextFieldSmall
                                  {...params}
                                  size='small'
                                  fullWidth
                                  placeholder="Select Existing Role"
                                  error={Boolean(touched.existing_role && errors.existing_role)}
                                  helperText={touched.existing_role && errors.existing_role}
                                />
                              )}
                            />
                          </ELabelInputMainGrid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={12} lg={12} mt={3}>
                        <ETypography sx={{ fontWeight: 600, mb: 2, fontSize: { lg: 18, md: 16 } }}>
                          Add-on Permissions
                        </ETypography>
                        {
                          permissionList?.map((permission, i) => (
                            <AddOnPermission key={`${permission?.id}_${i}`} formik={formik} data={permission} />
                          ))
                        }
                      </Grid>
                    </Grid>
                  </ECardBasic>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" className='justify-content-flex-end mt-16px'>
                    <ELoadingButton type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                      { id ? 'Update' : 'Save' }
                    </ELoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </EBoxPage>
          </Form>
        </FormikProvider>
      </LocalizationProvider>
    </EPageWithBreadCrumbs>
  )
}
