import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import EModal from '../../BaseComponents/EModal';
import { EIcon, EIconSmall, EIconStaticColor } from '../../BaseComponents/EIcon';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable';
import { EIconButton } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import moment from 'moment';
import { DEFAULT_ROWS_PERPAGE_40, DeviceWiseAttenanceObject, MY_ATTENDANCE_TAB, PAGES, PENDING_STATUS, REGULARIZATION_REQUEST, ROLE_ADMIN, ROLE_HR, ROW_SIZE_PER_PAGE_40, SUPER_ADMIN } from '../../constants';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import TimerIcon from '@iconify/icons-material-symbols/nest-clock-farsight-analog-outline-rounded'
import TimerIconRegularized from '@iconify/icons-material-symbols/nest-clock-farsight-analog-rounded'
import EChip from '../../BaseComponents/EChip';
import { HRAttendanceList } from '../../action/AttendanceAction';
import AttendanceSearchfields from '../../PageComponents/Attendance/AttendanceSearchfields';
import Regularizationform from '../../PageComponents/Attendance/Regularizationform';
import EHidden from '../../BaseComponents/EHidden';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import SearchNotFound from "../../PageComponents/SearchNotFound";

const tableHead = (role, companySetting, tab) => {

	let heads = [];

	heads.push({ id: 'Date', label: 'Date', alignRight: 'center' },
		{ id: 'Day', label: 'Day', alignRight: false });

	[ROLE_HR, SUPER_ADMIN, ROLE_ADMIN].includes(role) && heads.push({ id: 'TalentName', label: 'Employee Name', alignRight: false });
	heads.push({ id: 'CheckIn', label: 'Check In Time', alignRight: 'center' },
		{ id: 'CheckOut', label: 'Check Out Time', alignRight: 'center' },
		{ id: 'WorkingHour', label: 'Duration', alignRight: 'center', hideLabelCriteria: 'mdDown' },
		{ id: 'Status', label: 'Status', alignRight: 'center' },
		{ id: 'Attendance', label: 'DPR(i)', alignRight: 'center', tooltip: 'Daily Progress Report' },
	);

	// If regularization is allowed (required/requested) in company setting and regularization count !== 0 ? show regualrization setting : dont't show
	if (tab == MY_ATTENDANCE_TAB && companySetting?.attendance_regularization_required) {
		heads.push({ id: 'action', label: 'Regularization', alignRight: 'center' })
	}

	return heads;
}



function AttendanceLog(props) {
	const { tab } = props
	const dispatch = useDispatch();
	const auth = useSelector(state => state.authReducer);
	const attendance = useSelector(state => state.EmployeeMonthAttendanceReducer);
	const { setting } = useCompanySettingProvider();
	const [isOpen, setIsOpen] = useState({ row: null, isModal: false, data: {} });
	const [page, setPage] = useState(PAGES);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE_40);
	const [SearchParameters, setSearchParameters] = useState({
		sortBy: null,
		yearMonth: moment().format('YYYYMM'),
		employee_id: tab === MY_ATTENDANCE_TAB ? auth?.authData?.id : null,
		employeeObject: null
	});

	//Setting data
	const companySetting = setting?.attendanceSettingData?.companySetting;


	// If regularization is allowed in company setting and regularization count !== 0 ? show regualrization setting : dont't show
	const showRegularizationColmn = (tab == MY_ATTENDANCE_TAB &&
		companySetting?.attendance_regularization_required &&
		(companySetting?.attendance_regularization_count !== 0 || companySetting?.attendance_regularization_request)
	)

	useEffect(() => {
		if (auth?.authData && auth?.authData?.id) {
			let data = { self: tab == MY_ATTENDANCE_TAB ? true : false }


			if (SearchParameters?.yearMonth != null) {
				data = { ...data, year_month: SearchParameters?.yearMonth }
			} else {
				data = { ...data, year_month: moment().format('YYYYMM') }
			}
			if (SearchParameters?.sortBy != null) {
				data = { ...data, sortBy: SearchParameters?.sortBy }
			}
			if (SearchParameters?.employee_id) {
				data = { ...data, employee_id: SearchParameters?.employee_id };
			} else if (tab == MY_ATTENDANCE_TAB) {
				data = { ...data, employee_id: auth?.authData?.id };
			}
			if (auth?.authData && auth?.authData?.id) {
				setIsOpen({ ...isOpen, data: data });
				dispatch(HRAttendanceList(auth.authtoken, data, page, rowsPerPage));
			}
		}
	}, [SearchParameters, page, rowsPerPage])


	const handleSearchParameters = (newValue) => {
		setSearchParameters(newValue);
	};

	const TABLE_HEAD = tableHead(auth?.authData?.role, companySetting, tab);

	const handleChangePage = (event, newPage) => {
		setPage(newPage, page, rowsPerPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0, page, rowsPerPage);
	};
	// this function reseives time in hh:mm:ss format and returns h hrs m mins s secs format time
	const DurationFormatter = (value) => {
		var a = value ? value.split(':') : ''; // split it at the colons
		var seconds = a && (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
		let formattedTime = a && a[0] != '00' ? moment.utc(seconds * 1000).format("h[hrs] ") : '';
		formattedTime += a && a[1] != '00' ? moment.utc(seconds * 1000).format("m[mins] ") : '';
		// formattedTime += a && a[2] != '00' ? moment.utc(seconds * 1000).format("s[secs] ") : '';
		return formattedTime
	}

	// DISBALBE REGUALRIZATION
	// 1. `PAYROLL_SETTING`: Returns true if the row's payroll status is 'LOCKED'.
	// 2. `DISABLE_ROW`: Returns true if attendance regularization requested and row's request status is '1'.
	// 3. `COUNT_CHECK`: Returns true if attendance count matches the limit and both counts are not zero.
	// 4. `ZERO_CHECK`: Returns true if attendance regularization is required, count is greater than total regularized count, and the difference is not negative. Otherwise, returns true (fallback).
	// 5. Default: Returns false if none of the specified cases match.


	const disableRegularization = (status, row) => {


		switch (status) {
			case 'PAYROLL_SETTING':
				return row?.payroll_status == 'LOCKED'
			case 'DISABLE_ROW':
				return companySetting?.attendance_regularization_request && row?.regularized_request_status == '1'
			case 'COUNT_CHECK':
				return (companySetting?.attendance_regularization_count == row?.total_regularized_count) && companySetting?.attendance_regularization_count !== 0 && row?.total_regularized_count !== 0
			case 'ZERO_CHECK':
				return companySetting?.attendance_regularization_required && companySetting?.attendance_regularization_count > Number(row?.total_regularized_count) ? companySetting?.attendance_regularization_count - Number(row?.total_regularized_count) < 0 : true
			default:
				return false
		}
	}
	return (
		<>
			<AttendanceSearchfields
				tab={tab}
				AttendanceSearch={SearchParameters}
				setAttendanceSearch={handleSearchParameters}
			/>

			<EScrollbar>
				<ETableContainer>
					<ETable>
						{attendance?.empMonthAttLoading ?
							<><EListHead
								headLabel={TABLE_HEAD}
								rowCount={attendance?.empMonthAttData?.rows?.length}
								onRequestSort={() => null}
							/>
								<ETableBody>
									<ETableRow>
										<ETableCell align="center" colSpan={9} sx={{ py: 3 }}>
											<ECircularProgress color="primary" />
										</ETableCell>
									</ETableRow>
								</ETableBody>
							</>
							: !attendance?.empMonthAttLoading && attendance?.empMonthAttData?.rows?.length > 0 ?
								<>
									<EListHead
										headLabel={TABLE_HEAD}
										rowCount={attendance?.empMonthAttData?.rows?.length}
										onRequestSort={() => null}
									/>
									<ETableBody>

										{attendance?.empMonthAttData?.rows?.map((row, index) => {
											const { date, employee_code, regularize_remark, is_regularized, 
												check_in_time, check_out_time, total_working_hour,
												checkout_remark, fullname, attendance, check_out_device, check_in_device } = row;

											const checkInStatus = DeviceWiseAttenanceObject[check_in_device];
											const checkOutStatus = DeviceWiseAttenanceObject[check_out_device];

											return (
												<ETableRow className={disableRegularization("DISABLE_ROW", row) && 'table-disabled-row-bg'}
													hover key={index + fullname}>
													<ETableCell align="left" className='text-transform-capitalize'>
														{moment(date).format('DD/MM/YYYY')}
													</ETableCell>

													<ETableCell align="left" className='text-transform-capitalize'>
														{moment(date).format('dddd')}
													</ETableCell>

													{([ROLE_HR, SUPER_ADMIN, ROLE_ADMIN].includes(auth?.authData?.role)) ?
														<ETableCell align="left" className='minWidth-130 text-transform-capitalize'>
															{fullname}<br />
															({employee_code})
														</ETableCell>

														:
														<></>}
													<ETableCell align="center" className="minWidth-120 text-transform-capitalize">
														{is_regularized == 1 && regularize_remark != null
															?
															// If already regularized
															<ETooltip title={regularize_remark} arrow>
																<span>
																	{
																		check_in_time && checkInStatus &&
																		<EIconSmall icon={checkInStatus.icon} />
																	}
																	<span className='theme-main-text-color-bold'>
																		{check_in_time ? moment(check_in_time).format('hh:mm A') : "-"}
																	</span>
																</span>
															</ETooltip>
															:
															// if not regularized
															<>
																{
																	check_in_time && checkInStatus &&
																	<ETooltip title={checkInStatus.CheckInMessage} arrow>
																		<EIconSmall icon={checkInStatus.icon} />
																	</ETooltip>
																}
																{check_in_time ? moment(check_in_time).format('hh:mm A') : "-"}
															</>
														}
													</ETableCell>

													<ETableCell align="center" className="minWidth-120">
														{is_regularized == 1 && regularize_remark != null ?
															<ETooltip title={regularize_remark} arrow>
																<span>
																	{
																		check_out_time && checkOutStatus &&
																		<EIconSmall icon={checkOutStatus?.icon} />
																	}

																	<span className='theme-main-text-color-bold'>{check_out_time ? moment(check_out_time).format('hh:mm A') : "-"}
																	</span>
																</span>
															</ETooltip>
															:
															<>
																{
																	check_out_time && checkOutStatus &&
																	<ETooltip title={checkOutStatus?.CheckOutMessage} arrow>
																		<EIconSmall icon={checkOutStatus?.icon} />
																	</ETooltip>
																}
																{check_out_time ? moment(check_out_time).format('hh:mm A') : "-"}
															</>
														}

													</ETableCell>

													<EHidden width="mdDown">
														<ETableCell align="center" className='minWidth-120 text-transform-capitalize'>
															{total_working_hour ? DurationFormatter(total_working_hour) : '-'}
														</ETableCell>
													</EHidden>


													<ETableCell align="center" className='width-20'>
														{/* Condition checking if setting is automate or not
                                                        If No then direct show the abbrivation if no then checking date is today and checkout is not done
                                                        else show the attendace
                                                         */}

														{!companySetting?.allow_attendance_automate ?
															<EChip label={attendance} width='55px' /> :
															(moment().isSame(date, 'day') && !check_out_time ? '-' :
																(attendance ? <EChip label={attendance} width='55px' /> : "-"))
														}
													</ETableCell>


													<ETableCell align="center">
														{checkout_remark ?
															<ETooltip title={checkout_remark} arrow>
																<EIconButton sx={{ color: 'grey.500' }}>
																	<EIconSmall icon={InfoIcon} width={23} height={23} />
																</EIconButton>
															</ETooltip>
															: <></>}
													</ETableCell>

													{showRegularizationColmn && (
														<ETableCell align='center' className='width-20'>
															<RegularizationButton
																row={row}
																auth={auth}
																companySetting={companySetting}
																index={index}
																setIsOpen={setIsOpen}
																isOpen={isOpen}
															/>
														</ETableCell>
													)}
												</ETableRow>
											);
										})}
									</ETableBody>
								</>
								:
								<>
									<EListHead
										headLabel={TABLE_HEAD}
										rowCount={attendance?.empMonthAttData?.rows?.length}
										onRequestSort={() => null}
									/>
									<ETableBody>
										<ETableRow>
											<ETableCell align="center" colSpan={9}>
												<SearchNotFound />
											</ETableCell>
										</ETableRow>
									</ETableBody>
								</>
						}
					</ETable>
				</ETableContainer>
			</EScrollbar>
			{attendance?.empMonthAttData?.count && attendance?.empMonthAttData?.count >= 40 ?
				<ETablePagination
					rowsPerPageOptions={ROW_SIZE_PER_PAGE_40}
					component="div"
					count={attendance?.empMonthAttData?.count ? attendance?.empMonthAttData?.count : 0}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				: <></>}

			{isOpen.isModal &&
				<EModal open={isOpen.isModal} close={() => setIsOpen({ ...isOpen, isModal: true })} headervalue={"Regularization Request" + (isOpen?.row?.date ? ' - ' + moment(isOpen?.row?.date).format('DD/MM/YYYY') : '')}>
					<Regularizationform
						isAllowed={isOpen?.row?.office_hours_type == null ? false : true}//INFO: regularized_request_status==null, means this attendance is manually entered and hence it cannot be regularized
						company={companySetting}
						row={isOpen.row}
						setIsOpen={setIsOpen}
						isOpen={isOpen}
					/>
				</EModal>
			}



		</>
	)
}

const RegularizationButton = ({
	row,
	auth,
	companySetting,
	index,
	setIsOpen,
	isOpen
}) => {
	const { employee_id } = row;
	const finalDisableStatus =
		row?.payroll_status !== PENDING_STATUS ? true // return true if the row's payroll status is not 'PENDING'.
			:
			(
				companySetting?.attendance_regularization_required === false ? true // return true if attendance_regularization_required is false
					: (
						row?.regularized_request_status == '1' ? true // return true if regularized_request_status is '1' (i.e. regularized request is PENDING)
							:
							(
								companySetting?.attendance_regularization_count > 0 && companySetting?.attendance_regularization_count <= Number(row?.total_regularized_count) ? true // return true if attendance_regularization_count is greater then 0 AND attendance_regularization_count is less than total_regularized_count
									: false
							)
					)
			);
	return <>
		{employee_id == auth.authData?.id &&
			<EIconButton id={index}
				onClick={(e) => {
					setIsOpen({ ...isOpen, row: row, isModal: true });
				}}
				disabled={finalDisableStatus}
			>
				{row.is_regularized && row.regularized_request_status != REGULARIZATION_REQUEST.REQUEST_REJECT ?
					<EIconStaticColor icon={TimerIconRegularized} width={20} height={20} /> :
					<EIcon icon={TimerIcon} width={20} height={20} />
				}
			</EIconButton>
		}
	</>
}

export default AttendanceLog