import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { useNavigate } from "react-router";

import { EPageWithBreadCrumbs } from "../../../BaseComponents";
import { EBox, EBoxPage } from "../../../BaseComponents/EBox";
import { ETab, ETabContext, ETabList, ETabPanel } from "../../../BaseComponents/ETabs";
import { MyPayment } from "./MyPayment";
import { TalentRequests } from "./TalentRequests";
import { useCheckPermission } from "../../../hooks";
import { PERMISSIONS, SUPER_ADMIN } from "../../../constants";
import EModal from "../../../BaseComponents/EModal";
import PaymentDirectPayment from "../../../PageComponents/Payment/PaymentDirectPayment";
import EHidden from "../../../BaseComponents/EHidden";
import { EButton, EButtonOutlined, EButtonOutlinedIcon } from "../../../BaseComponents/EButtons";
import { EIcon } from "../../../BaseComponents/EIcon";

const PAGE_TITLE = "Payment Request"

const getTabs = (allowViewTalentRequest) => {
  const tabs = []
  if (allowViewTalentRequest) {
    tabs.push({
      label: "Employee Requests",
      value: "request",
      component: TalentRequests
    })
  }
  tabs.push({
    label: "My Payment",
    value: "payment",
    component: MyPayment
  })
  return tabs
}

function PaymentRequest({ auth }) {

  const navigate = useNavigate()

  const allowViewTalentRequest = useCheckPermission([PERMISSIONS.VerifyPayment, PERMISSIONS.SideBarTalentPayment])

  const TABS = useMemo(() => getTabs(allowViewTalentRequest), [allowViewTalentRequest])
  const initialTab = TABS[0].value

  const [tabValue, setTabValue] = useState(initialTab)
  const [modalState, setModalState] = useState({ modalData: null, modalCategory: null })
  const [refetch, setRefetch] = useState(false)

  const employee = useSelector((state) => state.EmployeeReducer)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <EPageWithBreadCrumbs
      title={PAGE_TITLE}
      pageHeading={PAGE_TITLE}
      breadcrumbsTitle={PAGE_TITLE}
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: PAGE_TITLE },
      ]}
      loading={false}

      rightComponent={
        <EBox display="flex" gap={2}>
          {
            auth?.authData?.role === SUPER_ADMIN
            &&
            <EButton
              variant="contained"
              onClick={() => setModalState({ ...modalState, modalCategory: 'DIRECT_PAYMENT' })}
            >
              Direct Payment
            </EButton>
          }
          {/* INFO: Buttons on right will be hidden on small devices (i.e. on less than 600px) */}
          <EHidden width="mdDown">
            <Grid item>
              <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
            </Grid>
          </EHidden>
          {/* INFO: Buttons on right will be visible on small devices (i.e. on less than 600px) */}
          <EHidden width="mdUp">
            <Grid item>
              <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                <EIcon icon={ForwardArrow} className='height-width-15px' />
              </EButtonOutlinedIcon>
            </Grid>
          </EHidden>
        </EBox>
      }
    >
      <EBoxPage>
        <ETabContext value={tabValue}>
          {
            TABS.length > 1 &&
            <ETabList onChange={handleChange} aria-label="lab API tabs example" allowScrollButtonsMobile variant="scrollable" alignitems="flex-start" className="mb-16px" >
              {
                TABS.map((tab, index) => (
                  <ETab label={tab.label} value={tab.value} key={index} />
                ))
              }
            </ETabList>
          }
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12}>
              {
                TABS.map((tab, index) => (
                  <ETabPanel value={tab.value} sx={{ padding: '0px' }} key={index}>
                    <tab.component refetch={refetch} />
                  </ETabPanel>
                ))
              }
            </Grid>
          </Grid>
        </ETabContext>
      </EBoxPage>
      {
        modalState.modalCategory && modalState.modalCategory === 'DIRECT_PAYMENT' &&
        <EModal open={modalState.modalCategory === 'DIRECT_PAYMENT'}
          headervalue={'Direct Payment'}
        >
          <PaymentDirectPayment
            selectedData={modalState}
            setModalState={setModalState}
            getPaymentFilterData={() => { setRefetch(prev => !prev); setTabValue(initialTab) }}
            auth={auth}
            employeeList={employee}
          />

        </EModal>
      }
    </EPageWithBreadCrumbs>
  )
}

export default PaymentRequest
