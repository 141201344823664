import { Grid, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddBoxIcon from '@iconify/icons-material-symbols/add-box-outline-rounded';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import UploadIcon from '@iconify/icons-material-symbols/upload-rounded';
import viewList from "@iconify/icons-material-symbols/view-list-outline-rounded";
import viewGrid from "@iconify/icons-material-symbols/grid-view-rounded";

import { EmployeeListData } from "../../action/EmployeeAction";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon } from "../../BaseComponents/EButtons";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { EDivider } from "../../BaseComponents/EDivider";
import EHidden from "../../BaseComponents/EHidden";
import Page from "../../BaseComponents/EPage";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { ACTIVE_USER, EMPLOYEE_CARD_LIST, EMPLOYEE_GRID_LIST, RELEASED_USER, INACTIVE_USER, PAGES, PERMISSIONS, ROLE_EMPLOYEE, TALENT_LIST_DOWNLOAD_URL } from "../../constants";
import TalentListCard from "../../PageComponents/Talents/TalentListCard";
import TalentListGrid from "../../PageComponents/Talents/TalentListGrid";
import { EIcon } from "../../BaseComponents/EIcon";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { useCheckPermission } from "../../hooks";
import { PaymentSearchFilter } from "../../PageComponents/Payment/PaymentSearchFilter";
import { DownLoadFile } from "../../action/AdminAction";
import { DesignationList } from "../../action/DesignationAction";

const initialFilter = { name: '', department: '', designation: '', sort: false, employee: null, particular: null, start: null }

const TalentList = (props) => {

    const theme = useTheme()
    const { auth, userStatus } = props;
    const { setting } = useCompanySettingProvider();
    const [listToggle, setListToggle] = useState(auth?.authData?.role !== ROLE_EMPLOYEE);
    const [searchParam, setSearchParam] = useState(initialFilter);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(EMPLOYEE_CARD_LIST);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const employeeListData = useSelector(state => state.EmployeeReducer);
    const companyDetail = useSelector(state => state.CompanyReducer);
    const designationList = useSelector(
        (state) => state.DesignationReducer.designationListData?.rows
    );
    //Setting data
    const companyAccountSetting = setting?.accountSettingData?.companySetting;
    // const TalentSettingContext = setting?.talentSettingData?.companySetting ?? {};
    const allowed_talent = companyAccountSetting?.company_data?.CompanyActivePlan?.allowed_talent;
    const active_employee_count = companyAccountSetting?.active_employee_count;
    const lastPayrollStatus = companyAccountSetting?.company_data?.lastPayroll === null ? companyAccountSetting?.company_data?.lastPayroll : companyAccountSetting?.company_data?.lastPayroll?.payroll_status;

    const allowAddTalent = useCheckPermission([PERMISSIONS.ViewAddTalent])
    const allowBulkUpload = allowAddTalent && userStatus == ACTIVE_USER && (employeeListData?.employeeData?.count == 0 || (active_employee_count != allowed_talent) && (lastPayrollStatus === null))
    const allowViewTalentDetail = useCheckPermission([PERMISSIONS.ViewEditTalent, PERMISSIONS.ViewReleaseTalent])


    // INFO: Calling API here to stop the calling this api repeatedly while visiting employee, released and archived list; now it'll be called only once for all pages.
    // This API is called since we have removed it from after login and check token actions
    useEffect(() => {
        if (auth.authtoken) {
            dispatch(DesignationList(auth.authtoken));
        }
    }, [])

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ sort: searchParam.sort }, PAGES, EMPLOYEE_CARD_LIST);
            setPage(PAGES)
            setRowsPerPage(EMPLOYEE_CARD_LIST)
            // setListToggle(true)
            setSearchParam(initialFilter)
        }
        return () => {
            setSearchParam(initialFilter)
            setRowsPerPage(EMPLOYEE_CARD_LIST)
            setListToggle(auth?.authData?.role !== ROLE_EMPLOYEE)
        }
    }, [userStatus])

    const handleChangePage = (event, newPage) => {
        callApi(searchParam, (newPage), rowsPerPage); //changed newPage-1 to newPage to fix issue on 18-01-23
        setPage(newPage); //changed newPage-1 to newPage to fix issue on 18-01-23
    }

    const handleChangePageCard = (event, newPage) => {
        callApi(searchParam, (newPage - 1), rowsPerPage);
        setPage((newPage - 1));
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, EMPLOYEE_GRID_LIST);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);
    };

    const listToggleMasterFn = (toggledData) => {
        const rowPerPageToggleCount = toggledData ? EMPLOYEE_CARD_LIST : EMPLOYEE_GRID_LIST;
        setListToggle(toggledData);
        setRowsPerPage(rowPerPageToggleCount)
        const params = {
            name: searchParam?.employee?.fullname,
            designation: searchParam?.particular?.id,
            sort: searchParam?.sort,
            year_month: searchParam?.start,
        }
        callApi({ ...params }, 0, rowsPerPage);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(EmployeeListData(auth.authtoken, userStatus, fnParams, fnPage, fnRowPerPage));
    }

    const preTitle = `${userStatus === RELEASED_USER ? 'Released List' : userStatus === INACTIVE_USER ? 'Archived List' : ' Employee Directory'}`

    const DownloadReport = (type) => {
     
        const sort = searchParam?.sort ;
        const department = searchParam?.department ? searchParam?.department : null;
        const designation = searchParam?.designation ? searchParam?.designation : null;
        const fullname = searchParam?.employee ? searchParam?.employee?.label : null;

        const params = {
            download_format: type,
            user_status: userStatus,
            auth_list: false,
            ...((sort === true || sort === false) && { sort }),
            ...(department && { department }),
            ...(designation && { designation }),
            ...(fullname && { fullname }),
        };
        const queryString = new URLSearchParams(params).toString();

        const url = `${TALENT_LIST_DOWNLOAD_URL}?${queryString}`;
        DownLoadFile(auth.authtoken, url, "Employee List")


    }
    const onSearch = (data) => {
        setSearchParam(data)
        setPage(0)
        const params = {
            name: data?.employee?.fullname,
            designation: data?.particular?.id,
            sort: data?.sort,
            year_month: data?.start,

        }
        callApi({ ...params }, 0, rowsPerPage);
    }

    const talentList = useMemo(() => employeeListData?.allEmployeeData?.rows?.filter((row) => {
        return row?.status == userStatus && row?.show_as_talent
    }), [userStatus])

    return (

        <Page title={`${preTitle} `} >
            <Grid container display="row" justifyContent="space-between" className="align-center">

                <Grid item>
                    {
                        auth?.authData?.role !== ROLE_EMPLOYEE ?
                            <EHeaderBreadcrumbs
                                heading={`${preTitle} `}
                                links={[
                                    { name: 'Dashboard', href: '/' },
                                    { name: 'Employee', href: '' },
                                    {
                                        name: `${preTitle} `,
                                    }
                                ]}
                            /> :
                            <EHeaderBreadcrumbs
                                heading={`${preTitle} `}
                                links={[
                                    { name: 'Dashboard', href: '/' },
                                    {
                                        name: `${preTitle} `,
                                    }
                                ]}
                            />
                    }
                </Grid>
                {/* INFO: This complonent will be hidden wgen screen size goes below md */}
                <EHidden width="mdDown">
                    <Grid item>
                        {
                            allowBulkUpload
                                ?
                                <ETooltip arrow title="Bulk Employee Upload">
                                    <EButton
                                        to={'/employee/bulk-employee-upload'}
                                        component={Link}
                                        variant="contained"
                                    >
                                        Import
                                    </EButton>
                                </ETooltip>
                                :
                                ''
                        }
                        {
                            allowAddTalent && userStatus === ACTIVE_USER &&
                            <EButton
                                to={'/employee/add-employee'}
                                component={Link}
                                variant="contained"
                                disabled={active_employee_count >= allowed_talent}
                                className='button-left-margin'
                            >
                                New Employee
                            </EButton>
                        }
                        <EButtonOutlined
                            variant="outlined"
                            onClick={() => navigate(-1)}
                            className='button-left-margin'>  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                className='margin-top-20px '
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        {`${preTitle} `}
                    </ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        {
                            allowBulkUpload
                                ?
                                <EButtonIcon to={'/employee/bulk-employee-upload'} component={Link} variant="contained" >
                                    <EIcon icon={UploadIcon} className='height-width-15px' />
                                </EButtonIcon>
                                :
                                ''
                        }
                        {
                            allowAddTalent && userStatus === ACTIVE_USER && //AddBoxIcon
                            <EButtonIcon
                                to={'/employee/add-employee'}
                                className='button-left-margin'
                                component={Link}
                                variant="contained"
                                disabled={active_employee_count >= allowed_talent}
                            >
                                <EIcon icon={AddBoxIcon} className='height-width-15px ' />
                            </EButtonIcon>
                        }
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                            <EIcon icon={ForwardArrow} className='height-width-15px' />
                        </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>
            <EHidden width="mdDown">
                <EDivider className='mt-8px' />
            </EHidden>
            <EBoxPage>
                <Grid container className='mb-16px'>
                    <Grid item xs={12}>
                        <PaymentSearchFilter
                            initialFilter={searchParam}
                            onSearch={onSearch}
                            onSort={onSearch}
                            DownloadReport={DownloadReport}
                            showTalents={true}
                            withTalentList={true}
                            talentList={talentList}
                            particulars={designationList}
                            particularPlaceholder="All Designations"
                            start={userStatus !== ACTIVE_USER && {
                                placeholder: 'All Month',
                                views: ['month'],
                                inputFormat: "MMMM",
                                minDate: `${new Date(companyDetail?.companyData?.created_at)}`,
                                maxDate: new Date(),
                                inputFormat: "MMM yyyy",
                            }}
                            withoutDateValidation={true}
                            talentOrder={1}
                            particularsOrder={2}
                            statusOrder={3}
                            sortTitle="Sort by name"
                            extraRightComponent={
                                allowViewTalentDetail && <ETooltip title={listToggle ? "List View" : "Card View"} arrow>
                                    <EButtonOutlinedIcon
                                        variant="outlined"
                                        onClick={() => {
                                            listToggleMasterFn(!listToggle)
                                        }}
                                        sx={{
                                            width: '100%',
                                            maxWidth: {
                                                xs: '47px',
                                                md: '55px'
                                            }
                                        }}
                                    >
                                        <EIcon
                                            icon={listToggle ? viewGrid : viewList}
                                            color={theme.palette.primary.main}
                                            sx={{
                                                width: listToggle ? 15 : 17,
                                                height: listToggle ? 15 : 17,
                                            }}
                                        />
                                    </EButtonOutlinedIcon>
                                </ETooltip>
                            }
                        />
                    </Grid>
                </Grid>

                {!employeeListData?.employeeLoading ? <>
                    {listToggle ? (
                        <Grid container>
                            <TalentListCard
                                employeeListData={employeeListData?.employeeData}
                                handleChangePage={handleChangePageCard}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                userStatus={userStatus}
                                authPermission={allowViewTalentDetail}
                            />
                        </Grid>
                    ) : (

                        <TalentListGrid
                            auth={auth}
                            employeeListData={employeeListData?.employeeData}
                            page={page}
                            userStatus={userStatus}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            token={auth?.authtoken}
                            authPermission={allowViewTalentDetail}
                            setting={setting}
                        />

                    )}
                </> : <>
                    <Grid container my={0} className='justify-content-center'>
                        <ECircularProgress color="primary" />
                    </Grid>
                </>}

            </EBoxPage>
        </Page>
    )

};

export default TalentList;
