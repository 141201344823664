import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AttendanceList, getPayrollDetail, LockedPayroll } from "../../action/PayrollAction";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from "../../BaseComponents/EButtons";
import { EDivider } from "../../BaseComponents/EDivider";
import Page from "../../BaseComponents/EPage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import base64 from 'base-64'
import editFill from '@iconify/icons-material-symbols/edit-outline-rounded'
import Lock from '@iconify/icons-material-symbols/lock-outline'
import ModalAdvanceDeductionUpdate from "../../PageComponents/Attendance/ModalAdvanceDeductionUpdate";
import FileDownloadIcon from "@iconify/icons-material-symbols/sim-card-download-outline-rounded";
import { EIcon } from "../../BaseComponents/EIcon";
import EModal from "../../BaseComponents/EModal";
import ModalAttendanceUpdate from "../../PageComponents/Attendance/ModalAttendanceUpdate";
import {
    ATTENDANCE_AND_PAYROLL_DOWNLOAD_URL, DOWNLOAD_CSV, DOWNLOAD_PDF, LOCKED_STATUS,
    PAYROLL_PENDING, PAYROLL_VERIFIED, PERMISSIONS
} from "../../constants";
import moment from "moment";
import { Stack } from "@mui/system";
import { EBox, EBoxPage } from "../../BaseComponents/EBox";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { ETooltip } from "../../BaseComponents/ETooltip";
import EMenuPopover, { EMenuItem } from "../../BaseComponents/EMenuPopover";
import { DownLoadFile } from "../../action/AdminAction";
import ManualAttendanceForm from "../../PageComponents/Attendance/ManualAttendanceForm";
import ManualAttendancePreview from "./ManualAttendancePreview";
import { EStickyCell, EStickyRow, EStickyTable, TypographyPayrollMonth } from "../../BaseComponents/EStickyTable";
import EHidden from "../../BaseComponents/EHidden";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import ManualIcon from '@iconify/icons-material-symbols/edit-calendar-outline-rounded';
import fullscreenOnIcon from '@iconify/icons-material-symbols/zoom-out-map-rounded';
import fullscreenOffIcon from '@iconify/icons-material-symbols/zoom-in-map-rounded';
import SearchNotFound from "../../PageComponents/SearchNotFound";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { useCheckPermission } from "../../hooks";

const AttendanceSheet = (props) => {
    const { auth, is_payroll } = props;
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [fullscreenActive, setFullScreenActive] = useState(false)

    const [isManualAttendance, setManualAttendance] = useState({ open: false, preview: false, data: false });//this state is for open/close handle of manual modals
    const [isOpenUpdateAttendanceModal, setIsOpenUpdateAttendanceModal] = useState(false);
    const [advDeductionStatus, setAdvDeductionStatus] = useState(false);
    const [lockmodalStatus, setLockModalStatus] = useState(false);
    const [attUpdateParams, setAttUpdateParams] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const { setting } = useCompanySettingProvider();
    
    const attendanceData = useSelector(state => state.AttendanceReducer);
    const companyDetail = setting?.attendanceSettingData?.companySetting;
    // const attendance_mode = companyDetail?.allow_attendance_automate ? companyDetail?.allow_attendance_automate : null

    // const attendance_mode = companyDetail?.companySettingData?.attendance_mode ? companyDetail?.companySettingData?.attendance_mode : null

    
    const monthPayroll = useSelector(state => state.PayrollReducer.payrollDetailData);
    const open = Boolean(anchorEl);

    const allowAttendanceEdit = useCheckPermission([PERMISSIONS.ViewEditVerifyAttendanceList])
    const allowAttendanceVerify = useCheckPermission([PERMISSIONS.ViewVerifyAttendanceList])

    const allowPayrollEdit = useCheckPermission([PERMISSIONS.ViewEditFinalizePayroll])
    const allowPayrollFinalize = useCheckPermission([PERMISSIONS.ViewFinalizePayroll])
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    // Toggle fullscreen
    const enterFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().then(() => {
                setFullScreenActive(!fullscreenActive)
            });
        } else {
            document.exitFullscreen().then(() => {
                setFullScreenActive(!fullscreenActive)
            });
        }

    }


    useEffect(() => {
        if (auth?.authtoken && params.id) {
            dispatch(getPayrollDetail(auth.authtoken, base64.decode(params.id)))
            dispatch(AttendanceList(auth.authtoken, base64.decode(params.id), is_payroll))
        }
    }, [auth?.authtoken, params.id]);


    const HandleDetails = (bData) => {

        setAttUpdateParams(bData)
        setIsOpenUpdateAttendanceModal(true)
    }
    const HandleAdvanceDeduction = (bData) => {
        setAttUpdateParams(bData)
        setAdvDeductionStatus(true)
    }

    const AcceptHandle = () => {
        //Acccept and lock the payroll
        dispatch(LockedPayroll(auth.authtoken, monthPayroll.id, auth?.authtoken?.company_id, is_payroll));
        setLockModalStatus(false);
    }

    const DownloadReport = (value) => {
        let url = ATTENDANCE_AND_PAYROLL_DOWNLOAD_URL;
        let file_name = is_payroll ? 'Payroll_' : 'Attendance_';
        file_name += moment(monthPayroll?.month_date).format("MMM-YYYY");
        if (monthPayroll?.id) {
            url += `?payroll_id=${monthPayroll?.id}&is_payroll=${is_payroll}`;
        }

        if (value != null) {
            url += `&download_format=${value}`;
        }
        DownLoadFile(auth.authtoken, url, file_name);
        setAnchorEl(null);
    };

    

    const showManualDownloadBtn = !is_payroll && !isManualAttendance?.preview && monthPayroll?.payroll_status && 
                                monthPayroll?.payroll_status != LOCKED_STATUS && //checking for locked payroll
                                !companyDetail?.allow_attendance_automate //Checking for manual setting

    const title = isManualAttendance?.preview ? 'Manual Attendance Preview' : 'Attendance List'
    const breadcrumbsTitle = isManualAttendance?.preview ? 'Manual' : 'Attendance List'

    return (
        <Page title={!is_payroll ? title : 'Payroll List'}>
            <Grid container display="row" justifyContent="space-between" className="align-center">
                <Grid item>

                    {is_payroll &&
                        <EHeaderBreadcrumbs
                            heading={'Payroll'}
                            links={[
                                { name: 'Dashboard', href: '/' },
                                { name: 'Payroll', href: '/' },
                                {
                                    name: monthPayroll ? ('Payroll-' + moment(monthPayroll?.month_date).format("MMM YYYY")) : 'Payroll',
                                }
                            ]}
                        />}
                    {!is_payroll &&
                        <EHeaderBreadcrumbs
                            heading={breadcrumbsTitle}
                            links={[
                                { name: 'Dashboard', href: '/' },
                                { name: 'Attendance', href: '' },
                                {
                                    name: monthPayroll ? ('Attendance List - ' + moment(monthPayroll?.month_date).format("MMM YYYY")) : 'Attendance ',
                                },
                                ...(!!isManualAttendance?.preview ? [{ name: breadcrumbsTitle }] : [])
                            ]}
                        />}

                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        {showManualDownloadBtn &&
                            <EButton variant="contained" onClick={() => setManualAttendance({ ...isManualAttendance, open: true })}>
                                Manual Attendance
                            </EButton>
                        }
                        {!isManualAttendance?.preview &&
                            <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                                Back
                            </EButtonOutlined>}
                    </Grid>
                </EHidden>
            </Grid>
            <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        {is_payroll ? 'Payroll ' : title} - {monthPayroll ? moment(monthPayroll?.month_date).format("MMM YYYY") : ''}
                    </ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        {showManualDownloadBtn &&
                            <EButtonIcon variant="contained" onClick={() => setManualAttendance({ ...isManualAttendance, open: true })}>
                                <EIcon icon={ManualIcon} className='height-width-15px' />
                            </EButtonIcon>
                        }
                        {!isManualAttendance?.preview &&
                            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                                <EIcon icon={ForwardArrow} className='height-width-15px' />
                            </EButtonOutlinedIcon>}

                    </Grid>
                </EHidden>

                <EHidden width="mdDown">
                    <Grid item >
                        <IconButton onClick={enterFullscreen}>
                            {!fullscreenActive ? (
                                <EIcon
                                    icon={fullscreenOnIcon}
                                    className="height-width-22px"
                                />
                            ) : (
                                <EIcon
                                    icon={fullscreenOffIcon}
                                    className="height-width-22px"
                                />
                            )}
                        </IconButton>
                        {!isManualAttendance?.preview ?
                            (<>
                                <IconButton onClick={handleClick}>
                                    <ETooltip title={"Download"} arrow>
                                        <EIcon
                                            icon={FileDownloadIcon}
                                            className="height-width-22px"
                                        />
                                    </ETooltip>
                                </IconButton>
                                <EMenuPopover
                                    open={open}
                                    onClose={handleClose}
                                    anchorEl={anchorEl}
                                    sx={{ width: 180, top: 40 }}
                                >
                                    <EMenuItem
                                        sx={{ typography: "body2", py: 1, px: 2.5 }}
                                        onClick={() => DownloadReport(DOWNLOAD_PDF)}
                                    >
                                        <EBox sx={{ mr: 2, width: 24, height: 24 }}>
                                            <PictureAsPdfIcon />
                                        </EBox>
                                        PDF
                                    </EMenuItem>
                                    <EMenuItem
                                        sx={{ typography: "body2", py: 1, px: 2.5 }}
                                        onClick={() => DownloadReport(DOWNLOAD_CSV)}
                                    >
                                        <EBox sx={{ mr: 2, width: 24, height: 24 }}>
                                            <InsertDriveFileIcon />
                                        </EBox>
                                        CSV
                                    </EMenuItem>
                                </EMenuPopover>
                            </>)
                            : ''}
                    </Grid>
                </EHidden>

            </Grid>
            <EDivider className='margin-top-05' />

            <EBoxPage>
                {!isManualAttendance?.preview &&
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            py={1}
                        >

                            <Grid item xs={12} my={2}>
                                {
                                    attendanceData &&
                                    !attendanceData?.attendanceListLoading &&
                                    Object.keys(attendanceData?.attendanceListData) &&
                                    Object.keys(attendanceData?.attendanceListData).length > 0 && <>
                                        <EStickyTable
                                            stickyHeaderCount={1}
                                            leftStickyColumnCount={!is_payroll ? 2 : 3}
                                            className={'tContainer'}
                                        >
                                            <EStickyRow key={'headRow'} className={'tHeadSheet'}>
                                                {
                                                    Object.values(Object.values(attendanceData?.attendanceListData)[0])
                                                        .map((hData) => {
                                                            return (
                                                                <EStickyCell
                                                                    className={'tColSheet'}
                                                                    style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}
                                                                    key={hData.label}
                                                                    isDesignation={hData.label === 'Designation'}
                                                                >
                                                                    <ETypography
                                                                        sx={{
                                                                            fontSize: { lg: '14px !important', xs: '12px !important' },
                                                                            ...(!hData?.date && { fontWeight: 600 }),
                                                                            ...(hData?.label !== 'Employee Name' && hData?.label !== 'Designation' && { textAlign: 'center' }),
                                                                        }}
                                                                    >
                                                                        {
                                                                            hData?.label == 'Employee Name' || hData?.label == 'Designation'
                                                                                ?
                                                                                <span className="bold-600">{hData?.label}</span>
                                                                                :
                                                                                hData?.label.split(" ").join("\n")
                                                                        }
                                                                    </ETypography>
                                                                </EStickyCell>
                                                            );
                                                        })
                                                }
                                            </EStickyRow>
                                            {
                                                Object.values(attendanceData?.attendanceListData).map((bDataRow, index) => {
                                                    return (
                                                        <EStickyRow key={"bodyRow" + index} className={'tBodySheet'}>
                                                            {Object.values(bDataRow).map((bData, indexChild) => {
                                                                let classnames = ' '

                                                                let instyle = {};
                                                                if (bData.label != 'Designation') {
                                                                    // instyle.fontWeight='600'
                                                                    classnames = 'text-align justify-content-center'
                                                                }

                                                                {/* let instyle = {
                                                                    fontWeight: '600'
                                                                }; */}
                                                                if (bData?.bgColor) {
                                                                    instyle.backgroundColor = `${bData.bgColor} !important`;
                                                                    instyle.color = '#403F3F';

                                                                }
                                                                if ((bData?.isEditAttendance || bData?.isEditAdvance) && monthPayroll?.payroll_status != LOCKED_STATUS) {
                                                                    instyle.cursor = 'pointer';
                                                                }
                                                                var displayValue = bData?.value;
                                                                // let displayValue = bData?.display_abbrivation;
                                                                //For last coloumn month count with slash
                                                                if (bData?.paid_days_outof) {
                                                                    displayValue = displayValue + '/' + bData?.paid_days_outof;
                                                                }
                                                                // else if (bData?.display_abbrivation) {
                                                                //     displayValue = bData?.display_abbrivation;
                                                                // }
                                                                if (bData?.isHtml) {
                                                                    classnames = ''
                                                                    displayValue = displayValue.split("<br />")

                                                                }

                                                                return (
                                                                    <EStickyCell
                                                                        className={'tColSheet ' + classnames}
                                                                        key={index + "bodyCol" + indexChild + displayValue}
                                                                        sx={{ ...instyle, fontSize: { lg: '14px !important', xs: '12px !important' } }}
                                                                        onClick={() => {
                                                                            if (allowAttendanceEdit && bData?.isEditAttendance) {
                                                                                HandleDetails(bData)
                                                                            }
                                                                            if (allowPayrollEdit && bData?.isEditAdvance) {
                                                                                HandleAdvanceDeduction(bData) 
                                                                            }
                                                                        }}
                                                                        isDesignation={bData.label === 'Designation'}
                                                                    >
                                                                        {bData?.tooltip ? (
                                                                            <ETooltip title={bData?.tooltip} arrow>
                                                                                <span>
                                                                                    {displayValue}
                                                                                    {
                                                                                        allowPayrollEdit && bData?.isEditAdvance && monthPayroll?.payroll_status != LOCKED_STATUS &&
                                                                                        <EIcon icon={editFill} sx={{ paddingTop: '4px' }} width={24} height={24} />
                                                                                    }
                                                                                </span>
                                                                            </ETooltip>
                                                                        ) : (

                                                                            <>
                                                                                {
                                                                                    bData?.isHtml ?
                                                                                        <ETypography sx={{ fontSize: { lg: '14px !important', xs: '12px !important' } }} >
                                                                                            <span className="bold-600">
                                                                                                {displayValue[0]}
                                                                                            </span>
                                                                                            <br />
                                                                                            <span> {displayValue[1] ? displayValue[1] : ''}</span>
                                                                                        </ETypography>
                                                                                        :
                                                                                        displayValue
                                                                                }
                                                                                {allowPayrollEdit && bData?.isEditAdvance && monthPayroll?.payroll_status != LOCKED_STATUS && <EIcon icon={editFill} sx={{ paddingTop: '4px' }} width={24} height={24} />}
                                                                            </>
                                                                        )}

                                                                    </EStickyCell>
                                                                );

                                                            })}
                                                        </EStickyRow>
                                                    )
                                                })
                                            }
                                        </EStickyTable>

                                        <Stack
                                            direction='row'
                                            spacing={2}
                                            paddingTop={1}
                                            justifyContent='flex-end'>

                                            {/* For attendance verification */}
                                            {(allowAttendanceEdit || allowAttendanceVerify) && (moment().diff(moment(monthPayroll.to_date), 'days') > 0 &&
                                                !is_payroll && monthPayroll?.payroll_status == PAYROLL_PENDING &&
                                                (!monthPayroll.PreviousPayroll || (monthPayroll?.PreviousPayroll.payroll_status == LOCKED_STATUS || monthPayroll?.PreviousPayroll.payroll_status == 'COMPLETED'))) ?

                                                <EButton
                                                    onClick={() => setLockModalStatus(true)}
                                                    startIcon={<EIcon icon={Lock} color={'white'} />}>
                                                    Finalize Attendance
                                                </EButton>
                                                : <></>
                                            }
                                            {/* For payroll lock verification */}
                                            {(allowPayrollEdit || allowPayrollFinalize) && (is_payroll && monthPayroll?.payroll_status == PAYROLL_VERIFIED &&
                                                (!monthPayroll.PreviousPayroll || (monthPayroll?.PreviousPayroll.payroll_status != PAYROLL_VERIFIED))) ?
                                                <EButton
                                                    onClick={() => setLockModalStatus(true)}
                                                    startIcon={<EIcon icon={Lock} color={'white'} />}>
                                                    Finalize Payroll
                                                </EButton>
                                                : <></>
                                            }
                                        </Stack>

                                        {/* COMMENTS OF ABBRIVATION */}
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            py={1}
                                        >
                                            <Grid item xs={12} my={2}>
                                                <ETypography variant="h5" className="theme-color-static" sx={{ fontSize: { lg: '18px !important', xs: '16px !important' } }}><i>Attendance Abbreviation</i></ETypography>
                                                <div className="attendance-sheet-helper">
                                                    <ul>
                                                        <li>
                                                            <ETypography variant="h6" sx={{ display: "flex", alignItems: 'center', gap: '5px', }}>
                                                                <EBox className="abbrivationShort" sx={{ backgroundColor: '#C8DBBE' }}>P</EBox>
                                                                <EBox className="abbrivationFull">Present</EBox>
                                                            </ETypography>
                                                        </li>
                                                        <li>
                                                            <ETypography variant="h6" sx={{ display: "flex", alignItems: 'center', gap: '5px', }}>
                                                                <EBox className="abbrivationShort" sx={{ backgroundColor: '#E9A6A6' }}>A</EBox>
                                                                <EBox className="abbrivationFull">Absent</EBox>
                                                            </ETypography>
                                                        </li>
                                                        <li>
                                                            <ETypography variant="h6" sx={{ display: "flex", alignItems: 'center', gap: '5px', }}>
                                                                <EBox className="abbrivationShort" sx={{ backgroundColor: '#DEECF6' }}>H</EBox>
                                                                <EBox className="abbrivationFull">Holiday</EBox>
                                                            </ETypography>
                                                        </li>
                                                        <li>
                                                            <ETypography variant="h6" sx={{ display: "flex", alignItems: 'center', gap: '5px', }}>
                                                                <EBox className="abbrivationShort" sx={{ backgroundColor: '#04BFBF' }}>PL</EBox>
                                                                <EBox className="abbrivationFull">Full/Half Day Paid Leave</EBox>
                                                            </ETypography>
                                                        </li>
                                                        <li>
                                                            <ETypography variant="h6" sx={{ display: "flex", alignItems: 'center', gap: '5px', }}>
                                                                <EBox className="abbrivationShort" sx={{ backgroundColor: '#E7B77D' }}>HD</EBox>
                                                                <EBox className="abbrivationFull">Half Day Leave</EBox>
                                                            </ETypography>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </Grid>
                                        </Grid>
                                    </>}

                                {/* IF NO RESULT AVAILABLE */}
                                {attendanceData &&
                                    !attendanceData?.attendanceListLoading &&
                                    Object.keys(attendanceData?.attendanceListData) &&
                                    Object.keys(attendanceData?.attendanceListData).length == 0 &&
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        p={10}
                                    >
                                        <SearchNotFound />

                                    </Grid>}

                                {/* IF NO RESULT LOADING THE RESULT */}
                                {attendanceData &&
                                    attendanceData?.attendanceListLoading &&
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        p={10}
                                    >
                                        <ECircularProgress />
                                    </Grid>
                                }

                            </Grid>
                        </Grid>


                    </>}

                {/* MANUAL ATTENDANCE IS CLICKED  */}
                {isManualAttendance?.preview &&
                    <ManualAttendancePreview
                        ManualState={isManualAttendance.data}
                        auth={auth}
                        payroll_id={monthPayroll?.id}
                        close={() => setManualAttendance({ ...isManualAttendance, preview: false })}
                    />}

            </EBoxPage>
            {/* {modalStatus && monthPayroll?.payroll_status != "LOCKED" && */}
            {isOpenUpdateAttendanceModal && attUpdateParams && Object.keys(attUpdateParams).length > 0 &&
                <EModal open={isOpenUpdateAttendanceModal} headervalue={`Update Attendance - ${attUpdateParams.empName}`}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            {attUpdateParams && Object.keys(attUpdateParams).length > 0 &&
                                <ModalAttendanceUpdate
                                    setIsOpenUpdateAttendanceModal={setIsOpenUpdateAttendanceModal}
                                    attUpdateParams={attUpdateParams}
                                    auth={auth}
                                    isPayroll={is_payroll}
                                    payrollId={base64.decode(params.id)}
                                />
                            }
                        </Grid>
                    </Grid>
                </EModal>
            }


            {advDeductionStatus && monthPayroll?.payroll_status != LOCKED_STATUS &&
                <EModal
                    open={advDeductionStatus}
                    // close={() => setAdvDeductionStatus(false)}
                    headervalue="Advance Deduction">

                    <ModalAdvanceDeductionUpdate
                        setAdvDeductionStatus={setAdvDeductionStatus}
                        attUpdateParams={attUpdateParams}
                        auth={auth}
                        isPayroll={is_payroll}
                        payrollId={base64.decode(params.id)}
                    />
                </EModal>
            }
            {/* Modal Confirmation to verfy  */}
            {lockmodalStatus &&
                <EModal
                    open={lockmodalStatus}
                    headervalue="Confirmation">
                    {(is_payroll && monthPayroll?.payroll_status == PAYROLL_VERIFIED &&
                        (!monthPayroll.PreviousPayroll || (monthPayroll?.PreviousPayroll.payroll_status != PAYROLL_VERIFIED))) ?
                        <ETypography variant="h6">
                            Are you sure you want to finalize Payroll for <TypographyPayrollMonth>{moment(monthPayroll?.month_date).format("MMMM YYYY")}</TypographyPayrollMonth> ?
                        </ETypography>
                        : <ETypography variant="h6">
                            Are you sure you want to finalize attendance for <TypographyPayrollMonth>{moment(monthPayroll?.month_date).format("MMMM YYYY")}</TypographyPayrollMonth> ?
                        </ETypography>
                    }

                    <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                        <EButtonOutlined size="large" variant="outlined"
                            onClick={() => setLockModalStatus(false)}
                        > No </EButtonOutlined>
                        <ELoadingButton
                            onClick={() => AcceptHandle()}
                            size="large" variant="outlined" type="submit" >Yes</ELoadingButton>
                    </Stack>
                </EModal>
            }
            {/* Thi is the manual attendance form */}
            {isManualAttendance.open &&
                <EModal
                    open={isManualAttendance.open}
                    headervalue="Manual Attendance">
                    <ManualAttendanceForm
                        setManualAttendance={setManualAttendance}
                        token={auth?.authtoken}
                        date={monthPayroll?.month_date} />
                </EModal>
            }

        </Page>
    )

};

export default AttendanceSheet;
