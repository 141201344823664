import React, { useEffect } from 'react';
import { FormControl, FormControlLabel, FormHelperText, Grid, Stack } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { ETextField } from '../../BaseComponents/ETextField';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { ManualAttendanceValidation } from '../../utils/validation';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import moment from 'moment';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { EDatePicker } from '../../BaseComponents/EDatePicker';
import { onKeyDown, ReturnBoolean } from '../../utils/CommonFunctions';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ABBREVIATION, NULL_STRING, PL } from '../../constants';
import { EmployeeListData } from '../../action/EmployeeAction';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ECheckbox } from '../../BaseComponents/ECheckbox';
import PropTypes from 'prop-types';
import { EFormHelperText } from '../../BaseComponents/ETypography';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
/**
 * [2023-02-16]
 * Author:-Aanchal Sahu
 * Description:-designed for manual attendance form
 **/

ManualAttendanceForm.propTypes = {
    setManualAttendance: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
};
export default function ManualAttendanceForm(props) {
    const { setManualAttendance, token, date } = props

    const dispatch = useDispatch()
    const employee = useSelector(state => state.EmployeeReducer)
    var end_date_month = moment(date).endOf('month').toDate()

    const minDate = date ? moment(date).startOf('month').toDate() : moment().format() //setting minimum date for the date pickers
    const maxDate = date && (moment().toDate() > end_date_month) ? end_date_month : date && (moment().toDate() < end_date_month) ? moment().toDate() : moment(moment().toDate()).startOf('month').toDate() //setting maximum date for the date pickers


    const formik = useFormik({
        initialValues: {
            from_date: minDate ? minDate : null,
            to_date: maxDate ? maxDate : null,
            abbreviation: null,
            isAllTalents: null,
            isFullDay: true,
            talent_names: [],
        },
        validationSchema: ManualAttendanceValidation,
        onSubmit: (data) => {

            const { from_date, talent_names, to_date, abbreviation } = data
            var DatesObject = {}
            const FinalArray = []

            // for loop for Creating common Date Array without the doj and release date
            for (let dt = new Date(from_date); dt <= new Date(to_date); dt.setDate(dt.getDate() + 1)) {
                DatesObject[moment(dt).format('YYYY-MM-DD')] = {
                    abbreviation: abbreviation?.id,
                    component_id: moment(dt).format('YYYY-MM-DD'),
                    bgColor: abbreviation?.id == 'P' ? '#C8DBBE' : '#A6A6A6'
                }
            }
            // INFO: This for loop is iterating the talents selected
            for (let i = 0; i < talent_names?.length; i++) {
                let SpecialDatesObject = {}
                // INFO: The following if condition checks for date of joining or released date (talents).
                const { doj, release_date } = talent_names[i]
                if ((talent_names[i].doj && moment(talent_names[i].doj).format('YYYYMM') == moment(from_date).format('YYYYMM')) ||
                    (talent_names[i].release_date && moment(talent_names[i].release_date).format('YYYYMM') == moment(from_date).format('YYYYMM'))) {
                    // INFO: The following loop is to create a new date object wrt doj and release date
                    for (let dt = new Date(from_date); dt <= new Date(to_date); dt.setDate(dt.getDate() + 1)) {
                        const new_abbreviation = (doj && moment(dt).toDate() >= moment(doj).toDate()) &&
                            (release_date ? moment(dt).toDate() <= moment(release_date).toDate() : moment(dt).toDate()) ? abbreviation?.id : null;
                        SpecialDatesObject[moment(dt).format('YYYY-MM-DD')] = {
                            abbreviation: new_abbreviation,
                            component_id: moment(dt).format('YYYY-MM-DD'),
                            bgColor: new_abbreviation == 'P' ? '#C8DBBE' : new_abbreviation == null ? 'transparent' : '#A6A6A6'
                        }
                    }
                }
                FinalArray.push(
                    {
                        attendance_dates: Object.values(SpecialDatesObject).length > 0 ? SpecialDatesObject : DatesObject,
                        employee_name: talent_names[i]?.fullname,
                        designation: talent_names[i]?.designationInfo?.designation_name,
                        employee_id: talent_names[i]?.id,
                        employee_code: talent_names[i]?.employee_code,
                        doj: talent_names[i]?.doj,
                        release_date: talent_names[i]?.release_date,
                    }
                )
            }
            setManualAttendance({ open: false, preview: true, data: FinalArray })

        }
    });

    const { errors, touched, getFieldProps, handleSubmit, setFieldValue } = formik;

    useEffect(() => {
        if (token) {
            dispatch(EmployeeListData(token, NULL_STRING, { month_year: moment(date).format('YYYYMM') }))
        }
    }, [token])

    const closeModal = () => {
        setManualAttendance({ open: false, preview: false, data: null });
    }

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <Grid container spacing={2}>

                            <Grid item lg={12} md={12} sm={12} >
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <ELabelInputMainGrid label={'From Date'} isModal={true} isfullgrid={true} >
                                            <EDatePicker
                                                minDate={minDate}
                                                maxDate={formik?.values?.to_date ? moment(formik?.values?.to_date).toDate() : maxDate ? maxDate : new Date()}
                                                name="from_date"
                                                inputFormat="dd/MM/yyyy"
                                                {...getFieldProps('from_date')}
                                                onChange={(newValue) => {
                                                    setFieldValue('from_date', newValue ? newValue : '')
                                                }}
                                                renderInput={(params) => <ETextField
                                                    {...params}
                                                    fullWidth
                                                    placeholder="dd/mm/yyy"
                                                    name="from_date"
                                                    onKeyDown={onKeyDown}
                                                    error={Boolean(touched.from_date && errors.from_date)}
                                                    helperText={touched.from_date && errors.from_date} />}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item md={12} lg={12} xs={12}>
                                        <ELabelInputMainGrid label={'To Date'} isModal={true} isfullgrid={true} >
                                            <EDatePicker
                                                minDate={formik?.values?.from_date ? moment(formik?.values?.from_date).toDate() : minDate}
                                                maxDate={maxDate ? maxDate : new Date()}
                                                name="to_date"
                                                inputFormat="dd/MM/yyyy"
                                                {...getFieldProps('to_date')}
                                                onChange={(newValue) => {
                                                    setFieldValue('to_date', newValue ? newValue : '')
                                                }}
                                                renderInput={(params) => <ETextField
                                                    {...params}
                                                    fullWidth
                                                    placeholder="dd/mm/yyy"
                                                    name="to_date"
                                                    onKeyDown={onKeyDown}
                                                    error={Boolean(touched.to_date && errors.to_date)}
                                                    helperText={touched.to_date && errors.to_date} />}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <ELabelInputMainGrid label={''} disableColon={true} isModal={true} isfullgrid={true} >
                                            <FormControl component="fieldset" error={Boolean(touched.isAllTalents && errors.isAllTalents)} >
                                                <ERadioGroup row={true} aria-label="isAllTalents" name="isAllTalents" {...getFieldProps('isAllTalents')} >
                                                    <FormControlLabel value={true} control={
                                                        <ERadio
                                                            onChange={(e, value) => {
                                                                setFieldValue('isAllTalents', true)
                                                                setFieldValue('talent_names', employee?.employeeData?.rows)
                                                            }}
                                                        />
                                                    } label='All Employees' />
                                                    <FormControlLabel value={false} control={
                                                        <ERadio
                                                            onChange={(e, value) => {
                                                                setFieldValue('isAllTalents', false)
                                                                setFieldValue('talent_names', [])
                                                            }}
                                                        />
                                                    } label="Individual Employee" />
                                                </ERadioGroup>
                                                {touched.isAllTalents && <EFormHelperText className='p0'> {errors.isAllTalents}</EFormHelperText>}
                                            </FormControl>
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    {(formik?.values?.isAllTalents == false || ReturnBoolean(formik?.values?.isAllTalents) == false) ?
                                        <Grid item md={12} lg={12} xs={12}>
                                            <ELabelInputMainGrid label={'Employee'} isModal={true} isfullgrid={true} >
                                                <EAutocomplete
                                                    name="Employee Name"
                                                    fullWidth
                                                    multiple
                                                    limitTags={2}
                                                    disableCloseOnSelect
                                                    forcePopupIcon={formik?.values?.talent_names && formik?.values?.talent_names.length > 0 ? false : true}
                                                    {...getFieldProps('talent_names')}
                                                    options={employee?.employeeData?.rows?.length && employee?.employeeData?.rows?.length > 0 ? employee?.employeeData?.rows : []}
                                                    onChange={(e, value) => {
                                                        setFieldValue('talent_names', value);
                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props} key={option.id}>
                                                            <ECheckbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option.label+" ("+option.employee_code+")"}
                                                        </li>
                                                    )}
                                                    renderInput={params => (
                                                        <ETextField
                                                            {...params}
                                                            name='talent_names'
                                                            // placeholder={formik?.values?.talent_names && formik?.values?.talent_names.length > 0 ? '' : "Select employee name"}
                                                            placeholder='Select employee name'
                                                            fullWidth
                                                            error={Boolean(touched.talent_names && errors.talent_names)}
                                                            helperText={touched.talent_names && errors.talent_names}
                                                        />
                                                    )}
                                                />
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        : ""}
                                    <Grid item md={12} lg={12} xs={12}>
                                        <ELabelInputMainGrid label={'Abbreviation '} isModal={true} isfullgrid={true} >
                                            <EAutocomplete
                                                name="abbreviation"
                                                fullWidth
                                                options={ABBREVIATION}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={option => option.label || ""}
                                                {...getFieldProps('abbreviation')}
                                                onChange={(e, value) => {
                                                    setFieldValue("abbreviation", value ? value : null)
                                                }}
                                                renderInput={params => (
                                                    <ETextField
                                                        {...params}
                                                        name='abbreviation'
                                                        placeholder='Select Abbreviation'
                                                        fullWidth
                                                        error={Boolean(touched.abbreviation && errors.abbreviation)}
                                                        helperText={touched.abbreviation && errors.abbreviation}
                                                    />
                                                )}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    {formik?.values?.abbreviation && formik?.values?.abbreviation?.id == PL ?
                                        <Grid item lg={12} xs={12}>
                                            <ELabelInputMainGrid label={'Type'}  isModal={true} isfullgrid={true} >
                                                <FormControl component="fieldset" error={Boolean(touched.isFullDay && errors.isFullDay)} >
                                                    <ERadioGroup row={true} aria-label="isFullDay" name="isFullDay" {...getFieldProps('isFullDay')} sx={{ pt: 1}}>
                                                        <FormControlLabel value={false} control={
                                                            <ERadio
                                                                onChange={(e, value) => {
                                                                    setFieldValue('isFullDay', false)
                                                                    // setFieldValue('from_date', null)
                                                                    // setFieldValue('from_date', null)
                                                                    // setFieldValue('to_date_half_day_paid_count', 0)
                                                                    // setFieldValue('to_date_half_day_unpaid_count', 0)
                                                                }}
                                                            />
                                                        } label='Half Day' />
                                                        <FormControlLabel value={true} control={
                                                            <ERadio
                                                                onChange={(e, value) => {
                                                                    setFieldValue('isFullDay', true)
                                                                }}
                                                            />
                                                        } label="Full Day" />
                                                    </ERadioGroup>
                                                    {touched.isFullDay && <FormHelperText> {errors.isFullDay}</FormHelperText>}
                                                </FormControl>
                                            </ELabelInputMainGrid>
                                        </Grid>
                                        : ''}
                                    <Grid item lg={12} className='modal1-buttons-stick-bottom '>
                                        <Stack direction='row' spacing={2} >
                                            <EButtonOutlined size="large" onClick={() => closeModal()} variant='outlined' > Cancel </EButtonOutlined>
                                            <ELoadingButton size="large" type="submit" variant='contained'>Next</ELoadingButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Form>
            </FormikProvider>
        </>
    );
}

