import React, { useEffect, useState } from 'react'
import AuthPage from '../../BaseComponents/EAuthPage'
import { AuthContentInner, AuthContentStyle, AuthRootStyle } from '../../BaseComponents/EContainer'
import PaymentSuccess from './PaymentSuccess'
import PaymentFailed from './PaymentFailed'
import { ContentBottomBack } from './change-password'
import { ELink } from '../../BaseComponents/ELink'
import { AuthNavbar } from '../../layouts/auth-navbar'
import { EBox } from '../../BaseComponents/EBox'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { completePlanPayment } from '../../action/OnBoardingAction'
import { CircularProgress, LinearProgress, Stack } from '@mui/material'

function PaymentInformation({ status = false }) {
    const { code } = useParams();
    const [loader, setLoader] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.authReducer);
    const navigate = useNavigate();

    const location = useLocation()
    const redirectedTo = location?.state?.redirectedTo ? location?.state?.redirectedTo : status ? 'Dashboard' : 'Payment Page'
    const redirectedToPath = location?.state?.redirectedToPath ? location?.state?.redirectedToPath : status ? '/dashboard' : '/'


    useEffect(() => {
        if (code) {
            const _payload = {
                code: code,
            }
            dispatch(completePlanPayment(auth.authtoken, _payload,
                (res) => {
                    setLoader(false)
                    setPaymentStatus(res.data.payment_status);
                },
                (error) => {
                    navigate("/login")
                }
            ))
        }
    }, [code, auth.authtoken])



    return (
        <AuthPage title="Payment Information">

            {loader &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: 1, height: "100vh" }}
                >
                    <CircularProgress />
                </Stack>
            }
            {!loader && (
                <>
                    <AuthNavbar />
                    <AuthRootStyle>
                        <EBox
                            className='flex-row-allCenter' >
                            <AuthContentStyle className="p0 grayColor2-color-border">
                                <AuthContentInner className='p-24px'>

                                    {paymentStatus ? <PaymentSuccess /> : <PaymentFailed />}



                                </AuthContentInner>
                                <ContentBottomBack className=" grayColor2-color-border">
                                    <ELink to={redirectedToPath}>
                                        {status ? 'Go to' : 'Back to'} <span className="link-color bold-600 ">{redirectedTo}</span>
                                    </ELink>
                                </ContentBottomBack>
                            </AuthContentStyle>
                        </EBox>


                    </AuthRootStyle>
                </>
            )}



        </AuthPage>
    )
}

export default PaymentInformation
