import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EHeaderBreadcrumbs from "../../../BaseComponents/EBreadcrum";
import { EButton, EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from "../../../BaseComponents/EButtons";
import { EDivider } from "../../../BaseComponents/EDivider";
import { useFormik, Form, FormikProvider } from 'formik';
import Page from "../../../BaseComponents/EPage";
import { ETypography, ETypographyPageHeading } from "../../../BaseComponents/ETypography";
import { NewTalentValidation, Passport, aadharnumber, alphaNumbericRegex, drivingLicense, pannumber } from "../../../utils/validation";
import moment from "moment";
import { ACTIVE_DEPARTMENT, ACTIVE_DESIGNATION, ACTIVE_USER, ADHAAR_DOC_ID, DRIVING_LICENCE_DOC_ID, PAN_DOC_ID, PASSPORT_DOC_ID } from "../../../constants";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AddFormBasicDetail from "../../../PageComponents/Talents/AddFormBasicDetail";
import AddFormUserLogin from "../../../PageComponents/Talents/AddFormUserLogin";
import AddFormPersonalDetail from "../../../PageComponents/Talents/AddFormPersonalDetail";
import AddFormBankDetail from "../../../PageComponents/Talents/AddFormBankDetail";
import AddEmergencyDetail from "../../../PageComponents/Talents/AddEmergencyDetail";
import AddFormDocuments from "../../../PageComponents/Talents/AddFormDocuments";
import { EmployeeListData } from "../../../action/EmployeeAction";
import { useDispatch, useSelector } from "react-redux";
import { EBox, EBoxPage, ItemBox } from "../../../BaseComponents/EBox";
import { CreateHRProfile } from "../../../action/TalentActions";
import EHidden from "../../../BaseComponents/EHidden";
import { EIcon } from "../../../BaseComponents/EIcon";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import EModal from "../../../BaseComponents/EModal";
import AddProfilePicture from "../../../PageComponents/Talents/AddProfilePicture";
import { DesignationList } from "../../../action/DesignationAction";
import { DepartmentList } from "../../../action/DepartmentAction";
import { useCompanySettingProvider } from "../../../context/CompanySettingContext";
import * as Yup from 'yup';
import { StateList } from "../../../action/StateCityAction";

const firstGrid = 0;
const RequiredField = 1;
const NonRequiredField = 2;



const AddHrProfile = (props) => {
    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const departmentList = useSelector(state => state.DepartmentReducer.departmentListData?.rows);
    const designationList = useSelector(state => state.DesignationReducer.designationListData?.rows);
    const stateList = useSelector(state => state.StateReducer.stateListData?.rows);
    const employeeList = useSelector(state => state.EmployeeReducer.employeeData?.rows);
    const [docArray, setDocArray] = useState([])//To store and  manipulate docs array
    const [docValidation, setDocValidation] = useState({ validation: {}, isReqd: [] })//To store and  manipulate docs array


    // INFO: getting employee & company settings
    const settingsContext = useCompanySettingProvider()
    const TalentSetting = settingsContext?.setting?.talentSettingData?.companySetting ?? {}
    const attendanceSetting = settingsContext?.setting?.attendanceSettingData?.companySetting
    const shiftList = attendanceSetting?.shifts ?? []
    // INFO: defaultShiftId is used to send shift_id as defaultShiftId when company have only one shift
    const defaultShiftId = shiftList?.filter(i => i.is_default_shift)?.[0]?.id || null
    // Shift Name change is allowed only if shifts is more than one. Otherwise, it is not allowed.
    const isShiftAllowed = shiftList?.length > 1
    const PayrollData = settingsContext?.setting?.accountSettingData?.company_data// fetching payroll data here from settings


      // Function to get validation regex of four default documents i.e ['aadhar card', 'pan card','driving license','passport'], for custom document it will return null ie. no validation
      const getDefaultDocValidation = (document_id) =>{
        switch (document_id) {
            case ADHAAR_DOC_ID:
                return aadharnumber
            case PAN_DOC_ID:
                return pannumber
            case DRIVING_LICENCE_DOC_ID:
                return drivingLicense
            case PASSPORT_DOC_ID:
                return Passport
            default:
                return null
        }
    }

    // INFO: Here, we are storing the document ids which are required, the following if condition runs only one time
    if (TalentSetting?.talent_document && docArray?.length == 0) {
       
        // INFO: Converting to JSON data to remove state mutation
        let JsonData = TalentSetting?.talent_document ? JSON.stringify(TalentSetting.talent_document) : [];
        let JsonArrayData = JSON.parse(JsonData)
        let dynamicDocObject = {}
        var reqd = []
        let FinalArrayData = JsonArrayData?.map((row, index) => {

            reqd.push(row?.is_required)
            dynamicDocObject = row?.is_required ?
                {
                    ...dynamicDocObject,
                    ['document_id_' + row.document_id]: Yup.string().required(row.document_name + " is required").nullable().matches(getDefaultDocValidation(row.document_id), `Please enter a valid ${row?.document_name.toLowerCase()} number`),
                    ['file_' + row.document_id]: Yup.mixed().required("File is required").nullable()
                        .test("type", "We only support pdf and jpeg/jpg", function (value) {
                            if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
                            return true;
                        })
                        .test("fileSize", "File Size is too large", (value) => {
                            const fileSize = value?.size / 1024 / 1024; //in mb
                            if (fileSize > 2) {
                                return false;
                            }
                            return true;
                        }),
                }
                :
                {
                    ...dynamicDocObject,
                    ['document_id_' + row.document_id]: Yup.string().nullable().matches(getDefaultDocValidation(row.document_id), `Please enter a valid ${row?.document_name.toLowerCase()} number`),
                    ['file_' + row.document_id]: Yup.mixed().nullable()
                        .test("type", "We only support pdf and jpeg/jpg", function (value) {
                            if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
                            return true;
                        })
                        .test("fileSize", "File Size is too large", (value) => {
                            const fileSize = value?.size / 1024 / 1024; //in mb
                            if (fileSize > 2) {
                                return false;
                            }
                            return true;
                        })
                }


            return { ...row, file: null, document_id_no: '' }
        })
        setDocValidation({ validation: dynamicDocObject, isReqd: reqd })
        setDocArray(FinalArrayData)
    }

    const validationSchema = Yup.object().shape({
        ...NewTalentValidation(auth.authtoken, TalentSetting).fields,
        ...docValidation.validation
    });

    const { field_profile_photo, field_dob, field_gender, field_marital_status, field_blood_group,
        field_address, field_ifsc_code, field_bank_name, field_branch_name, field_account_holder_name, field_account_number,
        field_emer_name, field_emer_relation, field_emer_contact_no
    } = TalentSetting
    //Storing settings
    const BorderOrder = useRef({
        basicDetails: firstGrid,
        isProfilePhoto: [field_profile_photo].includes(true) ? RequiredField : NonRequiredField,
        personalDetails: [field_dob, field_gender, field_marital_status, field_blood_group, field_address,].includes(true) ? RequiredField : NonRequiredField,
        // document: formikTalents?.values?.requiredDocuments.includes(1) ? RequiredField: NonRequiredField,
        document: docValidation?.isReqd ? RequiredField : NonRequiredField,
        emergencyDetails: [field_emer_name, field_emer_relation, field_emer_contact_no].includes(true) ? RequiredField : NonRequiredField,
        bankDetails: [field_ifsc_code, field_bank_name, field_branch_name, field_account_holder_name, field_account_number].includes(true) ? RequiredField : NonRequiredField,
        submit: NonRequiredField,
    })



    const formikTalents = useFormik({
        initialValues: {

            personal_detail_check: BorderOrder?.current?.personalDetails == RequiredField ? true : false,
            documents_check: null,//docValidation.isReqd ? true : false,
            bank_detail_check: BorderOrder?.current?.bankDetails == RequiredField ? true : false,
            emergency_contact_detail_check: BorderOrder?.current?.emergencyDetails == RequiredField ? true : false,
            personal_photo_check: BorderOrder?.current?.isProfilePhoto == RequiredField ? true : false,
            //Basic Details 
            fname: auth?.authData?.fname,
            lname: auth?.authData?.lname,
            email:  auth?.authData?.email,
            mobile: auth?.authData?.mobile,
            //Official Detail
            doj: null,
            department_object: null,
            designation_object: null,
            isShiftAllowed,
            shift_object: null,
            payroll_basic: '',
            work_mode_object: null,



            //photo
            personal_photo: null,

            //Personal Details
            personal_gender_object: null,
            personal_dob: null,
            personal_address_one: '',
            personal_address_two: '',
            personal_state: null,
            personal_district: null,
            personal_pin_code: '',
            landmark: '',
            personal_marital_status_obj: null,
            personal_blood_group_obj: null,

            // Documents
            requiredDocuments: [],

            // bank details
            ifsc_code: '',
            bank_name: '',
            branch_name: '',
            account_no: '',
            bank_account_holder_name: '',

            //newDepartment
            new_department: false,

            //newDesignation
            new_designation: false,

            //emergencycontact
            emr_contact_mobile: '',
            emr_person_name: '',
            emr_contact_relation: '',

        },
        validationSchema: validationSchema,
        onSubmit: (data) => { 
                data.shift_id = defaultShiftId
                //INFO: assigning values to actual variables from object variables so that in an error occurs, input fields do not get empty
                if (data && data.work_mode_object && data.work_mode_object.id) { data.work_mode = data.work_mode_object.id; }
                if (data && data.department_object && data.department_object.id) { data.department = data.department_object.id; }
                if (data && data.designation_object && data.designation_object.id) { data.designation = data.designation_object.id; }
                if (data && data.shift_object && data.shift_object.id) { data.shift_id = data.shift_object.id; }
                if (data && data.personal_gender_object && data.personal_gender_object.id) { data.personal_gender = data.personal_gender_object.id; }
                if (data && data.personal_state && data.personal_state.id) { data.personal_state_id = data.personal_state.id; }
                if (data && data.personal_district && data.personal_district.id) { data.personal_district_id = data.personal_district.id; }
                if (data && data.role_object && data.role_object.id) { data.role = data.role_object.id; }
                if (data && data.personal_blood_group_obj && data.personal_blood_group_obj.id) { data.personal_blood_group = data.personal_blood_group_obj.id; }
                if (data && data.personal_marital_status_obj && data.personal_marital_status_obj.id) { data.personal_marital_status = data.personal_marital_status_obj.id; }
                if (docArray && data.documents_check) { data.doc_array = docArray }
                if (data) {
                    data = { ...data, company_id: auth.authData?.company_id, created_by: auth.authData?.id }

                    dispatch(CreateHRProfile(auth.authtoken, data, navigate))
            }
        }
    });
    const { handleSubmit, getFieldProps, setFieldValue } = formikTalents;

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER, { is_active: true, form_list: true }))
            dispatch(DesignationList(auth.authtoken))//INFO: This is called to get all the updated list
            dispatch(DepartmentList(auth.authtoken))//INFO: This is called to get the updated list
            dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions

        }
    }, [auth.authtoken])

    useEffect(() => {
        // this is used here to validate the shift_object
        setFieldValue('isShiftAllowed', isShiftAllowed)
    }, [isShiftAllowed])


    // INFO: In the following function, we are setting the actual values (required/non-required) to the documents check;
    // This function will only run for the first time
    if (docValidation?.isReqd?.length > 0 && getFieldProps('documents_check').value == null) {
        setFieldValue('documents_check', docValidation?.isReqd?.includes(1) ? true : false)
        BorderOrder.current.document = docValidation?.isReqd?.includes(1) ? RequiredField : NonRequiredField;
    }

    const DesignationArray = designationList && designationList?.filter(object => { 
        return object.status == ACTIVE_DESIGNATION
    });

    const DepartmentArray = departmentList && departmentList?.filter(object => {
        return object.status == ACTIVE_DEPARTMENT
    });



    return (
        <Page title="My Profile" >

            {/* Header */}
            <EBox className='flex-row-horizontaRight'>
                <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
            </EBox>
            <EBox className='margin-top-20px '>
                <ETypographyPageHeading variant='h5'>
                    My Profile
                </ETypographyPageHeading>
                <EHidden width='mdDown'><EDivider className='margin-top-05 mb-16px' /></EHidden>

            </EBox>




            <FormikProvider value={formikTalents} >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <EBoxPage>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {/* BY using order in grids, we are going to handle order in here. ref: https://mui.com/material-ui/api/image-list/ */}
                                <Grid item xs={12} order={BorderOrder.current.basicDetails}>

                                    <AddFormBasicDetail
                                        formikTalents={formikTalents}
                                        departmentList={DepartmentArray}
                                        designationList={DesignationArray}
                                        shiftList={shiftList}
                                        isShiftAllowed={isShiftAllowed}
                                        auth={auth}
                                        PayrollData={PayrollData}//company setting-> payroll data(used to validate doj)
                                    />
                                </Grid>
                                <Grid item xs={12} order={BorderOrder.current.isProfilePhoto}>


                                  <AddProfilePicture
                                    formikTalents={formikTalents}
                                    employeeList={employeeList}
                                    auth={auth}
                                    photoEditorContainerJustify={'center'}
                                    photoEditorItemMD={5}
                                    photoEditorItemSM={8}
                                    isOpen={BorderOrder.current.isProfilePhoto == RequiredField ? true : false}
                                    />


                                </Grid>

                                <Grid item xs={12} order={BorderOrder.current.personalDetails}>
                                    <AddFormPersonalDetail
                                        formikTalents={formikTalents} stateList={stateList} token={auth.authtoken}
                                        isOpen={BorderOrder.current.personalDetails == RequiredField ? true : false}
                                        TalentSetting={TalentSetting}
                                    />
                                </Grid>
                                <Grid item xs={12} order={BorderOrder.current.document}>
                                    <AddFormDocuments formikTalents={formikTalents}
                                        isOpen={BorderOrder.current.document == RequiredField ? true : false}
                                        docArray={docArray} setDocArray={setDocArray}
                                    />
                                </Grid>
                                <Grid item xs={12} order={BorderOrder.current.bankDetails}>
                                    <AddFormBankDetail formikTalents={formikTalents}
                                        isOpen={BorderOrder.current.bankDetails == RequiredField ? true : false}
                                        TalentSetting={TalentSetting}
                                    />
                                </Grid>
                                <Grid item xs={12} order={BorderOrder.current.emergencyDetails}>
                                    <AddEmergencyDetail formikTalents={formikTalents}
                                        isOpen={BorderOrder.current.emergencyDetails == RequiredField ? true : false}
                                        TalentSetting={TalentSetting}
                                    />
                                </Grid>
                                <Grid item xs={12} order={BorderOrder.current.submit}>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center">
                                        <ItemBox>
                                            <EButtonOutlined variant="outlined" onClick={() => navigate(-1)}>  Cancel
                                            </EButtonOutlined>
                                        </ItemBox>
                                        <ItemBox><EButton variant="contained" type="submit" >  Create </EButton></ItemBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </EBoxPage>
                    </LocalizationProvider>
                </Form>
            </FormikProvider>
        </Page>
    )

};

export default AddHrProfile;
