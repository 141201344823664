import React, { useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import EditFill from "@iconify/icons-material-symbols/edit-outline-rounded";
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import cloneDeep from 'lodash/cloneDeep'

import { ECardBorderLess } from '../../../BaseComponents/ECard';
import EModal from '../../../BaseComponents/EModal';
import { GetHrsMinObject, formatTime, formatTimeString } from '../../../utils/CommonFunctions';
import { ECustomizedRadioWithTitle, EDataGrid } from '../../../BaseComponents';
import { OFFICE_HOURS_TYPE, OFFICE_HOURS_TYPE_LABEL, OFFICE_HOURS_TYPE_OPTIONS } from '../../../constants';
import { ETypography } from '../../../BaseComponents/ETypography';
import { EIcon, EIconStaticColor } from '../../../BaseComponents/EIcon';
import { useMemo } from 'react';
import { EButtonOutlined, EIconButton, ELoadingButton } from '../../../BaseComponents/EButtons';
import { EBox } from '../../../BaseComponents/EBox';
import { ShiftForm } from './Shifts';


const initialDeleteModalData = {
  open: false,
  id: "",
  name: "",
}

const initialConfirmationModalData = {
  open: false,
  from: "",
  to: "",
  value: "",
}

const initialTempShift = {
  id: "",
  shift_name: "",
  check_in_before: "",
  check_in_time: "",
  check_out_time: "",
  buffer_time: "",
  min_full_day_hour: "",
  min_half_day_hour: "",
  is_default_shift: false,
}

export const OfficeHoursSettings = ({ formik, showForm, setShowForm, showTable, setShowTable, disableFields }) => {

  const { values, errors, setFieldValue } = formik;

  const [deleteModalData, setDeleteModalData] = useState(initialDeleteModalData)
  const [confirmationModalData, setConfirmationModalData] = useState(initialConfirmationModalData)

  const { open, id, name } = deleteModalData

  const theme = useTheme()

  const handleAdd = (is_default_shift = false) => {
    setShowForm(true)
    setFieldValue('tempShift', {
      ...initialTempShift,
      is_default_shift,
      new: true,
    })
  }
  const handleEdit = (data) => {
    setShowForm(true)
    // getting the minimum hours to the hours & minutes object as accepted by the Autocomplete(dropdown)
    const hd_hours_object= data?.min_half_day_hour? GetHrsMinObject(data?.min_half_day_hour,false):null;
    const hd_minutes_object= data?.min_half_day_hour? GetHrsMinObject(data?.min_half_day_hour,true):null;
    const fd_hours_object= data?.min_full_day_hour? GetHrsMinObject(data?.min_full_day_hour,false):null;
    const fd_minutes_object= data?.min_full_day_hour? GetHrsMinObject(data?.min_full_day_hour,true):null;
    setFieldValue('tempShift', {
      ...data,
      check_in_before: formatTime(data.check_in_before, true),
      check_in_time: formatTime(data.check_in_time, true),
      check_out_time: formatTime(data.check_out_time, true),
      hd_hours_object: hd_hours_object,
      hd_minutes_object: hd_minutes_object,
      fd_hours_object: fd_hours_object,
      fd_minutes_object: fd_minutes_object,
      new: false,
    })

  }
  const handleDelete = (data) => {
    setDeleteModalData({
      open: true,
      id: data?.id,
      name: data?.shift_name,
    })
  }

  const removeShift = () => {
    const _shifts = cloneDeep(values?.shifts || [])
    const deletingShiftIndex = _shifts.findIndex((shift) => shift.id === id)
    _shifts[deletingShiftIndex].destroyed = true
    setFieldValue("shifts", _shifts);
    handleClose()
  }

  const handleClose = () => {
    setDeleteModalData(initialDeleteModalData)
    setConfirmationModalData(initialConfirmationModalData)
  }

  const columns = useMemo(() => [
    {
      field: 'sno',
      headerName: 'SNo.',
      flex: 1,
      width: 50,
      minWidth: 50,
      maxWidth: 80,
      sortable: false,
      editable: false,
      renderCell: (params) => (params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id) || 0) + 1,
    },
    {
      field: 'shift_name',
      headerName: 'Shift Name',
      flex: 1,
      width: 150,
      minWidth: 150,
      sortable: false,
      editable: false,
    },
    ...(values.office_hours_type === OFFICE_HOURS_TYPE.HOURLY
      ?
      [
        {
          field: 'check_in_before',
          headerName: 'Check-in Before',
          flex: 1,
          width: 150,
          minWidth: 150,
          sortable: false,
          editable: false,
          renderCell: (params) => formatTime(params?.row?.check_in_before),
        },
        {
          field: 'min_full_day_hour',
          headerName: 'Full Day',
          flex: 1,
          width: 150,
          minWidth: 150,
          sortable: false,
          editable: false,
          renderHeader: () => (
            <EBox >
              <strong>
                {'Full Day '}
              </strong>
              <br />
              <span>
                (Min Hours)
              </span>
            </EBox>
          ),
          renderCell: (params) => formatTimeString(params?.row?.min_full_day_hour),
        }
      ]
      :
      [
        {
          field: 'check_in_time',
          headerName: 'Check-in Time',
          flex: 1,
          width: 150,
          minWidth: 150,
          sortable: false,
          editable: false,
          renderCell: (params) => formatTime(params?.row?.check_in_time),
        },
        {
          field: 'check_out_time',
          headerName: 'Check-out Time',
          flex: 1,
          width: 150,
          minWidth: 150,
          sortable: false,
          editable: false,
          renderCell: (params) => formatTime(params?.row?.check_out_time),
        },
        {
          field: 'buffer_time',
          headerName: 'Buffer Time',
          flex: 1,
          width: 150,
          minWidth: 150,
          sortable: false,
          editable: false,
          renderCell: (params) => `${params?.row?.buffer_time}mins`
        },

      ]),

    {
      field: 'min_half_day_hour',
      headerName: 'Half Day (Min Hours)',
      flex: 1,
      width: 150,
      minWidth: 150,
      sortable: false,
      editable: false,
      renderHeader: () => (
        <EBox sx={{ lineHeight: 'initial' }}>
          <strong>
            {'Half Day '}
          </strong>
          <br />
          <span>
            (Min Hours)
          </span>
        </EBox>
      ),
      renderCell: (params) => formatTimeString(params?.row?.min_half_day_hour)
    },
    ...(!disableFields 
      ? 
      [
        {
          field: 'action',
          headerName: 'Action',
          flex: 1,
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          sortable: false,
          editable: false,
          type: 'actions',
          renderCell: (params) => {
            return (
              <>
                <EIconButton className="ml-4px p0" onClick={() => handleEdit(params?.row)} >
                  <EIcon icon={EditFill} sx={{ width: 24, height: 24 }} />
                </EIconButton>
                {
                  !params?.row?.is_default_shift &&
                  <EIconButton className="ml-16px p0" onClick={() => handleDelete(params?.row)}>
                    <EIcon icon={DeleteIcon} sx={{ width: 24, height: 24, color: theme.palette.error.red }} />
                  </EIconButton>
                }
              </>
            );
          }
        },
      ]
      :
      []
    ),
    // eslint-disable-next-line
  ], [values.office_hours_type, disableFields])

  const handleOfficeHourChange = (value) => {
    const from = value === 0 ? 1 : 0
    if (values?.shifts?.filter(i => i.destroyed !== true)?.length === 0) {
      return setFieldValue('office_hours_type', value)
    }
    setConfirmationModalData({
      open: true,
      from: OFFICE_HOURS_TYPE_LABEL[from],
      to: OFFICE_HOURS_TYPE_LABEL[value],
      value,
    })
  }

  const handleOfficeHourChangeConfirmation = (value) => {
    setFieldValue('office_hours_type', confirmationModalData.value)
    const clonedShifts = cloneDeep(values?.shifts)
    const destroyedShifts = clonedShifts?.map(i => ({ ...i, destroyed: true }))
    setFieldValue('shifts', destroyedShifts)
    setShowTable(false)
    handleAdd(true)
    handleClose()
  }

  return (
    <>
      <ECustomizedRadioWithTitle
        label="Office hours"
        extraLabel="Based on selection Entera Payroll will decide how to calculate attendance for your organization. It can be based on either total working hours or working hours within a specific time range."
        options={OFFICE_HOURS_TYPE_OPTIONS}
        value={values?.office_hours_type}
        name="office_hours_type"
        onChange={handleOfficeHourChange}
        error={errors?.office_hours_type || ""}
        disabled={disableFields}
      />
      <ECardBorderLess sx={{ padding: "18px 24px", mt: 2 }}>
        <Grid container columnSpacing={2}>
          <Grid item md={12} sm={12} xs={12} className="align-center">
            <ETypography>
              Employee will be expected to work for minimum working hours for full day, starting from the specific check-in time. There is no criteria set for checkout time.
            </ETypography>
          </Grid>
          {
            showTable
            &&
            <>
              <Grid item xs={12} display="flex" justifyContent="flex-end" >
                <EIconButton
                  onClick={() => handleAdd()}
                  sx={{
                    borderRadius: 0,
                    mb: 1
                  }}
                  disabled={disableFields}
                >
                  <ETypography
                    role={"heading"}
                    variant="h6"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      whiteSpace: 'initial',
                      fontSize: {
                        lg: '14px !important',
                        xs: '14px !important'
                      },
                      color: `${(!disableFields) ? theme.palette.primary.main : theme.palette.text.disabled} !important`
                    }}
                    className='theme-color-static text-align-right'
                  >
                    <EIconStaticColor icon={plusBox} sx={{ width: 15, height: 15, mr: 1.475 }} />
                    Add Shift
                  </ETypography>
                </EIconButton>
              </Grid>

              <Grid item xs={12}>
                <EDataGrid columns={columns} rows={values?.shifts?.filter(i => i.destroyed !== true) || []} getRowHeight={() => 'auto'} />
              </Grid>
            </>
          }
          {
            (showForm)
            &&
            <Grid item md={12} sm={12} xs={12} className="align-center">
              <ShiftForm formik={formik} showForm={showForm} setShowForm={setShowForm} showTable={showTable} setShowTable={setShowTable} />
            </Grid>
          }
        </Grid>
      </ECardBorderLess>

      {/* Office Hour Type Change Confirmation Modal */}
      <EModal
        open={confirmationModalData.open}
        headervalue={"Office Hours: Change Confirmation"}
        parentClassname='delete-confirmation-modal'
      >
        <Grid container rowSpacing={2}>
          <Grid item>
            <ETypography className="font-size-16px ">
              Do you wish to change from
              <span className="theme-main-text-color-bold"> {confirmationModalData.from} </span>
              to <span className="theme-main-text-color-bold"> {confirmationModalData.to} </span>
              mode of attendance calculation?
            </ETypography>
            <ETypography className="font-size-16px ">
              If yes, all the existing shifts will be deleted to proceed with the new settings. Please press yes to confirm.
            </ETypography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
            <EButtonOutlined size="large" variant="outlined" onClick={handleClose} > No </EButtonOutlined>
            <ELoadingButton size="large" type="button" variant="contained" onClick={handleOfficeHourChangeConfirmation} className='button-left-margin'> Yes </ELoadingButton>
          </Grid>
        </Grid>
      </EModal>

      {/* Shift Delete Confirmation Modal */}
      <EModal
        open={open}
        headervalue={"Delete Shift: Confirmation"}
        parentClassname='delete-confirmation-modal'
      >
        <Grid container rowSpacing={2}>
          <Grid item>
            <ETypography className="font-size-16px "> Are you sure you want to delete
              <span className="theme-main-text-color-bold"> {name} </span> ?</ETypography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
            <EButtonOutlined size="large" variant="outlined" onClick={handleClose} > No </EButtonOutlined>
            <ELoadingButton size="large" type="button" variant="contained" onClick={removeShift} className='button-left-margin'> Yes </ELoadingButton>
          </Grid>
        </Grid>
      </EModal>
    </>
  )
}
