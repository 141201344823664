import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep'
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETypography, } from '../../BaseComponents/ETypography';
import { ECardBasic, ECardDashboard } from '../../BaseComponents/ECard';
import { GET_ATTENDANCE_SETTING_API, PERMISSIONS, SETTING_ATTENDANCE, SET_ATTENDANCE_SETTING_API } from '../../constants';
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { Form, FormikProvider, useFormik } from 'formik';
import { AttendanceSettingValidation } from '../../utils/validation';
import { Stack } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ECollapsibleGrid, ECustomizedRadioWithTitle, EPageWithBreadCrumbs } from '../../BaseComponents';
import { AttendanceRegularizationSettings, IPSettings, WeeklyOffSettings } from '../../PageComponents/Settings/Attendance';
import { OfficeHoursSettings } from '../../PageComponents/Settings/Attendance/OfficeHoursSettings';
import EModal from '../../BaseComponents/EModal';
import { useBlocker } from '../../hooks/useBlocker';
import { GetHrsMinObject, formatTime } from '../../utils/CommonFunctions';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { SetCompanySetting } from '../../action/SettingAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCheckPermission } from '../../hooks';
import { AttendanceModeSetting } from '../../PageComponents/Settings/Attendance/AttendanceModeSetting';

export const OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
]

const initialModal = {
  open: false,
  onClick: () => null,
  header: '',
  body: <></>,
  loading: false,
}

function AttendanceSettings({ auth }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  /**
   * fetch the company setting from the useCompanySettingProvider
   */
  const { setting } = useCompanySettingProvider()

  const attendanceSettingData = setting?.attendanceSettingData ? setting?.attendanceSettingData : {}
  const settingLoading = setting?.settingLoading ?? false
  const company = useSelector(state => state.CompanyReducer)

  function getFormValues(defaultSetting = false) {
    let value = {
      is_default_setting: false,
      allow_attendance_automate: true,
      at_least_one_field: false,
      allow_ip_check: false,
      connection_name: null,
      ip_address: null,
      office_hours_type: 0,
      attendance_regularization_required: false,
      attendance_regularization_request: null,
      attendance_regularization_count: null,
      enable_weekly_off: true,
      default_weekly_off_day: "",
      show_attendance_on_payslip: true,
      enable_biometric: false,
      enable_web: false,
      enable_mobile: false,
      shifts: [],
      weekly_off: [],
      biometric: "",
      weekly_off_object: {},
      default_day_name: "",
      tempWeeklyOff: [],
      showForm: false,
      tempShift: {
        new: false,
        id: "",
        shift_name: "",
        check_in_before: "",
        check_in_time: "",
        check_out_time: "",
        buffer_time: "",
        min_full_day_hour: "",
        min_half_day_hour: "",
        is_default_shift: false
      },
      showTable: true
    };
    if (!settingLoading && Object.keys(attendanceSettingData || {}).length > 0) {
      const _setting = defaultSetting ? attendanceSettingData.companyDefaultSetting : attendanceSettingData.companySetting
      value = { ..._setting };
      const data = _setting;
      let default_day_name = "";
      if (data?.default_weekly_off_day !== "") {
        default_day_name = data?.default_weekly_off_day;
        value.default_day_name = default_day_name;
      }
      let weeklyOffDays = [];
      let weekly_off_object = data?.weekly_off_object;

      for (const key in weekly_off_object) {
        for (const inData of weekly_off_object[key]) {
          if (inData.is_check === 1 && inData.day_name !== default_day_name) {
            weeklyOffDays.push({
              id: inData.id,
              day_name: inData.day_name,
              week_no: inData.week_no,
              is_check: inData.is_check,
            });
          }
        }
      }
      value.tempWeeklyOff = weeklyOffDays
      value.showForm = true
      value.tempShift = {
        new: true,
        id: "",
        shift_name: "",
        check_in_before: "",
        check_in_time: "",
        check_out_time: "",
        buffer_time: "",
        min_full_day_hour: "",
        min_half_day_hour: "",
        is_default_shift: true,
      }
      if (data.allow_attendance_automate) {
        const isNew = data?.shifts?.filter(i => i.destroyed !== true)?.length === 0;
        value.showForm = false;
        value.showTable = true;
        value.at_least_one_field = data.enable_web || data.enable_mobile || data.enable_biometric
        const _tempShift = cloneDeep(data?.shifts[0]);
        if (defaultSetting) {
          const hd_hours_object = _tempShift?.min_half_day_hour ? GetHrsMinObject(_tempShift?.min_half_day_hour, false) : null;
          const hd_minutes_object = _tempShift?.min_half_day_hour ? GetHrsMinObject(_tempShift?.min_half_day_hour, true) : null;
          const fd_hours_object = _tempShift?.min_full_day_hour ? GetHrsMinObject(_tempShift?.min_full_day_hour, false) : null;
          const fd_minutes_object = _tempShift?.min_full_day_hour ? GetHrsMinObject(_tempShift?.min_full_day_hour, true) : null;

          value.is_default_setting = defaultSetting
          value.tempShift = {
            ..._tempShift,
            check_in_before: formatTime(_tempShift.check_in_before, true),
            check_in_time: formatTime(_tempShift.check_in_time, true),
            check_out_time: formatTime(_tempShift.check_out_time, true),
            hd_hours_object: hd_hours_object,
            hd_minutes_object: hd_minutes_object,
            fd_hours_object: fd_hours_object,
            fd_minutes_object: fd_minutes_object,
          };
        } else {
          value.is_default_setting = false
          value.showTable = !isNew;
          value.tempShift = {
            ...value.tempShift,
            new: isNew,

            id: "",
            shift_name: "",
            check_in_before: "",
            check_in_time: "",
            check_out_time: "",
            buffer_time: "",
            min_full_day_hour: "",
            min_half_day_hour: "",
            is_default_shift: isNew,
          };
        }
      }
    }

    return value;
  }

  /**
   * Setting the form initial values
   */
  const initialValues = useMemo(() => {
    const _initialValues = getFormValues()
    return _initialValues
  }, [attendanceSettingData, settingLoading])

  let _formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AttendanceSettingValidation,
    validateOnMount: true,
    onSubmit: () => {
      handleSave()
    }
  })

  const handleChange = (field, value) => {
    _formik.setFieldTouched(field, true)
    _formik.setFieldValue(field, value)
    setIsFormChanged(true)
  }

  const formik = { ..._formik, setFieldValue: handleChange }

  const { values, errors, setFieldValue, handleSubmit, dirty, setValues } = formik

  /**
   * useBlocker hook is used here to prevent the route change when the form is dirty (i.e. any value is changed in the form)
   */
  const [showModal, setShowModal, continueNavigate] = useBlocker(dirty)

  const allowEdit = useCheckPermission([PERMISSIONS.ViewEditAttendanceSettings])
  const disableFields = useMemo(() => (!allowEdit), [allowEdit])

  const [isFormChanged, setIsFormChanged] = useState(dirty)
  const [modal, setModal] = useState(initialModal)

  useEffect(() => {
    setIsFormChanged(dirty)
  }, [dirty])

  /**
   * Unsaved form value confirmation modal save button action
   */
  const confirmSave = () => {
    setShowModal(false)
    handleSubmit()
  }

  const handleDefaultApply = () => {
    setModal({
      open: true,
      onClick: () => {
        const data = { ...attendanceSettingData?.companyDefaultSetting, is_default_setting: true }
        delete data.tempShift
        delete data.biometric
        dispatch(SetCompanySetting(auth.authtoken, data, SET_ATTENDANCE_SETTING_API, GET_ATTENDANCE_SETTING_API, SETTING_ATTENDANCE, navigate));
        return
      },
      header: 'Default Setting: Confirmation',
      body: <>
        <ETypography className='font-size-16px'>
          This will restore all settings to their default values.
        </ETypography>
        <ETypography className='font-size-16px'>
          Any changes you've made will be lost!
        </ETypography>
        <ETypography className='font-size-16px'>
          Do you want to apply default settings?
        </ETypography>
      </>
    })
  }

  const closeModal = () => {
    setModal(initialModal)
  }

  const handleSave = () => {
    setModal({
      open: true,
      onClick: () => {
        saveSetting()
        closeModal()
      },
      header: 'Custom Setting: Confirmation',
      body: <>
        <ETypography className='font-size-16px'>
          Are you sure! you want to save these changes in
          {" "}
          <span className='theme-main-text-color-bold'>
            Attendance
          </span>
          {" "}
          settings?
        </ETypography>
      </>
    })
  }
  const saveSetting = () => {
    const payload = cloneDeep(values)
    delete payload.tempShift
    delete payload.company_id
    delete payload.created_at
    delete payload.created_by
    delete payload.updated_at
    delete payload.updated_by
    delete payload.weekly_off
    delete payload.default_day_name
    delete payload.tempWeeklyOff
    delete payload.showForm
    delete payload.showTable
    delete payload.biometric
    delete payload.at_least_one_field
    payload.attendance_regularization_required = !!payload.attendance_regularization_required
    payload.attendance_regularization_request = !!payload.attendance_regularization_request
    dispatch(SetCompanySetting(auth.authtoken, payload, SET_ATTENDANCE_SETTING_API, GET_ATTENDANCE_SETTING_API, SETTING_ATTENDANCE, navigate));
  }

  const handleCancel = () => {
    formik.resetForm()
    setIsFormChanged(false)
  }

  useEffect(() => {
    if (!dirty) return
    setFieldValue("at_least_one_field", values.enable_web || values.enable_mobile || values.enable_biometric)
  }, [values.enable_web, values.enable_mobile, values.enable_biometric])


  return (
    <EPageWithBreadCrumbs
      title="Attendance Settings"
      pageHeading="Attendance Settings"
      breadcrumbsTitle="Attendance Settings"
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Company Settings', href: '' },
        { name: 'Attendance Settings' },
      ]}
      loading={settingLoading}
      hideDivider={true}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <EBoxPage sx={{ padding: '16px 0 !important' }}>
              <Grid container spacing={3}>
                {/* Attendance Setting */}
                <Grid item xs={12}>
                  <ECollapsibleGrid
                    label='Automate your attendance'
                    labelClassName="font-size-18px"
                    extraLabel="By enabling this setting, you allow employees to mark their attendance on Entera Payroll through an automated process. If not, HR/Admin/Super Admin can mark attendance for all the employees manually."
                    options={OPTIONS}
                    value={values?.allow_attendance_automate}
                    expanded={values?.allow_attendance_automate}
                    name="allow_attendance_automate"
                    onChange={(value) => setFieldValue('allow_attendance_automate', value)}
                    error={errors?.allow_attendance_automate || ""}
                    disabled={disableFields}
                  >
                    <Grid item xs={12} className='pt-16px'>
                      <ECardDashboard className={'Bg-card6 border-none shadow-none'} sx={{ padding: { lg: '18px 24px !important', md: '18px 24px !important' } }}>
                        <Grid container rowSpacing={4.5}>
                          <Grid item xs={12}>
                            <AttendanceModeSetting formik={formik} disableFields={disableFields} />
                          </Grid>
                          {
                            values?.enable_biometric && !values?.enable_web && !values?.enable_mobile ? null :
                              <Grid item xs={12}>
                                <IPSettings formik={formik} disableFields={disableFields} />
                              </Grid>
                          }
                          <Grid item xs={12}>
                            <OfficeHoursSettings formik={formik} showForm={values.showForm} setShowForm={(value) => setFieldValue('showForm', value)} showTable={values.showTable} setShowTable={(value) => setFieldValue('showTable', value)} disableFields={disableFields} />
                          </Grid>
                          <Grid item xs={12}>
                            <AttendanceRegularizationSettings formik={formik} disableFields={disableFields} />
                          </Grid>
                        </Grid>
                      </ECardDashboard>
                    </Grid>
                  </ECollapsibleGrid>
                </Grid>

                {/* Weekly Off Setting */}
                <Grid item xs={12}>
                  <ECollapsibleGrid
                    label='Weekly Off'
                    labelClassName="font-size-18px"
                    extraLabel="The setting will allow you to select the company-wide day off on weekly basis."
                    options={OPTIONS}
                    value={values?.enable_weekly_off}
                    expanded={values?.enable_weekly_off}
                    name="enable_weekly_off"
                    onChange={(value) => setFieldValue('enable_weekly_off', value)}
                    error={errors?.enable_weekly_off || ""}
                    disabled={disableFields}
                  >
                    <Grid item xs={12} className='pt-16px'>
                      <ECardDashboard className={'Bg-card6 border-none shadow-none'} sx={{ padding: { lg: '18px 24px !important', md: '18px 24px !important' } }}>
                        <Grid container rowSpacing={4.5}>
                          <Grid item xs={12} >
                            <WeeklyOffSettings formik={formik} disableFields={disableFields} />
                          </Grid>
                        </Grid>
                      </ECardDashboard>
                    </Grid>
                  </ECollapsibleGrid>
                </Grid>

                {/* Payslip Setting */}
                <Grid item xs={12}>
                  <ECardBasic className='mb-0px border-05px-border6 '>
                    <ECustomizedRadioWithTitle
                      label='Show Attendance on Payslip'
                      labelClassName="font-size-18px"
                      extraLabel="Do you wish to include the information of working days and non-working days on Payslip?"
                      options={OPTIONS}
                      value={values?.show_attendance_on_payslip}
                      name="show_attendance_on_payslip"
                      onChange={(value) => setFieldValue('show_attendance_on_payslip', value)}
                      error={errors?.show_attendance_on_payslip || ""}
                      disabled={disableFields}
                    />
                  </ECardBasic>
                </Grid>

                {/* Save Button */}
                {
                  company?.companyData?.onBoarding?.onboarding_is_completed
                    ?
                    allowEdit &&
                    <Grid item xs={12}>
                      <EBox display={"flex"} justifyContent={"space-between"}>
                        <EButtonOutlined variant="outlined" onClick={handleDefaultApply}>
                          Apply Default Setting
                        </EButtonOutlined>
                        <EBox>
                          {
                            isFormChanged &&
                            <EButtonOutlined variant="outlined" onClick={handleCancel}>
                              Cancel
                            </EButtonOutlined>
                          }
                          <ELoadingButton disabled={!isFormChanged} type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                            Save
                          </ELoadingButton>
                        </EBox>
                      </EBox>
                    </Grid>
                    :
                    <Grid item xs={12}>
                      <Stack direction="row" className='justify-content-flex-end '>
                        <ELoadingButton type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                          Save & Next
                        </ELoadingButton>

                      </Stack>
                    </Grid>
                }
              </Grid>
            </EBoxPage>

            {/* Unsaved form value before change the route Confirmation Modal */}
            <EModal
              open={showModal}
              headervalue={"Confirmation"}
              parentClassname='delete-confirmation-modal'
            >
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <ETypography className="font-size-18px ">
                    Any unsaved changes will be lost. Would you like to save before leaving the page?
                  </ETypography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                  <EButtonOutlined size="large" variant="outlined" onClick={continueNavigate}>Discard</EButtonOutlined>
                  <ELoadingButton size="large" type="button" variant="contained" onClick={confirmSave} className='button-left-margin'> Save</ELoadingButton>
                </Grid>
              </Grid>
            </EModal>
          </Form>
        </FormikProvider>
      </LocalizationProvider>
      {/* INFO: MODAL to confirm form save */}
      <EModal open={modal?.open} headervalue={modal?.header}>
        {modal?.body}
        <Stack direction='row' spacing={2} paddingTop={2} className='modal1-buttons-stick-bottom'>
          <EButtonOutlined size="large" variant="outlined" onClick={closeModal}>No</EButtonOutlined>
          <ELoadingButton
            size="large"
            type="submit"
            variant="contained"
            onClick={modal?.onClick}
          >
            Yes
          </ELoadingButton>
        </Stack>
      </EModal>
    </EPageWithBreadCrumbs>
  )
}
export default AttendanceSettings
