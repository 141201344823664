
import React,{useState} from 'react';
import {Grid} from '@mui/material';
import { ACTIVE_USER, NIL} from '../../constants';
import { ECard } from '../../BaseComponents/ECard';
import {  ETypographyCardHeading } from '../../BaseComponents/ETypography';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { capitalizeCapitalString } from '../../utils/formatText';
import { EIconButton } from '../../BaseComponents/EButtons';
import { EIcon } from '../../BaseComponents/EIcon';
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import EModal from '../../BaseComponents/EModal';
import AddNewUserForm from '../userManagement/AddNewUserForm';

/**
 * [2022-11-24]
 * Created by:- Aanchal Sahu
 * Description:- designed this component according to view profile
 **/
function TalentUserLoginView(props) {
    const { employee, auth, allowEdit  } = props;

 
    
    const [editForm,setEditForm] = useState(false)
    return (
        <>
       
              <>
              {employee?.is_active!='0' &&  
            <ECard sx={{ padding: '20px', height: 'auto', textAlign: 'left', mx: "auto" }}>
                <Grid container spacing={2}  >
                    <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ p: 0 }}>

                        <Grid container display="row" justifyContent="space-between" className='pb-2rem ' alignItems='center'>
                            <Grid item>
                                <ETypographyCardHeading variant='h5'>Login Details</ETypographyCardHeading>
                            </Grid>
                            <Grid item >
                                {/* {employee?.status == ACTIVE_USER && !employee?.latestPayroll && !isMyprofile ? */}
                                {
                                    allowEdit &&
                                    <EIconButton disabled={employee.is_active > 0} onClick={()=>setEditForm(!editForm)} varient='link' sx={{ p: 0, m: 0, textAlign: 'right', color: 'dark.0' }}>
                                        <EIcon icon={EditIcon} width={23} height={23} />
                                    </EIconButton>
                                }
                                    {/* : <></> */}
                                {/* } */}
                            </Grid>
                            <Grid xs={12} lg={12} item>
                                <EDividerCard className='margin-top-05' />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Username"}  isNotForm={true}>
                           {employee?.username ? employee?.username  : NIL}
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Role"}  isNotForm={true}>
                            {employee?.customRole?.role_display_name || capitalizeCapitalString(employee?.role) || "-"}
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Reporting Manager"}  isNotForm={true}>
                            {employee?.reportingManager?.fullname ? employee?.reportingManager?.fullname : NIL}
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ELabelInputMainGrid label={"Status"} isNotForm={true}>
                            {/*Info: Here, we have 3 status i.e. 1=active, 2=disable & 0=who don't have login ids*/}
                            {employee?.is_active==ACTIVE_USER ?'Enable' : "Disable"}
                        </ELabelInputMainGrid>
                    </Grid>
                </Grid>
            </ECard>
}
            </>

            {
                editForm && <EModal open={editForm} close={() => setEditForm(false)} headervalue="Allocate Credentials" >
                    <AddNewUserForm auth={auth} close={() => setEditForm(false)} isEdit={employee}/>
                </EModal>
            }
        

        </>
    );
}

export default TalentUserLoginView
