import React, { useState, useRef } from "react";
import {
  Grid,
  Stack,
  styled,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { EFieldLabelSemiBold, ETypography} from "../../BaseComponents/ETypography";
import { ETextField, ETextFieldLogin } from "../../BaseComponents/ETextField";
// import { EButton } from "../BaseComponents/EButtons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { AuthEmailSent } from "../layouts/icons/auth-email-sent";
// import { AuthEmailInputIcon } from "../layouts/icons/auth-email-input-icon";

// import { AuthKeyIcon } from "../layouts/icons/auth-key-icon";
// import { AuthLockIcon } from "../layouts/icons/auth-lock-icon";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
// import { ResetPassAction } from "../../action/AuthAction";

import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { ELoadingButton } from "../../BaseComponents/EButtons";
import { companySignup } from "../../utils/validation";
import { createCompany } from "../../action/OnBoardingAction";
import { ECheckbox } from "../../BaseComponents/ECheckbox";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constants";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { ELinkStaticBlue } from "../../BaseComponents/ELink";

// import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";

const AuthGrid = styled(Grid)(({ theme }) => ({
  textAlign: "center",
}));

const defaultTitle = [
  { key: "1", label: "Founder/CEO" },
  { key: "2", label: "Co-Founder" },
  { key: "3", label: "HR Manager" },
  { key: "4", label: "Employee" },
];



const AuthSignUp = (props) => {
  const { planId,auth } = props;
  // const params = useParams();//INFO: commenting this since it's of no use as of now
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onBoardingData = useSelector(state => state.OnBoardingReducer);


  const [showPassword, setShowPassword] = useState(false);


  // Handling tooltip on password field to show information
  const [isPwdClicked, setIsPwdClicked] = useState(false);
  const handleTooltipClose = () => {
    setIsPwdClicked(false);
  };

  const handleTooltipOpen = () => {
    setIsPwdClicked(true);
  };

  const [loading,setLoading] = useState(false)

  // const [showConPassword, setShowConPassword] = useState(false);

// Handling Captcha loading/executing
const handleLoaded = (data )=> {
  setLoading(!loading)
  window.grecaptcha.ready(_ => {
    try{
      window.grecaptcha
      .execute(GOOGLE_RECAPTCHA_SITE_KEY, { action: "homepage" })
      .then(token => {
        data = {...data,captcha_token:token}
        dispatch(createCompany(data, navigate))
        setLoading(false)
      })
    }catch(error){
      console.log("error ", error)
    }
     
  })
}
  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      plan_id: planId,
      fullname: "",
      username: "",
      company_name: "",
      email: "",
      mobile: "",
      password: "",
      user_title: null,
      tncCheck: false,
      login_status: auth?.authData?.login_status,
    },
    validationSchema: companySignup(auth?.authtoken, auth?.authData?.id),
    onSubmit: (data) => {
      data = {
        ...data,
        user_title: data?.user_title ? data?.user_title.key : null,
      };
      handleLoaded(data)
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

    const clickAwayRef = useRef(null);


  return (
    <>
    <FormikProvider autoComplete="off" value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <AuthGrid>
            <ETypography
              variant="h3"
              mb={1}
              className="bold-400"
              sx={{ fontSize: "35px !important" }}
            >
              Welcome to Entera
            </ETypography>
          </AuthGrid>

          {/* Full Name */}
          <Grid>
            <EFieldLabelSemiBold>Full Name</EFieldLabelSemiBold>
            <ETextFieldLogin
              fullWidth
              type="text"
              placeholder="Full Name"
              {...getFieldProps("fullname")}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
              className="signup-form-input"
            />
          </Grid>

          {/* Company Name */}
          <Grid>
            <EFieldLabelSemiBold>Company Name</EFieldLabelSemiBold>
            <ETextFieldLogin
              autoComplete="off"
              fullWidth
              type="text"
              placeholder="Company Name"
              {...getFieldProps("company_name")}
              error={Boolean(touched.company_name && errors.company_name)}
              helperText={touched.company_name && errors.company_name}
              className="signup-form-input"
            />
          </Grid>

          {/* Email ID */}
          <Grid>
            <EFieldLabelSemiBold>Email ID</EFieldLabelSemiBold>
            <ETextFieldLogin
              className="signup-form-input"
              fullWidth
              autoComplete="off"
              type="text"
              placeholder="Email ID"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>

           {/* user Name */}
           <Grid>
            <EFieldLabelSemiBold>Username</EFieldLabelSemiBold>
            <ETextFieldLogin
              autoComplete="off"
              fullWidth
              type="text"
              placeholder="Username"
              {...getFieldProps("username")}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              className="signup-form-input"
            />
          </Grid>


          {/* Contact Number */}
          <Grid>
            <EFieldLabelSemiBold>Contact Number</EFieldLabelSemiBold>
            <ETextFieldLogin
              fullWidth
              autoComplete="off"
              type="text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength: 10 }}
              placeholder="Contact Number"
              {...getFieldProps("mobile")}
              error={Boolean(touched.mobile && errors.mobile)}
              helperText={touched.mobile && errors.mobile}
              className="signup-form-input"
            />
          </Grid>

          {/* Password */}
          <Grid 
          onMouseOver={() => setIsPwdClicked(true)}
          onMouseLeave={() => setIsPwdClicked(false)}
          > 
           <EFieldLabelSemiBold>Password</EFieldLabelSemiBold>
            {/* <ClickAwayListener ref={clickAwayRef} onClickAway={()=>handleTooltipClose}>  */}
            <ETooltip 
             onClose={()=>handleTooltipClose}
             open={isPwdClicked}
             arrow placement="right-start" signup="true"  title={
               <ul className="p-16px">
                <ETypography  className="font-size-16px bold-500">
               Password must:
                </ETypography> 
                <p className="pl-16px signup-info-list">
                 <li>Be a minimum of 8 characters</li>
                 <li>Include at least one lowercase letter (a-z)</li>
                 <li>Include at least one uppercase letter (A-Z)</li>
                 <li>Include at least one number (0-9)</li>
                 <li>Include at least one symbol (!@#$%^&*_=+-)</li>

                </p>

                
             </ul>
              }>
            <ETextFieldLogin
              fullWidth
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              {...getFieldProps("password")}
              onClick={handleTooltipOpen}  
             
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              className="signup-form-input"
            />
            </ETooltip>
            {/* </ClickAwayListener>   */}
          </Grid>

          {/* Title */}
          <Grid >
            <EFieldLabelSemiBold>Title</EFieldLabelSemiBold>
            <EAutocomplete
              name="user_title"
              fullWidth
              autoComplete={true}
              options={defaultTitle}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.label || ""}
              {...getFieldProps("user_title")}
              onChange={(e, value) => {
                setFieldValue("user_title", value);
              }}
              renderInput={(params) => (
                <ETextFieldLogin
                  {...params}
                  name="user_title"
                  type="text"
                  placeholder="Title in the Organization"
                  fullWidth
                  className="signup-form-input"
                  error={Boolean(touched.user_title && errors.user_title)}
                  helperText={touched.user_title && errors.user_title}
                />
              )}
            />
           
          </Grid>

            {/* Terms and Condition  */}
            <Grid>
              <ECheckbox
                className="signup-form-checkbox"
                name="tncCheck"
                {...getFieldProps("tncCheck")}
                disabled={Boolean(formik?.values?.notification_is_default)}
                onChange={(e, value) => {
                  setFieldValue("tncCheck", value);
                }}
              />
              <span className="font-size-12px" >
                I agree to the{" "}
                <Link className="link-color" to="/terms-and-condition">
                  Terms & Conditions
                </Link>{" "}
                and{" "}
                <Link className="link-color" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </span>
              {touched.tncCheck && <FormHelperText sx={{ marginTop: '0px !important', color: '#D14343' }}> {errors.tncCheck}</FormHelperText>}
            </Grid>
          
          
          <ELoadingButton
            fullWidth
            size="large"
            type="submit"
            loading={onBoardingData?.onBoardLoading}
            className="borderRadius-4px font-size-16px bold-700"
          >
            Sign Up
          </ELoadingButton>
        </Stack>

          <ETypography className="font-size-16px bold-400 text-align mt-16px" >
            Already have an account?{" "}
            <ELinkStaticBlue
              to="/login"
              underline="none"
            >
              {"Login"}
            </ELinkStaticBlue>
          </ETypography>
      </Form>
    </FormikProvider>

    {/* captcha */}
     <div
     className="g-recaptcha"
     data-sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
     data-size="invisible"
     data_callback="onSubmit"
     data-action='submit'
   ></div>
   </>
  );
};

export default AuthSignUp;
