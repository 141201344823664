import _ from 'lodash';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { THEMES } from '../constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

const baseOptions = {
  breakpoints: {
    values: {//The following are new break points according to mui v5
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920, //Info: added this due to hr dashboard xxl screen
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        sizeSmall: {
          padding: '6px 16px'
        },
        sizeMedium: {
          padding: '8px 20px'
        },
        sizeLarge: {
          padding: '11px 24px'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px'
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        },
        subheaderTypographyProps: {
          variant: 'body2'
        }
      },
      styleOverrides: {
        root: {
          padding: '32px 24px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#E6E8F0'
        },
        multiline: {
         display:'grid',
         alignItems:'center',
         height:'100%'
      }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#F3F4F6',
          '.MuiTableCell-root': {
            color: '#374151'
          },
          borderBottom: 'none',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            fontSize: '0.75rem',//'12px',
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: 'uppercase'
          },
          '& .MuiTableCell-paddingCheckbox': {
            paddingTop: 4,
            paddingBottom: 4
          }
        }
      }
    }
  },
  shape: {
    borderRadius: 8
  },
  typography,
};

const themesOptions = (theme) => {
  return {
    mode:theme === THEMES.LIGHT ?  'LIGHT' : 'DARK',
    palette: {
      neutral: {
        100: '#F3F4F6',
        200: '#E5E7EB',
        300: '#D1D5DB',
        400: '#9CA3AF',
        500: '#6B7280',
        600: '#4B5563',
        700: '#374151',
        800: '#1F2937',
        900: '#787878',
        1000: '#E9E8E6',
        grayColor1: '#7F7F7F',
        grayColor2: '#BFBFBF',
        // yellowColor1: '#F6EDE1', //INFO: commenting this since yellowColor1 is again declared below by @Dharam Sir, Please check.
        yellowColor2: '#E7B77D',
        whiteColor1: '#FFFFFF',
        greenColor1: '#078C03',
        greenColor2: '#C8DBBE',
        yellowColor1: '#F2E7AE',
      },
      action: {
        active: '#6B7280',
        focus: 'rgba(55, 65, 81, 0.12)',
        hover: 'rgba(55, 65, 81, 0.04)',
        selected: 'rgba(55, 65, 81, 0.08)',
        disabledBackground: 'rgba(55, 65, 81, 0.12)',
        disabled: 'rgba(55, 65, 81, 0.26)',
      }, 
      link:{
        active:' #0B8AD9',
        hrLink : theme === THEMES.LIGHT ?  '#2987C1' : '#D97904',
      },
      background: {
        default: theme === THEMES.LIGHT ?  '#F3F2EF' : '#262626',
        default2: theme === THEMES.LIGHT ?  '#FBFBFB' : '#262626',
        authDefault:'#F6F8FC',
        signupTooltip:'#FCFBF7',
        paper: theme === THEMES.LIGHT ?  '#FFFFFF' :  '#333333',
        tableHeader: theme === THEMES.LIGHT ?  '#DEDDDC' : 'rgba(100, 100, 100, 1)',
        modalbg: theme === THEMES.LIGHT ?  '#FBFBFB':'#333333',
        textFieldBg: theme === THEMES.LIGHT ?  '#F1F1F1':'#474747',
        textFieldBg2: theme === THEMES.LIGHT ?  '#FFFFFF':'#474747',
        textfieldDisabled:'rgba(179, 179, 179, 0.05)',
        activeAccordian: theme === THEMES.LIGHT ?  'rgba(217, 217, 217, 0.5)':'rgba(217, 217, 217, 0.2)',
        activeAccordianButton: theme === THEMES.LIGHT ?  '#403F3F':'rgba(217, 217, 217, 0.6)',
        card: theme === THEMES.LIGHT ?  '#FBFBFB' : '#333333 ',
        card2: theme === THEMES.LIGHT ?  'rgba(245, 245, 245, 0.6)' : '#5C5C5C ',
        card3: theme === THEMES.LIGHT ?  '#F3F2EF' : '#414141',
        card4: theme === THEMES.LIGHT ?  '#EAEAEA' : '#3F3F3F',
        card5: theme === THEMES.LIGHT ?  '#F3F3F3' : '#3F3F3F',
        card6: theme === THEMES.LIGHT ?  '#F1F1F1' : '#3F3F3F',
        card7: theme === THEMES.LIGHT ?  '#EEEEEE' : '#3F3F3F',
        card8: theme === THEMES.LIGHT ?  '#EBEBEB' : '#3F3F3F',
        drawerBg: theme === THEMES.LIGHT ?  '#F3F3F3':'#333333',
        buttonDisable:'rgba(166, 166, 166, 0.5)',
        dullLightOrange:'#EDD38E',
        stickyTableHead: theme === THEMES.LIGHT ?  '#D0D0D0':'#343434',
        stickyTableBorder: theme === THEMES.LIGHT ?  '#000000':'rgba(217, 217, 217, 0.5)',
        NavbarBorder: theme === THEMES.LIGHT ?  '#B2AAAA':'#474747',
        // button1: theme === THEMES.LIGHT ? '#F5F5F5':'#737271',
        button1: theme === THEMES.LIGHT ? '#F5F5F5':'#737271',
        subMenuBg:'#FDC582',
        bgColorLight1:'rgba(217, 217, 217, 0.2)',
        lightGreen:'#73A605',
        grayVariant1: theme === THEMES.LIGHT ? '#F1F1F1':'rgba(245, 245, 245, 0.4)',
        darkBlue:theme === THEMES.LIGHT ?'#2E40C9':'#303A8D',
        darkBlueStatic:'#2E40C9',
        darkGrey:theme === THEMES.LIGHT ?'#323E4A':'#323940',
        bg1: theme === THEMES.LIGHT ?  '#F3F3F3':'#F1F1F1',
        bg2:'#F1F1F1',
        birthdayBg: theme === THEMES.LIGHT ? '#FCF0F0' : '#FCF0F0',
        staticGreyHR: theme === THEMES.LIGHT ? '#D9D9D9' : '#474747', 
        // Colors used in Attendance statistics
        darkOrangeBrown: '#A6775B',
        lightBrownYellow: '#D9B88F',
        midnightBlue : '#2C4A73',
        oceanBlue : '#049DBF',
        offWhite : '#EEEEEE',
        // onoarding 
        Champagne: '#FFF4E8'
      },
      divider: theme === THEMES.LIGHT ?  '#403F3F' : '#F5F5F5 ',
      tooltip: {
        width: "92px",
        height: "36px",
        borderRadius: "18px",
        boxShadow: "0 20px 80px 0",
        backgroundColor: "red"
    },
      primary: {                           
        main: '#D97904',  //will be used for orange i.e our theme color
        light: '#F0A107',
        dark: '#F06507',
        mainLight:'#E8B04B',
        contrastText: '#FFFFFF',
        lightest:'rgba(217, 121, 4, 0.5)',
        lightest2:theme === THEMES.LIGHT ? '#FCF2E6':'rgba(217, 121, 4, 0.1)',
      },
      secondary: {
        main: '#10B981',
        light: '#3FC79A',
        dark: '#0B815A',
        contrastText: '#FFFFFF'
      },
      success: {
        main: '#14B8A6',
        light: '#43C6B7',
        dark: '#0E8074',
        contrastText: '#FFFFFF',
        green:'#078C03',
        greenLight:'#DFEDD1',
        darkGreen: theme === THEMES.LIGHT ? '#186C55':'#07926C',
      },
      info: {
        main: '#2196F3',
        light: '#64B6F7',
        dark: '#0B79D0',
        contrastText: '#FFFFFF',
        darkGrey:theme === THEMES.LIGHT ?'#323E4A':'#323940',
        blue:'#14A1D9',
        blue2:'#14A1D9',
        blue3:'#0B8AD9'
      },
      warning: {
        main: '#FFB020',
        light: '#FFBF4C',
        dark: '#B27B16',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#D14343',
        light: '#DA6868',
        dark: '#922E2E',
        contrastText: '#FFFFFF',
        red:'#D2000B',
        redLight:'#EFD0D2',
      },
      text: {
        primary: theme === THEMES.LIGHT ?  '#403F3F':'#F5F5F5',
        staticPrimaryLight:'#F5F5F5',
        primaryOpposite: theme === THEMES.LIGHT ?  '#F5F5F5':'#403F3F',
        secondary: '#65748B',
        disabled: theme === THEMES.LIGHT ?  'rgba(55, 65, 81, 0.48)' : '#F5F5F5',
        button: theme === THEMES.LIGHT ?  '#403F3F' : '#F5F5F5 ',
        default: '#403F3F',
        subtitle2: theme === THEMES.LIGHT ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
        disabledBottonText:'#8E9197',
        button1:theme === THEMES.LIGHT ? '#000000':'#F5F5F5',
        heading:theme === THEMES.LIGHT ? '#000000':'#F5F5F5',
        sideBar:'#FBFBFB',
        redColor:'#D2000B',
        blueColor:'#2987C1',
        primaryStaticLight:'#F5F5F5',
        primaryStaticLight2:'rgba(64, 63, 63, 0.2)',
        greyColor:'#6F6F6F',
        greyDisabledColor:theme === THEMES.LIGHT ?'#6F6F6F':'#EFD0D2',
        text1:theme === THEMES.LIGHT ?'rgba(64, 63, 63, 0.4)':'rgba(245, 245, 245, 0.4)',
        staticBlue:'#0D99FF',
        staticBlue2:'#E7B77D',
        staticBlue3: '#0B8AD9',
        staticGrey:'#D9D9D9',
        staticLightGrey:'#D1D1D1',
        staticLightGrey2:'#768FA6',
        staticOrange:'#E7B77D',
        staticGrey2:'#DDDDDD',
        greyColor2:theme === THEMES.LIGHT ?'#BAB9B8':'#BAB9B8',
        description:theme === THEMES.LIGHT ? "rgba(127, 127, 127, 1)" : 'rgba(195, 195, 195, 1)',  
        staticGrey3:'#737271',
        // greyColor2:theme === THEMES.LIGHT ?'#BAB9B8':'#BAB9B8',
        greyColor3:theme === THEMES.LIGHT ?'#808080':'#616161',
        greyColor4:theme === THEMES.LIGHT ?'#808080':'#ACACAC',
        greyColor5:'#808080',
        greyColor6:theme === THEMES.LIGHT ?'#808080':'#C0C0C0',
        greyColor7:theme === THEMES.LIGHT ?'#808080':'#CECECE',
        greyColor8:theme === THEMES.LIGHT ?'#808080':'#A7A7A7',
        blackGrey: theme === THEMES.LIGHT ?'#403F3F':'#F5F5F5',
        greyColor9: theme === THEMES.LIGHT ?'#403F3F':'#CCCCCC',
        greyColor10:theme === THEMES.LIGHT ?'#ACACAC':'#808080',
        greyColor11: theme === THEMES.LIGHT ? '#C3C3C3' : '#ACACAC',

      },
      input:{
        primary: theme === THEMES.LIGHT ?  'rgba(0, 0, 0, 0.23)':'#474747',
        inputIcon:theme === THEMES.LIGHT ? ' rgba(64, 63, 63, 0.5)': 'rgba(245, 245, 245, 0.5)',
        border1: theme === THEMES.LIGHT ?'rgba(64, 63, 63, 0.3)':'rgba(245, 245, 245, 0.4)',
        Disabled:'#8C8B8B',
        Disabled2:THEMES.LIGHT ? '#ABAAAA':'#8C8B8B',
      },
      chip:{
        successbg:theme === THEMES.LIGHT ? '#DFEDD1':'#078C03',//green
        successText:theme === THEMES.LIGHT ? '#078C03':'#DFEDD1',
        secondarybg:theme === THEMES.LIGHT ? 'rgba(166, 166, 166, 0.5)':'rgba(166, 166, 166, 0.5)',//grey
        secondaryText:theme === THEMES.LIGHT ? '#6F6F6F':'#F5F5F5',
        errorbg:theme === THEMES.LIGHT ? '#EFD0D2':'#D2000B',
        errorText:theme === THEMES.LIGHT ? '#D2000B':'#F5F5F5',
        warningbg:THEMES.LIGHT ? '#F2E7AE':'#D97904',
        warningText:THEMES.LIGHT ? '#D97904':'#F5F5F5',
        infobg:theme === THEMES.LIGHT ? 'rgba(0, 249, 255, 0.14)':'#2987C1',
        infoText:theme === THEMES.LIGHT ? '#2987C1':'#F5F5F5',
        orangebg:theme === THEMES.LIGHT ? '#F2E7AE':'#D97904',
        orangeText:theme === THEMES.LIGHT ? '#D97904':'#F5F5F5',

        aGreenBg:theme === THEMES.LIGHT ? 'rgba(7, 140, 3, 0.2)':'#C8DBBE',
        aGreenBgOnboarding:theme == THEMES.LIGHT ? '#00BF8A':'#C8DBBE',
        aGreenText:theme === THEMES.LIGHT ? '#078C03':'#404040',
        aBlueBg:theme === THEMES.LIGHT ? 'rgba(13, 153, 255, 0.1)':'#A9BED9',
        aBlueText:theme === THEMES.LIGHT ? '#0D99FF':'#404040',
        aRedBg:theme === THEMES.LIGHT ? 'rgba(255, 0, 0, 0.1)':'#E9A6A6',
        aRedText:theme === THEMES.LIGHT ? '#FF0000':'#404040',
        aPinkBg:theme === THEMES.LIGHT ? 'rgba(245, 53, 170, 0.3)':'#04BFBF',
        aPinkText:theme === THEMES.LIGHT ? '#F535AA':'#404040',
        aYellowBg:theme === THEMES.LIGHT ? 'rgba(217, 121, 4, 0.5)':'#F2D272',
        aYellowText:theme === THEMES.LIGHT ? '#B3670C':'#404040',
      },
      border:{
        light:'#9C9B9A',
        paper1:theme === THEMES.LIGHT ? '#BFBFBF':'#333333',
        divider1:theme === THEMES.LIGHT ? '#807C7C':'#F5F5F5',
        divider2:theme === THEMES.LIGHT ? '#B2AAAA':'#B2AAAA',
        divider3:theme === THEMES.LIGHT ? '#D4D4D4':'#D4D4D4',
        border1:'#B9B9B9',
        border2:theme === THEMES.LIGHT ? '#C3C3C3':'#C3C3C3',
        border3:theme === THEMES.LIGHT ? 'transparent':'#7F7F7F',
        border4:theme === THEMES.LIGHT ?  'rgba(114, 115, 111,# 0.2)' : "rgba(166, 166, 166, 0.5)",
        border5:theme === THEMES.LIGHT ?  "#403F3F":'rgba(114, 115, 111, 0.2)',
        border6:theme === THEMES.LIGHT ?  "#D0D0D0":'#333333',
        border7:theme === THEMES.LIGHT ? '#BFBFBF':'#C3C3C3',
        border8:'#E8AF68',
        staticBorder7:"#D0D0D0",
        birthdayBorder: theme === THEMES.LIGHT ? '#C17676':'#C17676',
      },
      table:{
        disabledRowBg:theme === THEMES.LIGHT ? '#E3E3E3' :'#3F3F3F',
        emptyCellBg : theme === THEMES.LIGHT ? '#EDD38E' : 'rgba(237, 211, 142, 0.3)'
      }
    },
    shadows: theme === THEMES.LIGHT ?  softShadows : strongShadows,
    customShadows:{
        shadow1:theme === THEMES.LIGHT ?  
        ' 0px 0px 10px rgba(228, 216, 216, 0.02), 0px 0px 9px rgba(228, 216, 216, 0.13), 0px 0px 8px rgba(228, 216, 216, 0.45), 0px 0px 6px rgba(228, 216, 216, 0.77), 0px 0px 3px rgba(228, 216, 216, 0.88), 0px 0px 0px rgba(228, 216, 216, 0.9)' :
         '0px 0px 0px 0px',
         shadowSideNavbar:theme === THEMES.LIGHT ?  
         '24px 0px 10px rgba(16, 58, 50, 0.01), 13px 0px 8px rgba(16, 58, 50, 0.03), 6px 0px 6px rgba(16, 58, 50, 0.06), 1px 0px 3px rgba(16, 58, 50, 0.07), 0px 0px 0px rgba(16, 58, 50, 0.07) '
         :'24px 0px 10px rgba(16, 58, 50, 0.01), 13px 0px 8px rgba(16, 58, 50, 0.03), 6px 0px 6px rgba(16, 58, 50, 0.06), 1px 0px 3px rgba(16, 58, 50, 0.07), 0px 0px 0px rgba(16, 58, 50, 0.07)',
          loginShadow:' 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)',
        modalShadow:' 0px 17px 7px rgba(0, 0, 0, 0.01), 0px 10px 6px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)',
        },
    images: {
      loginImage1: theme === THEMES.LIGHT ?  '/assets/images/login-image-light.svg' : '/assets/images/login-image-dark.svg',
    }    
  }
};

export const createMuiTheme = (config = {}) => {

  let themeOptions = themesOptions(config.theme);
  // let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}



// const themesOptions = [
//   {
//     name: THEMES.LIGHT,
//     palette: {
//       neutral: {
//         100: '#F3F4F6',
//         200: '#E5E7EB',
//         300: '#D1D5DB',
//         400: '#9CA3AF',
//         500: '#6B7280',
//         600: '#4B5563',
//         700: '#374151',
//         800: '#1F2937',
//         900: '#111827'
//       },
//       action: {
//         active: '#6B7280',
//         focus: 'rgba(55, 65, 81, 0.12)',
//         hover: 'rgba(55, 65, 81, 0.04)',
//         selected: 'rgba(55, 65, 81, 0.08)',
//         disabledBackground: 'rgba(55, 65, 81, 0.12)',
//         disabled: 'rgba(55, 65, 81, 0.26)'
//       },
//       background: {
//         default: '#F9FAFC',
//         paper: '#FFFFFF'
//       },
//       divider: '#E6E8F0',
//       primary: {
//         main: '#5048E5',
//         light: '#828DF8',
//         dark: '#3832A0',
//         contrastText: '#FFFFFF'
//       },
//       secondary: {
//         main: '#10B981',
//         light: '#3FC79A',
//         dark: '#0B815A',
//         contrastText: '#FFFFFF'
//       },
//       success: {
//         main: '#14B8A6',
//         light: '#43C6B7',
//         dark: '#0E8074',
//         contrastText: '#FFFFFF'
//       },
//       info: {
//         main: '#2196F3',
//         light: '#64B6F7',
//         dark: '#0B79D0',
//         contrastText: '#FFFFFF'
//       },
//       warning: {
//         main: '#FFB020',
//         light: '#FFBF4C',
//         dark: '#B27B16',
//         contrastText: '#FFFFFF'
//       },
//       error: {
//         main: '#D14343',
//         light: '#DA6868',
//         dark: '#922E2E',
//         contrastText: '#FFFFFF'
//       },
//       text: {
//         primary: '#121828',
//         secondary: '#65748B',
//         disabled: 'rgba(55, 65, 81, 0.48)'
//       }
//     },
//     shadows: softShadows
//   },
//   {
//     name: THEMES.DARK,
//     palette: {
//       neutral: {
//         100: '#F3F4F6',
//         200: '#E5E7EB',
//         300: '#D1D5DB',
//         400: '#9CA3AF',
//         500: '#6B7280',
//         600: '#4B5563',
//         700: '#374151',
//         800: '#1F2937',
//         900: '#111827'
//       },
//       action: {
//         active: '#6B7280',
//         focus: 'rgba(55, 65, 81, 0.12)',
//         hover: 'rgba(55, 65, 81, 0.04)',
//         selected: 'rgba(55, 65, 81, 0.08)',
//         disabledBackground: 'rgba(55, 65, 81, 0.12)',
//         disabled: 'rgba(55, 65, 81, 0.26)'
//       },
//       background: {
//         default: '#F9FAFC',
//         paper: '#FFFFFF'
//       },
//       divider: '#E6E8F0',
//       primary: {
//         main: '#5048E5',
//         light: '#828DF8',
//         dark: '#3832A0',
//         contrastText: '#FFFFFF'
//       },
//       secondary: {
//         main: '#10B981',
//         light: '#3FC79A',
//         dark: '#0B815A',
//         contrastText: '#FFFFFF'
//       },
//       success: {
//         main: '#14B8A6',
//         light: '#43C6B7',
//         dark: '#0E8074',
//         contrastText: '#FFFFFF'
//       },
//       info: {
//         main: '#2196F3',
//         light: '#64B6F7',
//         dark: '#0B79D0',
//         contrastText: '#FFFFFF'
//       },
//       warning: {
//         main: '#FFB020',
//         light: '#FFBF4C',
//         dark: '#B27B16',
//         contrastText: '#FFFFFF'
//       },
//       error: {
//         main: '#D14343',
//         light: '#DA6868',
//         dark: '#922E2E',
//         contrastText: '#FFFFFF'
//       },
//       text: {
//         primary: '#121828',
//         secondary: '#65748B',
//         disabled: 'rgba(55, 65, 81, 0.48)'
//       }
//     },
//     shadows: strongShadows
//   }
// ];
