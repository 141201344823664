import {useEffect, useState} from'react';
import { Grid, InputAdornment } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { ETypography, ETypographyCardHeading } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import moment from "moment";
import DepartmentForm from "../../PageComponents/Masters/DepartmentForm";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { Field } from "formik";
import { MOBILE_NUMBER_LIMIT, WORK_MODE } from "../../constants";
import { EBox } from "../../BaseComponents/EBox";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { onKeyDown } from "../../utils/CommonFunctions";
import DesignationForm from '../Masters/DesignationForm';
import { useSelector } from 'react-redux';
import ECreatableAutoComplete from '../../BaseComponents/ECreatableAutoComplete';

const AddFormBasicDetail = (props) => {

    const { formikTalents, departmentList, designationList, PayrollData, shiftList, isShiftAllowed } = props;
    const auth = useSelector((state) => state.authReducer);
    const department = useSelector(state => state.DepartmentReducer);
    const designation = useSelector(state => state.DesignationReducer)
    const [addDepartment,setAddDepartment] = useState('')
    const [addDesignation,setAddDesignation] = useState('')



    const [openDepartmentForm, setOpenDepartmentForm] = useState(false);
    const [openDesignationForm, setOpenDesignationForm] = useState(false);
  
    const handleClose = () => { 
        if(openDepartmentForm){
            setOpenDepartmentForm(false)
        }else{
            setOpenDesignationForm(false)  
        }
    };
  

    // CODE IS COMMENTED IS FOR EXPERIMENTAL
    // BY DHARAM 
    // const minDojValidation = companyData?.planFromOrlastPayrollDate ? moment(companyData.planFromOrlastPayrollDate).add(1, 'day').toDate() : new Date();

    // Info: As per discussion with @Dharam Sir & @sunil Sir on 16th May 23, The only validation in the DOJ will be the last Payroll locked date (to_date+1) else it will be open.
    const minDojValidation= PayrollData?.lastPayroll?.from_date? moment(PayrollData?.lastPayroll?.to_date).add(1, 'day').toDate(): null;

    const { errors, touched, getFieldProps, setFieldValue,initialValues } = formikTalents;

    useEffect(()=>{
        if(formikTalents?.values?.new_department){
            // if departmentList has elements, Set the value of 'department_object' field to the first element of departmentList
            departmentList?.length > 0 && setFieldValue('department_object',departmentList[0])
         }
         
         if(formikTalents?.values?.new_designation){
             // if designationList has elements, Set the value of 'designation_object' field to the first element of departmentList
             designationList?.length > 0 && setFieldValue('designation_object',designationList[0])
         }

      },[formikTalents?.values?.new_department, department , formikTalents?.values?.new_designation, designation ])


    return (

        <ECard className='card_design_1 pb-8px'>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="mb-8px"
            >
                <ETypographyCardHeading variant='h5' className="font-size-20px">
                    Basic Details *
                </ETypographyCardHeading>
            </Grid>

            <EDivider />

            <EBox >

                <Grid container py={2} spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ELabelInputMainGrid label={'First Name'} >
                            <ETextField
                                fullWidth
                                {...getFieldProps('fname')}
                                disabled={initialValues?.fname ? true : false}  //disabling the field if intialValue is not empty (Add HR Profile)
                                error={Boolean(touched.fname && errors.fname)}
                                helperText={touched.fname && errors.fname}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ELabelInputMainGrid label={'Last Name'} >
                            <ETextField
                                fullWidth
                                {...getFieldProps('lname')}  
                                disabled={initialValues?.lname ? true : false}   //disabling the field if intialValue is not empty (Add HR Profile)
                                error={Boolean(touched.lname && errors.lname)}
                                helperText={touched.lname && errors.lname}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ELabelInputMainGrid label={'Email Id'} >
                            <ETextField
                                fullWidth
                                {...getFieldProps('email')}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ELabelInputMainGrid label={'Contact Number'} >
                            <ETextField
                                fullWidth
                                {...getFieldProps('mobile')}
                                error={Boolean(touched.mobile && errors.mobile)}
                                helperText={touched.mobile && errors.mobile}
                                inputProps={{ maxLength: MOBILE_NUMBER_LIMIT }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <span className="color-text-primary">+91</span>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <ETypography variant='h5' className="font-size-20px mb-8px"> Official Details * </ETypography>
                        <EDivider />
                    </Grid>

                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Date of Joining'} >
                            <EDatePicker
                                views={["day", 'month', 'year']}
                                name="doj"
                                {...getFieldProps('doj')}
                                minDate={minDojValidation}
                                maxDate={new Date()}
                                inputFormat="dd/MM/yyyy"
                                onChange={(selectedDate) => {
                                    setFieldValue("doj", selectedDate)
                                }}
                                renderInput={(params) =>
                                    <Field
                                        readOnly
                                        component={ETextField}
                                        onKeyDown={onKeyDown}  
                                        {...params} fullWidth name="doj"
                                        error={Boolean(touched.doj && errors.doj)}
                                        helperText={touched.doj && errors.doj}
                                    />}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Department'} >
                        <ECreatableAutoComplete 
                            modalHeader={'Add Department'} 
                            options={departmentList && departmentList?.length > 0 ? departmentList : []} 
                            value={addDepartment}
                            setValue={setAddDepartment}
                            open={openDepartmentForm}
                            toggleOpen={setOpenDepartmentForm}
                            placeholder="Select Department"
                            {...getFieldProps('department_object')}
                            FieldName="department_object"
                            setFieldValue={setFieldValue}
                            error={Boolean(touched.department_object && errors.department_object)}
                            helperText={touched.department_object && errors.department_object}
                            form={
                            <DepartmentForm 
                            close={() => handleClose()}
                            buttonTitle={true}
                            dept={departmentList}
                            formikTalents={formikTalents}
                            auth={auth}/>}
                             />
                     
                      

                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Salary'} small_text={'(CTC/Month)'} >
                            <ETextField
                                fullWidth
                                placeholder='Salary'
                                {...getFieldProps('payroll_basic')}
                                error={Boolean(touched.payroll_basic && errors.payroll_basic)}
                                helperText={touched.payroll_basic && errors.payroll_basic}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <ELabelInputMainGrid label={'Designation'} >
                            <ECreatableAutoComplete 
                            modalHeader={'Add Designation'} 
                            options={designationList && designationList?.length > 0 ? designationList : []} 
                            value={addDesignation}
                            setValue={setAddDesignation}
                            open={openDesignationForm}
                            {...getFieldProps('designation_object')}
                            FieldName="designation_object"
                            setFieldValue={setFieldValue}
                            toggleOpen={setOpenDesignationForm}
                            error={Boolean(touched.designation_object && errors.designation_object)}
                            helperText={touched.designation_object && errors.designation_object}
                            placeholder="Select Designation"
                            form={
                            <DesignationForm 
                            close={() => handleClose()}
                            formikTalents={formikTalents}
                            buttonTitle={true}
                            designation={designationList}
                            auth={auth}/>}
                             />
                     
                              
                              
                            
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} >
                        <ELabelInputMainGrid label={'Work Mode'} >
                            <EAutocomplete
                                name="work_mode_object"
                                fullWidth
                                options={WORK_MODE}
                                forcePopupIcon={!formikTalents?.values?.work_mode_object ? true : false}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={option => option.label || ""}
                                {...getFieldProps('work_mode_object')}
                                onChange={(e, value) => {
                                    setFieldValue("work_mode_object", value)
                                }}
                                renderInput={params => (
                                    <ETextField
                                        {...params}
                                        name='work_mode_object'
                                        placeholder=' Select Work Mode'
                                        fullWidth
                                        error={Boolean(touched.work_mode_object && errors.work_mode_object)}
                                        helperText={touched.work_mode_object && errors.work_mode_object}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    {
                        isShiftAllowed &&
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Shift Name'} >
                                <EAutocomplete
                                    name="shift_object"
                                    fullWidth
                                    options={shiftList}

                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.shift_name || ""}
                                    {...getFieldProps('shift_object')}
                                    onChange={(e, value) => {
                                        setFieldValue("shift_object", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='shift_object'
                                            placeholder=' Select Shift'
                                            fullWidth
                                            error={Boolean(touched.shift_object && errors.shift_object)}
                                            helperText={touched.shift_object && errors.shift_object}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    }
                </Grid>
            </EBox>
 
        </ECard>

    )

};

export default AddFormBasicDetail;