import React from "react";
import { Stack, Grid } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { EFieldLabel } from "../../BaseComponents/ETypography";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { CheckinCheckoutAttendance } from "../../action/AttendanceAction";
import PropTypes from "prop-types";
import useIp from "../../utils/useIp";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import { WORK_MODE_HOME, WORK_MODE_HYBRID, WORK_MODE_OFFICE } from "../../constants";
import { browserName, isMobile } from 'react-device-detect';

//auth is mandatory in this component
CheckIn.propTypes = {
  auth: PropTypes.object.isRequired,
  sx: PropTypes.object,
  attendanceSettings: PropTypes.object.isRequired
};


const CheckInBox = (props) => {
  const { HandleCheckIn, close } = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={12} lg={12} xl={12} className="mb-16px">
        <EFieldLabel>
          Are you sure you want to{" "}
          <span className="theme-main-text-color-bold font-style-italic">
            check In{" "}
          </span>
          ?{" "}
        </EFieldLabel>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          className="modal1-buttons-stick-bottom"
        >
          <Stack direction={{ xs: "column", sm: "row" }}>
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => {
                close();
              }}
            >
              {" "}
              No{" "}
            </EButtonOutlined>
            <ELoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={HandleCheckIn}
              className="button-left-margin"
            >
              Yes
            </ELoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}



const IpErrorBox = (props) => {
  const { checkIp, close } = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={12} lg={12} xl={12} className="mb-16px">
        <EFieldLabel>
          {checkIp ?
            <>Please Check-in using the company’s network system</> :
            <>The system is currently unable to retrieve the IP address to which you are connected. Please try again later or contact your HR department for assistance.</>}
        </EFieldLabel>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          xl={12}
          className="modal1-buttons-stick-bottom"
        >
          <Stack direction={{ xs: "column", sm: "row" }}>
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => {
                close();
              }}
            >
              {" "}
              Close{" "}
            </EButtonOutlined>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function CheckIn(props) {
  const { close, auth, attendanceSettings } = props;
  const dispatch = useDispatch();
  const { checkIp, ipLoader } = useIp(attendanceSettings.allow_ip_check ? attendanceSettings.ip_address : null);

  const HandleCheckIn = (ip_address = null) => {
    let data = {
      updated_by: auth?.authData?.id,
      employee_id: auth?.authData?.id,
      attendance_date: moment(new Date()).format("YYYY-MM-DD"),
      ip_address: ip_address,
      is_mobile: isMobile,
      browser_name: browserName
    };
    dispatch(CheckinCheckoutAttendance(auth.authtoken, data));
    close();
  };

  /**
   * if company is not enabled the ip setting
   * Then every employee with any mode [Hybrid, Office, Remote] of the company can checkIN
   */
  if (!attendanceSettings.allow_ip_check ||
    auth.authData?.work_mode === parseInt(WORK_MODE_HOME)) {
    return <>
      {!ipLoader ? <>
        <CheckInBox HandleCheckIn={() => HandleCheckIn(checkIp)} close={close} />
      </> : <LoadingGrid />}
    </>
  }


  /**
   * if company is enabled the ip setting then first we need to fetch the ip address of router 
   * For fetchig ip address we are usig thred party API which is mentioned in useIp Hook    * 
   */


  if (attendanceSettings.allow_ip_check &&
    auth.authData?.work_mode !== parseInt(WORK_MODE_HOME)) {
    return (
      <>
        {!ipLoader ? <>
          {(checkIp &&
            (checkIp == attendanceSettings.ip_address ||
              parseInt(WORK_MODE_HYBRID) == auth.authData?.work_mode)) && <>
              <CheckInBox HandleCheckIn={() => HandleCheckIn(checkIp)} close={close} />
            </>}

          {(checkIp &&
            checkIp != attendanceSettings.ip_address &&
            parseInt(WORK_MODE_OFFICE) == auth.authData?.work_mode) && <>
              <IpErrorBox close={close} checkIp={checkIp} />
            </>}

          {(!checkIp) && <>
            <IpErrorBox close={close} checkIp={checkIp} />
          </>}
        </> : <LoadingGrid />}
      </>
    )
  }
}
