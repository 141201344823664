
import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TalentLatestPaymentList } from "../../action/PaymentAction";
import { EButton } from "../../BaseComponents/EButtons";
import EChip from "../../BaseComponents/EChip";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { EIcon } from "../../BaseComponents/EIcon";
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowXSNewBorder } from "../../BaseComponents/ETable";
import { ETypography } from "../../BaseComponents/ETypography";
import { ACCOUNTANT, ACTIVE_USER, COMPLETE_STATUS, DASHBOARD, DEFAULT_ROWS_PERPAGE_4, PAGES, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_SALARY, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, SUPER_ADMIN } from "../../constants";
import { numberWithCommas } from "../../utils/formatNumber";
import RupeesIcon from '@iconify/icons-material-symbols/currency-rupee-rounded'
import { ELinkHRDAshMUI } from "../../BaseComponents/ELink";
import EModal from "../../BaseComponents/EModal";
import PaymentRequestModal from "../Payment/PaymentRequestModal";
import { EmployeeListData } from "../../action/EmployeeAction";
import { useNavigate } from "react-router-dom";
import base64 from 'base-64'
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { EBox } from "../../BaseComponents/EBox";
import { ETooltip } from "../../BaseComponents/ETooltip";

function DashboardPayments(props) {
    const { auth } = props
    const [modalState, setModalState] = useState({ modalData: null, modalName: null });
    const { setting } = useCompanySettingProvider()
    const employee = useSelector((state) => state.EmployeeReducer);
    const company = useSelector(state => state.CompanyReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const payment = useSelector(state => state.PaymentReducer);
    const [isAdminRole, setIsAdminRole] = useState(false)
    const [showAmount,setShowAmount] = useState({
        open: false,
        id:0
    })

    const enable_advance_salary = setting?.paymentSettingData?.companySetting?.enable_advance_salary;
    const enable_reimbursement = setting?.paymentSettingData?.companySetting?.enable_reimbursement;


    // this TalentLatestPaymentList api is calling for list which will only be called in the first time and when the user make a request
    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(TalentLatestPaymentList(auth.authtoken, auth?.authData?.id, "request_self", PAGES, DEFAULT_ROWS_PERPAGE_4));
        }
    }, [auth?.authtoken])

    // this employee list api is calling for payment request form parameter which will only be called once
    useEffect(() => {
        if (auth?.authtoken && (!payment?.PaySalarySuccess)) {
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
        }
        getSettingByRole(auth?.authData?.role)
    }, [auth?.authtoken])

    const getSettingByRole = (role) => {
        let ADMIN_ROLES = [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR]
        let TALENT_ROLES = [ROLE_EMPLOYEE, ACCOUNTANT]
        if (ADMIN_ROLES.includes(role)) {
            return setIsAdminRole(() => true)
        } else if (TALENT_ROLES.includes(role)) {
            return setIsAdminRole(() => false)
        }
        return isAdminRole
    }


    return (
        // <ECardBlank className='height-360px' sx={{background:'none', border:'none'}}>
        <>
            <Grid container spacing={3} className='justify-content-space-between' >

                <Grid item >
                    <ETypography className='font-size-20px bold-600'>My Payments</ETypography>
                </Grid>

                {
                    (isAdminRole || enable_advance_salary || enable_reimbursement) &&
                    <Grid item >
                        <EButton size='small'
                            onClick={() => { setModalState({ modalData: null, modalName: true }) }}>
                            Payment Request
                        </EButton>
                    </Grid>

                }

                <Grid item xs={12} className={payment?.PaySalaryLoading || (!payment || payment?.PaySalaryData?.rows?.length <= 0) ?
                    'justify-content-center align-center display-flex flex-direction-column minHeight-262px pt-0 ' : 'minHeight-262px pt-0 '}>
                    {payment?.PaySalaryLoading ?
                        <>
                            <ECircularProgress color="primary" />
                        </>
                        :
                        payment && payment?.PaySalaryData?.rows?.length > 0 ?

                            <ETableContainer className='dashboard-table-container  p0 overflow-hidden' >
                                <ETable>
                                    <ETableBody>
                                        {payment?.PaySalaryData?.rows?.map((row, index) => {
                                            const { transaction_date,id, transaction_category, pay_amount, transaction_status } = row;
                                            return (
                                                <ETableRowXSNewBorder hover tabIndex={-1} sx={{ margin: '10px 0px', height: '65px' }} key={index}>
                                                    <ETableCell align="left"  sx={{ minWidth: '110px' }} >
                                                  
                                                        {pay_amount ? 
                                                        <EBox className="display-flex" sx={{ minWidth:'70%'}}>
                                                        <EIcon icon={RupeesIcon} />
                                                         
                                                         <EBox marginLeft={2} className="flex-row-allCenter">
                                                         <ETypography
                                                         variant='h6'>
                                                                <ETooltip onClick={() => {
                                                                    setShowAmount({
                                                                        open: id == showAmount.id ? !showAmount.open : true,
                                                                        id: id
                                                                    })
                                                                        }} arrow title={showAmount.open && showAmount.id === id ? 'Click to hide' : 'Click to show'}>
                                                                    <ETypography className='font-size-16px bold-600 cursor-pointer theme-color-static text-decoration-underline'>
                                                                        {showAmount.open && showAmount.id === id  ? numberWithCommas(pay_amount, 'edad') : 'xxx.xx'}
                                                                    </ETypography>

                                                                </ETooltip>

                                                                <span className='small-text-label font-weight-400 color-text-primary text-decoration-none'>
                                                                            {transaction_category && transaction_category == PAYMENT_CATEGORY_ADVANCE
                                                                                ? 'Advance'
                                                                                : transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
                                                                                    ? 'Reimbursement'
                                                                                    : transaction_category == PAYMENT_CATEGORY_OTHER
                                                                                        ? 'Other'
                                                                                        : transaction_category == PAYMENT_CATEGORY_SALARY ? 'Salary' : '-'}
                                                                </span>
                                                            </ETypography>
                                                         </EBox>
                                                            
                                                       </EBox>
                                                            : '-'}
                                                    </ETableCell>
                                                    <ETableCell align="left" sx={{ padding: '10px 15px' }}>
                                                        <ETypography variant='h6' style={{ fontSize: "1rem", lineHeight: '1.4rem' }} color={'info.main'}>

                                                            {moment(transaction_date).format('DD/MM/YYYY')}
                                                        </ETypography>
                                                        <ETypography variant='caption' className='small-text-label'>
                                                            {transaction_status == 'CANCELLED' ? "Updated on"
                                                                :
                                                                transaction_status == 'APPROVED' ? 'Updated on'
                                                                    :
                                                                    transaction_status == 'COMPLETE' ? "Transaction Date"
                                                                        :
                                                                        transaction_status == 'PENDING' ?
                                                                            "Applied Date" : ''}
                                                        </ETypography>
                                                    </ETableCell>
                                                    <ETableCell align="right">
                                                        <EChip width='90px' fontSize='0.75rem'
                                                            className='active-red-chip'
                                                            label={
                                                                transaction_status == 'CANCELLED' ? "Rejected"
                                                                    :
                                                                    transaction_status == 'APPROVED' ? 'Approved'
                                                                        :
                                                                        transaction_status == 'COMPLETE' ? "Paid"
                                                                            :
                                                                            transaction_status == 'PENDING' ?
                                                                                "Pending" : ''
                                                            }
                                                        />
                                                    </ETableCell>

                                                </ETableRowXSNewBorder>
                                            )
                                        })}

                                    </ETableBody>
                                </ETable>
                            </ETableContainer>
                            :
                            <>
                                <EBox sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                                    <img alt='' src="/assets/images/hrDashboardNodata/payments.svg" className="flex-column-allCenter" style={{ maxHeight: '150px' }} />
                                    <ETypography className='font-size-14px flex-column-allCenter' sx={{ marginTop: '12px', fontWeight: '400', fontSize: '16px' }}>No Payments</ETypography>
                                    {/* <ETypography className='font-size-14px theme-color-static'>Previous Month</ETypography> */}
                                </EBox>
                            </>
                    }
                </Grid>

            </Grid>
            {((!payment?.PaySalaryLoading || (!payment && payment?.PaySalaryData?.rows?.length <= 0)) && payment?.PaySalaryData?.rows?.length > 0) &&
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" >
                    <Grid item >
                        <ELinkHRDAshMUI disabled={payment?.PaySalaryData?.rows?.length > 0 ? false : true}
                            onClick={() => navigate('/my-profile', { state: { id: `${base64.encode(auth?.authData?.id)}`, payment: `${base64.encode(true)}` } })}
                            className={'font-size-14px bold-600 cursor-pointer'}>
                            Show All</ELinkHRDAshMUI>
                    </Grid>
                </Grid>
            }
            {modalState.modalName &&
                <EModal open={modalState.modalName}
                    headervalue={`Payment Request`}
                >
                    <PaymentRequestModal
                        setModalState={setModalState}
                        auth={auth}
                        getPaymentFilterData={null}
                        employeeList={employee}
                        companyData={company}
                        fromPage={DASHBOARD}
                        setting={setting}

                    />
                </EModal>}
        </>
        // </ECardBlank>
    );
}

export default React.memo(DashboardPayments)