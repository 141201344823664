
import { Grid } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DepartmentList } from "../action/DepartmentAction";
import { DesignationList } from "../action/DesignationAction";
import { EmployeeAuthListData } from "../action/EmployeeAuthAction";
import { EButton, EButtonOutlined, EButtonOutlinedIcon, EIconButton } from "../BaseComponents/EButtons";
import { EIcon, EIconInputAdornment } from "../BaseComponents/EIcon";
import EModal from "../BaseComponents/EModal";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../BaseComponents/ETable";
import { ACTIVE_USER, DEFAULT_ROWS_PERPAGE, PAGES, PERMISSIONS, ROLE_HR, ROW_SIZE_PER_PAGE, SUPER_ADMIN, USER_ROLE_FETCH_TYPE } from "../constants";
import editOutlineRounded from '@iconify/icons-material-symbols/edit-outline-rounded'
import checkCircleOutlineRounded from '@iconify/icons-material-symbols/check-circle-outline-rounded'
import cancelOutlineRounded from '@iconify/icons-material-symbols/cancel-outline-rounded'
import { EBox, EBoxPage } from "../BaseComponents/EBox";
import EScrollbar from "../BaseComponents/EScrollbar";
import { ECircularProgress } from "../BaseComponents/ECircularProgress";
import SearchNotFound from "../PageComponents/SearchNotFound";
import EChip from "../BaseComponents/EChip";
import { ETablePagination } from "../BaseComponents/ETablePagination";
import { ETypography } from "../BaseComponents/ETypography";
// import AddSuperAdmin from "../PageComponents/userManagement/AddSuperAdmin";
import AddNewUserForm from "../PageComponents/userManagement/AddNewUserForm";
import UserSettingSearchForm from "../PageComponents/userManagement/UserSettingSearchForm";
import { capitalizeCapitalString } from "../utils/formatText";
import EHidden from "../BaseComponents/EHidden";
import { Link } from "react-router-dom";
import base64 from 'base-64'
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import passwordResetIcon from '@iconify/icons-material-symbols/lock-reset-rounded';
import KeyIcon from '@iconify/icons-material-symbols/key-rounded';
import UserPasswordChangeForm from "../PageComponents/userManagement/UserPasswordChangeForm";
import { ETooltip } from "../BaseComponents/ETooltip";
import ChangePrimarySuperAdmin from "../PageComponents/userManagement/ChangePrimarySuperAdmin";
import { useCompanySettingProvider } from "../context/CompanySettingContext";
import { useCheckPermission } from "../hooks";
import { getCustomUserRoleData } from "../action/CustomUserRole";
import { EPageWithBreadCrumbs } from "../BaseComponents";

const ActiveLoginCredentials = 1

const TABLE_HEAD = [
    { id: 'Name', label: 'Employee Name', alignRight: false },
    { id: 'Role', label: 'Role', alignRight: false, hideLabelCriteria: 'lgDown' },
    { id: 'Reporting Manager', label: 'Reporting Manager', alignRight: false },
    { id: 'Username', label: 'Username', alignRight: false },
    { id: 'Show in Payroll', label: 'Show in Payroll', alignRight: 'center' },
    { id: 'Status', label: 'Status', alignRight: 'center' },
    { id: 'Action', label: 'Action', alignRight: 'center' },
];
/**
 * [2022-11-25]
 * Author: Aanchal Sahu
 **/
function UserManagement(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setting } = useCompanySettingProvider();
    const auth = useSelector(state => state.authReducer);
    const Theme = useSelector(state => state.ThemeReducer)
    const empAuth = useSelector(state => state.EmployeeAuthReducer)



    /**
     * commenting code after descussion with sharad sir 
     * no need to create super admin from usermanagment 
     */
    // const [IsNewSuperAdmin, setIsNewSuperAdmin] = useState(false);

    const [page, setPage] = useState(PAGES);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [SearchedValues, setSearchedValues] = useState({ name: "", role_object: null, sort: true, employee: null, particular: null, });
    const [modal, setModal] = useState({open: '', data: null})

    
    // Reducers
    const dept = useSelector((state) => state.DepartmentReducer);
    const designation = useSelector((state) => state.DesignationReducer);

 

    /**
     * Add Superadmin permission now removing as per descussion
     */
    // const allowAddSuperAdmin = useCheckPermission([PERMISSIONS.AddSuperAdmin])
    const allowAddEditCredentials = useCheckPermission([PERMISSIONS.ViewAddEditCredentials])
    const allowChangeEmployeePassword = useCheckPermission([PERMISSIONS.ChangeEmployeePassword])

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeAuthListData(auth?.authtoken, ACTIVE_USER, page, rowsPerPage, { ...SearchedValues, form_list: false }, navigate))
        }
    }, [page, rowsPerPage, SearchedValues])

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(DepartmentList(auth.authtoken));
            dispatch(DesignationList(auth.authtoken));
            /**
            * INFO: If the logged in user is SUPER ADMIN then fetch all Role otherwise exclude SUPER ADMIN Role
            */
            const params = {
                type: auth?.authData?.role == SUPER_ADMIN ? USER_ROLE_FETCH_TYPE.ALL : USER_ROLE_FETCH_TYPE.WITHOUT_SUPER_ADMIN
            }
            /**
             * INFO: To fetch User Roles
             */
            dispatch(getCustomUserRoleData({ params }))
        }
    }, [auth])


 

    // Pagination functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage, page, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0, page, rowsPerPage);
    };

    // Handle Search
    const onSearch = (value) => {
        const searchValues = {
            name: value?.employee?.fullname,
            designation: value?.employee?.designation,
            department: value?.employee?.department,
            role: value?.particular?.role_name,
            sort: value?.sort,
            employee: value?.employee,
            particular: value?.particular,
        }
        setSearchedValues(searchValues)
        setPage(0, page, rowsPerPage);
    }

    // To get Role String 
    const getRoleString = (role) => {
        if (role === SUPER_ADMIN) {
            return 'Super Admin'
        } else if (role === ROLE_HR) {
            return role
        } else {
            return capitalizeCapitalString(role)
        }
    }

    return (
        <EPageWithBreadCrumbs
            title="User Management"
            pageHeading="User Management"
            breadcrumbsTitle="User Management"
            breadcrumbsLinks={[
                { name: 'Dashboard', href: '/' },
                {
                    name: 'User Management',
                }
            ]}
            loading={empAuth?.empAuthListLoading}
            hideDivider={true}

            rightComponent={
                <EBox display="flex" gap={2}>
                    {
                        allowAddEditCredentials &&
                        <EButton variant="contained" className="button-left-margin" onClick={() =>
                            setModal({open:'EDIT', data:null})
                        } > Allocate Credentials
                        </EButton>
                    }
                    <EHidden width="mdDown">
                        <Grid>
                            <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                        </Grid>
                    </EHidden>
                    <EHidden width="mdUp">
                        <Grid>
                            <EButtonOutlinedIcon
                                variant="outlined"
                                onClick={() => navigate(-1)}
                                className='button-left-margin'>
                                <EIcon icon={ForwardArrow} className='height-width-15px' />
                            </EButtonOutlinedIcon>
                        </Grid>
                    </EHidden>
                </EBox>
            }
        >
            <EBoxPage>

                {/* SEARCH FIELD */}
                <UserSettingSearchForm search={SearchedValues} setSearch={onSearch} auth={auth} />

                {/* TABLE */}
                <EScrollbar sx={{ marginTop: 2 }}>
                    <ETableContainer >
                        <ETable>

                            {empAuth?.empAuthListLoading && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={dept?.departmentListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}
                                />

                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            </>
                            }
                            {!empAuth?.empAuthLoading && empAuth?.empAuthListData?.rows?.length > 0 && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={dept?.departmentListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}

                                />
                                <ETableBody>
                                    {empAuth && empAuth?.empAuthListData?.rows?.map((row, index) => {
                                        const { is_active, is_primary, fullname, id, show_as_talent, reportingManager, employee_code, username, role, customRole = null } = row;
                                        
                                        const disableChangePwBtn =  is_active == ActiveLoginCredentials && (!(id != auth?.authData?.id && role == SUPER_ADMIN && is_primary == 1) || (id == auth?.authData?.id && is_primary == 1))
                                        const disableEditBtn = (id != auth?.authData?.id && role == SUPER_ADMIN && is_primary != 1) ||(id != auth?.authData?.id && role != SUPER_ADMIN) ||(role == SUPER_ADMIN && is_primary == 1 && auth?.authData?.role == SUPER_ADMIN && auth?.authData?.is_primary == 1)
                                       
                                        return (
                                            <ETableRow hover key={index + fullname} className='icons-on-hover'>

                                                {/* Full Name */}
                                                <ETableCell align="left" className="text-transform-capitalize minWidth-210">
                                                    {!is_primary || (is_primary && auth?.authData?.is_primary) ?
                                                        <ETypography className='bold-700 text-transform-capitalize font-size-14px' to={`/employee/${base64.encode(row.id)}`}
                                                            component={Link}>
                                                            {fullname ? fullname : '-'}
                                                        </ETypography> :
                                                        <span> {fullname ? fullname : '-'}</span>
                                                    }
                                                    <br />
                                                    {employee_code ? `(${employee_code})` : '-'}
                                                </ETableCell>

                                                {/* Role */}
                                                <EHidden width="lgDown">
                                                    <ETableCell align="left" className="text-transform-capitalize" >
                                                        <span className="display-flex align-center">
                                                            {is_primary ? <EIcon icon={KeyIcon} className="height-width-15px theme-color-static" /> : ''}
                                                            {
                                                                capitalizeCapitalString(customRole?.role_display_name) || getRoleString(role) || "-"
                                                            }
                                                        </span>
                                                    </ETableCell>
                                                </EHidden>

                                                {/* Reporting Manager */}
                                                <ETableCell align="left" className=' text-transform-capitalize'>
                                                    {reportingManager?.fullname ? reportingManager?.fullname : '-'}</ETableCell>

                                                {/* User Name */}
                                                <ETableCell align="left"  >{username ? username : "-"}</ETableCell>

                                                {/* Show In Payroll */}
                                                <ETableCell align="center" className="minWidth-100">
                                                    {show_as_talent && show_as_talent == 1 ?
                                                        <EIconInputAdornment icon={checkCircleOutlineRounded} className='icon-green' />
                                                        : <EIconInputAdornment icon={cancelOutlineRounded} className='icon-light-red' />
                                                    }
                                                </ETableCell>

                                                {/* Status */}
                                                <ETableCell align="center" >
                                                    <EChip label={is_active == 1 ? "Enable" : "Disable"} />
                                                </ETableCell>

                                                {/* Action */}
                                                <ETableCell align="center" className="minWidth-80">

                                                    {/* Edit Action */}
                                                    {
                                                        allowAddEditCredentials &&
                                                        <ETooltip title='Edit' arrow>
                                                            <span>
                                                            <EIconButton variant="" onClick={() => {
                                                                setModal({open:'EDIT', data:row})
                                                            }}
                                                                disabled={disableEditBtn ? false : true}
                                                            >
                                                                <EIcon width={23} height={23} icon={editOutlineRounded} />
                                                            </EIconButton>
                                                            </span>
                                                        </ETooltip>
                                                    }
                                                    {/* Change password action */}
                                                    {
                                                        allowChangeEmployeePassword &&
                                                        <ETooltip title='Change Password' arrow>
                                                            <span>
                                                            <EIconButton
                                                                onClick={() => {
                                                                    setModal({ open: 'RESET_PASSWORD', data: { fullname: fullname, id: id, employee_code: employee_code }})
                                                                }}
                                                                disabled={
                                                                    disableChangePwBtn ? false : true}
                                                            >
                                                                <EIcon width={23} height={23} icon={passwordResetIcon} />
                                                            </EIconButton>
                                                            </span>

                                                        </ETooltip>
                                                    }
                                                    {/* Change Primary SA action */}
                                                    {id == auth?.authData?.id && role == SUPER_ADMIN && is_primary &&
                                                        <ETooltip
                                                            title={
                                                                <span align='center'>Switch Primary <br />Super Admin</span>
                                                            }
                                                            arrow
                                                        >

                                                            <EIconButton
                                                                variant=""
                                                                onClick={() => {
                                                                setModal({ open: 'CHANGE_SUPERADMIN', data: { fullname: fullname, id: id, employee_code: employee_code }})
                                                                }
                                                            }
                                                            >
                                                                <EIcon width={23} height={23} icon={KeyIcon} />
                                                            </EIconButton>

                                                        </ETooltip>
                                                    }
                                                </ETableCell>
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            </>}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>


                {/* PAGINATION */}
                {/* Rows per page and paging functionality */}
                {empAuth?.empAuthListData?.count && empAuth?.empAuthListData?.count > DEFAULT_ROWS_PERPAGE ?
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={empAuth?.empAuthListData?.count ? empAuth?.empAuthListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <></>}
                {!empAuth?.empAuthLoading && empAuth?.empAuthListData?.rows?.length == 0 && (
                    <SearchNotFound />
                )}
            </EBoxPage>

            {/* Modal for Update User Credentials & Allocate Credentials */}
            {modal?.open == 'EDIT' &&
                <EModal open={modal?.open == 'EDIT'}
                    close={() =>
                        setModal('')
                    }
                    headervalue={modal?.data ? "Update User Credentials" : "Allocate Credentials"}>
                    <AddNewUserForm
                        setting={setting}
                        search={SearchedValues}
                        open={modal.open == 'EDIT'}
                        page={page}
                        close={() => {
                            setModal('')
                        }
                        }
                        isEdit={modal?.data}
                        auth={auth}
                        loadingButton={empAuth?.empAuthLoading}
                        dept={dept}
                        designation={designation}
                    />
                </EModal>
            }

            {/* Modal for Change password */}
            {modal.open == 'RESET_PASSWORD' &&
                <EModal open={modal.open == 'RESET_PASSWORD'}
                    close={() =>
                       setModal('')
                    }
                    headervalue="Confirmation : Change Password" >
                    <UserPasswordChangeForm
                        loading={empAuth.empAuthLoading}
                        search={SearchedValues}
                        data={modal?.data}
                        close={() => { setModal('') }}
                        auth={auth}
                        page={page}
                    />
                </EModal>
            }

            {/* Modal for Change Primary Super Admin*/}
            {modal.open == 'CHANGE_SUPERADMIN' &&
                <EModal open={modal.open == 'CHANGE_SUPERADMIN'}
                    close={() => setModal('')}
                    headervalue="Change Primary Super Admin" >
                    <ChangePrimarySuperAdmin
                       loading={empAuth.empAuthLoading}
                        search={SearchedValues}
                        data={modal?.data}
                        close={() => { setModal('') }}
                        auth={auth}
                        page={page}
                    />
                </EModal>
            }

        </EPageWithBreadCrumbs>
    )
}
export default UserManagement
