import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid, Link } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { numberWithCommas } from "../../utils/formatNumber";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { accountantPayValidation } from "../../utils/validation";
import { EIcon } from "../../BaseComponents/EIcon";
import { useDispatch } from "react-redux";
import { HRAccountantPaymentAction } from "../../action/PaymentAction";
import { APPROVED_STATUS, IMAGE_URL, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_MODE, PENDING_STATUS, PAYMENT_CATEGORY_OTHER, REMARK_LIMIT } from "../../constants";
import AttachFile from '@iconify/icons-material-symbols/file-present-rounded';
import moment from "moment";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { EBox } from "../../BaseComponents/EBox";



const PaymentActionModal = (props) => {

  const { selectedData, setModalState, auth, getPaymentFilterData } = props;
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      payment_medium: PAYMENT_MODE[0],
      payment_detail: '',
      accountant_remark: '',
    },
    validationSchema: accountantPayValidation,
    onSubmit: (data) => {
      data.payment_medium = data.payment_medium.id;
      data = { ...data, payment_id: selectedData.modalData?.id };
      dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, 'final-action'));
      setModalState({ modalData: null, modalCategory: null })
    }
  })

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik} >
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid label={'Employee Name '} isfullgrid={true} isModal={true} isNotForm={true}>
              <EBox display="flex" gap={0.5}>
                <ETypography>{selectedData.modalData?.employeeData?.fullname}</ETypography>
                <ETypography noWrap className="text-capitalize blue-color font-weight-400-with-imp"
                >
                  ({selectedData.modalData?.employeeData?.employee_code ? selectedData.modalData?.employeeData?.employee_code : '-'})
                </ETypography>
              </EBox>
            </ELabelInputMainGrid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid label={'Request Date'} isfullgrid={true} isModal={true} isNotForm={true}>
              <ETypography>{moment(selectedData.modalData?.transaction_date).format('DD/MM/YYYY')}</ETypography>
            </ELabelInputMainGrid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid label={'Request Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
              <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
            </ELabelInputMainGrid>
          </Grid>
          {
            selectedData.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT && <Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Attachment'} isfullgrid={true} isModal={true} isNotForm={true}>
                {selectedData.modalData?.reimbursement_attachment ? (
                  <Link target={'_blank'} href={IMAGE_URL +
                    '/' +
                    auth?.authData?.company_id +
                    '/reimbursement/' +
                    selectedData.modalData?.employee_id +
                    '/' +
                    selectedData.modalData?.reimbursement_attachment}>
                    <EIcon icon={AttachFile} className='theme-color-static' />
                  </Link>) : <></>}
              </ELabelInputMainGrid>
            </Grid>}
          {
            selectedData.modalData.transaction_category != PAYMENT_CATEGORY_ADVANCE && selectedData.modalData.transaction_category != PAYMENT_CATEGORY_OTHER ?
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <ELabelInputMainGrid label={'Bill Date'} isfullgrid={true} isModal={true} isNotForm={true}>
                  <ETypography>{selectedData.modalData?.reimbursement_bill_date ? moment(selectedData.modalData?.reimbursement_bill_date).format('DD/MM/YYYY') : '-'}</ETypography>
                </ELabelInputMainGrid>
              </Grid> : ""
          }

          {[PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_ADVANCE].includes(selectedData.modalData?.transaction_category) &&
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Reason'} isfullgrid={true} isModal={true} isNotForm={true}>
                <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData?.remark}</ETypography>
              </ELabelInputMainGrid>
            </Grid>
          }
          {(selectedData.modalData?.transaction_status == APPROVED_STATUS || (selectedData.modalData?.transaction_status == PENDING_STATUS)) ? <>
            <Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Payment Mode'} isfullgrid={true} isModal={true}>
                <EAutocomplete
                  name="Payment Mode"
                  fullWidth
                  {...getFieldProps('payment_medium')}
                  options={PAYMENT_MODE}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => {
                    setFieldValue('payment_medium', value ? value : null)
                    if (value?.id != 'CHEQUE') { setFieldValue('payment_detail', '') }
                  }}
                  renderInput={params => (
                    <ETextField
                      {...params}
                      name='payment_medium'
                      placeholder="Select Payment Mode"
                      fullWidth
                      error={Boolean(touched.payment_medium && errors.payment_medium)}
                      helperText={touched.payment_medium && errors.payment_medium}
                    />
                  )}
                />
              </ELabelInputMainGrid>
            </Grid>
            {getFieldProps('payment_medium').value && getFieldProps('payment_medium').value?.id == 'CHEQUE' &&
              <Grid item xs={12} sm={12} lg={12} xl={12}>

                <ELabelInputMainGrid label={'Cheque Number'} isfullgrid={true} isModal={true}>
                  <ETextField
                    name='payment_detail'
                    {...getFieldProps('payment_detail')}
                    placeholder="Cheque Number"
                    rows={3}
                    fullWidth
                    error={Boolean(touched.payment_detail && errors.payment_detail)}
                    helperText={touched.payment_detail && errors.payment_detail}
                  />
                </ELabelInputMainGrid>
              </Grid>}
            <Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true}>
                <ETextField
                  name='accountant_remark'
                  placeholder="Enter your remarks here"
                  multiline
                  rows={3}
                  {...getFieldProps('accountant_remark')}
                  fullWidth
                  inputProps={{ maxLength: REMARK_LIMIT }}
                  error={Boolean(touched.accountant_remark && errors.accountant_remark)}
                  helperText={touched.accountant_remark && errors.accountant_remark}
                />
                <ETypography className='font-size-14px mt-4px greyColor11-color'>
                  {`${formik?.values?.accountant_remark?.length || 0}/${REMARK_LIMIT}`}
                </ETypography>
              </ELabelInputMainGrid>
            </Grid>
          </> : <>
            <Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Payment Mode'} isfullgrid={true} isModal={true}>
                <ETypography>{selectedData.modalData?.payment_medium == 'CASH' ? 'Cash' : 'Cheque'}</ETypography>
              </ELabelInputMainGrid>
            </Grid>
            {selectedData.modalData?.payment_medium === 'CHEQUE' && <Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Cheque Number'} isfullgrid={true} isModal={true}>
                <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData?.payment_medium_ref}</ETypography>
              </ELabelInputMainGrid>
            </Grid>}

            <Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true}>
                <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData?.accountant_remark}</ETypography>
              </ELabelInputMainGrid>
            </Grid>
          </>}

        </Grid>
        <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
          <EButtonOutlined size="large" variant="outlined"
            onClick={() => setModalState({ modalData: null, modalCategory: null })}
          > Cancel </EButtonOutlined>

          {(selectedData.modalData?.transaction_status == APPROVED_STATUS || (selectedData.modalData?.transaction_status == PENDING_STATUS)) && (
            <ELoadingButton
              size="large" variant="outlined" type="submit" >Pay</ELoadingButton>
          )}


        </Stack>
      </Form>
    </FormikProvider>
  )
}

export default PaymentActionModal