import { Grid, Link, ListItem, useTheme } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import {
  EButton,
  EButtonIcon,
  EButtonOutlined,
  EButtonOutlinedIcon,
} from "../../BaseComponents/EButtons";
import EHidden from "../../BaseComponents/EHidden";
import { EIcon } from "../../BaseComponents/EIcon";
import Page from "../../BaseComponents/EPage";
import {
  ETypography,
  ETypographyCardHeading,
  ETypographyPageHeading,
  EWordCount,
} from "../../BaseComponents/ETypography";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { EBox, EBoxPage } from "../../BaseComponents/EBox";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import EModal from '../../BaseComponents/EModal'

// FORMIK
import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// ACTION
import { releaseTalent } from "../../action/TalentActions";

// ICON
import DescriptionBoxIcon from "@iconify/icons-material-symbols/description-outline-rounded";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";
import { Stack } from "@mui/system";
import { ReleaseTalentValidation } from "../../utils/validation";
import { CHARACTER_LIMIT, NOC_DOCUMENT_DOWNLOAD, RELEASE_ID, RESIGN_ID } from "../../constants";
import moment from "moment";

import { DownLoadFile } from "../../action/AdminAction";
import { useState } from "react";

function ReleaseTalent(props) {
  const { auth } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme()

  // getting employee information via location
  const location = useLocation();

  const [open, setOpen] = useState(false)

  // saving object return from useFormik as formik
  const formikTalents = useFormik({
    initialValues: {
      transfer_type: null,
      employee_id: location.state.id,
      company_id: location.state.company_id,
      last_working_date: "",
      reason_terminate: null,
      reason_other: "",
      attachment_file: "",
      noc_file: "",
    },
    validationSchema: ReleaseTalentValidation,
    onSubmit: (data) => {
      setOpen(true)

    },
  });

  // option array for select field I.e transfer_types
  const transferOptions = [
    { label: "Release", id: RELEASE_ID },
    { label: "Resign", id: RESIGN_ID },
    // { label: "Termination", id: 3 },
  ];

  // option array for select field I.e transfer_types
  const reasonOptions = [
    { label: "Incompetence", id: 1 },
    { label: "Action against Company policy", id: 2 },
    { label: "Theft/Criminal behavior", id: 3 },
    { label: "Discriminatory behavior", id: 4 },
    { label: "Physical violence against employee", id: 5 },
    { label: "Other", id: 6 },
  ];


  const onKeyDown = (e) => {
    e.preventDefault();
  };


  const DownloadReport = (value) => {

    DownLoadFile(auth.authtoken, NOC_DOCUMENT_DOWNLOAD, 'noc_file.pdf')
}

  // Formik Props
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
    values,
  } = formikTalents;
  // onChange for input[type='file']
  const requestFile = (e) => {
    if (e.target.files[0]) {
      setFieldValue(e.target.name, e.target.files[0]);
    }
  };

  const handleConfirmation = () =>{
    let data = values
    data = {...data,
      transfer_type: data.transfer_type.id,
      reason: data?.transfer_type?.id===RELEASE_ID ? data?.reason_terminate?.label : data?.reason_other,
      attachment_file: data?.attachment_file,
      noc_file: data?.noc_file,
    };
    dispatch(releaseTalent(auth.authtoken, data, navigate));
  }
  

  return (
    <Page title={"View Employee"}>
      {/* HEADER */}
      <Grid
        container
        display="row"
        justifyContent="space-between"
        className="align-center"
      >
        <Grid item>
          <EHeaderBreadcrumbs
            heading={`Release`}
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Employee", href: "/employee/employee-list" },
              { name: "Employee Directory", href: "/employee/employee-list" },
              { name: `${location.state?.employee_code}`, href: "" },
              {
                name: `Release`,
              },
            ]}
          />
        </Grid>
        {/* INFO: This complonent will be hidden when screen size goes below md */}
        <EHidden width="mdDown">
          <Grid item>
            <EButton
              target={'_blank'}
              variant="contained"
              onClick={() => DownloadReport()}
            >
              NOC Document
            </EButton>
            <EButtonOutlined
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              {" "}
              Back{" "}
            </EButtonOutlined>
          </Grid>
        </EHidden> 
      </Grid>

      {/* EMPLOYEE INFORMATION */}

      <Grid
        container
        display="row"
        justifyContent="space-between"
        className="margin-top-20px "
      >
        <Grid item>
          <ETypographyPageHeading variant="h5">
            {location.state.fullname}
            <span className="small-text">
              {location.state.designationInfo?.designation_name}
            </span>{" "}
          </ETypographyPageHeading>
        </Grid>
        <EHidden width="mdUp">
          <Grid item>
            <EButtonIcon
              target={'_blank'}
              variant="contained"
              onClick={() => DownloadReport()}
            >
              <EIcon icon={DescriptionBoxIcon} className='height-width-15px' />
            </EButtonIcon>
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              {" "}
              <EIcon icon={ForwardArrow} className='height-width-15px' />{" "}
            </EButtonOutlinedIcon>
          </Grid>
        </EHidden>
      </Grid>
      <EHidden width="mdDown">
        <EDivider className="pt-8px" />
      </EHidden>
      
      {/* CARD */}
      <EBoxPage>
        <FormikProvider value={formikTalents}>
          <Form autoCapitalize="off" >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ECard className="card_design_1">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="mb-8px"
                >
                  <ETypographyCardHeading variant="h5" className="font-size-20px">
                    Exit Details
                  </ETypographyCardHeading>
                </Grid>
                <EDivider className="mb-16px" />

                {/* FORM */}
                <EBox >
                  <Grid container spacing={2}>
                    {/* Department */}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label={"Department"} isNotForm={true} >
                        <ETypography>
                          {location?.state?.departmentInfo?.department_name}
                        </ETypography>
                      </ELabelInputMainGrid>
                    </Grid>
                    {/* Reporting Manager */}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label={"Reporting Manager"} isNotForm={true}>
                        <ETypography>
                          {location.state.reportingManager?.fullname}
                        </ETypography>
                      </ELabelInputMainGrid>
                    </Grid>
                    {/* Exit type */}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label={"Exit type"}>
                        <EAutocomplete
                          name="transfer_type"
                          label="Department"
                          className="pr-16px"
                          forcePopupIcon={formikTalents?.values?.transfer_type ? false : true}
                          options={transferOptions}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          {...getFieldProps("transfer_type")}
                          onChange={(e, value) => {
                            setFieldValue("transfer_type", value);
                          }}
                          getOptionLabel={(option) => option.label || ""}
                          renderInput={(params) => (
                            <ETextField
                              {...params}
                              name="transfer_type"
                              // label="Department"
                              placeholder="Select Exit Type"
                              InputLabelProps={{ shrink: true }}
                              error={Boolean(
                                touched.transfer_type && errors.transfer_type
                              )}
                              helperText={
                                touched.transfer_type && errors.transfer_type
                              }
                            />
                          )}
                        />
                      </ELabelInputMainGrid>
                    </Grid>
                    {/*Last Working Day*/}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label={"Last Working Day"}>
                        <EDatePicker
                          views={["day", "month", "year"]}
                          name="last_working_date"
                          {...getFieldProps("last_working_date")}
                          minDate={new Date()} //Current date
                          maxDate={moment(new Date()).add(3, 'month').format()} // current date + 3 (i.e notice period)
                          inputFormat="dd/MM/yyyy"
                          onChange={(selectedDate) => {
                            setFieldValue("last_working_date", selectedDate);
                          }}
                          renderInput={(params) => (
                            <Field
                              readOnly
                              component={ETextField}
                              onKeyDown={onKeyDown}
                              {...params}
                              fullWidth
                              name="last_working_date"
                              error={Boolean(
                                touched.last_working_date &&
                                errors.last_working_date
                              )}
                              helperText={
                                touched.last_working_date &&
                                errors.last_working_date
                              }
                            />
                          )}
                        />
                      </ELabelInputMainGrid>
                    </Grid>

                    {/* Reasons */}
                    {values.transfer_type?.id !== RELEASE_ID && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ELabelInputMainGrid label={"Reason"} isfullgrid={true}>
                          <ETextField
                            fullWidth
                            name="reason_other"
                            {...getFieldProps("reason_other")}
                            multiline
                            rows={5}
                            className="w-100per"
                            inputProps={{
                              maxLength: CHARACTER_LIMIT,
                            }}
                            error={Boolean(touched.reason_other && errors.reason_other)}
                            helperText={touched.reason_other && errors.reason_other}
                          />
                          <EWordCount>{`${formikTalents.values?.reason_other?.length}/${250}`}</EWordCount>
                        </ELabelInputMainGrid>
                      </Grid>
                    )}

                    {/* Reason for Termination */}
                    {values.transfer_type?.label === "Release" && (
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ELabelInputMainGrid label={"Reason"}>
                          <EAutocomplete
                            name="reason_terminate"
                            label="Reason"
                            className="pr-16px"
                            options={reasonOptions}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            {...getFieldProps("reason_terminate")}
                            onChange={(e, value) => {
                              setFieldValue("reason_terminate", value);
                            }}
                            getOptionLabel={(option) => option.label || ""}
                            renderInput={(params) => (
                              <ETextField
                                {...params}
                                name="reason_terminate"
                                // label="Department"
                                placeholder="Select Reason"
                                InputLabelProps={{ shrink: true }}
                                error={Boolean(touched.reason_terminate && errors.reason_terminate)}
                                helperText={touched.reason_terminate && errors.reason_terminate}
                              />
                            )}
                          />
                        </ELabelInputMainGrid>
                      {
                      values.reason_terminate?.label == 'Other' ? 
                      <ELabelInputMainGrid>
                          <ETextField
                            
                            name="reason_other"
                            {...getFieldProps("reason_other")}
                            multiline
                            rows={5}
                            className="width-100per mt-16px"
                            inputProps={{
                              maxLength: CHARACTER_LIMIT,
                            }}
                            error={Boolean(touched.reason_other && errors.reason_other)}
                            helperText={touched.reason_other && errors.reason_other}
                          />
                        </ELabelInputMainGrid>: ''
                    }
                      </Grid>
                    )}

                    {/* Attachment */}
                    {values.transfer_type?.label == "Resign" ?
                      <Grid item xs={12} sm={6} md={6} lg={6} className="input-file-selector pr-16px">
                        <ELabelInputMainGrid label={"Attachment"}>
                          <ETextField
                            fullwidth="true"
                            name="attachment_file"
                            type="file"
                            accept="application/pdf,image/jpg,image/jpeg"
                            onChange={(e) => requestFile(e)}
                            fullWidth
                            error={Boolean(touched.attachment_file && errors.attachment_file)}
                            helperText={touched.attachment_file && errors.attachment_file}
                          />
                          <span className="greyColor4-color font-size-12px">* Attach resignation related doc for reference</span>
                        </ELabelInputMainGrid>
                      </Grid>
                      : ""}

                    {/* NOC Document */}
                    <Grid item xs={12} sm={6} md={6} lg={6} className="input-file-selector" >
                      <ELabelInputMainGrid label={"NOC Document"}>
                        <ETextField
                          fullwidth="true"
                          name="noc_file"
                          type="file"
                          accept="application/doc"
                          onChange={(e) => requestFile(e)}
                          fullWidth
                          error={Boolean(touched.noc_file && errors.noc_file)}
                          helperText={touched.noc_file && errors.noc_file}
                        />
                        <span className="greyColor4-color font-size-12px">* Allowed format is pdf</span>
                      </ELabelInputMainGrid>
                    </Grid>
                  </Grid>
                </EBox>
              </ECard>

              {/*Form Action */}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
              >
                <EButtonOutlined
                  size="large"
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  {" "}
                  Cancel{" "}
                </EButtonOutlined>
                <EButton size="large" type="submit" variant="contained">
                  Submit
                </EButton>
              </Stack>
            </LocalizationProvider>
          </Form>
        </FormikProvider>
        <EModal open={open} headervalue={'Reporting Manager: Confirmation'} >
          <>
          <ETypography sx={{fontSize:'16px'}}>
            As <b>{location.state.fullname}</b> is being released, you'll become the reporting manager for all employees who were previously reporting to <b>{location.state.fullname}</b>. Are you sure you want to continue ?
          </ETypography>
          <ListItem className='p0 m0' sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}>
            <EBox sx={{ marginLeft: 'auto', marginTop: '15px'}}>
                <EButtonOutlined variant='outlined' size='large' onClick={()=>{setOpen(!open)}} sx={{ marginRight: '10px' }}> No</EButtonOutlined>
                <EButton type="submit" variant="contained" size='large' onClick={handleConfirmation}> Yes</EButton>
            </EBox>
        </ListItem>
          </>
        </EModal>
      </EBoxPage>
    </Page>
  );
}

export default ReleaseTalent;
