import { Button, IconButton, styled } from "@mui/material";
import { LoadingButton } from '@mui/lab';

export const EButton = styled(Button)(({ theme }) => ({
    background:theme.palette.primary.main,
    fontSize:'0.875rem',//14px
    color:'#F5F5F5',
    ':hover':{
        backgroundColor:theme.palette.primary.light,
    }  ,
    ':active':{
        backgroundColor:theme.palette.primary.dark,
    },
     '&.Mui-disabled':{
        backgroundColor: theme.palette.background.buttonDisable,
        color:theme.palette.text.disabledBottonText,
        svg:{
            color:theme.palette.background.buttonDisable,
        },
    },
    lineHeight: '0!important',
    minHeight: '40px',
    padding: '10px 20px',
    [theme.breakpoints.down('lg')]: {
        padding: '6px 16px',
        minHeight: '33px',
        lineHeight: '0!important'
    },
}));

export const EButtonIcon = styled(EButton)(({ theme }) => ({
    minWidth:'31px !important',
    height:'31px',
    padding:'8px !important',
    background:theme.palette.primary.main,
    color:theme.palette.text.primaryStaticLight,
    svg:{
        color:theme.palette.text.primaryStaticLight,
    },
}));

/**
   * [2023-03-16]
   * Admin: Purva Sharma
   * Description:- Changed the loading button indicator button as previously it was similar to primay.main theme i.e orange color hence was not visible
   **/
export const ELoadingButton = styled(LoadingButton)(({ theme }) => ({
    background:theme.palette.primary.main,
    fontSize:'0.875rem',//14px
    color:'#F5F5F5',
    ':hover':{
        backgroundColor:theme.palette.primary.light,
    }  ,
    ':active':{
        backgroundColor:theme.palette.primary.dark,
    },
    '& .MuiLoadingButton-loadingIndicator':{
        color:theme.palette.text.primaryStaticLight,   
    },
    lineHeight: '140% !important',
    minHeight: '40px',
    padding: '10px 20px',
    [theme.breakpoints.down('lg')]: {
        padding: '6px 16px',
        minHeight: '33px',
        // lineHeight: '0!important'
    },
}));

export const EIconButton = styled(IconButton)(({ theme }) => ({
    color:theme.palette.text.button,
    '&.Mui-disabled':{
        svg:{
            color:theme.palette.background.buttonDisable,
        },
    }
}));

export const EIconButtonNav = styled(IconButton)(({ theme }) => ({
    color:theme.palette.text.button,
    ':hover':{
        background:theme.palette.background.tableHeader,
        color:theme.palette.primary.main,
    }  ,
    '&.Mui-disabled':{
        svg:{
            color:theme.palette.background.buttonDisable,
        },
    }
}));

export const EButtonOutlined = styled(Button)(({ theme }) => ({
    borderColor:theme.palette.primary.main,
    color:theme.palette.text.button,
    fontSize:'0.875rem',//14px
    ':hover':{
        borderColor:theme.palette.primary.light,
    }  ,
    ':active':{
        borderColor:theme.palette.primary.dark,
    } ,
    '&.Mui-disabled':{
        backgroundColor: theme.palette.background.buttonDisable,
        color:theme.palette.text.disabledBottonText,
        svg:{
            color:'rgba(64, 63, 63, 0.2) !important',
        },
    },
    lineHeight: '0!important',
    minHeight: '40px',
    padding: '10px 20px',
    [theme.breakpoints.down('lg')]: {
        padding: '6px 16px',
        minHeight: '33px',
        lineHeight: '0!important'
    },
}));

// Used in account Settings
export const EButtonOutlinedBlueText = styled(EButtonOutlined)(({ theme }) => ({
    border:`0.5px solid ${theme.palette.text.default} !important`,
    backgroundColor: theme.palette.background.bg1,
    borderRadius: '4px',
    color:theme.palette.info.blue3,
    
}));

export const EButtonOutlinedIcon = styled(EButtonOutlined)(({ theme }) => ({
    minWidth:'31px !important',
    height:'31px',
    padding:'8px !important',
    svg:{
        color:theme.palette.text.button,
    },
}));


// Used in SubscriptionSuccess 
export const EButtonOutlinedIconLink = styled(EButtonOutlined)(({ theme }) => ({
    background: "#FFFFFF !important",
    width:'100%',
    border: "1px solid #403F3F !important",
    boxShadow:"0px 14px 5px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.09), 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
    borderRadius: '4px',
    display:'flex',
    alignItems:'center',
    padding:'0 !important',
    margin:'0 !important'
   
}));

export  const EButtonProfile = styled(Button)(({ theme }) => ({
    border: `2px dashed ${theme.palette.text.staticGrey3}`,
    borderRadius: "100% !important",
    padding: '9px 9px',
    '&.Mui-disabled':{
        svg:{
            color:theme.palette.background.buttonDisable,
        },
    }
}));

export const EButtonVariant3 = styled(Button)(({ theme }) => ({
    background:'transparent',
    color:theme.palette.primary.main,
    'svg':{
        color:theme.palette.primary.main,
        height:'1rem',
    },
    ':hover':{
        backgroundColor:theme.palette.background.dullLightOrange,
    },
    ':active':{
        backgroundColor:theme.palette.background.dullLightOrange,
    },
     '&.Mui-disabled':{
        backgroundColor: theme.palette.background.buttonDisable,
        color:theme.palette.text.disabledBottonText,
        svg:{
            color:theme.palette.background.buttonDisable,
        },
    }  
}));

export const EButtonRed = styled(EButton)(({ theme }) => ({
    background:theme.palette.text.redColor,
    color:theme.palette.text.primaryStaticLight,
    svg:{
        color:theme.palette.text.primaryStaticLight,
    },
    ':hover':{
        backgroundColor:theme.palette.text.redColor,
    }  ,
    ':active':{
        backgroundColor:theme.palette.text.redColor,
    },
     '&.Mui-disabled':{
        backgroundColor: theme.palette.background.buttonDisable,
        color:theme.palette.text.disabledBottonText,
        svg:{
            color:theme.palette.background.buttonDisable,
        },
    }  
}));