import { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  Avatar,
  FormControlLabel,
  FormGroup,
  Switch,
  Toolbar,
} from "@mui/material";
import { DRAWER_WIDTH, FEMALE, MALE, PERMISSIONS, THEMES } from "../constants";
import { AccountPopover, SettingPopover } from "./account-popover";
import useSettings from "../hooks/useSettings";
import { ETextFieldSearch } from "../BaseComponents/ETextField";
import { EIcon, EIconInputAdornment, EIconNav } from "../BaseComponents/EIcon";
import { EDashboardNavbarRoot } from "../BaseComponents/LayoutElements";
import { ETypography } from "../BaseComponents/ETypography";
import { ETooltip } from "../BaseComponents/ETooltip";
import EModal from "../BaseComponents/EModal";
import RequestNewFeatureForm from "../PageComponents/RequestNewFeatureForm";
import EHidden from "../BaseComponents/EHidden";
import { EButton, EButtonOutlined, EIconButton, EIconButtonNav } from "../BaseComponents/EButtons";
import { EBox } from "../BaseComponents/EBox";
//icons
import MenuIcon from "@iconify/icons-material-symbols/menu-rounded";
import SearchIcon from "@iconify/icons-mdi/search";
import DarkModeIcon from '@iconify/icons-material-symbols/dark-mode-rounded'
import LightModeIcon from '@iconify/icons-material-symbols/light-mode-outline'
import ArrowUpIcon from "@iconify/icons-material-symbols/arrow-drop-up-rounded";
import ArrowDownIcon from "@iconify/icons-material-symbols/arrow-drop-down-rounded";
import SettingIcon from "@iconify/icons-material-symbols/settings-outline-rounded";
import featureReqIcon from "@iconify/icons-material-symbols/rate-review-outline-rounded";
import { useSelector } from "react-redux";
import NotificationPopover from "../pages/Notification/notification_popover";
import { useNavigate } from "react-router-dom";
import GlobalSearch from "../PageComponents/GlobalSearch/GlobalSearch";
import { useCheckPermission } from "../hooks";
import { useCompanySettingProvider } from "../context/CompanySettingContext";
// import BellIcon from "@iconify/icons-material-symbols/notifications-outline-rounded";


export const DashboardNavbar = (props) => {

  const { onSidebarOpen, auth, ...other } = props;
  const navigateTo = useNavigate()
  const company = useSelector(state => state.CompanyReducer);
  const settingsRef = useRef(null);
  const accountRef = useRef(null);
  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  // const [openSettingPopover, setOpenSettingPopover] = useState(false);
  const [openFeatureReq, setOpenFeatureReq] = useState(false)
  const { settings, saveSettings } = useSettings();
  const [openSearch,setOpenSearch] = useState(false)

  const settingsContext = useCompanySettingProvider()
  const accountSetting = settingsContext?.setting?.accountSettingData?.companySetting
  const isPlanExpired = accountSetting?.activePlan?.isPlanExpired

  const handleThemeChange = () => {
    saveSettings({
      theme: settings.theme == THEMES.DARK ? THEMES.LIGHT : THEMES.DARK,
    });
  };
  const HandlePhoto = (auth) => {
    // const company_id = auth?.authData?.company_id;
    // const employee_id = auth?.authData?.id;
    const personal_photo = auth?.authData?.employeeInfo?.personal_photo;
    const personal_gender = auth?.authData?.employeeInfo?.personal_gender;
    
    if (personal_photo && personal_photo != "undefined") {
      return (
        personal_photo
      );
    } else {
      const defaultImage =
        personal_gender == FEMALE
          ? "/assets/images/female.png"
          : personal_gender == MALE
            ? "/assets/images/male.png"
            : "";
      return defaultImage;
    }
  };

  const showSetting = useCheckPermission([
    PERMISSIONS.TopBarSetting,

    PERMISSIONS.SideBarAccountSetting,
    PERMISSIONS.OnlyViewAccountSettings,
    PERMISSIONS.ViewEditAccountSettings,

    PERMISSIONS.SideBarTalentDataSetting,
    PERMISSIONS.OnlyViewTalentDataSettings,
    PERMISSIONS.ViewEditTalentDataSettings,
    
    PERMISSIONS.SideBarAttendanceSetting,
    PERMISSIONS.OnlyViewAttendanceSettings,
    PERMISSIONS.ViewEditAttendanceSettings,
    
    PERMISSIONS.SideBarLeaveSetting,
    PERMISSIONS.OnlyViewLeaveSettings,
    PERMISSIONS.ViewEditLeaveSettings,
    
    PERMISSIONS.SideBarPayrollSetting,
    PERMISSIONS.OnlyViewPayrollSettings,
    PERMISSIONS.ViewEditPayrollSettings,
    
    PERMISSIONS.SideBarPaymentSetting,
    PERMISSIONS.OnlyViewPaymentSettings,
    PERMISSIONS.ViewEditPaymentSettings,
    
    PERMISSIONS.SideBarAdditionalSetting,
    PERMISSIONS.OnlyViewAdditionalSettings,
    PERMISSIONS.ViewEditAdditionalSettings,
  ])

  return (
    <>
      <EDashboardNavbarRoot
        sx={{
          left: {
            lg: DRAWER_WIDTH,
          },
          width: {
            lg: `calc(100% - ${DRAWER_WIDTH + "px"})`,
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: {xs:'1rem', sm:'1rem',md:"2.5rem"},
          }}
        >
          <EIconButton
            onClick={onSidebarOpen} //to open or close the side nav bar
            className="pl0"
            sx={{
              display: {
                md: "inline-flex",
                lg: "none",
              },
            }}
          >
            <EIcon icon={MenuIcon} fontSize="small" />
          </EIconButton>
          {/* INFO: main search textbox */}
          <ETextFieldSearch
            sx={{
              display: { lg: "block", md: "block", sm: "block", xs: "none" },
              width: '20rem',
              '& input, svg': {
                cursor: 'pointer'
              }
            }}
            onClick={()=>setOpenSearch(!openSearch)}
            id="outlined-basic"
            size="small"
            placeholder="Search"
            inputProps={{readOnly: true}}
            InputProps={{
              startAdornment: <EIconInputAdornment icon={SearchIcon} />,
            }}
          />

          <EBox sx={{ flexGrow: 1 }} />
          
          {/* INFO: IF ONBOARDING IS NOT COMPLETED THEN HIDE THE MENUS */}
          {
            (company.companyData?.onBoarding?.onboarding_is_completed && !isPlanExpired) ? <>

            {/* ******Commenting Notifications as per now constructed by @Dharmendra Sir *******/}
            {/* INFO: NOTIFICATIONS Component */}
            <NotificationPopover auth={auth} />

            {/* New Feature req */}
            <ETooltip arrow title="Request Feature">
              <EIconButtonNav
                onClick={() => setOpenFeatureReq(true)}
                // className="mr-8px"
              >
                <EIconNav icon={featureReqIcon} />
              </EIconButtonNav>
            </ETooltip>

            {/* New Feature req MODAL */}
            {openFeatureReq && (
              <EModal
                open={openFeatureReq}
                newMaxWidth={"sm"}
                close={() => setOpenFeatureReq(false)}
                headervalue="Feature Request"
              >
                <RequestNewFeatureForm
                  close={() => setOpenFeatureReq(false)}
                />
              </EModal>
            )}

            {/*INFO: SettingIcon*/}
            {
              showSetting &&
              <ETooltip arrow title="Company Settings">
                <EIconButtonNav
                  onClick={() => navigateTo('/setting')}
                  ref={accountRef}
                  className="mr-8px"
                >
                  <EIconNav icon={SettingIcon} />
                </EIconButtonNav>
              </ETooltip>
            }

          </>:''}



          {/*INFO: THEME Switcher*/}
          <EHidden width="lgDown">
            <FormGroup
              className="mr-16px"
              sx={{
                //theme switcher
                "& .MuiFormControlLabel-root": {
                  margin: "0px !important",
                },
              }}
            >
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={settings?.theme == THEMES.DARK ? true : false}
                    onClick={() => handleThemeChange()}
                  />
                }
              />
            </FormGroup>
          </EHidden>

          {/* INFO: THEME Switcher Icons for small fields */}
          <EHidden width="lgUp">
            {settings?.theme == THEMES.DARK 
              ? 
              <ETooltip arrow title="">
                <EIconButtonNav
                  onClick={() => handleThemeChange()}
                >
                  <EIconNav icon={LightModeIcon} sx={{cursor:'pointer'}}/>
                </EIconButtonNav>
              </ETooltip>
              : 
              <ETooltip arrow title="">
              <EIconButtonNav
                onClick={() => handleThemeChange()}
              >
                <EIconNav icon={LightModeIcon} sx={{cursor:'pointer'}}/>
              </EIconButtonNav>
            </ETooltip>
            }
          </EHidden>


          {/*INFO: Employee's profile*/}
          <EBox
            className='display-flex cursor-pointer responsive-w-1rem-12rem align-center justify-content-space-between'
            onClick={() => setOpenAccountPopover(true)}
            ref={settingsRef}
          >
            <EBox className='display-flex align-center justify-content-start responsive-w-1rem-12rem'>

              <Avatar className='navbar-avatar' src={HandlePhoto(auth)} />

              {/*INFO: The following part will not be shown when screen size goes down from lg*/}
              <EHidden width="lgDown">
                <EBox sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
                  <ETypography component={"div"} className="font-size-14px text-transform-capitalize minWidth-32px maxWidth-128px text-dots" >
                    {auth?.authData?.fname ? auth?.authData?.fname : ""}
                  </ETypography>
                  <ETypography component={"div"} className="font-size-10px minWidth-32px maxWidth-128px text-dots">
                    {auth?.authData?.designationInfo?.designation_name
                      ? auth?.authData?.designationInfo?.designation_name
                      : ""}
                  </ETypography>
                </EBox>
              </EHidden>
            </EBox>

            {/*INFO: The following part will not be shown when screen size goes down from lg*/}
            <EHidden width="lgDown">
              <EBox>
                <ETypography component={"div"}>
                  {" "}
                  {openAccountPopover === false ? (
                    <EIcon icon={ArrowDownIcon} />
                  ) : (
                    <EIcon icon={ArrowUpIcon} />
                  )}
                </ETypography>
              </EBox>
            </EHidden>
          </EBox>
        </Toolbar>
      </EDashboardNavbarRoot>

      {/*INFO: Account's Popover*/}
      <AccountPopover
        anchorEl={settingsRef.current}
        open={openAccountPopover}
        onClose={() => setOpenAccountPopover(false)}
        auth={auth}
      />

      {/*INFO: Setting's Popover*/}
      {/* <SettingPopover
        auth={auth}
        anchorEl={accountRef.current}
        open={openSettingPopover}
        onClose={() => setOpenSettingPopover(false)}
      /> */}
      {/* GLOBAL SEARCH MODAL */}
      {
         <EModal open={openSearch} headervalue="Global Search">
          <GlobalSearch
          setOpen={setOpenSearch}
          auth={auth}
          
          />
        </EModal>
      }
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

//MaterialUISwitch is the designed switch for changing theme
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 36,
  padding: 2,
  paddingTop: 5,
  paddingLeft: -3,
  borderRadius: "100%",
  "& .MuiSwitch-switchBase": {
    margin: 3,
    marginTop: 5.5,
    marginLeft: 3,
    padding: 0,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        padding: "2px",
        backgroundImage: "url(/assets/images/sun.png)",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#474747" : "#474747",
        borderRadius: "50px",
        height: 23,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
    width: 22,
    height: 22,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: "url(/assets/images/moon.png)",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#474747" : "#aab4be",
    borderRadius: "50px",
    height: 23,
  },
}));
