// import React from 'react';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import DepartmentReducer from './DepartmentReducer';
import CompanyReducer from './CompanyReducer';
import authReducer from './AuthReducer';
import authResetReducer from './AuthResetReducer';
import authResetLinkReducer from './AuthResetLinkReducer';
import DesignationReducer from './DesignationReducer';
import EmployeeAuthReducer from './EmployeeAuthReducer';
import HolidayReducer from './HolidayReducer';
import EmployeeReducer from './EmployeeReducer';
// import CompanySettingReducer from './CompanySettingReducer';
import StateReducer from './StateReducer';
import CityReducer from './CityReducer';
import TalentSalaryAppraisalReducer from './TalentSalaryAppraisalReducer';
import EmployeePaymentReducer from './EmployeePaymentReducer';
import EmployeeMonthAttendanceReducer from './EmployeeMonthAttendanceReducer';
import AttendanceDayEmployeeReducer from './AttendanceDayEmployeeReducer'
import LeaveEmployeeReducer from './LeaveEmployeeReducer';
import EmployeeLeaveLogReducer from './EmployeeLeaveLogReducer';
import LeaveComponentReducer from './LeaveComponentReducer';
import PayrollReducer from './PayrollReducer';
import AttendanceReducer from './AttendanceReducer';
// import AttendanceAbbreviationReducer from './AttendanceAbbreviationReducer';
import PaymentReducer from './PaymentReducer';
import FreelancerReducer from './FreelancerReducer';
import ContractReducer from './ContractReducer'
import TandCReducer from './TandCReducer'
import SupportReducer from './SupportReducer';
import UpdateProfileReducer from './UpdateProfileReducer';
import EmployeeAttendanceGraphReducer from './EmployeeAttendanceGraphReducer';
import OnBoardingReducer from './OnBoardingReducer';
import PlanReducer from './PlanReducer';
import RegularizationRequestReducer from "./RegularizationRequestReducer";
import EmployeeFormReducer from './EmployeeFormReducer';
import LeaveMasterReducer from './LeaveMasterReducer';
import GlobalSettingReducer from './GlobalSettingReducer';
import NotificationReducer from './NotificationReducer';
import UserSettingReducer from './UserSettingReducer';
import NotificationCategoryReducer from './NotificationCategoryReducers';
import HRDashboardReducer from './HRDashboardReducer';
import PayrollReportReducer from './PayrollReportReducer';
import SettingReducer from './SettingReducer';
import ScheduleACallReducer from "./ScheduleACallReducer";
import CustomUserRole from './CustomUserRole';
import CalendarDashboardReducer from './CalendarDashboardReducer';
import SubscriptionPlanReducer from "./SubscriptionPlanReducer"
import InvoiceReducer from "./InvoiceReducer"
import AppraisalReportListReducer from './AppraisalReportListReducer';
import CostToCompanyListReducer from './CostToCompanyListReducer';
import ReportCheckInOutReducer from './ReportCheckInOutReducer';


const rootReducer = history => combineReducers({
  router: connectRouter(history),
  authReducer,
  authResetReducer,
  authResetLinkReducer,
  CompanyReducer,
  DepartmentReducer,
  DesignationReducer,
  EmployeeAuthReducer,
  HolidayReducer,
  EmployeeReducer,
  // CompanySettingReducer,
  StateReducer,
  CityReducer,
  TalentSalaryAppraisalReducer,
  EmployeePaymentReducer,
  EmployeeMonthAttendanceReducer,
  AttendanceDayEmployeeReducer,
  LeaveEmployeeReducer,
  EmployeeLeaveLogReducer,
  LeaveComponentReducer,
  PayrollReducer,
  AttendanceReducer,
  // AttendanceAbbreviationReducer,//commented since it's useless as of now
  PaymentReducer,
  FreelancerReducer,
  ContractReducer,
  TandCReducer,
  SupportReducer,
  UpdateProfileReducer,
  EmployeeAttendanceGraphReducer,
  OnBoardingReducer,
  PlanReducer,
  RegularizationRequestReducer,
  LeaveMasterReducer,
  EmployeeFormReducer,
  GlobalSettingReducer,
  NotificationReducer,
  UserSettingReducer,
  NotificationCategoryReducer,
  HRDashboardReducer,
  PayrollReportReducer,
  SettingReducer,
  ScheduleACallReducer,
  CustomUserRole,
  CalendarDashboardReducer,
  SubscriptionPlanReducer,
  InvoiceReducer,
  AppraisalReportListReducer,
  CostToCompanyListReducer,
  ReportCheckInOutReducer,
});

export default rootReducer;