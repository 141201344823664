
import {
    REPORT_BY_CHECK_IN_OUT_DETAIL_FAILURE, REPORT_BY_CHECK_IN_OUT_DETAIL_SUCCESS, REPORT_BY_CHECK_IN_OUT_DETAIL_LOADING
} from '../constants';


const INITIAL_STATE = {
    reportByCheckInOutDetailData: {},
    reportByCheckInOutDetailSuccess: false,
    reportByCheckInOutDetailLoading: false,
    reportByCheckInOutDetailMessage: null,
};

const ReportCheckInOutReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case REPORT_BY_CHECK_IN_OUT_DETAIL_LOADING:
            return {
                ...state,
                reportByCheckInOutDetailLoading: true,
                reportByCheckInOutDetailData: {}
            };

        case REPORT_BY_CHECK_IN_OUT_DETAIL_SUCCESS:
            return {
                ...state,
                reportByCheckInOutDetailLoading: false,
                reportByCheckInOutDetailSuccess: true,
                reportByCheckInOutDetailData: action.payload.data,
                reportByCheckInOutDetailMessage: action.payload.message,
            };

        case REPORT_BY_CHECK_IN_OUT_DETAIL_FAILURE:
            return {
                ...state,
                reportByCheckInOutDetailLoading: false,
                reportByCheckInOutDetailSuccess: false,
                reportByCheckInOutDetailMessage: action.payload.message,
            };
            
        default:
            return state;
    }
};

export default ReportCheckInOutReducer;
