import React from 'react';
import { FormControlLabel, Grid } from '@mui/material';
import { ECardBorderLess } from '../../../BaseComponents/ECard';
import { ELabelInputMainGrid } from '../../../BaseComponents/EGrid';
import { ETextFieldSmall } from '../../../BaseComponents/ETextField';
import { ECustomizedRadioWithTitle } from '../../../BaseComponents';
import { OPTIONS } from '../../../pages/Settings/AttendanceSettings';
import { ECheckbox } from '../../../BaseComponents/ECheckbox';
import { EHelperText } from '../../../BaseComponents/ETypography';


export const AttendanceModeSetting = ({ formik, disableFields }) => {

  const { values, errors, touched, getFieldProps, setFieldValue } = formik

  return (
    <>
      <ECustomizedRadioWithTitle
        label="Select attendance mode to mark attendance"
        extraLabel="Allow employee to mark their attendance only through web/app/biometric device or all of them."
        options={OPTIONS}
        value={true}
        name="enable_biometric"
        onChange={(value) => setFieldValue('enable_biometric', value)}
        error={errors?.enable_biometric || ""}
        disabled={disableFields}
        withOutCheckBox={true}
      />
      <ECardBorderLess sx={{ padding: "18px 24px", mt: 2 }}>
        {/* error for at least one checkbox selection */}
        {
          errors?.at_least_one_field
          &&
          <EHelperText className='color-text-error-red '>
            {errors?.at_least_one_field}
          </EHelperText>
        }

        <Grid container columnSpacing={2}>
          <Grid item md={12} sm={12} xs={12} className="align-center">
            <FormControlLabel
              sx={{ m: 0 }}
              control={
                <ECheckbox
                  disabled={disableFields}
                  sx={{ marginBottom: "0", p: 1, pl: 0 }}
                  checked={values.enable_web ? true : false}
                  onChange={(e, value) => {
                    setFieldValue('enable_web', value)
                  }}
                />
              }
              label={'Web App'}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} className="align-center">
            <FormControlLabel
              sx={{ m: 0 }}
              control={
                <ECheckbox
                  disabled={disableFields}
                  sx={{ marginBottom: "0", p: 1, pl: 0 }}
                  checked={values.enable_mobile ? true : false}
                  onChange={(e, value) => {
                    setFieldValue('enable_mobile', value)
                  }}
                />
              }
              label={'Mobile App'}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} className="align-center">
            <FormControlLabel
              sx={{ m: 0, mb: 1 }}
              control={
                <ECheckbox
                  disabled={disableFields}
                  sx={{ marginBottom: "0", p: 1, pl: 0 }}
                  checked={values.enable_biometric ? true : false}
                  onChange={(e, value) => {
                    if (value && !values?.enable_web && !values?.enable_mobile) {
                      setFieldValue('allow_ip_check', false)
                    }
                    setFieldValue('enable_biometric', value)
                  }}
                />
              }
              label={'Biometric Device'}
            />
            <ELabelInputMainGrid
              label={"Biometric Device Serial No."}
              alignlabel={'align-center'}
              labelPT={'0px'}
            >
              <ETextFieldSmall
                fullWidth
                size='small'
                sx={{ maxWidth: 348 }}
                autoComplete="off"
                placeholder="Serial No."
                {...getFieldProps("biometric")}
                value={values?.biometric || ""}
                error={Boolean(touched.biometric && errors.biometric)}
                helperText={touched.biometric && errors.biometric}
                disabled={true}
              />
            </ELabelInputMainGrid>
          </Grid>
        </Grid>
      </ECardBorderLess>
    </>
  )
}
