
import React from "react";
import { Grid } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { ETypography } from "../../BaseComponents/ETypography";
import { EDivider } from "../../BaseComponents/EDivider";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";


export default function PrimaryContract({ primaryAdmin }) {
    const phoneNumber = primaryAdmin?.mobile ? '+91-' + primaryAdmin.mobile : '-';
    return (
        <ECard p={20}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <ETypography className="font-size-20px bold-600"> Primary Contact </ETypography>

            </Grid>
            <EDivider />
            <Grid container py={1} spacing={1} pt={1} >
                <Grid item md={6} sm={6} xs={12}>
                    <ELabelInputMainGrid label={'Name'} isNotForm={true}>
                        {primaryAdmin?.fullname ? primaryAdmin?.fullname : '-'}
                    </ELabelInputMainGrid>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ELabelInputMainGrid label={'Contact Number'} isNotForm={true}>
                        {/* {primaryAdmin?.mobile ?    primaryAdmin?.mobile : '-'} */}
                        {phoneNumber} 
                    </ELabelInputMainGrid>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ELabelInputMainGrid label={'Email ID'} isNotForm={true}>
                        {primaryAdmin?.email ? primaryAdmin?.email : '-'}
                    </ELabelInputMainGrid>
                </Grid>
            </Grid>
        </ECard>
    )
}
