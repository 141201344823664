// dharam code
import React, { useEffect } from 'react'
import { FormikProvider, useFormik, Form } from 'formik';
import { Grid, FormControl, FormControlLabel } from "@mui/material";
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { EFieldLabel, ETypography } from '../../BaseComponents/ETypography';
import { ECardBorderLess } from '../../BaseComponents/ECard';
import { ETextFieldSearch } from '../../BaseComponents/ETextField';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { useNavigate } from 'react-router-dom';
import { useCalculatePlan } from '../../hooks/useCalculateplan';
import { PLAN_TIME_FREQUENCY_TYPE } from '../../constants';
import { addMoreTalent, completePlanPayment } from '../../action/OnBoardingAction';
import { useDispatch, useSelector } from 'react-redux';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import moment from 'moment';
import { AddMoreTalentValidation } from '../../utils/validation';
import { getBillTenure } from '../../utils/CommonFunctions';
import BillingInfo from '../../PageComponents/Accounts/BillingInfo';
import { PaymentSummary } from './PaymentSummary';
import { EPageWithBreadCrumbs } from '../../BaseComponents';


function AddMoreTalent({ isUpgradePlan = false }) {
  const title = isUpgradePlan ? 'Upgrade Plan' : 'Add More Users'
  const { subscriptions, calculatePlan, calculationObj } = useCalculatePlan(isUpgradePlan)
  const navigate = useNavigate()
  const auth = useSelector(state => state.authReducer)
  const settingsContext = useCompanySettingProvider()
  const accountSetting = settingsContext?.setting?.accountSettingData?.companySetting

  const dispatch = useDispatch();


  const upgradePlanValues = {
    plan: accountSetting?.activePlan?.plan_time_frequency_type === PLAN_TIME_FREQUENCY_TYPE.MONTH ? 2 : 3,
    noOfTalent: "",
    plan_data: subscriptions.find(item => item.plan_time_frequency_type == accountSetting?.activePlan?.plan_time_frequency_type) || [],
    plan_type: accountSetting?.activePlan?.plan_time_frequency_type,
    minimum_employee: accountSetting?.activePlan?.minimum_employee ? accountSetting?.activePlan?.minimum_employee : 1,
    maximum_employee: accountSetting?.activePlan?.maximum_employee ? accountSetting?.activePlan?.maximum_employee : 999,
  }


  const paidInitialValues = {
    plan: accountSetting?.activePlan?.plan_id || 3,
    noOfTalent: "",
    plan_data: subscriptions.find(item => item.id == accountSetting?.activePlan?.plan_id) || [],
    plan_type: accountSetting?.activePlan?.plan_time_frequency_type,
    minimum_employee: accountSetting?.activePlan?.minimum_employee ? accountSetting?.activePlan?.minimum_employee : 1,
    maximum_employee: accountSetting?.activePlan?.maximum_employee ? accountSetting?.activePlan?.maximum_employee : 999,
  }

  // saving object return from useFormik as formik
  const formik = useFormik({
    validateOnChange: true,
    // IniitalValues for input fields
    initialValues: isUpgradePlan ? upgradePlanValues : paidInitialValues,
    enableReinitialize: true,
    // Validation for input fields
    validationSchema: AddMoreTalentValidation,
    // onSubmit action
    onSubmit: (data) => {
      dispatch(addMoreTalent(auth.authtoken, {
        plan_id: data?.plan_data?.id,
        no_of_talents: data?.noOfTalent,
        is_upgrade: isUpgradePlan,
      }, (res) => {
        console.log(res.data.paymentUrl);
        window.location.href = res.data.paymentUrl

      }));
    },
  });


  // Formik Props
  const { getFieldProps, values, setFieldValue, touched, errors } = formik;


  useEffect(() => {
    // if(values?.noOfTalent > 0){
    calculatePlan(values?.plan_type, values?.noOfTalent)
    // }

  }, [values?.noOfTalent])

  return (
    <EPageWithBreadCrumbs
      title={title}
      pageHeading={title}
      breadcrumbsTitle={title}
      breadcrumbsLinks={[
        { name: "Dashboard", href: "/" },
        { name: "Profile", href: "" },
        { name: "My Subscription", href: "/my-subscription" },
        { name: title, href: "" },
      ]}
      // loading={globalSettingLoading || Object.keys(globalSettingData || {}).length === 0}
      hideDivider={true}
    >
      <EBoxPage>
        <FormikProvider value={formik}>
          <Form noValidate>
            <Grid container spacing={2} style={{ padding: 0 }}>
              {/*  Payment Information */}

              <Grid item sm={12} md={7} lg={7} xl={8}>
                <ECardBorderLess className='p-34px'>
                  <ETypography className='font-size-20px theme-color-static bold-400'>
                    {isUpgradePlan ? 'Subscription Details' : 'Additional License'}
                  </ETypography>



                  {/* Existing Plan */}
                  {
                    isUpgradePlan
                      ?
                      <ETypography className='font-size-14px bold-600 py-10px'>
                        Upgrading To
                        <br />
                        <span className='bold-400 greyColor5-color'>
                          {values?.plan_data?.plan_name}
                        </span>
                      </ETypography>
                      :
                      <ETypography className='font-size-14px bold-600 py-10px'>
                        Existing Plan
                        <br />
                        <span className='bold-400 greyColor5-color'>
                          {accountSetting?.activePlan?.plan_name}
                          {/* {`(${moment(moment(accountSetting?.activePlan?.plan_valid_from)).format('MMM YYYY')})`}  */}
                          {" "}({`${values?.plan_type == PLAN_TIME_FREQUENCY_TYPE.MONTH ? moment().format('MMM YYYY') : `${moment().format('MMM YYYY')} to Dec ${moment().format('YYYY')}`}`})
                        </span>
                      </ETypography>
                  }



                  {/* Subscription Type  */}
                  <ECardBorderLess className='Bg-card4' >

                    <Grid container padding={2} spacing={1} justifyContent={'space-between'}>
                      {/* Radio Button */}
                      {
                        isUpgradePlan &&
                        <EBox paddingLeft={1} marginBottom={1}>
                          <EFieldLabel className='font-size-14px bold-600 '>Billing Cycle</EFieldLabel>
                          <br />
                          <FormControl component="fieldset" className='mb-minus-10px mt-minus-6px'>
                            <ERadioGroup
                              {...getFieldProps('plan')}
                              row={true}
                            >
                              {
                                subscriptions?.length > 0 && subscriptions?.map(subscription => {
                                  // if (!subscription.is_paid) return null
                                  return (
                                    <FormControlLabel
                                      key={subscription.id}
                                      value={Number(subscription.id)}
                                      control={
                                        <ERadio
                                          onChange={() => {
                                            setFieldValue('plan', subscription?.id)
                                            setFieldValue('plan_type', subscription?.plan_time_frequency_type)
                                            setFieldValue('plan_data', subscription)
                                            if (values?.noOfTalent < subscription?.minimum_employee) {
                                              setFieldValue('noOfTalent', subscription?.minimum_employee)
                                            }
                                            setFieldValue('minimum_employee', subscription?.minimum_employee)
                                            setFieldValue('maximum_employee', subscription?.maximum_employee)
                                            calculatePlan(subscription?.plan_time_frequency_type, values?.noOfTalent < subscription?.minimum_employee ? subscription?.minimum_employee : values?.noOfTalent)
                                          }}
                                        />
                                      }
                                      label={subscription?.title}
                                    />
                                  )
                                }

                                )

                              }

                            </ERadioGroup>
                            {/* {touched.plan && <EFormHelperText sx={{ marginTop: '0px !important', color: '#D14343' }}> {errors.plan}</EFormHelperText>} */}
                          </FormControl>
                        </EBox>
                      }


                      {/* Bill Tenure */}
                      <Grid item sm={8}>
                        <ETypography className='font-size-14px bold-600 '>
                          Bill Tenure
                          <br />
                          <span className='bold-400 greyColor5-color'>
                            {getBillTenure(values?.plan_type, null, isUpgradePlan ? values?.plan_data?.plan_valid_to : accountSetting?.activePlan?.plan_valid_to)} {isUpgradePlan ? values?.plan_type === PLAN_TIME_FREQUENCY_TYPE.MONTH ? '(Billed Monthly)' : '(Billed Annually)' : null}
                          </span>
                        </ETypography>
                      </Grid>

                    </Grid>

                    {/* No. of Talent */}
                    <Grid paddingX={2} marginBottom={0} container flexDirection={'column'}>
                      <Grid item>
                        <EFieldLabel className='font-size-14px bold-600'>No. of employee adding in existing plan</EFieldLabel>
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} >

                        <ETextFieldSearch
                          name='noOfTalent'
                          {...getFieldProps('noOfTalent')}
                          value={values?.noOfTalent}
                          onChange={(e) => {
                            if (Number(e.target.value) > values?.plan_data.maximum_employee) return
                            setFieldValue('noOfTalent', e.target.value)
                          }}

                          sx={{ width: '185px' }}
                          type='number'
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: values?.plan_data?.minimum_employee, max: values?.plan_data.maximum_employee }}
                          error={Boolean(touched.noOfTalent && errors.noOfTalent)}
                          helperText={touched.noOfTalent && errors.noOfTalent}
                        />
                      </Grid>
                    </Grid>

                    {/* *Annual billing begins with min. 20 talents (₹ 75.00/talent/month) */}
                    <Grid paddingX={2} item>
                      <ETypography className='bold-400 font-size-12px greyColor5-color'>
                        {isUpgradePlan && (`*Annual billing begins with min. ${values?.plan_data?.minimum_employee} talents (₹ ${Number(values?.plan_data?.price).toFixed(2)}/talent/month)`)}
                      </ETypography>
                    </Grid>
                  </ECardBorderLess>

                  {/* Billing Address */}
                  <BillingInfo />
                </ECardBorderLess>
              </Grid>

              {/*   Payment Summary  */}
              <Grid item sm={12} md={5} lg={5} xl={4}>
                <PaymentSummary
                  data={{ ...values, ...calculationObj }}
                  isAdditonal
                  title={getBillTenure(values?.plan_type, null, isUpgradePlan ? values?.plan_data?.plan_valid_to : accountSetting?.activePlan?.plan_valid_to)}
                  skipButton={
                    <ETypography onClick={() => navigate(-1)} mt={1} className='text-align cursor-pointer' >
                      Go Back
                    </ETypography>
                  }
                />
              </Grid>

            </Grid>

          </Form>
        </FormikProvider>
      </EBoxPage>
    </EPageWithBreadCrumbs>
  )
}

export default AddMoreTalent
