
import {
    APPRAISAL_REPORT_LIST_FAILURE, APPRAISAL_REPORT_LIST_SUCCESS, APPRAISAL_REPORT_LIST_LOADING
} from '../constants';


const INITIAL_STATE = {
    appraisalReportListData: {},
    appraisalReportListSuccess: false,
    appraisalReportListLoading: false,
    appraisalReportListMessage: null,
};

const AppraisalReportListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case APPRAISAL_REPORT_LIST_LOADING:
            return {
                ...state,
                appraisalReportListLoading: true,
                appraisalReportListData: {}
            };

        case APPRAISAL_REPORT_LIST_SUCCESS:
            return {
                ...state,
                appraisalReportListLoading: false,
                appraisalReportListSuccess: true,
                appraisalReportListData: action.payload.data,
                appraisalReportListMessage: action.payload.message,
            };

        case APPRAISAL_REPORT_LIST_FAILURE:
            return {
                ...state,
                appraisalReportListLoading: false,
                appraisalReportListSuccess: false,
                appraisalReportListMessage: action.payload.message,
            };
            
        default:
            return state;
    }
};

export default AppraisalReportListReducer;
