import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { HoldPaymentFormValidation } from "../../utils/validation";
import { CancelTalentPayment } from "../../action/PaymentAction";
import { Grid } from "@mui/material";
import { ETypography } from "../../BaseComponents/ETypography";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { useParams } from "react-router-dom";
import { CANCELLED_STATUS, PAID_STATUS, PARTIALLY_PAID_STATUS, PARTIAL_STATUS, PAYMENT_TRANSACTION_APPROVAL_STATUS_COLOR, SALARY_PAYMENT_MEDIUM, SALARY_PAYMENT_TRANSACTION_APPROVAL_STATUS } from "../../constants";
import moment from "moment";
import { EBox } from "../../BaseComponents/EBox";
import { EIcon } from "../../BaseComponents/EIcon";
import DownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded'
import { numberWithCommas } from "../../utils/formatNumber";


const SalaryStatusDetalViewModal = (props) => {
  const { paymentData, setSalaryDueModal, auth, getPaymentFilterData } = props;

  const dispatch = useDispatch();
  const { id } = useParams()

  const formik = useFormik({
    initialValues: {
      remark: ''
    },
    validationSchema: HoldPaymentFormValidation,
    onSubmit: (data) => {
      data = {
        ...data,
        employee_id: paymentData?.employeeData?.id,
        payroll_id: paymentData?.payrollData?.id,
        transaction_id: paymentData?.id,
        created_by: auth?.authData?.id
      };
      dispatch(CancelTalentPayment(auth.authtoken, data, getPaymentFilterData, id));
      setSalaryDueModal({ modalData: null, modalCategory: null })

    }
  });


  return (
    <>

      <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={() => null} >

          <Grid container spacing={2} sx={{ paddingTop: "10px" }} >

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true} isNotForm={true}>
                <ETypography>{paymentData?.employeeData?.fullname} {`(${paymentData?.employeeData?.employee_code})`}</ETypography>
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Payable Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
                <ETypography>{paymentData?.PayableAmt}</ETypography>
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid
                label={'Status'}
                isModal={true}
                isfullgrid={true}
                isNotForm={true}
              >
                <ETypography
                  fontWeight={600}
                  className={`${PAYMENT_TRANSACTION_APPROVAL_STATUS_COLOR[paymentData?.Status]}`}
                >
                  {SALARY_PAYMENT_TRANSACTION_APPROVAL_STATUS[paymentData?.Status]}
                </ETypography>
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Paid Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
                <ETypography>{paymentData?.PaidAmt}</ETypography>
              </ELabelInputMainGrid>
            </Grid>

            {
              paymentData?.childTransaction?.length > 0
              &&
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <ELabelInputMainGrid label={'Payment Details'} isfullgrid={true} isModal={true} isNotForm={true} sx={{}}>
                  {
                    paymentData?.childTransaction?.map((item, index) => {
                      return (
                        <EBox sx={{ marginBottom: '16px' }} key={index}>
                          <EBox sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
                            <ETypography>
                              {`${numberWithCommas(item?.collect_amount)} (By ${SALARY_PAYMENT_MEDIUM[item?.payment_medium]} - ${moment(item?.transaction_date).format('DD/MM/YYYY')}) `}
                            </ETypography>
                            <EIcon icon={DownloadIcon} />
                          </EBox>
                          <ETypography sx={{ fontSize: '14px' }}>
                            {`${item?.remark}`}
                          </ETypography>
                        </EBox>
                      )
                    })
                  }
                </ELabelInputMainGrid>
              </Grid>
            }

            {
              (paymentData?.transaction_status == CANCELLED_STATUS)
                ?
                <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ marginTop: paymentData?.childTransaction?.length > 0 ? '-16px' : '0px' }}>
                  <ELabelInputMainGrid label={'Cancelled Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
                    <ETypography>{paymentData?.CancelledAmt}</ETypography>
                    <ETypography sx={{ fontSize: '14px' }}>
                      {`${paymentData?.remark}`}
                    </ETypography>
                  </ELabelInputMainGrid>
                </Grid>
                :
                <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ marginTop: paymentData?.childTransaction?.length > 0 ? '-16px' : '0px' }}>
                  <ELabelInputMainGrid label={'Balance Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
                    <ETypography>{paymentData?.BalanceAmt}</ETypography>
                  </ELabelInputMainGrid>
                </Grid>
            }

          </Grid>

          <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
            <EButtonOutlined size="large" variant="outlined"
              onClick={() => setSalaryDueModal({ modalData: null, modalCategory: null })}
            >
              Close
            </EButtonOutlined>

            {
              (paymentData?.transaction_status == PARTIALLY_PAID_STATUS || paymentData?.transaction_status == PARTIAL_STATUS)
              &&
              <ELoadingButton
                size="large" variant="outlined" type="submit" >Download All</ELoadingButton>
            }

            {
              (paymentData?.transaction_status == PAID_STATUS)
              &&
              <ELoadingButton
                size="large" variant="outlined" type="submit" >Download Salary Slip</ELoadingButton>
            }

          </Stack>


        </Form>
      </FormikProvider>
    </>
  );
};

export default SalaryStatusDetalViewModal