import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_ROWS_PERPAGE, PAGES, REPORT_BY_DATE, REPORT_BY_TALENT,
  REPORT_BY_TIME, WORK_MODE_HOME, WORK_MODE_HOME_LABEL, WORK_MODE_HYBRID,
  WORK_MODE_HYBRID_LABEL, WORK_MODE_OFFICE, WORK_MODE_OFFICE_LABEL,
  ATTENDANCE_LOG_REPORT_DOWNLOAD_URL,
  // CheckInStatusIconAndMessage,
  CheckOutStatusIconAndMessage,
  DeviceWiseAttenanceObject
} from '../../constants';
import { useNavigate } from 'react-router';
import Page from '../../BaseComponents/EPage';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { EButton, EButtonOutlined, EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ECardBasic } from '../../BaseComponents/ECard';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow, ETypographyTable } from '../../BaseComponents/ETable';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import { EDivider } from '../../BaseComponents/EDivider';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import { DownLoadFile } from '../../action/AdminAction';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import ReportSearchBar from '../../PageComponents/Report/ReportSearchBar';
import DateMonthScroller from '../../PageComponents/Report/DateMonthScroller';
import { UserAttendanceReportLog } from '../../action/ReportAction';
import { EBoxPage } from '../../BaseComponents/EBox';
import EChip from '../../BaseComponents/EChip';
import ReportsResultsTime from './ReportResultsTime';
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import homeIcon from '@iconify/icons-material-symbols/home';
import apartmentIcon from '@iconify/icons-material-symbols/apartment-rounded';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { PaymentSearchFilter } from '../../PageComponents/Payment/PaymentSearchFilter';

/**
 * [2022-12-01]
 * Created by:- Aanchal Sahu
 * Description:- designed this component according to the new theme
 **/

function ReportsResults(props) {
  const { report } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const attendance = useSelector((state) => state.EmployeeMonthAttendanceReducer);
  const company = useSelector((state) => state.CompanyReducer);
  const auth = useSelector((state) => state.authReducer);
  const Theme = useSelector((state) => state.ThemeReducer);
  const [SearchParameters, setSearchParameters] = useState({
    start_date: report == REPORT_BY_TIME ? new Date() : null,
    end_date: report == REPORT_BY_TIME ? new Date() : null,
    start: new Date(),
    end: new Date(),
    sort: report == REPORT_BY_TIME ? false : null,
    page: PAGES,
    rowsPerPage: 10,
    date: new Date(),
    report_type: report,
    employee_id: report != REPORT_BY_DATE ? auth?.authData?.id : null,
    employee_details: null,
    employee: null,
    attendance_date: report == REPORT_BY_DATE ? new Date() : null,
    attendance_month: report != REPORT_BY_DATE ? new Date() : null,
    attendance_time: report == REPORT_BY_TIME ? moment().format() : null,
    startTime: report == REPORT_BY_TIME ? moment().format() : null,
  });

  const [dateError, setDateError] = useState({
    startDateError: null,
    endDateError: null
  })

  const attendanceDay = useSelector(state => state.AttendanceDayEmployeeReducer);

  // INFO: totalNoOfPages is for card pagination total page count

  const TABLE_HEAD = [];
  TABLE_HEAD.push(
    { id: 'S.No.', label: 'S.No.', alignRight: 'center' },
  );
  if (report == REPORT_BY_DATE) {
    TABLE_HEAD.push(
      { id: 'Employee Name', label: 'Employee Name', alignRight: false }
    );
  } else {
    TABLE_HEAD.push({ id: 'Date', label: 'Date', alignRight: false });
  }

  TABLE_HEAD.push(
    { id: 'Login Time', label: 'Login Time', alignRight: 'center' },
    { id: 'Logout Time', label: 'Logout Time', alignRight: 'center' },
    { id: 'Check-in Time', label: 'Check In Time', alignRight: 'center' },
    { id: 'Check-out Time', label: 'Check Out Time', alignRight: 'center' },
    { id: 'Default Attendance', label: 'Default Attendance', alignRight: 'center' },
    { id: 'Remark', label: 'Remark', alignRight: 'center' },
    { id: 'Modified To', label: 'Modified To', alignRight: 'center' },
    { id: 'Date & Time of Modulation', label: 'Date & Time of Modulation', alignRight: 'center', notCapitalize: true },
  );

  const [page, setPage] = useState(PAGES);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);


  useEffect(() => {
    if (attendance?.empMonthAttLoading == false) {
      dispatch(UserAttendanceReportLog(auth.authtoken, SearchParameters, page, rowsPerPage));
    }
  }, [report, page, rowsPerPage, SearchParameters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PERPAGE));
    setPage(0);
  };

  const onSearch = (params) => {
    let searchParams = {
      ...SearchParameters,
      ...params,
      date: report == REPORT_BY_DATE ? params.start : moment().startOf('month').toDate()
    }
    const startDate = searchParams?.start ? moment(searchParams?.start) : moment()
    const endDate = searchParams?.end ? moment(searchParams?.end) : moment()
    if (report == REPORT_BY_DATE) {
      searchParams.attendance_date = startDate
      searchParams.start_date = startDate
      searchParams.start = startDate
      searchParams.end_date = endDate
      searchParams.end = endDate
      searchParams.attendance_month = null
      searchParams.employee_details = null
      searchParams.employee = null
    } else if (report == REPORT_BY_TIME) {
      searchParams.attendance_date = null;
      searchParams.attendance_month = null;
      searchParams.employee_details = null
      searchParams.employee = null
      searchParams.attendance_time = params?.startTime ? params?.startTime : new Date();
      searchParams.startTime = params?.startTime ? params?.startTime : new Date();
      searchParams.sort = params?.sort;
      searchParams.start_date = params?.start ? startDate : null;
      searchParams.end_date = params?.end ? endDate : null;
    } else if (report == REPORT_BY_TALENT) {
      searchParams.attendance_month = moment(searchParams?.date).format('YYYYMM');
      searchParams.employee_id = searchParams?.employee ? searchParams?.employee?.id : auth?.authData?.id
      searchParams.attendance_date = null;
    }
    setDateError(prevState => ({
      ...prevState,
      endDateError: params?.end ? null : 'Please select end date.',
      startDateError: params?.start ? null : 'Please select start date.'
    }));

    setSearchParameters(searchParams);
    setRowsPerPage(DEFAULT_ROWS_PERPAGE);
    setPage(0);
  };

  const getSelectedDateMonth = (paramDate) => {

    const searchParams = {
      ...SearchParameters,
      date: paramDate,
    }
    if (report == REPORT_BY_DATE) {
      searchParams.attendance_date = moment(paramDate).format('YYYY-MM-DD');
      searchParams.attendance_month = null;
      searchParams.employee_details = null;
    }
    if (report == REPORT_BY_TALENT) {
      searchParams.attendance_month = moment(paramDate).format('YYYYMM');
      searchParams.attendance_date = null
    }
    setSearchParameters(searchParams);
    setRowsPerPage(DEFAULT_ROWS_PERPAGE);
    setPage(0);
  };

  const downloadSearchReport = (type) => {

    const sort = SearchParameters?.sort;
    const attendance_date = SearchParameters.attendance_date;
    const attendance_month = SearchParameters.attendance_month ? moment(SearchParameters.attendance_month).format('YYYYMM') : null;
    const employee_id = SearchParameters.employee_id ? SearchParameters.employee_id : null;
    const attendance_time = SearchParameters.attendance_time ? moment(SearchParameters.attendance_time).format('HH:mm') : null;
    const from_date = SearchParameters.start_date ? moment(SearchParameters.start_date).format('YYYY-MM-DD') : null;
    const to_date = SearchParameters.end_date ? moment(SearchParameters.end_date).format('YYYY-MM-DD') : null;

    const reportParams = {
      [REPORT_BY_DATE]: { title: 'Date Wise Report', report_type: 'BYDATE' },
      [REPORT_BY_TIME]: { title: 'Time Wise Report', report_type: 'BYTIME' },
      [REPORT_BY_TALENT]: { title: 'Employee Attendance Log Report', report_type: 'BYNAME' },
    }

    let params = {
      ...((sort === true || sort === false) && { sort }),
    }

    if (REPORT_BY_TALENT === report) {
      params = {
        ...params,
        ...(attendance_month && REPORT_BY_TALENT === report && { attendance_month }),
        ...(employee_id && { employee_id }),
      }
    }
    if (REPORT_BY_DATE === report) {
      params = {
        ...params,
        ...(attendance_date && { attendance_date }),
      }
    }
    if (REPORT_BY_TIME === report) {
      params = {
        ...params,
        ...(attendance_time && { attendance_time }),
        ...(from_date && { from_date }),
        ...(to_date && { to_date }),
      }
    }
    params = {
      ...params,
      report_format: type,
      report_type: reportParams[report] ? reportParams[report].report_type : '',
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${ATTENDANCE_LOG_REPORT_DOWNLOAD_URL}?${queryString}`;
    DownLoadFile(auth.authtoken, url, reportParams[report].title)
  }

  return (
    <Page title="Attendance Log Report">
      <Grid container display="row" justifyContent="space-between">
        <Grid item>
          <EHeaderBreadcrumbs
            heading={report == REPORT_BY_TALENT ? 'By Name' : report == REPORT_BY_TIME ? 'By Time' : 'By Date'}
            links={[
              { name: 'Dashboard', href: '/' },
              { name: 'Report', href: '/report' },
              { name: 'Attendance Log Report', href: '/report/attendance-log-report' },
              { name: report == REPORT_BY_TALENT ? 'By Name' : report == REPORT_BY_TIME ? 'By Time' : 'By Date' }
            ]}
          />
        </Grid>
        <Grid item>
          <EHidden width="mdDown">
            <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} color="secondary">
              Back
            </EButtonOutlined>
          </EHidden>
        </Grid>
      </Grid>


      <Grid container display="row" justifyContent="space-between" className='pb-2rem margin-top-20px'>
        <Grid item xs={12} sm={12} className='display-flex justify-content-space-between'>
          <ETypographyPageHeading variant='h5'>Attendance Log Report - {report == REPORT_BY_TALENT ? 'By Name' : report == REPORT_BY_TIME ? 'By Time' : 'By Date'}</ETypographyPageHeading>
          <EHidden width="mdUp">
            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
              <EIcon icon={ForwardArrow} className='height-width-22px' /> </EButtonOutlinedIcon>
          </EHidden>
        </Grid>
        <EHidden width="mdDown">
          <Grid lg={12} xs={12} item>
            <EDivider className='margin-top-05' />
          </Grid>
        </EHidden>
      </Grid>

      <EBoxPage>

        <PaymentSearchFilter
          initialFilter={SearchParameters}
          onSearch={onSearch}
          onSort={onSearch}
          DownloadReport={downloadSearchReport}
          showTalents={report === REPORT_BY_TALENT}
          start={report !== REPORT_BY_TALENT && {
            placeholder: 'Start Date',
            views: ['year', 'month', 'day'],
            inputFormat: 'dd/MM/yyyy',
            order: 1,
            maxDate: new Date(),
            error: report == REPORT_BY_TIME && Boolean(dateError?.startDateError),
            helperText: report == REPORT_BY_TIME && dateError?.startDateError ? dateError?.startDateError : null,
          }}
          end={report !== REPORT_BY_TALENT && {
            placeholder: 'End Date',
            views: ['year', 'month', 'day'],
            inputFormat: "dd/MM/yyyy",
            order: 2,
            maxDate: new Date(),
            error: report == REPORT_BY_TIME && Boolean(dateError?.endDateError),
            helperText: report == REPORT_BY_TIME && dateError?.endDateError ? dateError?.endDateError : null,
          }}
          startTime={report === REPORT_BY_TIME && {
            placeholder: 'hh:mm',
            order: 3,
          }}
          particularPlaceholder="All Designations"
          talentOrder={1}
          particularsOrder={2}
          statusOrder={3}
        />
        {report != REPORT_BY_TIME &&
          <ECardBasic sx={{ mb: 5, padding: '0px', marginTop: '16px' }} className='theme-color-border mb-16px'>

            {report == REPORT_BY_TALENT && <Grid mt={2} >

              <ETypography variant='h6' align='center' >
                {SearchParameters?.employee_id != auth?.authData?.id && SearchParameters?.employee_details?.name ?
                  <>
                    {SearchParameters?.employee_details?.name ? SearchParameters?.employee_details?.name : ''}
                  </>
                  : 'My Attendance'}
              </ETypography>

            </Grid>}
            <DateMonthScroller
              isDate={report == REPORT_BY_TALENT ? false : true}
              {...SearchParameters}
              onClick={getSelectedDateMonth}
              company={company}

            />
          </ECardBasic>
        }
        {attendance?.empMonthAttLoading && Object.keys(attendance?.empMonthAttData)?.length == 0 ?
          <ETableContainer>
            <ETable>
              <ETableBody>
                <ETableRow>
                  <ETableCell align="center" colSpan={10} sx={{ py: 3 }}>
                    <CircularProgress color="primary" />
                  </ETableCell>
                </ETableRow>
              </ETableBody>
            </ETable>
          </ETableContainer>
          :
          !attendance?.empMonthAttLoading && (attendance?.empMonthAttData?.rows?.length == 0 || Object.keys(attendance?.empMonthAttData)?.length == 0) ?
            <SearchNotFound />
            :
            Object.keys(attendance?.empMonthAttData)?.length > 1 && report != REPORT_BY_TIME ?
              <>
                <EScrollbar>
                  <ETableContainer>
                    <ETable>
                      <EListHead
                        headLabel={TABLE_HEAD}
                        rowCount={attendance?.empMonthAttData?.rows?.length}
                        onRequestSort={() => null}
                        authvar={auth}
                        Theme={Theme}
                      />
                      {attendance?.empMonthAttLoading && (
                        <ETableBody>
                          <ETableRow>
                            <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                              <ECircularProgress color="primary" />
                            </ETableCell>
                          </ETableRow>
                        </ETableBody>
                      )}
                      {!attendance?.empMonthAttLoading && attendance?.empMonthAttData?.rows?.length > 0 && (
                        <ETableBody>
                          {attendance?.empMonthAttData?.rows?.map((row, index) => {
                            const {
                              date,
                              fullname,
                              login_logout,
                              check_in_time,
                              check_out_time,
                              checkin_is_mobile,
                              checkout_is_mobile,
                              check_in_device,
                              check_out_device,
                              system_gen_abb,
                              system_remark,
                              modulated_abb,
                              employee_code,
                              updated_at, is_regularized, regularize_remark,
                              check_in_from, work_mode_attendance_remark, work_mode
                            } = row;


                            const checkInStatus = DeviceWiseAttenanceObject[check_in_device];
                            const checkOutStatus = DeviceWiseAttenanceObject[check_out_device];

                            const isToday = moment(date).isSame(new Date(), "day");
                            let loginLogoutArray = [];

                            if (login_logout) {
                              loginLogoutArray = login_logout.split(',');
                            }

                            return (
                              <ETableRow hover key={index} tabIndex={-1} >
                                <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px' : ""}>
                                  {index + 1}
                                </ETableCell>
                                {report == REPORT_BY_DATE ? (
                                  <>
                                    {/* Talent Name */}
                                    <ETableCell align="left"
                                      className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px minWidth-150 ' : "min-width-150px max-width-300px"}> {/*table-cells-padding-vertical-align*/}
                                      <ETypography className="dark-grey-color bold-font font-size-14px bold-600">
                                        {fullname}<br />
                                        <span className="bold-400">
                                          ({employee_code})
                                        </span>

                                        {/* workModeIndicationInfo returns work mode according to various conditions */}
                                        {workModeIndicationInfo(check_in_from, work_mode_attendance_remark, work_mode, is_regularized)}
                                      </ETypography>
                                    </ETableCell>
                                  </>
                                ) : (
                                  <ETableCell align="left" style={{ verticalAlign: 'top' }} className="width-160px pt-16px">
                                    {moment(date).format('DD/MM/YYYY')}
                                  </ETableCell>
                                )}

                                {/* Login Time */}
                                <ETableCell className={loginLogoutArray?.length > 1 ? "width-80px width-130px px0 pt8px pb-8px" : 'width-80px px0 '} align="center">
                                  {loginLogoutArray?.length > 0 ?
                                    loginLogoutArray?.map((row, index) => {
                                      const arrayIn = row.split("=");

                                      return (
                                        <Grid
                                          container
                                          flexDirection={'column'}
                                          spacing={1}
                                          key={index + arrayIn[0].replace(/\s/g, "")}
                                        >
                                          <Grid item >
                                            <ETypography className={'align-center height-100per table-font-size '}>

                                              {arrayIn[0]
                                                ? moment(arrayIn[0]).format('HH:mm')
                                                : '-'}
                                            </ETypography>
                                          </Grid>
                                          {loginLogoutArray.length > index + 1 ? (
                                            <Grid item>
                                              <EDivider className="position-relative borderColor-staticBorder7 mb-8px" />
                                            </Grid>
                                          ) : (
                                            ''
                                          )}
                                        </Grid>
                                      );
                                    }) : "-"}
                                </ETableCell>

                                {/* Logout Time */}
                                <ETableCell className={loginLogoutArray?.length > 1 ? "width-130px px0 pt8px pb-8px" : 'width-130px px0 '} align="center">
                                  {loginLogoutArray?.length > 0 ?
                                    loginLogoutArray?.map((row, index) => {
                                      const arrayOut = row.split("=");
                                      return (
                                        <Grid
                                          container
                                          flexDirection={'column'}
                                          spacing={1}
                                          key={index + arrayOut[1]}
                                          className={loginLogoutArray.length <= index + 1 ? '' : 'mb-8px'}
                                        >
                                          <Grid item>
                                            <ETypography className={'align-center width-80px height-100per table-font-size'}>
                                              {arrayOut[1] && arrayOut[1] != '-'
                                                ? moment(arrayOut[1]).format('HH:mm')
                                                : '-'}
                                            </ETypography>
                                          </Grid>
                                          {loginLogoutArray.length > index + 1 ? (
                                            <Grid item>
                                              <EDivider className="position-relative borderColor-staticBorder7 " />
                                            </Grid>
                                          ) : (
                                            ''
                                          )}
                                        </Grid>
                                      );
                                    }) : '-'}
                                </ETableCell>

                                {/* Check in Time */}
                                <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px' : ""}>
                                  <ETypographyTable className={'justify-content-center align-center width-80px' +
                                    (is_regularized == 1 && regularize_remark != null ? ' ' + ' theme-main-text-color-bold' : '')}>
                                    {
                                      check_in_time && checkInStatus &&
                                      <ETooltip title={checkInStatus?.CheckInMessage} arrow>
                                        <EIcon icon={checkInStatus?.icon} className='responsive-display' />
                                      </ETooltip>
                                    }
                                    {check_in_time ? moment(check_in_time).format('HH:mm') : '-'}
                                  </ETypographyTable>
                                </ETableCell>

                                {/* Check out Time */}
                                <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px' : "width-130px  "}>
                                  <ETypographyTable className={'width-80px justify-content-center align-center ' +
                                    (is_regularized == 1 && regularize_remark != null ? ' ' + ' theme-main-text-color-bold' : '')}>

                                    {
                                      check_out_time
                                      &&
                                      <ETooltip title={checkOutStatus?.CheckOutMessage} arrow>
                                        <EIcon icon={checkOutStatus?.icon} className='responsive-display' />
                                      </ETooltip>
                                    }
                                    {check_out_time ? moment(check_out_time).format('HH:mm') : '-'}
                                  </ETypographyTable>
                                </ETableCell>

                                {/* Default Attendance */}
                                <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px  ' : "width-130px  "}>
                                  <ETypographyTable className='justify-content-center align-center'>
                                    {system_gen_abb && !isToday ? <EChip label={system_gen_abb} /> : '-'}</ETypographyTable>
                                </ETableCell>

                                {/* Remark */}
                                <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px  ' : "width-130px  "}>
                                  {system_remark ?
                                    <ETooltip title={system_remark} arrow>
                                      <EIcon icon={InfoIcon} />
                                    </ETooltip>
                                    : '-'}
                                </ETableCell>

                                {/* Modified To */}
                                <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-130px  ' : "width-130px  "}>
                                  {modulated_abb && !isToday ? <EChip label={modulated_abb} /> : '-'}
                                </ETableCell>

                                {/* Date & Time of Modulation */}
                                <ETableCell align="center" className={loginLogoutArray?.length > 1 ? 'table-cells-padding-vertical-align pt-16px width-160px' : ""}>
                                  <ETypography className='width-160px'>
                                    {updated_at ? moment(updated_at).format('DD/MM/YYYY HH:mm') : '-'}
                                  </ETypography>
                                </ETableCell>
                              </ETableRow>
                            );
                          })}
                        </ETableBody>
                      )}

                    </ETable>
                  </ETableContainer>
                  <ETablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={attendance?.empMonthAttData?.count ? attendance?.empMonthAttData?.count : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </EScrollbar>
              </>
              :
              <ReportsResultsTime attendance={attendance} SearchParameters={SearchParameters}
                setSearchParameters={setSearchParameters}
                page={page} setPage={setPage}

              />
        }
      </EBoxPage>
    </Page>
  );
}
export default ReportsResults;

// Returns work mode WRT all conditions 
const workModeIndicationInfo = (check_in_from, work_mode_attendance_remark, work_mode, is_regularized) => {

  // conditions for label according to work_mode
  var text = work_mode == WORK_MODE_OFFICE ? WORK_MODE_OFFICE_LABEL
    : work_mode == WORK_MODE_HOME ? WORK_MODE_HOME_LABEL
      : work_mode == WORK_MODE_HYBRID ? WORK_MODE_HYBRID_LABEL
        : '';
  // Conditions for office icon
  var iconName = check_in_from == WORK_MODE_OFFICE ? apartmentIcon
    : check_in_from == WORK_MODE_HOME ? homeIcon
      : null;

  // Conditions for office icon: if is_regularized= true then grey
  var iconColor = is_regularized ? 'color-staticLightGrey' : 'theme-color-static';

  return (
    <span className='pt-8px'>
      <ETooltip title={work_mode_attendance_remark} arrow>
        <span className='display-flex align-center theme-color-static'>
          {iconName && <EIconInputAdornment className={iconColor} icon={iconName} />}
          {text ? text : ''}
        </span>
      </ETooltip>
    </span>)
}

