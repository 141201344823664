import React from 'react'
import { ECardHR } from '../../BaseComponents/ECard'
import { ETypography } from '../../BaseComponents/ETypography'
import { Grid, useTheme } from '@mui/material'


const StaffingSummary = ({value}) => {
    const theme = useTheme()
  return (
    //Male Female Ratio Code
    // <EBox className="font-size-24px bold-600 display-flex align-flex-end flex-direction-column position-absolute" style={{ bottom: '16px', right: '16px' }}>
    //     <EBox className="display-flex align-center">
    //         <EIcon icon={manIcon} className="greyColor5-color" />1:3<EIcon icon={manIcon} className="greyColor5-color" />
    //     </EBox>
    //     <EBox className="font-size-16px greyColor5-color" >Employee Ratio</EBox>
    // </EBox>
    <>
        <ECardHR sx={{marginTop:'20px'}}>
            <Grid container>
                <Grid item xs={6}>
                    <ETypography className="font-size-20px bold-600" >Total Employees</ETypography>
                </Grid>
                <Grid item xs={6}>
                    <ETypography sx={{textAlign:'right'}} className="font-size-20px bold-600">{value?.total_employee || 0}</ETypography>
                </Grid>
            </Grid>
        </ECardHR>

        <ECardHR>
            <Grid container>
                <Grid item xs={6}>
                    <ETypography className="font-size-20px bold-600" >Present
                     {/* : <span style={{fontSize:'12px', color:theme.palette.text.greyColor4}}>{value?.report_date}</span> */}
                     </ETypography>
                </Grid>
                <Grid item xs={6}>
                    <ETypography sx={{textAlign:'right'}} className="font-size-20px bold-600">{value?.employee_present_count || 0}</ETypography>
                </Grid>
            </Grid>
        </ECardHR>


        <ECardHR>
            <Grid container>
                <Grid item xs={6}>
                    <ETypography className="font-size-20px bold-600" >Absent</ETypography>
                </Grid>
                <Grid item xs={6}>
                    <ETypography sx={{textAlign:'right'}} className="font-size-20px bold-600">{value?.employee_absent_count || 0}</ETypography>
                </Grid>
            </Grid>
        </ECardHR>
        <ECardHR>
            <Grid container>
                <Grid item xs={6}>
                    <ETypography className="font-size-20px bold-600" >Other's</ETypography>
                </Grid>
                <Grid item xs={6}>
                    <ETypography sx={{textAlign:'right'}} className="font-size-20px bold-600">{value?.unalocated_user || 0}</ETypography>
                </Grid>
            </Grid>
        </ECardHR>
    </>
  )
}

export default StaffingSummary