
import { useState, useEffect } from 'react';
import { DeviceWiseAttenanceObject } from '../constants';


function useCheckInOutMsgIcon(checkInDevice, checkOutDevice) {
  const [checkInStatus, setCheckInStatus] = useState(null);
  const [checkOutStatus, setCheckOutStatus] = useState(null);

  useEffect(() => {
    if (checkInDevice) {
      setCheckInStatus(DeviceWiseAttenanceObject[checkInDevice]?.icon || null);
    }
    if (checkOutDevice) {
      setCheckOutStatus(DeviceWiseAttenanceObject[checkOutDevice]?.icon || null);
    }
  }, [checkInDevice, checkOutDevice]);

  return { checkInStatus, checkOutStatus };
}

export default useCheckInOutMsgIcon
