import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, useTheme } from "@mui/material";
import { EButton } from "../../BaseComponents/EButtons";
import { EBox, EBoxPage } from "../../BaseComponents/EBox";
import { ECard, ECardBorderLess } from "../../BaseComponents/ECard";
import { ETypography, ETypographyCardHeading } from "../../BaseComponents/ETypography";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import moment from "moment";
import { PLAN_TIME_FREQUENCY_TYPE } from "../../constants";
import { getBillTenure } from "../../utils/CommonFunctions";

export default function SubscriptionDetails() {

  const navigate = useNavigate();
  const { globalSettingData } = useSelector((state) => state.GlobalSettingReducer);
  const { companySetting } = globalSettingData

  // Current date
  const currentDate = moment();

  // Date to compare with
  const compareDate = moment(companySetting?.activePlan?.renewal_date);

  // Compare dates
  const isSameOrAfter = currentDate.isSameOrAfter(compareDate);



  return (
    <EBoxPage>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center-start"
      >
        <Grid item xs={12}>
          <ECard className="card_design_1">
            <ETypographyCardHeading className="font-size-18px mb-16px ">
              Active Subscription
            </ETypographyCardHeading>




            <Grid container direction="row" spacing={2}>

              {/* Donut Card */}
              <Grid item xs={12} sm={7} md={5} lg={5} >
                <ECardBorderLess className={'Bg-card5 '}>
                  <Grid container spacing={1} className="align-center">

                    {/* Donut */}
                    <Grid item xs={5} sm={5}>
                      <EBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <Grid style={{ width: '100%' }}>
                          <Doughnut data={DonutData(globalSettingData?.companySetting?.availableSeat, globalSettingData?.companySetting?.subscribedTalent, globalSettingData?.companySetting?.additionalTalent)} options={barOptions} />
                        </Grid>
                      </EBox>
                    </Grid>

                    {/* Count */}
                    <Grid item xs={7} sm={7}>
                      <ETypography className="display-flex justify-content-space-between font-size-14px mb-4px">
                        <span>Available Users </span>
                        <span className="blue-color-static bold-600">{globalSettingData?.companySetting?.availableSeat}</span>
                      </ETypography>

                      <ETypography className="display-flex justify-content-space-between font-size-14px mb-4px">
                        <span >Subscribed Users </span>
                        <span className="theme-color-static bold-600">{globalSettingData?.companySetting?.subscribedTalent}</span>
                      </ETypography>

                    </Grid>

                    {/* Total Count */}
                    <Grid item xs={12} className="align-center display-flex justify-content-center bold-600 ">
                      Total Active Users : {globalSettingData?.companySetting?.active_employee_count}
                    </Grid>

                  </Grid>

                </ECardBorderLess>
              </Grid>

              {/* Data Column 1 */}
              <Grid item xs={5.8} sm={5.5} md={3.4} lg={3.4} className="mt-16px">

                <ETypography className="font-size-16px bold-600">
                  {companySetting?.activePlan?.plan_name}
                  <span className="font-size-14px bold-400">
                    {'  '}   {companySetting?.activePlan?.is_paid ? companySetting?.activePlan?.plan_time_frequency_type === PLAN_TIME_FREQUENCY_TYPE.MONTH ? '(Billed Monthly)' : '( Billed Annually )' : null}
                  </span>
                </ETypography>

                {
                  companySetting?.activePlan?.is_paid
                    ?
                    <>
                      <ETypography className="greyColor5-color font-size-16px">
                        License purchased for {companySetting?.activePlan?.allowed_talent} Users
                      </ETypography>

                      <ETypography className="greyColor5-color font-size-16px">
                        ({getBillTenure(companySetting?.activePlan?.plan_time_frequency_type, companySetting?.activePlan?.plan_valid_from, companySetting?.activePlan?.plan_valid_to)})
                      </ETypography>


                      <ETypography className={isSameOrAfter ? "icon-red font-size-16px bold-700" : 'greyColor5-color font-size-16px '}>
                        Renewal Date - {moment(companySetting?.activePlan?.renewal_date).format('DD/MM/YYYY')}
                      </ETypography>
                    </>
                    :
                    <ETypography className="greyColor5-color font-size-16px">
                      Maximum {companySetting?.activePlan?.allowed_talent} Users allowed
                    </ETypography>
                }
              </Grid>

              {/* Data column 2 */}
              <Grid item xs={5.8} sm={5.5} md={3.4} lg={3.4} className="mt-16px">
                <EButton
                  variant="contained"
                  sx={{ minWidth: '174px' }}
                  onClick={() => navigate(companySetting?.activePlan?.is_paid ? '/add-more-talent' : '/upgrade-plan')}
                  className="button-left-margin mb-16px"
                >
                  {companySetting?.activePlan?.is_paid ? 'Add More Talent' : 'Upgrade Plan'}
                </EButton>

                {
                  isSameOrAfter && <EButton
                    variant="contained"
                    sx={{ minWidth: '174px' }}
                    onClick={() => navigate('/payment-details')}
                    className="button-left-margin font-size-14px"
                  >
                    Renew Subscription
                  </EButton>
                }
              </Grid>



            </Grid>

          </ECard>
        </Grid>
      </Grid>
    </EBoxPage>
  )
}


// bar option value for donut
const barOptions = {
  cutout: '80%',
  borderRadius: 0,
  displayColor: false,
  plugins: {
    tooltip: {
      enabled: false,
    },

  },
};

const DonutData = (available, subscribed, additional) => {
  const theme = useTheme();
  const isData = available > 0 || subscribed > 0 || additional > 0 ? true : false;
  return ({
    labels: '',
    datasets: [
      {
        label: "",
        data: isData ? [subscribed, additional, available] : [1],//['orange','red','blue'] if data is available, else [1] for no data
        backgroundColor: isData ? [theme?.palette?.border?.border8, theme.palette.text.redColor, theme?.palette?.info?.blue] : [theme?.palette?.text?.staticLightGrey2],//['orange','red','blue'] if data is available, else ['grey'] for no data
        borderWidth: 0,
        weight: 0.05,
      },
    ],
  })
}