import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import {
    UPDATE_PROFILE_LOADING, UPDATE_PROFILE_SUCCESS, SERVER_URL, UPDATE_PROFILE_ADD_SUCCESS, UPDATE_PROFILE_ADD_FAILURE, UPDATE_PROFILE_ADD_LOADING,
} from '../constants';
import { Logout } from './AuthAction';

/**
 * [2022-04-05]
 * Changes By: Aanchal Sahu
 * Description:- changed update-profile to change-password
 **/ 
// export function UpdateProfileAction(token, Data, navigate) {
//     const data = new FormData();
//     data.append('email', Data.email);
//     data.append('fullname', Data.fullname);
//     data.append('id', Data.id)
//     return (dispatch) => {
//         dispatch(updateProfileAddLoading());
//         axios({
//             method: 'post',
//             url: `${SERVER_URL}/user/update-profile`,
//             headers: {
//                 "Authorization": 'Bearer ' + token,
//             },
//             data
//         })
//             .then((res) => {
//                 if (res.status === 200) {
//                     Success.fire({
//                         text: "html",
//                         html: `<span style="color:white">${res.data.message}</span>`,
//                     })
//                     dispatch(updateProfileAddSuccess(res.data))
//                     // dispatch(LoginVerification(token,navigate))
//                     navigate('/change-password', { replace: true });
//                 }
//             }).catch((error) => {
//                 if (error?.response) {
//                     Errors.fire({
//                         text: "html",
//                         html: `<span style="color:white">${error.response.data.message}</span>`,
//                     })
//                     dispatch(updateProfileAddFailure(error.response.data));
//                     if (error?.response?.status == 401) {
//                         dispatch(Logout())
//                       }
//                 }
//             });
//     };
// }


export function UpdatePasswordAction(token, Data, navigate) {

    const data = new FormData();
    data.append('oldPassword', Data.oldpassword);
    data.append('newPassword', Data.password);
    data.append('retypePassword', Data.confirm_password);
    data.append('userid', Data.id)
    return (dispatch) => {
        dispatch(updateProfileLoading());
        axios({
            method: 'post',
            url: `${SERVER_URL}/user/change-password`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
            data,
        })
            .then((res) => {

                if (res.status === 200) {
                    Success.fire({
                        text: "html",
                        html: `<span style="color:white">${res.data.message}</span>`,
                    })

                    dispatch(updateProfileAddSuccess(res.data))
                    dispatch(Logout())

                }
            }).catch((error) => {

                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(updateProfileAddFailure(error.response.data));
                    // if (error?.response?.status == 401) {
                    //     dispatch(Logout())
                    // }
                }
            });
    };
}



export function updateProfileLoading() {
    return {
        type: UPDATE_PROFILE_LOADING,
    };
}
export function updateProfileSuccess(payload) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        payload
    };
}



export function updateProfileAddLoading() {
    return {
        type: UPDATE_PROFILE_ADD_LOADING,
    };
}
export function updateProfileAddSuccess(payload) {
    return {
        type: UPDATE_PROFILE_ADD_SUCCESS,
        payload
    };
}

export function updateProfileAddFailure(payload) {
    return {
        type: UPDATE_PROFILE_ADD_FAILURE,
        payload
    };
}



