export const FAQSdata = {
  rows: [
    {
      id: '1',
      questions: 'Are you experiencing login issues?',
      answers: `<p> Wrong credentials entered. Please check your Login ID and Password <br/>
        If you have edited either your contact number or email address on the portal under personal details during your last login,
        which was also used as USER-ID for ENTERA, the user-id may get auto updated too. Please try to login using updated details or
         contact <a style="color: #0B8AD9" href="mailto:/info@bizolutiontech.com">info@bizolutiontech.com</a></p>`
    },
    {
      id: '2',
      questions: 'How do I reset my Password?',
      answers: `
        <p>
          <ol class='ml11'>
            <li>Click on forgot password</li>
            <li>Enter your registered email id</li>
            <li>You will receive a link on to reset your password on your registered email id. Reset your Password</li>
          </ol>
         </p>`
    },
    {
      id: '3',
      questions: 'Who else is authorized to check and manage my account on ENTERA?',
      answers: `<p> Your management and HR have the privilege to access and manage your account apart from you.</p>`
    },
    {
      id: '4',
      questions: 'How many roles can be assigned to users using ENTERA?',
      answers: `<p> There are 5 classifications for roles to be assigned, including
        <ol class='ml11'>
          <li> <b>Super-Admin</b>: There can be only one primary super-admin, but there’s no constraints on count for secondary super admins. There is no 
          restriction on this role</li>
          <li> <b>Admin</b>: The In-charge/Reporting manager is responsible for activities of the team assigned to them</li>
          <li> <b>HR</b>: Authorized to track, modify, and manage the profile of the employee with respect to Leave, Appraisal/Pay-cut, and Attendance</li>
          <li> <b>Accountant</b>: Authorized to have privilege related to payment of all the members of ENTERA</li>
          <li> <b>Employees</b>: We refer our employees as Employees because we believe in their potential! All the Employees are authorized to manage their 
          account only.</li>
        </ol>
       </p>`
    },
    {
      id: '5',
      questions: 'What is a customized dashboard?',
      answers: `<p> A custom dashboard enables you to display a specific set of metrics and data points on one screen within your account.</p>`
    },
    {
      id: '6',
      questions: 'Can I access my data on ENTERA after I discontinue working with my current organization?',
      answers: ` <p> No, all your ENTERA credentials will be dissolved on termination from your registered organization. To fetch any details, 
        you need to personally reach out to your previous firm.</p>`
    },
    {
      id: '7',
      questions: 'What is Regularization?',
      answers: `<p> Attendance Regularization is an option given to Employees to rectify and amend their incorrect attendance entries, to get paid 
        for their working hours. </p>`
    },
    {
      id: '8',
      questions: 'What action needs to be taken if the employee fails to mark the attendance due to functional error?',
      answers: `<p> If the error occurs within the portal, go to support tab and raise a ticket addressing your issue with a screenshot of the exact
         page/tab. <br/>
        If the error occurs outside the portal, reach out to us on <a href="mailto:info@bizolutiontech.com" style="color: #0B8AD9">info@bizolutiontech.com</a>
       </p>`
    },
    {
      id: '9',
      questions: 'Where can I check the status of my leave application?',
      answers: `<p> The status of your leave application will be reflected under Dashboard > Attendance > Leave log</p>`
    },
    {
      id: '10',
      questions: 'Can a user be restricted from using ENTERA, while still being an active user?',
      answers: `<p> Your Admin has the privilege to control your account apart from you. Kindly contact your reporting manager for the same.</p>`
    },
    {
      id: '11',
      questions: 'Is ENTERA flexible to be used on multiple devices?',
      answers: `<p> The user can actively login only on one device at a time.</p>`
    },
    {
      id: '12',
      questions: 'How do you calculate the number of payable days?',
      answers: `<p> For a given month, the number of payable dates is calculated based on the number of days in a month (either 28/30/31) </p>`
    },
    {
      id: '13',
      questions: 'How do I change the primary contact information of the firm on ENTERA?',
      answers: `<p> Any primary contact information can be updated under company profile tab</p>`
    },
    {
      id: '14',
      questions: 'How long does it take for a ticket to be resolved?',
      answers: `<p> The user is our priority, and we take responsibility to resolve any of your concerns at the earliest.</p>`
    },
    {
      id: '15',
      questions: 'How long will my ticket be active?',
      answers: `<p> If no response is received from the user-end within 48 hours, the ticket is closed assuming that the user is completely 
        satisfied with the solution provided. <br/>If the user is actively responding, all the queries are resolved within 48 hours from our end.</p>`
    },
    {
      id: '16',
      questions: 'Can I reopen my Ticket?',
      answers: `<p> No, you need to follow the process of generating a new ticket. </p>`
    },
    {
      id: '17',
      questions: 'What is the maximum size limit for the file attached with each ticket?',
      answers: `<p> Only one file can be attached with each ticket response in the .jpeg or.png format of size less than 1MB</p>`
    },
    {
      id: '18',
      questions: 'Through what possible channels can I get support?',
      answers: `<p> We provide our support through emails, Phone or WhatsApp Business from 9:00 AM to 6:00 PM, from Monday to Saturday. </p>`
    },
    {
      id: '19',
      questions: 'What is the purpose of the “Request Feature” button in the top nav bar?',
      answers: `<p> If you feel that entera is missing out on any particular feature which you need in entera, use this button to convey your suggestion to us and we will add that feature according to your suggestion. </p>`
    },
    {
      id: '20',
      questions: 'how to add multiple employees on a single click in the employee list? (HR and above role)?',
      answers: `<p> You can add multiple employees by clicking on the “Bulk Import” button present in the Employees module section. Below is the path for the “Bulk Import” button.<br>
      Entera Dashboard > Employee > Employee List
      . </p>`
    },
    {
      id: '21',
      questions: ' What is the use of the “Released List” section under the Employee module?',
      answers: `<p> when an employee resigns/is terminated from the company, he/she is moved from the employee list to the released list.


      </p>`
    },
    {
      id: '22',
      questions: 'In the User Management module, what does the “key” icon suggest?',
      answers: `<p> The “Key” icon present under the “Role” column in the user management module indicates that particular employee is the Primary Super admin of the company. 
      Primary super admin can choose Secondary super admin by clicking on the “Key” icon present in the “Action” column.
      

      </p>`
    },
    {
      id: '23',
      questions: ' From where I can access my salary payslip?',
      answers: `<p> You can access your salary payslip by following below path:<br>
      In website - Enter Dashboard > Payment module > Salary payslip<br>
      In Entera App - Profile menu > payslips.
      </p>`
    },




  ]
};