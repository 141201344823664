import { createContext, useContext, useEffect } from "react";
import {
    GET_ADDITIONAL_SETTING_API,
    GET_ATTENDANCE_SETTING_API,
    GET_TALENT_SETTING_API,
    GET_PAYMENT_SETTING_API,
    GET_LEAVE_SETTING_API,
    GET_ACCOUNT_SETTING_API, SETTING_ADDITIONAL, SETTING_ATTENDANCE, SETTING_ACCOUNT, SETTING_LEAVE,
    SETTING_PAYMENT, SETTING_TALENT, SETTING_PAYROLL, SET_PAYROLL_SETTING_API, GET_PAYROLL_SETTING_API, USER_LOGIN_STATUS
} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { GetCompanySetting, settingFailure, settingLoadingStop } from "../action/SettingAction";

/**
 * [2023-03-31]
 * Changes By: Purva Sharma
 * Description:- This Context provider is responsible for calling and holding all the setting intially when the app loads
 **/

// creating a  context, to use it acorss the webapp
const CompanySettingContext = createContext();

// Provider Function
function CompanySettingProvider({ children, token }) {

    const dispatchAction = useDispatch()
    const setting = useSelector(state => state.SettingReducer);
    const { authData } = useSelector((state) => state.authReducer);

    const GetAllSettings = (token) => {
        return (dispatch) => {
            return dispatch(GetCompanySetting(token, GET_ADDITIONAL_SETTING_API, SETTING_ADDITIONAL, false))
                .then(() => dispatch(GetCompanySetting(token, GET_ATTENDANCE_SETTING_API, SETTING_ATTENDANCE, false)))
                .then(() => dispatch(GetCompanySetting(token, GET_ACCOUNT_SETTING_API, SETTING_ACCOUNT, false)))
                .then(() => dispatch(GetCompanySetting(token, GET_LEAVE_SETTING_API, SETTING_LEAVE, false)))
                .then(() => dispatch(GetCompanySetting(token, GET_TALENT_SETTING_API, SETTING_TALENT, false)))
                .then(() => dispatch(GetCompanySetting(token, GET_PAYMENT_SETTING_API, SETTING_PAYMENT, false)))
                .then(() => dispatch(GetCompanySetting(token, GET_PAYROLL_SETTING_API, SETTING_PAYROLL, true)))
        };
    };
    // useEffect
    useEffect(() => {
        /**
         * Only call settings APIs if User's login_status if not PLAN_PENDING(3) i.e. User's company have a valid active plan
         */
        if (token && authData.login_status !== USER_LOGIN_STATUS.PLAN_PENDING) {
            dispatchAction(GetAllSettings(token));
        }

    }, [token]);
    // reutnring the CompanySettingContext Provider i.e to make "values" accesible to all its children
// console.log(setting.settingLoading);
    return (
        <CompanySettingContext.Provider
            value={{
                setting
            }}
        >
            {/* Need a descussion with team */}
            {children}
            {/* {!setting.settingLoading && children } */}
        </CompanySettingContext.Provider>
    );
}

//defining CompanySettingContext as custom hook to use its values across children.
const useCompanySettingProvider = () => useContext(CompanySettingContext);
export { useCompanySettingProvider, CompanySettingProvider };