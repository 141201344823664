import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, Grid, Stack } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import moment from 'moment';
import { LeaveApprovalValidation } from '../../utils/validation';
import { ApprovalLeave, ViewLeave } from '../../action/EmployeeLeaveAction';
import { EButton, EButtonOutlined, EIconButton } from '../../BaseComponents/EButtons';
import { EHelperText, ETypography, EWordCount } from '../../BaseComponents/ETypography';
import { ETextField } from '../../BaseComponents/ETextField';
import { ELabelInputMainGrid, LoadingGrid } from '../../BaseComponents/EGrid';
import { EIcon, EIconSmall } from '../../BaseComponents/EIcon';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import ArrowUp from '@iconify/icons-material-symbols/keyboard-arrow-up-rounded';
import ArrowDown from '@iconify/icons-material-symbols/keyboard-arrow-down-rounded';
import { EBox } from '../../BaseComponents/EBox';
import { EConnector, EStep, EStepIcon, EStepLabel, EStepper } from '../../BaseComponents/EStepper';
import { ETooltip } from '../../BaseComponents/ETooltip';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { getDayCount } from '../../utils/CommonFunctions';
import { REJECT_STATUS, APPROVE_STATUS, CHARACTER_LIMIT } from '../../constants';
import { getCurrentNode, getSteps, showFullDayLeaveDetails, showHalfDayLeaveDetails } from './LeaveViewForm';
import SearchNotFound from '../../PageComponents/SearchNotFound';

/**
 * LOGIC:
 * Here we have to check if (reporting_manager_status coming from details prop):
 * -> reporting_manager_status=null; means no action has been taken from reporting manager
 * -> reporting_manager_status!=null; means HR's action is pending
 * 
 * [2022-11-28]
 * Created By:- Aanchal Sahu
 * Description:- designed in new theme
 **/

function LeaveApproveForm(props) {
    const dispatch = useDispatch()
    const { auth, close, details, SearchParameters } = props;
    const leave = useSelector(state => state.LeaveEmployeeReducer); // ViewLeave Action reducer
    let leaveData = leave?.empLeaveViewData; // ViewLeave Action reducer

    // INFO: state for the show/hide of time periods details
    const [showDetails, setShowDetails] = useState(false)

    useEffect(() => {
        if (auth?.authtoken && details?.id) {
            dispatch(ViewLeave(auth.authtoken, details?.id));
        }
    }, [auth.authtoken])

    const formik = useFormik({
        initialValues: {
            leave_id: details?.id,
            employee_id: details?.employee_id,
            approval_remark: '',
            approval_status: ''
        },
        validationSchema: LeaveApprovalValidation,
        onSubmit: (data) => {
            data.search = SearchParameters
            data.isRM = details?.reporting_manager_id == auth?.authData?.id ? true : false; //if RM status== pending, is rem==true else false;
            dispatch(ApprovalLeave(auth.authtoken, data, close));
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
    const { label_from_halfday, label_to_halfday } = leaveData;

    // INFO: steps value for view leave action progress.
    const steps = useMemo(() =>
        getSteps(leaveData),
        [leaveData]);

    // Info: setting the stage for the progress Stepper
    const CompletedStage = useMemo(() => getCurrentNode(leaveData?.is_direct_status_changed), [leaveData?.is_direct_status_changed]);

    // Counting half days & full days
    const TotalNumberOfFullDays = leaveData?.label_from_fullday && leaveData?.label_to_fullday ? getDayCount(leaveData?.label_from_fullday, leaveData?.label_to_fullday) : null;
    const TotalNumberOfHalfDays = label_from_halfday && label_to_halfday && !moment(new Date(label_from_halfday)).isSame(new Date(label_to_halfday)) ?
        2 : label_from_halfday ? 1 : label_to_halfday ? 1 : null;

    return (<>
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                {leave?.empLeaveViewLoading ?
                    <LoadingGrid />
                    : leaveData && Object.keys(leaveData).length > 0 ?
                        <Grid container spacing={2} >
                            <Grid item xs={12} xl={12} className='p0'>
                                <EBox className='mt-24px mb-16px height-50px position-relative overflow-hidden'>
                                    <EStepper activeStep={CompletedStage} connector={<EConnector />} alternativeLabel >
                                        {steps && steps?.map((row) => (
                                            <EStep key={row?.id} >
                                                <EStepLabel
                                                    StepIconComponent={EStepIcon}>
                                                    <span className=' display-flex justify-content-center align-center'>
                                                        {row?.label}
                                                        {row?.remark ?
                                                            <ETooltip arrow title={
                                                                <span className='text-align-webkit-center'>
                                                                    {row?.name ? <span>{row?.name}<br /></span> : ''}
                                                                    <span >{row?.remark}</span>
                                                                </span>}>
                                                                <EIconSmall icon={InfoIcon} className='height-width-18px color-text-primary' />
                                                            </ETooltip>
                                                            : ''
                                                        }
                                                    </span>
                                                </EStepLabel>
                                            </EStep>
                                        ))}
                                    </EStepper>
                                </EBox>
                            </Grid>

                            {/* Applied Date */}
                            <Grid item xs={12} xl={12}>
                                <ELabelInputMainGrid label={'Applied Date'} isNotForm={true} isModal={true} isfullgrid={true}>
                                    <ETypography >
                                        {leaveData?.applied_date ? moment(leaveData?.applied_date).format('DD/MM/YYYY') : ''}
                                    </ETypography>
                                </ELabelInputMainGrid>
                            </Grid>

                            {/* Leave Period */}
                            <Grid item xs={12} xl={12}>
                                <ELabelInputMainGrid label={'Leave Period'} isNotForm={true} isModal={true} isfullgrid={true}>
                                    {leaveData ?
                                        (moment(leaveData?.from_date).format('DD/MM/YYYY') == moment(leaveData?.to_date).format('DD/MM/YYYY') ?
                                            moment(leaveData?.from_date).format('DD/MM/YYYY') :
                                            moment(leaveData?.from_date).format('DD/MM/YYYY') + " to " + moment(leaveData?.to_date).format('DD/MM/YYYY'))
                                        : '-'}

                                    {/* Conditionally: Show Details */}
                                    <EIconButton onClick={() => { setShowDetails(!showDetails) }} className='p0 '> <EIcon className='ml-16px theme-color-static ' icon={showDetails ? ArrowUp : ArrowDown} />  </EIconButton>

                                    {showDetails ?
                                        <span className='greyColor4-color font-size-14px'>
                                            <br />

                                            {/* Full Days Info: show if TotalNumberOfFullDays!=0 */}
                                            {TotalNumberOfFullDays ? showFullDayLeaveDetails(leaveData?.label_from_fullday, leaveData?.label_to_fullday, TotalNumberOfFullDays) : ''}

                                            {/* putting break if we have both TotalNumberOfFullDays && TotalNumberOfHalfDays */}
                                            {TotalNumberOfFullDays && TotalNumberOfHalfDays ? <br /> : ''}

                                            {/* Half Days Info */}
                                            {TotalNumberOfHalfDays ? showHalfDayLeaveDetails(leaveData?.label_from_halfday, leaveData?.label_to_halfday, TotalNumberOfHalfDays) : ''}

                                        </span>
                                        : ''
                                    }
                                </ELabelInputMainGrid>
                            </Grid>

                            {/* Reason */}
                            <Grid item xs={12} xl={12}>
                                <ELabelInputMainGrid label={'Reason'} isNotForm={true} isModal={true} isfullgrid={true}>
                                    <ETypography >
                                        {leave?.empLeaveViewData?.reason ? leave?.empLeaveViewData?.reason : "-"}
                                    </ETypography>
                                </ELabelInputMainGrid>
                            </Grid>

                            {/* Status */}
                            <Grid item xs={12} xl={12}>
                                <ELabelInputMainGrid label={'Status'} isfullgrid={true} isModal={true} labelPT='0rem'>
                                    <FormControl component="fieldset" error={Boolean(touched.approval_status && errors.approval_status)} className='mb-minus-10px mt-minus-6px'>
                                        <ERadioGroup row={true} aria-label="approval_status" name="approval_status" {...getFieldProps('approval_status')} >
                                            <FormControlLabel value={APPROVE_STATUS} control={
                                                <ERadio size="small"
                                                    onChange={(e, value) => {
                                                        setFieldValue('approval_status', APPROVE_STATUS)
                                                    }}
                                                />
                                            } label={<span className='font-size-14px'>Approve</span>} />
                                            <FormControlLabel value={REJECT_STATUS} control={
                                                <ERadio size="small"
                                                    onChange={(e, value) => {
                                                        setFieldValue('approval_status', REJECT_STATUS)
                                                    }}
                                                />
                                            } label={<span className='font-size-14px'>Reject</span>} />
                                        </ERadioGroup>
                                        {touched.approval_status && <EHelperText className="color-text-error-red m0px"> {errors.approval_status}</EHelperText>}
                                    </FormControl>
                                </ELabelInputMainGrid>
                            </Grid>

                            {/* Remark */}
                            <Grid item xs={12} xl={12}>
                                <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true} >
                                    <ETextField
                                        multiline
                                        rows={3}
                                        name='approval_remark'
                                        {...getFieldProps('approval_remark')}
                                        fullWidth
                                        inputProps={{ maxLength: CHARACTER_LIMIT }}
                                        error={Boolean(touched.approval_remark && errors.approval_remark)}
                                        helperText={touched.approval_remark && errors.approval_remark}
                                    />
                                    <EWordCount>{`${formik?.values?.approval_remark.length}/${CHARACTER_LIMIT}`}</EWordCount>
                                </ELabelInputMainGrid>
                            </Grid>

                        </Grid>
                        :
                        <SearchNotFound page='dashboard' />
                }
                {/* Buttons */}
                <Stack direction='row' spacing={2} className='modal1-buttons-stick-bottom '>
                    <EButtonOutlined variant='outlined' size='large' onClick={() => close()}> Cancel </EButtonOutlined>
                    {leaveData && Object.keys(leaveData).length > 0 && <EButton type="submit" variant='contained' size='large'> Save </EButton>}
                </Stack>
            </Form>
        </FormikProvider>
    </>
    );
}

export default LeaveApproveForm



