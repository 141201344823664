import { Grid, IconButton } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { EHelperText, ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import { EIcon } from "../../BaseComponents/EIcon";
import { ADHAAR_DOC_ID, DRIVING_LICENCE_DOC_ID, PAN_DOC_ID, PASSPORT_DOC_ID } from "../../constants";
import { EBox } from "../../BaseComponents/EBox";

const AddFormDocuments = (props) => {
    const { formikTalents, isOpen, docArray, setDocArray } = props;


    ///Function to set maxInputLimit in the field for default documents
    const getMaxLength = (document_id) => {
        switch (document_id) {
            case ADHAAR_DOC_ID:
                return 12
            case PAN_DOC_ID:
                return 10
            case DRIVING_LICENCE_DOC_ID:
                return 16
            case PASSPORT_DOC_ID:
                return 12
            default:
                return null
        }
    }

    const SetDocumentNumber = (value, name, index) => {
        setFieldValue(name, value)
        setDocArray((prevState) => {
            const update = [...prevState];
            if (index >= 0) {
                update[index].document_id_no = value
            }
            return update;
        })

    }

    const FileFunction = (e, name, index) => {
        if (e.target.files[0]) {
            setFieldValue(name, e.target.files[0])

            setDocArray((prevState) => {
                const update = [...prevState];
                if (index >= 0) {
                    update[index].file = e.target.files[0]
                }
                return update;
            })
        }

    }

   

    const { errors, getFieldProps,setFieldValue } = formikTalents;

    return (
        <ECard className={!getFieldProps('documents_check').value ? 'card_design_2' : 'card_design_1 pb-8px'}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypography variant='h5' className="font-size-20px">
                    Documents
                </ETypography>
                {!isOpen &&
                    <IconButton onClick={() => setFieldValue('documents_check', !getFieldProps('documents_check').value)}>
                        <EIcon icon={getFieldProps('documents_check').value ? minusBox : plusBox} />
                    </IconButton>
                }
            </Grid>

            {getFieldProps('documents_check').value && <>
                <EDivider sx={{ marginBottom: '8px' }} />

                {docArray?.length > 0 && docArray?.map((row, index) => {
                    const { document_name, document_id, is_required } = row

                    const name = 'document_id_' + document_id; //INFO: name of 
                    const file_name = 'file_' + document_id;


                    return (
                        <EBox key={index}>
                         <EBox className="display-flex" gap={'20px'} padding={'8px 21px'}>
                            <EBox className="flex-row-allCenter" width={'50%'}>
                                <ELabelInputMainGrid label={document_name + (is_required ? '*' : '')}>
                                    <ETextField
                                        fullWidth
                                        sx={{ height: '10%' }}
                                        placeholder="Document Number"
                                        // {...getFieldProps(name)}
                                        inputProps={{ style: { textTransform: getFieldProps(name).value ? 'uppercase' : "" }, maxLength: getMaxLength(document_id) }}
                                        onChange={(e) => {
                                            e?.target?.value && SetDocumentNumber(e?.target?.value?.toUpperCase(), name, index)
                                        }}
                                        error={Boolean(errors[name])}
                                        helperText={errors[name]}
                                    />
                                </ELabelInputMainGrid>

                            </EBox>





                            <EBox sx={{ display: 'flex', alignSelf: 'flex-start' }} marginTop={{ sm: '22px', lg: 0 }} width={'50%'}>
                                <ETextField fullWidth type="file"

                                    inputProps={{
                                        accept: 'image/jpg, image/jpeg, application/pdf'
                                    }}
                                    className={!Object.keys(formikTalents.values).includes(file_name) ? "input-file-no-file-chosen input-file-selector" : 'input-file-selector'}
                                    onChange={(e) => { FileFunction(e, file_name, index) }}
                                    error={Boolean(errors[file_name])}
                                    helperText={errors[file_name]}
                                />
                            </EBox>
                        </EBox>
                        </EBox>
                       
                    )
                })}

                <Grid item md={6} sm={6} xs={12} py={2} className='docs-responsive-display display-flex '>
                    <EHelperText className="greyColor4-color">* Allowed format is pdf or jpeg/jpg</EHelperText>
                </Grid>
            </>}
        </ECard>
    )

};

export default AddFormDocuments;
