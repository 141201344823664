import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AttendanceList, getPayrollDetail } from "../../action/PayrollAction";
import { ETypography } from "../../BaseComponents/ETypography";
import base64 from 'base-64'
import editFill from '@iconify/icons-material-symbols/edit-outline-rounded'
import { EIcon } from "../../BaseComponents/EIcon";
import {
  ATTENDANCE_AND_PAYROLL_DOWNLOAD_URL, DEFAULT_ROWS_PERPAGE, LOCKED_STATUS,
  NIL,
  PERMISSIONS,
  REPORT_CHECK_IN_OUT_DOWNLOAD_URL,
  ROW_SIZE_PER_PAGE
} from "../../constants";
import moment from "moment";
import { EBox, EBoxPage } from "../../BaseComponents/EBox";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { DownLoadFile } from "../../action/AdminAction";
import { EStickyCell, EStickyRow, EStickyTable } from "../../BaseComponents/EStickyTable";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
import { useCheckPermission } from "../../hooks";
import { EPageWithBreadCrumbs } from "../../BaseComponents";
import { PaymentSearchFilter } from '../../PageComponents/Payment/PaymentSearchFilter'
import { getCheckInOUtReport } from "../../action/ReportAction";
import { EDivider } from "../../BaseComponents/EDivider";
import { useTheme } from "@mui/material";
import { ETablePagination } from "../../BaseComponents/ETablePagination";


const ReportCheckInOut = (props) => {
  const { auth, is_payroll } = props;
  const navigate = useNavigate();
  const theme = useTheme()
  const dispatch = useDispatch();
  const { setting } = useCompanySettingProvider();
  const allowPayrollEdit = useCheckPermission([PERMISSIONS.ViewEditFinalizePayroll])
  const monthPayroll = useSelector(state => state.PayrollReducer.payrollDetailData);
  const reportData = useSelector(state => state.ReportCheckInOutReducer);

  const [searchParam, setSearchParam] = useState({
    start: moment().format('MMM yyyy'),
    employee_id: null
  });

  const [dateError, setDateError] = useState({
    startDateError: null,
    endDateError: null
  })

  const onSearch = (params) => {
    let searchParams = {
      ...params,
      year_month: params?.start ? moment(params?.start).format('YYYYMM') : null
    }

    setDateError(prevState => ({
      ...prevState,
      startDateError: params?.start ? null : 'Please select a month'
    }));

    if (params?.start) {
      setSearchParam(searchParams);
      setRowsPerPage(DEFAULT_ROWS_PERPAGE);
      setPage(0);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);


  const callApi = (fnParams, fnPage, fnRowPerPage) => {
    const params = {
      year_month: fnParams?.start ? moment(fnParams?.start).format('YYYYMM') : null,
      year: fnParams?.year ? fnParams?.year?.id : null,
      sort: fnParams?.sort,
      employee_id: fnParams?.employee ? fnParams?.employee?.id : null
    }
    dispatch(getCheckInOUtReport({ ...params, page: fnPage, per_page: fnRowPerPage }))
  }

  useEffect(() => {
    if (auth.authtoken) {
      callApi({ ...searchParam }, page, DEFAULT_ROWS_PERPAGE);
    }
  }, [searchParam])

  const handleChangePage = (event, newPage) => {
    callApi(searchParam, newPage, rowsPerPage);
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
    setRowsPerPage(rowPerPageCount);
    callApi(searchParam, 0, rowPerPageCount);
    setPage(0);
  };


  const DownloadReport = (value) => {
    let url = REPORT_CHECK_IN_OUT_DOWNLOAD_URL;
    let file_name = 'Check_In_Out_Report_';

    file_name += moment(searchParam?.start).format("MMM-YYYY");
    if (value != null) {
      url += `?download_format=${value}`;
    }
    if (searchParam?.start != null) {
      url += `&year_month=${moment(searchParam?.start).format('YYYYMM')}`;
    }
    if (searchParam?.sort != null) {
      url += `&sort=${searchParam?.sort}`;
    }
    if (searchParam?.employee != null) {
      url += `&employee_id=${searchParam?.employee?.id}`;
    }
    DownLoadFile(auth.authtoken, url, file_name);
  };

  return (
    <EPageWithBreadCrumbs
      title="Check In/Out Report"
      pageHeading="Check In/Out Report"
      breadcrumbsTitle="Check In/Out Report"
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Report', href: '/report/attendance-log-report' },
        { name: 'Attendance Log Report', href: '/report/attendance-log-report' },
        { name: 'Check In/Out Report', href: '/' },
      ]}
      loading={false}
    >

      <EBoxPage>
        <PaymentSearchFilter
          initialFilter={searchParam}
          onSearch={onSearch}
          onSort={onSearch}
          DownloadReport={DownloadReport}
          showTalents={true}
          talentOrder={8}
          start={{
            placeholder: 'All Month',
            views: ['month', 'year'],
            inputFormat: "MMM yyyy",
            error: dateError?.startDateError,
            helperText: dateError?.startDateError ? dateError?.startDateError : null,
          }}
        />
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            py={1}
          >

            <Grid item xs={12} my={2}>
              {
                reportData &&
                !reportData?.reportByCheckInOutDetailLoading &&
                Object.keys(reportData?.reportByCheckInOutDetailData?.rows || {}) &&
                Object.keys(reportData?.reportByCheckInOutDetailData?.rows || {}).length > 0
                &&
                <>
                  <EStickyTable
                    stickyHeaderCount={2}
                    leftStickyColumnCount={2}
                    className={'tContainer'}
                  >
                    <EStickyRow key={'headRow'} className={'tHeadSheet'}>
                      {
                        Object.values(Object.values(reportData?.reportByCheckInOutDetailData?.rows)[0])
                          .map((hData) => {
                            return (
                              <EStickyCell
                                className={'tColSheet'}
                                style={{ whiteSpace: 'pre', verticalAlign: 'middle' }}
                                key={hData.label}
                              >
                                <ETypography
                                  sx={{
                                    fontSize: { lg: '14px !important', xs: '12px !important' },
                                    ...(!hData?.date && { fontWeight: 600 }),
                                    ...(hData?.label !== 'Talent Name' && { textAlign: 'center' }),
                                  }}
                                >
                                  {
                                    // If column name is "Talent Name" 
                                    hData?.label == 'Talent Name'
                                      ?
                                      //then Bold font size
                                      <span className="bold-600">{hData?.label}</span>
                                      :
                                      // otherwise normal fontsize
                                      hData?.label.split(" ").join("\n")
                                  }
                                </ETypography>
                              </EStickyCell>
                            );
                          })
                      }
                    </EStickyRow>


                    {
                      Object.values(reportData?.reportByCheckInOutDetailData?.rows).map((bDataRow, index) => {
                        return (
                          <EStickyRow key={"bodyRow" + index} className={'tBodySheet'}>
                            {Object.values(bDataRow).map((bData, indexChild) => {
                              let classnames = ' '

                              var displayValue = bData?.value;

                              return (
                                <EStickyCell
                                  className={'tColSheet ' + classnames}
                                  key={index + "bodyCol" + indexChild + displayValue}
                                  sx={{ fontSize: { lg: '14px !important', xs: '12px !important' } }}
                                >
                                  <EBox sx={{ minWidth: '60px' }}>
                                    {
                                    ( bData?.key == 'employee_name' || bData?.key == 's_no')
                                        ?
                                        <ETypography sx={{ fontSize: { lg: '14px !important', xs: '12px !important' } }} >
                                          <span className={bData?.key == 'employee_name' ? "bold-600": ""}>
                                            { bData?.value }
                                          </span>
                                          <br />
                                          <span> { bData?.employee_code ? ` (${bData?.employee_code})` : null} </span>
                                        </ETypography>
                                        :
                                        <>
                                          <ETypography
                                            sx={{
                                              fontSize: '14px',
                                              color: bData?.is_regularized ? theme?.palette?.primary?.main : theme?.palette?.text?.primary,
                                              textAlign: 'center'
                                            }}
                                          >
                                            { bData?.check_in_time ? moment(bData?.check_in_time).format('hh:mm A') : NIL }
                                          </ETypography>

                                          <EDivider
                                            sx={{ borderColor: theme?.palette?.border?.staticBorder7 }}
                                          />

                                          <ETypography
                                            sx={{
                                              fontSize: '14px',
                                              color: bData?.is_regularized ? theme?.palette?.primary?.main : theme?.palette?.text?.primary,
                                              textAlign: 'center'
                                            }}
                                          >
                                            { bData?.check_out_time ? moment(bData?.check_out_time).format('hh:mm A') : NIL }
                                          </ETypography>

                                        </>
                                    }
                                  </EBox>
                                </EStickyCell>
                              );
                            })}
                          </EStickyRow>
                        )
                      })
                    }
                  </EStickyTable>
                </>}

              {reportData?.reportByCheckInOutDetailData?.count > DEFAULT_ROWS_PERPAGE && (
                <Grid container my={3}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <ETablePagination
                    rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                    component="div"
                    count={reportData?.reportByCheckInOutDetailData?.count ? reportData?.reportByCheckInOutDetailData?.count : 10}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              )}



              {/* IF NO RESULT AVAILABLE */}
              {
                reportData &&
                !reportData?.reportByCheckInOutDetailLoading &&
                Object.keys(reportData?.reportByCheckInOutDetailData?.rows || {}) &&
                Object.keys(reportData?.reportByCheckInOutDetailData?.rows || {}).length == 0 &&
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  p={10}
                >
                  <SearchNotFound />

                </Grid>}

              {/* IF NO RESULT LOADING THE RESULT */}
              {reportData &&
                reportData?.reportByCheckInOutDetailLoading &&
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  p={10}
                >
                  <ECircularProgress />
                </Grid>
              }

            </Grid>
          </Grid>
        </>
      </EBoxPage>
    </EPageWithBreadCrumbs>
  )

};

export default ReportCheckInOut;
