import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, Grid } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from '../../BaseComponents/EButtons';
import { EHelperText, ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ECardBasic, ECardDashboard } from '../../BaseComponents/ECard';
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { Stack } from '@mui/system';
import EHidden from '../../BaseComponents/EHidden';
import { EIcon } from '../../BaseComponents/EIcon';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { PERMISSIONS, SET_TALENT_SETTING_API, XL } from '../../constants';
import { Form, FormikProvider, useFormik } from 'formik';
import { ELabelInputMainGrid, LoadingGrid } from '../../BaseComponents/EGrid';
import { ReturnBoolean, ReturnBoolean2 } from '../../utils/CommonFunctions';
import { ETextFieldSmall } from '../../BaseComponents/ETextField';
import { TalentSettingValidation } from '../../utils/validation';
import { ECheckbox } from '../../BaseComponents/ECheckbox';
import { useState } from 'react';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { SetTalentSetting } from '../../action/SettingAction';
import { useCheckPermission } from '../../hooks';
import EModal from '../../BaseComponents/EModal';
const _ = require('lodash');

const DOCUMENT_ID = 3;

// INFO: This is an array for multiple checkboxes
const Checkboxes = [
    {
        id: 0,
        name: "Personal Details",
        array: [
            { id: 1, label: 'Date of Birth', field_name: 'field_dob', is_required: 0 },
            { id: 2, label: 'Gender', field_name: 'field_gender', is_required: 0 },
            { id: 3, label: 'Marital Status', field_name: 'field_marital_status', is_required: 0 },
            { id: 4, label: 'Blood Group', field_name: 'field_blood_group', is_required: 0 },
            { id: 5, label: 'Address', field_name: 'field_address', smallText: '(Address, Landmark,City, State, PIN Code)', is_required: 0 },
            { id: 10, label: 'Profile Photo', field_name: 'field_profile_photo', is_required: 0 }
        ]
    },
    {
        id: 1,
        name: "Bank Details",
        array: [
            { id: 11, label: 'IFSC Code', field_name: 'field_ifsc_code', is_required: 0 },
            { id: 12, label: 'Bank Name', field_name: 'field_bank_name', is_required: 0 },
            { id: 13, label: 'Branch Name', field_name: 'field_branch_name', is_required: 0 },
            { id: 14, label: "Cardholder's Name", field_name: 'field_account_holder_name', is_required: 0 },
            { id: 15, label: 'Account Number', field_name: 'field_account_number', is_required: 0 },
        ]
    },
    {
        id: 2,
        name: "Emergency Details",
        array: [
            { id: 16, label: 'Name', field_name: 'field_emer_name', is_required: 0 },
            { id: 17, label: 'Relation', field_name: 'field_emer_relation', is_required: 0 },
            { id: 18, label: 'Contact Number', field_name: 'field_emer_contact_no', is_required: 0 },
        ]
    },
    {
        id: DOCUMENT_ID,
        name: "Documents",
        array: []
    }
]

const initialModal = {
    open: false,
    onClick: () => null,
    header: '',
    body: <></>,
    loading: false,
}

function TalentSettings(props) {

    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checkBoxArray, setCheckBoxArray] = useState([])
    const settingData = useSelector((state) => state.SettingReducer);
    const settingsContext = useCompanySettingProvider(); //INFO: getting the setting data from the context.
    const company = useSelector(state => state.CompanyReducer)

    const talentSettings = settingsContext?.setting?.talentSettingData ? settingsContext?.setting?.talentSettingData : {}

    const formik = useFormik({
        initialValues: {
            // 1st card: Enable employee directory company-wide
            enable_talent_directory: null,
            is_default_setting: false,
            at_least_one_field: false,//checks if at least one checkbox is checked

            // 2nd card : Create custom employee details form 
            talent_custom_field: null,
            field_dob: false,
            field_gender: false,
            field_marital_status: false,
            field_blood_group: false,
            field_address: false,
            field_landmark: false,
            field_city: false,
            field_state: false,
            field_pin_code: false,
            field_profile_photo: false,
            field_ifsc_code: false,
            field_bank_name: false,
            field_branch_name: false,
            field_account_holder_name: false,
            field_account_number: false,
            field_emer_name: false,
            field_emer_relation: false,
            field_emer_contact_no: false,

            // 3rd card : Create custom employee details form
            enable_talent_code_prefix: null,
            talent_code_prefix: '',
            talent_document: []
        },
        validationSchema: TalentSettingValidation,
        onSubmit: () => {
            handleSave()
        }
    });

    const { errors, touched, getFieldProps, setFieldValue, handleSubmit, values, setFieldTouched } = formik;

    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditTalentDataSettings])

    const [isFormChanged, setIsFormChanged] = useState(Object.keys(touched).length > 0)
    const [modal, setModal] = useState(initialModal)

    useEffect(() => {
        setIsFormChanged(Object.keys(touched).length > 0)
    }, [touched])


    // INFO: This useEffect is for setting the initial data coming from API 
    useEffect(() => {
        if (settingData?.settingLoading == false && settingsContext?.setting?.settingLoading == false && Object.keys(talentSettings).length != 0) {
            const data = talentSettings.is_default_setting == true ? talentSettings?.companyDefaultSetting :
                talentSettings?.is_default_setting == false ? talentSettings?.companySetting : {};
            // Calling this function to set the initial values
            ValueSettingFunction(data, null)
        }
    }, [auth.authtoken, talentSettings.is_default_setting, settingData?.settingLoading]);


    //INFO: This function is used for setting values in formik and document array state
    const ValueSettingFunction = (data, isSubmit) => {

        if (data) {
            const { enable_talent_directory, talent_custom_field, enable_talent_code_prefix, talent_code_prefix, talent_document } = data

            setFieldValue('enable_talent_directory', enable_talent_directory);
            setFieldValue('talent_custom_field', talent_custom_field);
            setFieldValue('enable_talent_code_prefix', enable_talent_code_prefix);
            setFieldValue('talent_code_prefix', talent_code_prefix ? talent_code_prefix : '');

            // Converting to JSON data to remove state mutation
            let JsonData = talent_document ? JSON.stringify(talent_document) : null;
            // Parsing to remove state mutation
            const parsedJsonDocumentData = JsonData ? JSON.parse(JsonData) : null;

            // INFO: setting values and document array in Docs array state
            let filterVariables = Checkboxes && Checkboxes?.length > 0 && Checkboxes?.filter(item => {
                if (item?.array && item?.array?.length > 0 && item.id != DOCUMENT_ID) {
                    const childArray = item?.array?.map(itemChild => {
                        itemChild.is_required = data[itemChild.field_name] ? 1 : 0; //initializing the is_required field of array
                        return itemChild;
                    })
                    return childArray
                }
                else if (item.id == DOCUMENT_ID) {
                    item.array = parsedJsonDocumentData; //initializing docs array to the last object of array i.e. document object
                }
                return item //returning modified objects
            })

            setCheckBoxArray(filterVariables)// setting array to state

            // if talent_custom_field =true then only we'll the HandleDocumentArrayChange function for validation check of checkboxes
            if (talent_custom_field) { HandleDocumentArrayChange(); }

            if (isSubmit == null) {  //means this function is called by 1st time useEffect
                setFieldValue('is_default_setting', talentSettings?.is_default_setting);
            }
        }
    }

    // INFO: This function is responsible to handle the change in Document array state
    const HandleDocumentArrayChange = (mainIndex = null, childIndex = null, value = null) => {
        var isRequiredDoc = [];
        if (mainIndex !== null) {
            // Only set touched if function is called while checking the document checkbox
            setFieldTouched('talent_document', true);
        }
        setCheckBoxArray((prevState) => {
            const update = [...prevState];
            //setting new values
            if (mainIndex != null && childIndex != null) {
                update[mainIndex].array[childIndex].is_required = value;
            }

            // Checking if at least one of the checkbox is checked or not
            var result = update && update?.length > 0 && update?.map(item => {
                // looping through the child array
                item?.array && item?.array?.length > 0 && item?.array?.filter(itemChild => {
                    // pushing 1 if the isrequired is 1 i.e. true
                    if (itemChild?.is_required == 1) { isRequiredDoc.push(1); }
                })
                return item
            });

            // checking if the docs has something required or not.
            if (isRequiredDoc?.length > 0 && values?.at_least_one_field != true) {
                setFieldValue('at_least_one_field', true);
            } else if (isRequiredDoc?.length == 0 && values?.at_least_one_field != false) {
                setFieldValue('at_least_one_field', false);
            }

            return result;
        })
    }

    const handleDefaultApply = () => {
        setModal({
            open: true,
            onClick: () => {
                const Data = { ...talentSettings?.companyDefaultSetting, is_default_setting: true }
                dispatch(SetTalentSetting(auth.authtoken, Data, SET_TALENT_SETTING_API, navigate))
            },
            header: 'Default Setting: Confirmation',
            body: <>
                <ETypography className='font-size-16px'>
                    This will restore all settings to their default values.
                </ETypography>
                <ETypography className='font-size-16px'>
                    Any changes you've made will be lost!
                </ETypography>
                <ETypography className='font-size-16px'>
                    Do you want to apply default settings?
                </ETypography>
            </>
        })
    }

    const closeModal = () => {
        setModal(initialModal)
    }

    const handleSave = () => {
        setModal({
            open: true,
            onClick: () => {
                saveSetting()
                closeModal()
            },
            header: 'Custom Setting: Confirmation',
            body: <>
                <ETypography className='font-size-16px'>
                    Are you sure! you want to save these changes in
                    {" "}
                    <span className='theme-main-text-color-bold'>
                        Employee Data
                    </span>
                    {" "}
                    settings?
                </ETypography>
            </>
        })
    }
    const saveSetting = () => {
        const data = { ...values, talent_document: checkBoxArray, is_default_setting: false, }

        if (checkBoxArray && checkBoxArray?.length > 0) {
            checkBoxArray && checkBoxArray?.length > 0 && checkBoxArray?.filter(item => {
                // looping through the child array to assign values to data from array
                if (item.id != DOCUMENT_ID) {
                    item?.array && item?.array?.length > 0 && item?.array?.filter(itemChild => {
                        //setting checkbox values to data; if the is_required is 1 then true else false.
                        data[itemChild.field_name] = itemChild.is_required == 1 ? true : false;

                    })
                } else {
                    // setting document array to talent_document
                    data.talent_document = item.array;
                }
            });
        }
        dispatch(SetTalentSetting(auth.authtoken, data, SET_TALENT_SETTING_API, navigate))
    }

    const handleCancel = () => {
        ValueSettingFunction(talentSettings?.companySetting, false)
        setIsFormChanged(false)
    }

    const handleChange = (field, value) => {
        setFieldTouched(field, true)
        setFieldValue(field, value)
        setIsFormChanged(true)
    }

    return (
        <Page title="Employee Settings" >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Employee Data'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Company Settings', href: '' },
                            {
                                name: 'Employee Data',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate('/')} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid
                        container
                        display="row"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item xs={8} sm={8} className='display-flex align-center'>
                            <ETypographyPageHeading> Employee Data </ETypographyPageHeading>
                        </Grid>
                        <Grid item xs={4} sm={4} className='display-flex justify-content-flex-end'>
                            <EHidden width="mdUp">
                                <EButtonOutlinedIcon variant="outlined" onClick={() => navigate('/')} >
                                    <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                            </EHidden>
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            {settingData?.settingLoading ?
                                <LoadingGrid size={XL} isPage={true} />
                                :
                                <EBoxPage className='p0'>
                                    <Grid container spacing={2} className='mb-16px'>
                                        {/* 1st card: Enable employee directory company-wide */}
                                        <CustomizedGrid label="Enable employee directory company-wide"
                                            smallLabel="All the employees of the organization on Entera Payroll will have access to the database of every other employee, irrespective of their hierarchy. If turned on, the employee can look through the details of their mangers as well."
                                            radioComponent={
                                                <FormControl component="fieldset" {...getFieldProps('enable_talent_directory')} >
                                                    <ERadioGroup row={true} aria-label="enable_talent_directory" name="enable_talent_directory"
                                                        value={values?.enable_talent_directory != null ? ReturnBoolean(values?.enable_talent_directory) : null}>
                                                        <FormControlLabel
                                                            disabled={(!allowEdit) ? true : false}
                                                            value={true} control={
                                                                <ERadio className=' px-6px'
                                                                    onChange={(e, value) => {
                                                                        handleChange('enable_talent_directory', true)
                                                                    }}
                                                                />
                                                            } label={<span className='font-size-16px'>Yes</span>} />
                                                        <FormControlLabel value={false}
                                                            disabled={(!allowEdit) ? true : false}
                                                            control={
                                                                <ERadio className=' px-6px'
                                                                    onChange={(e, value) => {
                                                                        handleChange('enable_talent_directory', false)

                                                                    }}
                                                                />
                                                            } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                    </ERadioGroup>
                                                    {errors?.enable_talent_directory && values?.enable_talent_directory == null
                                                        ? <EHelperText className='color-text-error-red mt-minus-6px'>  {errors?.enable_talent_directory}</EHelperText> : ''}
                                                </FormControl>
                                            }
                                        >
                                        </CustomizedGrid>

                                        {/* 2nd card : Create custom employee details form */}
                                        <CustomizedGrid label="Create custom employee details form"
                                            smallLabel="Continue with default form on Entera Payroll or customize it based on your organization's requirements."
                                            radioComponent={
                                                <FormControl component="fieldset" {...getFieldProps('talent_custom_field')} >
                                                    <ERadioGroup row={true} aria-label="talent_custom_field" name="talent_custom_field"
                                                        value={values?.talent_custom_field != null ? ReturnBoolean(values?.talent_custom_field) : null}>
                                                        <FormControlLabel value={true}
                                                            disabled={(!allowEdit) ? true : false}
                                                            control={
                                                                <ERadio className=' px-6px'
                                                                    onChange={(e, value) => {
                                                                        handleChange('talent_custom_field', true)
                                                                        HandleDocumentArrayChange();
                                                                    }}
                                                                />
                                                            } label={<span className='font-size-16px'>Yes</span>} />
                                                        <FormControlLabel value={false}
                                                            disabled={(!allowEdit) ? true : false}
                                                            control={
                                                                <ERadio className=' px-6px'
                                                                    onChange={(e, value) => {
                                                                        handleChange('talent_custom_field', false)
                                                                        // setCheckBoxArray(talentSettings?.companyDefaultSetting?.talent_document)
                                                                    }}
                                                                />
                                                            } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                    </ERadioGroup>
                                                    {errors?.talent_custom_field && values?.talent_custom_field == null ?
                                                        <EHelperText className='color-text-error-red mt-minus-6px'>  {errors?.talent_custom_field}</EHelperText> : ''}
                                                </FormControl>
                                            }
                                        >
                                            {ReturnBoolean2(values?.talent_custom_field) ?
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                    <ECardDashboard className={'Bg-card5 border-none shadow-none mt-10px'} sx={{ padding: '18px 24px !important' }}>
                                                        <Grid container >
                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                                {/* INFO: Since this section's design pattern was mostly similar, 
                                                                 its main elements are stored in checkBoxArray state along with document array,
                                                                which is used to loop here to get the desired result*/}

                                                                {/* error for at least one checkbox selection */}
                                                                {errors?.at_least_one_field ?
                                                                    <EHelperText className='color-text-error-red '>  {errors?.at_least_one_field}</EHelperText> : ''}

                                                                {checkBoxArray && checkBoxArray?.length > 0 && checkBoxArray?.map((row, index) => {
                                                                    const { array, id, name } = row;
                                                                    return (
                                                                        row?.array && array?.length > 0 ?// checking if the array exists and have values in it or not
                                                                            <Grid container key={index + name}>
                                                                                {/* Heading of checkboxes */}
                                                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='pt-16px'>
                                                                                    <span className='bold-600 theme-color-static'>{name}</span>
                                                                                </Grid>
                                                                                {/* looping over array for the checkboxes */}
                                                                                {array?.map((childRow, childIndex) => {
                                                                                    const { document_name, label, is_required, smallText } = childRow;
                                                                                    const finalLabel = (id == DOCUMENT_ID ? document_name : label);
                                                                                    return (
                                                                                        <CheckboxGrid key={index + childIndex + 'personal'}>{/* grid items */}
                                                                                            {/* Checkboxes */}
                                                                                            <FormControlLabel
                                                                                                disabled={(!allowEdit) ? true : false}
                                                                                                control={
                                                                                                    <ECheckbox
                                                                                                        checked={is_required ? true : false}
                                                                                                        onChange={(e, value) => {
                                                                                                            HandleDocumentArrayChange(id, childIndex, value ? 1 : 0)
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                                className=' width-100'
                                                                                                label={finalLabel}
                                                                                            />
                                                                                            {smallText ?
                                                                                                <div className='pl2rem font-size-12px  greyColor4-color mt-minus-10px'>{smallText}</div> : ''
                                                                                            }
                                                                                        </CheckboxGrid>
                                                                                    )
                                                                                })}
                                                                            </Grid>
                                                                            : <></>)
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </ECardDashboard>
                                                </Grid>
                                                : ''}
                                        </CustomizedGrid>

                                        {/* 3rd card : Employee Code Prefix */}
                                        <CustomizedGrid label="Employee Code Prefix"
                                            smallLabel="Set the prefix for the employee code for your organization."
                                            radioComponent={
                                                <FormControl component="fieldset" {...getFieldProps('enable_talent_code_prefix')} >
                                                    <ERadioGroup row={true} aria-label="enable_talent_code_prefix" name="enable_talent_code_prefix"
                                                        value={values?.enable_talent_code_prefix != null ? ReturnBoolean(values?.enable_talent_code_prefix) : null}>
                                                        <FormControlLabel value={true}
                                                            disabled={(!allowEdit) ? true : false}
                                                            control={
                                                                <ERadio className=' px-6px'
                                                                    onChange={(e, value) => {
                                                                        handleChange('enable_talent_code_prefix', true)
                                                                    }}
                                                                />
                                                            } label={<span className='font-size-16px'>Yes</span>} />
                                                        <FormControlLabel value={false}
                                                            disabled={(!allowEdit) ? true : false}
                                                            control={
                                                                <ERadio className=' px-6px'
                                                                    onChange={(e, value) => {
                                                                        handleChange('enable_talent_code_prefix', false)
                                                                        handleChange('talent_code_prefix', '')
                                                                    }}
                                                                />
                                                            } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                    </ERadioGroup>
                                                    {errors?.enable_talent_code_prefix && values?.enable_talent_code_prefix == null ?
                                                        <EHelperText className='color-text-error-red mt-minus-6px'>  {errors?.enable_talent_code_prefix}</EHelperText> : ''}
                                                </FormControl>
                                            }
                                        >
                                            {ReturnBoolean2(values?.enable_talent_code_prefix) ?
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                    <ECardDashboard className={'Bg-card5 border-none shadow-none mt-10px'} sx={{ padding: '18px 24px !important' }}>
                                                        <Grid container >
                                                            <Grid item xl={5} lg={6} md={6} sm={6} xs={12}>
                                                                <ELabelInputMainGrid label={'Code Prefix'} isModal={true} labelPT={'0.5rem'}>
                                                                    <ETextFieldSmall
                                                                        name='talent_code_prefix'
                                                                        placeholder="Code Prefix"
                                                                        {...getFieldProps('talent_code_prefix')}
                                                                        fullWidth
                                                                        inputProps={{ maxLength: 3 }}
                                                                        error={Boolean(touched.talent_code_prefix && errors.talent_code_prefix)}
                                                                        helperText={touched.talent_code_prefix && errors.talent_code_prefix}
                                                                        disabled={!allowEdit}
                                                                    />
                                                                </ELabelInputMainGrid>
                                                            </Grid>
                                                        </Grid>
                                                    </ECardDashboard>
                                                </Grid>
                                                : ''}
                                        </CustomizedGrid>

                                    </Grid>
                                    {
                                        company?.companyData?.onBoarding?.onboarding_is_completed
                                            ?
                                            allowEdit &&
                                            <EBox display={"flex"} justifyContent={"space-between"}>
                                                <EButtonOutlined variant="outlined" onClick={handleDefaultApply}>
                                                    Apply Default Setting
                                                </EButtonOutlined>
                                                <EBox>
                                                    {
                                                        isFormChanged &&
                                                        <EButtonOutlined variant="outlined" onClick={handleCancel}>
                                                            Cancel
                                                        </EButtonOutlined>
                                                    }
                                                    <ELoadingButton
                                                        disabled={!isFormChanged}
                                                        type='submit'
                                                        variant="contained"
                                                        size="large"
                                                        className='button-left-margin font-size-14px'
                                                    >
                                                        Save
                                                    </ELoadingButton>
                                                </EBox>
                                            </EBox>
                                            :
                                            <Stack direction="row" className='justify-content-flex-end '>
                                                <ELoadingButton type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                                                    Save & Next
                                                </ELoadingButton>

                                            </Stack>
                                    }
                                </EBoxPage>
                            }
                        </Grid>

                    </Grid>
                </Form>
            </FormikProvider>
            {/* INFO: MODAL to confirm form save */}
            <EModal open={modal?.open} headervalue={modal?.header}>
                {modal?.body}
                <Stack direction='row' spacing={2} paddingTop={2} className='modal1-buttons-stick-bottom'>
                    <EButtonOutlined size="large" variant="outlined" onClick={closeModal} color="secondary">No</EButtonOutlined>
                    <ELoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={modal?.onClick}
                    >
                        Yes
                    </ELoadingButton>
                </Stack>
            </EModal>
        </Page>
    );
}
export default TalentSettings

const CustomizedGrid = (props) => {
    const { label, children, smallLabel = null, radioComponent = null } = props
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <ECardBasic className=' border-05px-border6 mb-0px' >
                <Grid container >
                    <Grid item xl={10.5} lg={10} md={10.2} sm={9} xs={8}>
                        <span className='bold-600 font-size-18px '>{label}</span>
                        <p className=' greyColor4-color'>{smallLabel ? smallLabel : ''}</p>
                    </Grid>
                    {radioComponent ? //INFO: This grid if for the radio component
                        <Grid item xs={4} sm={3} md={1.8} lg={2} xl={1.5} align='right'>
                            {radioComponent}
                        </Grid>
                        : ''}
                    {children}
                </Grid>
            </ECardBasic>
        </Grid>
    )
}

const CheckboxGrid = (props) => {
    const { children } = props
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} >
            {children}
        </Grid>
    )
}
